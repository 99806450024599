import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeLoader } from "@/Components/LimeLoader";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { api } from "@/lib/api-client";
import type { GetCategories } from "@/server-types";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  DndContext,
  type DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ActionIcon, Button, Divider, Drawer, Flex, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { type CSSProperties, useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { LuArrowDown, LuArrowUp } from "react-icons/lu";
import { RiDraggable, RiListUnordered } from "react-icons/ri";
import { useNavigate } from "react-router";

export const ServiceCategories = () => {
  const navigate = useNavigate();

  const {
    data: categories,
    isPending: categoriesIsPending,
    refetch: refetchCategories,
  } = api.category.useGetCategories({});

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { isOpen: isSidebarOpen } = useSidebarStore((state) => state);

  useEffect(() => {
    if (categories) {
      setSortedCategories(categories.map((c) => ({ ...c, id: c.tagId })));
    }
  }, [categories]);

  const onCategoryClick = (categoryId: number) => {
    navigate(`/dashboard/services/categories/${categoryId}`);
  };

  const [sortedCategories, setSortedCategories] = useState(
    categories?.map((c) => ({ ...c, id: c.tagId })) || [],
  );

  const [mode, setMode] = useState<"view" | "edit">("view");

  const { mutateAsync: saveOrder } = api.category.usePostCategorySortOrder(
    sortedCategories?.map((c) => c.tagId) || [],
  );

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      setSortedCategories((items) => {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <>
      <LimePageHeader
        title={t`Storitve`}
        rightSection={{
          label: t`Dodaj kategorijo`,
          onClick: () => onCategoryClick(-1),
          identifier: "add-category",
        }}
      />

      <LimePageTabs
        activeTab={"categories"}
        flexDirection={"row"}
        rightSection={
          <Flex
            gap={"md"}
            align={"center"}
            p={isMobile ? "xs" : 0}
            direction={"row"}
          >
            {isMobile ? (
              <ActionIcon
                onClick={openDrawer}
                style={{
                  flexShrink: 0,
                }}
              >
                <RiListUnordered
                  size={20}
                  style={{
                    flexShrink: 0,
                  }}
                  color={mode === "view" ? "black" : Color.Brand}
                />
              </ActionIcon>
            ) : (
              <Button
                variant="light"
                radius={20}
                size="xs"
                style={{
                  flexShrink: 0,

                  outline:
                    mode === "edit"
                      ? "2px solid var(--mantine-color-brand-light-color)"
                      : undefined,
                }}
                onClick={() =>
                  setMode((prev) => (prev === "view" ? "edit" : "view"))
                }
              >
                <Trans>Uredi zaporedje</Trans>
              </Button>
            )}
          </Flex>
        }
        onChangeTab={async (tab) => {
          if (tab === "services") {
            navigate("/dashboard/services");
          } else {
            await refetchCategories();
          }
        }}
        tabs={[
          {
            label: t`Vse storitve`,
            value: "services",
          },
          {
            label: t`Kategorije`,
            value: "categories",
          },
        ]}
      />

      {isMobile ? <Divider /> : undefined}

      {categoriesIsPending ? <LimeLoader /> : undefined}

      <Flex direction={"column"} mt={"md"} px={isMobile ? undefined : "xl"}>
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
          sensors={sensors}
        >
          <SortableContext
            items={sortedCategories}
            strategy={verticalListSortingStrategy}
          >
            <div>
              {sortedCategories.map((category) => (
                <CategoryListItem
                  key={category.tagId}
                  category={category}
                  onClick={() => onCategoryClick(category.tagId)}
                  id={category.id}
                  mode={mode}
                  onMoveItemDirection={(direction) => {
                    const index = sortedCategories.findIndex(
                      (c) => c.id === category.id,
                    );
                    if (direction === "up") {
                      setSortedCategories((items) =>
                        arrayMove(items, index, index - 1),
                      );
                    } else {
                      setSortedCategories((items) =>
                        arrayMove(items, index, index + 1),
                      );
                    }
                  }}
                  isFirstItem={sortedCategories[0].id === category.id}
                  isLastItem={
                    sortedCategories[sortedCategories.length - 1].id ===
                    category.id
                  }
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </Flex>
      {mode === "edit" && (
        <Flex
          pos={"fixed"}
          bottom={0}
          left={isMobile ? 0 : isSidebarOpen ? 270 : 60}
          w={isMobile ? "100%" : `calc(100% - ${isSidebarOpen ? 270 : 60}px)`}
          justify={"center"}
          pt={15}
          pb={15}
          bg={"white"}
          gap={15}
        >
          <Button
            onClick={() => {
              setMode("view");
              setSortedCategories(
                categories?.map((c) => ({ ...c, id: c.tagId })) || [],
              );
            }}
            variant="outline"
            c={Color.SecondaryText}
            styles={{
              root: {
                borderColor: Color.SecondaryText,
              },
            }}
          >
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={async () => {
              try {
                await saveOrder();
                setMode("view");

                notifications.show({
                  message: t`Vrstni red storitev je usprešno posodobljen`,
                  color: "green",
                });
              } catch (e) {
                notifications.show({
                  message: t`Napaka pri posodabljanju`,
                  color: "red",
                });
              }
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      )}

      {isMobile ? (
        <>
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            position="bottom"
            size={"sm"}
            styles={{
              content: {
                borderRadius: "16px 16px 0 0",
              },
            }}
            title={t`Nastavitve`}
          >
            <Flex>
              <Button
                leftSection={<RiListUnordered />}
                variant="transparent"
                onClick={() => {
                  setMode((prev) => (prev === "view" ? "edit" : "view"));
                  closeDrawer();
                }}
              >
                <Text c={Color.SecondaryText}>
                  <Trans>Uredi zaporedje</Trans>
                </Text>
              </Button>
            </Flex>
          </Drawer>
        </>
      ) : null}
    </>
  );
};

function CategoryListItem({
  category,
  onClick,
  id,
  mode,
  onMoveItemDirection,
  isFirstItem,
  isLastItem,
}: {
  category: GetCategories["response"][number];
  onClick?: () => void;
  id: number;
  mode: "view" | "edit";
  onMoveItemDirection: (direction: "up" | "down") => void;
  isFirstItem?: boolean;
  isLastItem?: boolean;
}) {
  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  // const style = transform
  //   ? {
  //       transform: `translate3d(0, ${transform.y}px, 0)`,
  //       transition,
  //       zIndex: 100,
  //     }
  //   : {};

  const style: CSSProperties = {
    opacity: isDragging ? 0.75 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : undefined,
  };

  return (
    <div
      style={style}
      {...(mode === "edit" ? attributes : undefined)}
      {...(mode === "edit" ? listeners : undefined)}
      ref={setNodeRef}
    >
      <Button
        variant="white"
        c={Color.PrimaryText}
        justify={"space-between"}
        h={"50px"}
        px={"15px"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
          // ...style,
        }}
        fullWidth
        styles={{
          label: {
            justifyContent: "space-between",
            width: "100%",
            cursor: mode === "edit" ? "grab" : "pointer",
          },
        }}
        onClick={mode === "edit" ? undefined : onClick}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          {category.TagNameLocalized.find(
            (tl) => tl.language === defaultLanguage,
          )?.name ||
            category.TagNameLocalized.find((tl) => tl.language === "sl")
              ?.name ||
            category.TagNameLocalized.find((tl) => tl.name !== "")?.name ||
            category.tagName ||
            ""}
        </Text>
        {mode === "view" ? (
          <BiChevronRight />
        ) : (
          <Flex direction={"row"} align={"center"} style={{ flexGrow: 0 }}>
            {!isMobile && !isFirstItem ? (
              <ActionIcon onClick={() => onMoveItemDirection("up")}>
                <LuArrowUp />
              </ActionIcon>
            ) : null}
            <RiDraggable />
            {!isMobile && !isLastItem ? (
              <ActionIcon onClick={() => onMoveItemDirection("down")}>
                <LuArrowDown />
              </ActionIcon>
            ) : null}
          </Flex>
        )}
      </Button>
    </div>
  );
}
