import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { Flex, Table, Text } from "@mantine/core";
import dayjs from "dayjs";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ContentType } from "recharts/types/component/Tooltip";

export const NotificationsTab = ({ clientId }: { clientId: number }) => {
  const { data } = api.admin.useClientNotifications(clientId, {
    startDate: "2024-12-01",
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
  });

  const customTooltip = ({
    active,
    payload,
  }: {
    active: boolean;
    payload: { payload: { [key: string]: number } }[];
  }) => {
    if (!data) return null;

    if (active && payload && payload.length) {
      const tooltipData = payload[0].payload;

      return (
        <Flex
          direction={"column"}
          bg={"white"}
          p={10}
          style={{
            boxShadow: " 0px 5px 20px 0px rgba(166, 166, 166, 0.3)",
            borderRadius: 8,
          }}
        >
          <Table>
            <Table.Tr>
              <Table.Th>Obdobje</Table.Th>
              <Table.Td>{tooltipData.label}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Število sms sporočil</Table.Th>
              <Table.Td>{tooltipData.count}</Table.Td>
            </Table.Tr>
            {tooltipData.count > data.smsLimit ? (
              <Table.Tr>
                <Table.Th>Prekoračen limit</Table.Th>
                <Table.Td>{tooltipData.count - data.smsLimit}</Table.Td>
              </Table.Tr>
            ) : (
              <></>
            )}
          </Table>
        </Flex>
      );
    }
  };

  return (
    <Flex direction={"column"} gap={30}>
      <Text variant={TextVariant.Subheading} c="#6b8f72">
        Limit: {data?.smsLimit}{" "}
      </Text>
      <ResponsiveContainer width={"100%"} height={250}>
        {data ? (
          <LineChart
            width={730}
            data={data?.notificationsPerBillingCycle || []}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" type="category" />
            <YAxis />
            <Tooltip
              content={customTooltip as ContentType<"string", "string">}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              dot={false}
            />

            <ReferenceLine
              y={data?.smsLimit || 0}
              stroke="red"
              strokeDasharray="3 3"
            >
              {" "}
            </ReferenceLine>
          </LineChart>
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </Flex>
  );
};
