import { t } from "@lingui/core/macro";
import { LimeLoader } from "@/Components/LimeLoader";
import { LimeSwitch } from "@/Components/LimeSwitch";
import TimeSpanRow from "@/Components/TimeSpanRow";
import { daysOfWeek } from "@/constants/daysOfWeek";
import { api } from "@/lib/api-client";
import { PostResourceGroupSchedule } from "@/server-types";
import { Box, Collapse, Divider, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router";
import { TimeType } from "../../../../../../shared/types/time-type";
import { ActionButton } from "../Components/ActionButton";
import useManageResourceGroup from "../useManageResourceGroup";

enum DayOfWeek {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}

type Schedule = {
  dayOfWeek: DayOfWeek;
  timeFrom: "" | TimeType;
  timeTo: "" | TimeType;
  isEnabled: boolean;
  isEnabledForm: boolean;
};

export const ScheduleTab = () => {
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();

  const selectedResourceGroupId = id ? parseInt(id) : undefined;
  const isEditing =
    selectedResourceGroupId != -1 && searchParams.get("onboarding") == null;

  const { navigateToTab, handleBackButton } = useManageResourceGroup();

  const {
    data: resourceGroupScheduleData,
    isPending: isFetchingResourceGroupSchedule,
    isError: isFetchingResourceGroupScheduleError,
  } = api.resourceGroups.useGetResourceGroupSchedule(selectedResourceGroupId);

  const {
    mutateAsync: postResourceGroupSchedule,
    isPending: isPostingResourceGroupSchedule,
  } = api.resourceGroups.usePostResourceGroupSchedule();

  const form = useForm<{
    isScheduleEnabled: boolean;
    schedules: Schedule[];
  }>({
    initialValues: {
      isScheduleEnabled: false,
      schedules: [],
    },

    validate: {
      schedules: {
        timeFrom: (value: string, values, path) => {
          const index = parseInt(path.split(".")[1]);
          const schedule = values.schedules[index];

          if (schedule.isEnabled && !value) {
            return t`Obvezen podatek`;
          }

          return null;
        },
        timeTo: (value: string, values, path) => {
          const index = parseInt(path.split(".")[1]);
          const schedule = values.schedules[index];

          if (schedule.isEnabled && !value) {
            return t`Obvezen podatek`;
          }

          return null;
        },
      },
    },
  });

  useEffect(() => {
    if (isFetchingResourceGroupScheduleError) {
      handleBackButton({});
      return;
    }

    const existingSchedules =
      resourceGroupScheduleData?.schedules?.map((schedule) => {
        return {
          ...schedule,
          dayOfWeek: schedule.dayOfWeek as unknown as DayOfWeek,
        };
      }) || [];
    const missingSchedules = [...Array<DayOfWeek>(7).keys()].filter(
      (day) =>
        !existingSchedules.some(
          (s) => s.dayOfWeek === Object.values(DayOfWeek)[day],
        ),
    );

    const newSchedules = missingSchedules.map((day) => {
      const schedule: Schedule = {
        dayOfWeek: Object.values(DayOfWeek)[day] as DayOfWeek,
        timeFrom: "",
        timeTo: "",
        isEnabled: false,
        isEnabledForm: false,
      };

      return schedule;
    });

    const schedules = [...existingSchedules, ...newSchedules];

    const orderedSchedules = schedules.sort((a, b) => {
      const aIndex = Object.values(DayOfWeek).indexOf(a.dayOfWeek);
      const bIndex = Object.values(DayOfWeek).indexOf(b.dayOfWeek);

      return aIndex - bIndex;
    });

    form.setValues({
      isScheduleEnabled: resourceGroupScheduleData?.isScheduleEnabled,
      schedules: orderedSchedules,
    });
  }, [resourceGroupScheduleData, isFetchingResourceGroupScheduleError]);

  const toggleDay = (index: number, enabled: boolean) => {
    form.setFieldValue(`schedules.${index}.isEnabled`, !enabled);
  };

  const handleSubmit = async (values: {
    schedules: Schedule[];
    isScheduleEnabled: boolean;
  }) => {
    if (!selectedResourceGroupId) return;

    try {
      postResourceGroupSchedule({
        resourceGroupId: selectedResourceGroupId,
        body: {
          schedules: values.schedules.map((schedule) => {
            const dayOfWeek =
              schedule.dayOfWeek as unknown as PostResourceGroupSchedule["body"]["schedules"][0]["dayOfWeek"];

            if (!values.isScheduleEnabled) {
              schedule.isEnabled = false;
            }

            if (schedule.isEnabled) {
              return {
                ...schedule,
                isEnabled: true,
                dayOfWeek,
              };
            }

            return {
              ...schedule,
              isEnabled: false,
              isEnabledForm: false,
              dayOfWeek,
            };
          }),
          isScheduleEnabled: values.isScheduleEnabled,
        },
      });

      if (!isEditing) {
        navigateToTab({
          currentTab: "schedule",
          direction: "next",
          resourceGroupId: selectedResourceGroupId,
        });
      } else {
        notifications.show({
          message: t`Urnik sredstva uspešno posodobljen`,
          color: "green",
        });
      }
    } catch (e) {
      notifications.show({
        message: t`Napaka pri posodabljanju urnika.`,
        color: "red",
      });
    }
  };

  if (selectedResourceGroupId !== -1 && isFetchingResourceGroupSchedule) {
    return <LimeLoader />;
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <div className="mt-4 flex flex-col pb-20">
        <LimeSwitch
          label={t`Urnik po meri`}
          // toolTip={t`placeholder`}
          {...form.getInputProps("isScheduleEnabled", {
            type: "checkbox",
          })}
          size="md"
          px={"md"}
        />

        <Divider w={"100%"} my={"md"} />

        <Collapse in={form.values.isScheduleEnabled}>
          {form.values.schedules?.map((schedule, index) => {
            const scheduleLabel = daysOfWeek().find(
              (d) => d.value === schedule.dayOfWeek.toString(),
            )?.label;

            return (
              <React.Fragment key={schedule.dayOfWeek}>
                <Flex direction={"column"} align={"flex-start"} w={"100%"}>
                  <LimeSwitch
                    label={scheduleLabel}
                    {...form.getInputProps(`schedules.${index}.isEnabled`, {
                      type: "checkbox",
                    })}
                    onChange={() => toggleDay(index, schedule.isEnabled)}
                    size="md"
                    px={"md"}
                  />

                  <Collapse in={schedule.isEnabled} w={"100%"} px={"md"}>
                    <Box mt={"sm"}>
                      <TimeSpanRow
                        fromTimeValue={schedule.timeFrom}
                        toTimeValue={schedule.timeTo}
                        onFromTimeChange={(value: string) => {
                          form.setFieldValue(
                            `schedules.${index}.timeFrom`,
                            value,
                          );
                        }}
                        onToTimeChange={(value: string) => {
                          form.setFieldValue(
                            `schedules.${index}.timeTo`,
                            value,
                          );
                        }}
                        minTime={"00:00"}
                        maxTime={"23:59"}
                        allowAnyTime
                        error={
                          (form.errors[`schedules.${index}.timeFrom`] ??
                            form.errors[`schedules.${index}.timeTo`]) as string
                        }
                      />
                    </Box>
                    <LimeSwitch
                      label={t`Naročanje preko spleta`}
                      {...form.getInputProps(
                        `schedules.${index}.isEnabledForm`,
                        {
                          type: "checkbox",
                        },
                      )}
                      size="md"
                      mt={"lg"}
                    />
                  </Collapse>
                </Flex>
                <Divider w={"100%"} my={"md"} />
              </React.Fragment>
            );
          })}
        </Collapse>
      </div>

      <ActionButton
        isLoading={isPostingResourceGroupSchedule}
        text={isEditing ? t`Shrani` : t`Nadaljuj`}
        onBackButtonClick={() => {
          navigateToTab({
            currentTab: "schedule",
            direction: "previous",
            resourceGroupId: selectedResourceGroupId,
          });
        }}
      />
    </form>
  );
};
