import React, { ChangeEvent, ElementType, useCallback, useMemo } from "react";
import { Input, LimeInput } from "./LimeInput";
import { MantineFormValues } from "./shared.types";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input";
import { InputProps, Select, SelectItem, SelectProps } from "@heroui/react";
import { cn } from "@/utils";
import { t } from "@lingui/core/macro";

type LimePhoneInputProps = MantineFormValues &
  Pick<DefaultInputComponentProps, "defaultCountry"> & {
    label?: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
    disablePointerEvents?: boolean;
    variant?: InputProps["variant"];
  };

export const LimePhoneInput = ({ ...props }: LimePhoneInputProps) => {
  return (
    <div>
      <PhoneInput
        {...props}
        disabled={props.isDisabled}
        className={cn("group h-14", {
          "pointer-events-none": props.disablePointerEvents,
        })}
        inputComponent={CustomInput}
        countrySelectComponent={(p) => (
          <CustomCountrySelectWithIcon {...p} variant={props.variant} />
        )}
      />
    </div>
  );
};

const CustomInput = React.forwardRef(
  (props: Input, ref: React.Ref<HTMLInputElement>) => (
    <LimeInput
      ref={ref}
      {...props}
      autoComplete="tel"
      variant={props.variant ?? "bordered"}
      classNames={{
        mainWrapper: "h-14",
        inputWrapper: "h-14",
        base: "h-14",
      }}
      error={props.error}
      errorMessage={undefined}
    />
  ),
);
CustomInput.displayName = "CustomInput";

const CustomCountrySelectWithIcon = ({
  value,
  options,
  iconComponent: Icon,
  arrowComponent,
  unicodeFlags,
  ...rest
}: CustomCountrySelectWithIconProps) => {
  const selectedOption = useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <div className="PhoneInputCountry">
      <CustomCountrySelect
        {...rest}
        value={value}
        options={options}
        className={"PhoneInputCountrySelect"}
        disabled={rest.disabled}
      />

      {/* Either a Unicode flag icon. */}
      {unicodeFlags && value && (
        <div className="PhoneInputCountryIconUnicode">
          {getUnicodeFlagIcon(value)}
        </div>
      )}

      {/* Or an SVG flag icon. */}
      {!(unicodeFlags && value) && Icon && (
        <div
          className={cn(
            "flex h-14 w-14 items-center justify-center rounded-medium border-2 border-default-200 group-[.PhoneInput--focus]:border-default-foreground",
            {
              "border-0 bg-default-100": rest.variant === "flat",
              "opacity-disabled": rest.disabled,
            },
          )}
        >
          <Icon
            aria-hidden
            country={value}
            label={selectedOption && selectedOption.label}
            aspectRatio={unicodeFlags ? 1 : undefined}
          />
        </div>
      )}
    </div>
  );
};

type CustomCountrySelectWithIconProps = CustomCountrySelectProps & {
  iconComponent?: ElementType;
  arrowComponent?: ElementType;
  unicodeFlags?: boolean;
};

const CustomCountrySelect = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  disabled,
  readOnly,
  tabIndex,
  className,
  variant,
}: CustomCountrySelectProps) => {
  const onChange_ = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      onChange(value === "ZZ" ? undefined : value);
    },
    [onChange],
  );

  return (
    <Select
      label={name}
      selectedKeys={value ? [value] : ["ZZ"]}
      variant={variant ?? "bordered"}
      onChange={onChange_}
      onFocus={onFocus}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      aria-readonly={readOnly}
      tabIndex={tabIndex ? Number(tabIndex) : undefined}
      className={cn(className, "data-[focus=true]:border-default-foreground")}
      isDisabled={disabled}
      classNames={{
        popoverContent: "w-fit",
        listboxWrapper: "min-w-52 w-fit",
      }}
      selectionMode="single"
      disallowEmptySelection
      aria-label={t`Izberi državo`}
    >
      {options.map(({ value, label, divider }) => (
        <SelectItem
          key={divider ? "|" : value || "ZZ"}
          textValue={divider ? "|" : value || "ZZ"}
          startContent={getUnicodeFlagIcon(value || "ZZ")}
        >
          {label}
        </SelectItem>
      ))}
    </Select>
  );
};

type CustomCountrySelectProps = {
  name?: string;
  value?: string; // Two letter country code (e.g. "US", "RU")
  onChange: (value?: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  options: {
    value?: string;
    label: string;
    divider?: boolean;
  }[];
  disabled?: boolean;
  readOnly?: boolean;
  tabIndex?: number | string;
  className?: string;
  variant?: SelectProps["variant"];
};

function getSelectedOption(
  options: {
    value?: string;
    label: string;
    divider?: boolean;
  }[],
  value?: string,
) {
  for (const option of options) {
    if (!option.divider && option.value === value) {
      return option;
    }
  }
}
