import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import { useState } from "react";
import {
  RiBuilding4Line,
  RiMapPin2Fill,
  RiMoneyDollarCircleFill,
  RiUserAddFill,
} from "react-icons/ri";
import styled from "styled-components";
import { TextVariant } from "@/types/text-variants";
import { Flex, Table, Text } from "@mantine/core";
import AppointmentActions from "../AppointmentActions";
import { getLocalizedName } from "@/utils";
import { i18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { RiHistoryLine } from "react-icons/ri";
import { Button } from "@heroui/react";
import { Modal } from "@mantine/core";
import {
  getAppointmentCancelReasonOptions,
  getUserAppointmentCancelReasonOptions,
} from "@/constants/cancelReasons";

import AppointmentLog from "@/pages/Dashboard/Calendar/components/calendar/AppointmentLog/AppointmentLog";

import AppointmentCancelModal from "../../../Calendar/components/calendar/AppointmentActionsDropdown/AppointmentCancelModal";
import { useDisclosure } from "@mantine/hooks";
import RemoveCustomerFromAppointmentModal from "@/Components/AppointmentModal/components/RemoveCustomerFromAppointmentModal";

const CustomerAppointment = (props) => {
  const {
    appointment,
    isMobile,
    index,
    deleteUserAppointment,
    updateUserAppointmentAttendance,
    selectedAppointmentType,
    refreshCustomerData,
    currencySymbol,
    listRef,
  } = props;

  const [showFullComment, setShowFullComment] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(props.appointment.comment);
  const [
    appointmentCancelModalOpened,
    { open: openAppointmentCancelModal, close: closeAppointmentCancelModal },
  ] = useDisclosure(false);

  const [
    removeCustomerFromAppointmentModalOpened,
    {
      open: openRemoveCustomerFromAppointmentModal,
      close: closeRemoveCustomerFromAppointmentModal,
    },
  ] = useDisclosure(false);

  const [
    appointmentLogOpened,
    { open: openAppointmentLog, close: closeAppointmentLog },
  ] = useDisclosure(false);

  const maxCommentLength = 100;
  const isCommentTooLong = appointment?.comment?.length > maxCommentLength;
  const commentHasTooManyNewLines =
    (appointment?.comment?.match(/\n/g) || []).length > 4;

  let comment = appointment?.comment;
  if (comment && !showFullComment) {
    if (commentHasTooManyNewLines) {
      comment = comment.substring(0, maxCommentLength / 3) + " [...]";
    } else if (isCommentTooLong) {
      comment = comment.substring(0, maxCommentLength) + " [...]";
    }
  }

  const { locale } = i18n;
  useLingui();

  console.log("Appointment in CustomerAppointment", appointment);
  const appointmentCancelReasonOptions = getAppointmentCancelReasonOptions();
  const appointmentCancelReasonLabel =
    appointment.appointmentCancelReason === "Custom"
      ? appointment.customAppointmentCancelReason
      : appointmentCancelReasonOptions.find(
          (opt) => opt.value === appointment.appointmentCancelReason,
        )?.label;

  const customerAppointmentCancelReasonOptions =
    getUserAppointmentCancelReasonOptions();

  const customerAppointmentCancelReasonLabel =
    appointment.customerAppointmentCancelReason === "Custom"
      ? appointment.customCustomerAppointmentCancelReason
      : customerAppointmentCancelReasonOptions.find(
          (opt) => opt.value === appointment.customerAppointmentCancelReason,
        )?.label;

  return (
    <div
      style={{
        width: "100%",
        padding: props.isMobile ? "0.5rem 0.5rem" : "1rem 1rem",
        borderRadius: "12px",
        backgroundColor: "#ebebeb80",
        border: props.appointment.isPreScheduled
          ? "2px dashed #f5a623"
          : "none",
      }}
    >
      <div>
        {appointment.appointmentIsDeleted && (
          <Text fw={"500"} c={"red"}>
            <Trans>Razlog za odpoved:</Trans> {appointmentCancelReasonLabel}
          </Text>
        )}

        {!appointment.appointmentIsDeleted &&
          appointment.userAppointmentIsDeleted && (
            <Text fw={"500"} c={"red"}>
              <Trans>Razlog za odstranitev stranke s termina:</Trans>{" "}
              {customerAppointmentCancelReasonLabel}
            </Text>
          )}
      </div>

      <AppointmentsListItem isMobile={isMobile} key={index}>
        <AppointmentDateTime isMobile={isMobile}>
          <AppointmentTime isMobile={isMobile}>
            {dayjs(appointment.startTime).format("HH:mm")}
          </AppointmentTime>
          <AppointmentDate isMobile={isMobile}>
            {isMobile
              ? dayjs(appointment.startTime).format("DD. MM")
              : dayjs(appointment.startTime).format("DD. MM. YYYY")}
          </AppointmentDate>
        </AppointmentDateTime>
        <Divider></Divider>
        <AppointmentCancelModal
          appointmentId={appointment.appointmentId}
          isLinked={appointment.linkedAppointmentId}
          isRepeating={appointment.repeatingAppointmentId}
          isOpen={appointmentCancelModalOpened}
          onClose={() => {
            closeAppointmentCancelModal();
            refreshCustomerData();
          }}
        />
        <RemoveCustomerFromAppointmentModal
          appointmentId={appointment.appointmentId}
          isOpen={removeCustomerFromAppointmentModalOpened}
          onClose={() => {
            closeRemoveCustomerFromAppointmentModal();
            refreshCustomerData();
          }}
          userAppointmentId={appointment.userAppointmentId}
          customerId={appointment.customerId}
        />

        <AppointmentDetails>
          <AppointmentService isMobile={isMobile}>
            {appointment.service}
          </AppointmentService>
          <AppointmentDetailsItem>
            <RiMoneyDollarCircleFill />{" "}
            <span>
              {appointment.price} {currencySymbol}
            </span>
          </AppointmentDetailsItem>
          <AppointmentDetailsItem>
            <RiUserAddFill /> <span>{appointment.users}</span>
          </AppointmentDetailsItem>
          {appointment.resources.length > 0 && (
            <AppointmentDetailsItem>
              <RiBuilding4Line />
              <span>{appointment.resources}</span>
            </AppointmentDetailsItem>
          )}
          <AppointmentDetailsItem>
            <RiMapPin2Fill />
            <span>{appointment.location}</span>
          </AppointmentDetailsItem>
          <div style={{ width: "100%" }}>
            <p
              style={{
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".95rem",
                cursor: "default",
              }}
            >
              <Trans>Komentar</Trans>:
            </p>
            {isEditing ? (
              <TextArea
                value={editedComment}
                onChange={(e) => setEditedComment(e.target.value)}
                rows={10}
                cols={30}
              />
            ) : (
              <ApptComment
                onMouseEnter={() => {
                  setShowFullComment(true);
                }}
                onMouseLeave={() => {
                  setShowFullComment(false);
                }}
              >
                {comment || <Trans>Ni komentarja</Trans>}
              </ApptComment>
            )}

            {appointment.customFields &&
              Object.entries(appointment.customFields).length > 0 && (
                <Flex direction={"column"} mt={10}>
                  <Text variant={TextVariant.BodyEmphasized}>
                    <Trans>Dodatne informacije</Trans>
                  </Text>
                  <Table
                    withRowBorders={false}
                    bg={"#f8f8f8"}
                    styles={{ table: { borderRadius: "4px" } }}
                  >
                    {appointment.customFields.map((extraDetail, index) => {
                      const inputElement = (() => {
                        switch (extraDetail.type) {
                          case "multi-select":
                          case "checkbox":
                            return (
                              <Text>
                                {extraDetail.value
                                  .map(
                                    (v) =>
                                      v.defaultLanguage || Object.values(v)[0],
                                  )
                                  .join(", ")}
                              </Text>
                            );
                          case "radio":
                          case "select":
                            return (
                              <Text>
                                {extraDetail.value.defaultLanguage ||
                                  Object.values(extraDetail.value)[0]}
                              </Text>
                            );
                          case "date":
                            return (
                              <Text>
                                {dayjs(extraDetail.value, "MM-DD-YYYYTHH:mm")
                                  .toDate()
                                  .toLocaleString(locale, {
                                    day: "2-digit",
                                    month: "numeric",
                                    year: "numeric",
                                  })}
                              </Text>
                            );
                          case "time":
                            return (
                              <Text>
                                {dayjs(extraDetail.value, "MM-DD-YYYYTHH:mm")
                                  .toDate()
                                  .toLocaleString(locale, {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                              </Text>
                            );
                          default:
                            return <Text>{extraDetail.value}</Text>;
                        }
                      })();

                      return (
                        <Table.Tr key={index}>
                          <Table.Td pt={2} pb={2}>
                            <Text variant={TextVariant.BodyEmphasized}>
                              {getLocalizedName(
                                Object.entries(extraDetail.label).map(
                                  ([lang, value]) => ({
                                    language: lang,
                                    name: value,
                                  }),
                                ),
                              )}
                            </Text>
                          </Table.Td>
                          <Table.Td pt={0} pb={0}>
                            {inputElement}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table>
                </Flex>
              )}
          </div>
        </AppointmentDetails>

        <Flex
          direction="column"
          align="center"
          ml={"auto"}
          mr={"md"}
          gap={"md"}
        >
          {!appointment.appointmentIsDeleted &&
            !appointment.userAppointmentIsDeleted && (
              <AppointmentActions
                selectedAppointmentType={selectedAppointmentType}
                refreshCustomerData={props.refreshCustomerData}
                onClickDelete={() => {
                  if (!appointment.isPreScheduled) {
                    openAppointmentCancelModal();
                  } else {
                    openRemoveCustomerFromAppointmentModal();
                  }
                }}
                listRef={listRef}
                deleteUserAppointment={deleteUserAppointment}
                updateUserAppointmentAttendance={
                  updateUserAppointmentAttendance
                }
                appointment={appointment}
                isMobile={isMobile}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                setEditedComment={setEditedComment}
                updateUserAppointmentComment={() => {
                  props
                    .updateUserAppointmentComment(
                      appointment.userAppointmentId,
                      editedComment,
                    )
                    .then((res) => {
                      if (res.status === 200) {
                        setIsEditing(false);
                      }
                    });
                }}
              />
            )}
          <Button
            variant="bordered"
            startContent={<RiHistoryLine />}
            onClick={() => {
              openAppointmentLog();
            }}
          >
            <Trans>Spremembe</Trans>
          </Button>
        </Flex>

        <Modal
          radius={"lg"}
          title={t`Spremembe`}
          opened={appointmentLogOpened}
          onClose={closeAppointmentLog}
        >
          <AppointmentLog appointmentId={appointment.appointmentId} />
        </Modal>
      </AppointmentsListItem>
    </div>
  );
};

const AppointmentService = styled.h2`
  font-size: ${(props) => (props.isMobile ? "1rem" : "1.2rem")};
  margin-bottom: 0.5rem;
  color: #2f2f2f;
`;
const AppointmentDetailsItem = styled.div`
  font-size: 0.9rem;
  color: #969ba9;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const AppointmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 0.5rem;
`;

const Divider = styled.div`
  width: 2px;
  background: #6c738670;
`;

const AppointmentDate = styled.label`
  white-space: nowrap;
  font-size: ${(props) => (props.isMobile ? "0.8rem" : "1rem")};
`;

const AppointmentTime = styled.label`
  font-size: ${(props) => (props.isMobile ? "1.1rem" : "1.5rem")};
`;

const AppointmentDateTime = styled.div`
  display: flex;
  padding: ${(props) => (props.isMobile ? "0.1rem" : " 0.5rem 0")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  min-width: ${(props) => !props.isMobile && "100px"};
`;

const AppointmentsListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.isMobile ? "0.5rem" : "0.8rem")};
`;

const ApptComment = styled.p`
  white-space: pre-wrap;
  font-size: 0.8rem;
  line-break: anywhere;
  padding: 0;
  margin: 0;
  cursor: default;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  padding: 0.5rem;
  border-radius: 8px;
  font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.8rem;
`;

export default CustomerAppointment;
