import { keepPreviousData } from "@tanstack/react-query";
import {
  GetUsers,
  GetUserHasPin,
  PostUserPin,
  PostVerifyUserPin,
  GetUserPermissions,
} from "../../../../../server/src/types";

import { get, post } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

/**
 * GET /user
 */
export const useUserList = (query?: GetUsers["query"]) => {
  return useCustomQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetUsers>("/user", query),
    placeholderData: keepPreviousData,
    retry: true,
  });
};

export const useGetUserHasPin = (query: GetUserHasPin["query"]) => {
  return useCustomQuery({
    queryKey: ["user-has-pin", query],
    queryFn: () => get<GetUserHasPin>(`/user/has-pin`, query),
    enabled: !!query.userId,
  });
};

export const usePostUserPIN = () => {
  return useCustomMutation({
    mutationKey: ["post-user-pin"],
    mutationFn: (body: PostUserPin["body"]) =>
      post<PostUserPin>("/user/pin", body),
  });
};

export const useVerifyUserPIN = () => {
  return useCustomMutation({
    mutationKey: ["verify-user-pin"],
    mutationFn: (body: PostVerifyUserPin["body"]) =>
      post<PostVerifyUserPin>("/user/pin/verify", body),
  });
};

export const useGetUserPermissions = ({
  query,
  disabled,
}: {
  query: GetUserPermissions["query"];
  disabled?: boolean;
}) => {
  return useCustomQuery({
    queryKey: ["user-permissions"],
    queryFn: () => get<GetUserPermissions>("/user/permissions", query),
    enabled: !disabled,
  });
};
