import { PostNextServiceWarnings } from "@/server-types";
import { useCustomQuery } from "../custom-query";
import { post } from "../request-helpers";

export const useGetNextServiceWarnings = (
  body: PostNextServiceWarnings["body"],
) => {
  return useCustomQuery({
    queryKey: ["getNextServiceWarnings", body],
    queryFn: () =>
      post<PostNextServiceWarnings>("/calendar/service/next-warnings", body),
  });
};
