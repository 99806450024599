import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import { Button, Skeleton, useDisclosure } from "@heroui/react";
import { CirclePlus } from "lucide-react";
import { useNavigate } from "react-router";
import { ElectronicDeviceCard } from "./ElectronicDeviceCard";
import { ManageElectronicDeviceModal } from "./ManageElectronicDeviceModal";
import { useState } from "react";
import { TaxRegisterAuthorizationDialog } from "../../AuthorizationDialog";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const TaxRegisterSetupElectronicDevice = () => {
  const navigate = useNavigate();

  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(
    undefined,
  );

  const {
    isOpen: isManageModalOpen,
    onOpen: onOpenManageModal,
    onOpenChange: onOpenManageModalChange,
  } = useDisclosure();

  const {
    data: devices,
    isPending,
    isSuccess,
    refetch: refetchElectronicDevices,
    processedErrorMessage,
  } = api.taxRegister.useGetOrganizationElectronicDevice({});

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchElectronicDevices();
        }}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Vzpostavitev podjetja`}
        subPage={{
          title: t`Elektronske naprave`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex flex-col px-4 md:px-8">
        <p className="text-sm">
          <Trans>
            Kot elektronske naprave so označeni posamezni računalniki
            (blagajne), preko katerih se v poslovnih prostorih izdajajo računi.
          </Trans>
        </p>

        <LimeAlert color="danger" message={processedErrorMessage} />

        <p className="mb-4 mt-4 font-semibold">
          <Trans>Seznam elektronskih naprav</Trans>
        </p>

        {isSuccess && devices.length === 0 && (
          <p className="pb-4 text-sm">
            <Trans>Nimate elektronskih naprav</Trans>
          </p>
        )}

        {isPending &&
          Array.from({ length: 2 }).map(() => (
            <Skeleton
              key={Math.random()}
              className="mb-4 h-24 w-full rounded-xl"
            />
          ))}
        {isSuccess &&
          devices.length > 0 &&
          devices.map((device) => (
            <ElectronicDeviceCard
              key={device.id}
              electronicDevice={device}
              handleEditClick={() => {
                setSelectedDeviceId(device.id);
                onOpenManageModal();
              }}
            />
          ))}

        <Button
          className="w-fit"
          startContent={<CirclePlus />}
          variant="light"
          onPress={() => {
            setSelectedDeviceId(undefined);
            onOpenManageModal();
          }}
          isDisabled={isPending}
        >
          <Trans>Dodaj elektronsko napravo</Trans>
        </Button>
      </div>

      <ManageElectronicDeviceModal
        onOpenCreateModalChange={(deviceId) => {
          setSelectedDeviceId(undefined);
          onOpenManageModalChange();

          if (deviceId) {
            refetchElectronicDevices();
          }
        }}
        isManageModalOpen={isManageModalOpen}
        electronicDevice_id={selectedDeviceId}
      />
    </>
  );
};
