import { t } from "@lingui/core/macro";
import { I18n, i18n } from "@lingui/core";
import { formatCurrency } from "../../../../shared/utils/utils";
import {
  GiftCardDocument,
  GiftCardReceiptDocument,
  GiftCardTransactionAccountDocument,
  InvoiceDocument,
  PrinterConfig,
  TransactionAccountReceiptDocument,
  PaymentType,
} from "./types";
import {
  buildHtmlQrCode,
  buildUpnQrCodeString,
  getPaymentTypeLabel,
} from "./utils";
import dayjs from "dayjs";

export async function renderInvoiceToHtml({
  invoiceData: invoice,
  printerConfig,
  isCopy,
}: {
  invoiceData: InvoiceDocument["invoiceData"];
  printerConfig: PrinterConfig;
  isCopy: boolean;
}): Promise<string> {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const companyData = invoice.additionalReceiptData._documentIssuer;
  const companyInfo = `
    <div style="text-align: center">
      <span>${companyData.name}</span>
      <br />
      ${
        companyData.address
          ? `
        <span>${companyData.address}</span>
        <br />
      `
          : ``
      }
      ${companyData.zip ? `<span>${companyData.zip} ${companyData.city}</span>` : ``}
      <br />
      <span>${companyData.isTaxSubject ? `ID za DDV` : `Davcna stevilka`}: ${companyData.taxNumber || "/"}</span>
      <br />
    </div>
  `;

  const customerData = invoice.additionalReceiptData.customerData;
  const customerInfo = customerData
    ? `
    <div style="text-align: center">
      <span>${customerData.name}</span>
      <br />
      ${
        customerData.address
          ? `
        <span>${customerData.address}</span>
        <br />
      `
          : ``
      }
      ${customerData.zip && customerData.city ? `<span>${customerData.zip} ${customerData.city}</span>` : ``}
      <br />
      <span>ID za DDV: ${customerData.taxNumber || "/"}</span>
      <br />
    </div>
  `
    : ``;

  const invoiceHeader = `
    <div style="font-weight: bold; text-align: center;">
      ${invoice.canceled ? "STORNIRAN RACUN" : ""}
      <br />
      RACUN ST.: ${invoice.number}
    </div>
  `;

  // 5. Items table
  //    Instead of spacing with ESC/POS commands, create a simple HTML table
  const itemsTableRows = invoice.additionalReceiptData.invoiceData.items
    .map((item) => {
      // First row: only the article name (spread across all columns)
      const row1 = `
      <tr>
        <td colspan="4" style="text-align: left;">${item.name}</td>
      </tr>
    `;

      // Second row: price, quantity, discount, amount
      const row2 = `
      <tr>
        <td style="text-align: right;">${item.price.toFixed(2)}</td>
        <td style="text-align: right;">${item.quantity}x</td>
        <td style="text-align: right;">${item.discount || ""}</td>
        <td style="text-align: right;">${item.totalWithTax.toFixed(2)}</td>
      </tr>
    `;

      return row1 + row2;
    })
    .join("");

  const itemsTable = `
  <table style="width: 100%; border-collapse: collapse; margin-top: 0.5em; border-bottom: 1px solid black;">
    <!-- Table header for the second row -->
    <thead style="font-weight: bold; border-bottom: 1px solid black;">
      <tr>
        <td>Artikel/Cena</td>
        <td>Kol</td>
        <td>Popust</td>
        <td>Vrednost</td>
      </tr>
    </thead>
    <tbody>
      ${itemsTableRows}
    </tbody>
  </table>
`;

  // 6. Totals section
  const totalsSection = `
    <div style="margin-top: 1em;">
      <div style="display: flex; justify-content: space-between;">
        <span>SKUPAJ:</span>
        <span>${invoice.totalWithoutDiscount}</span>
      </div>
      ${
        invoice.totalDiscount
          ? `<div style="display: flex; justify-content: space-between;">
              <span>POPUST:</span> 
              <span>-${invoice.totalDiscountFormatted}</span>
             </div>`
          : ``
      }
      <div style="display: flex; justify-content: space-between;">
        <span>ZA PLACILO ${invoice.currencyId}:</span>
        <span style="font-size: 1.2em; font-weight: bold;">
          ${invoice.total}
        </span>
      </div>
    </div>
  `;

  const paymentMethodAmountsHTML = `
  <table style="width: 100%; border-collapse: collapse;">
    <tbody>
      ${invoice.additionalReceiptData.payments
        .map((payment) => {
          const label = getPaymentTypeLabel(payment.type as PaymentType);
          const formattedAmount = payment.amount.toFixed(2);

          return `
            <tr>
              <td style="text-align: left;">${label}</td>
              <td style="text-align: right;">${formattedAmount}</td>
            </tr>
          `;
        })
        .join("")}
    </tbody>
  </table>
`;

  let totalNetPrice = 0;
  let totalTaxAmount = 0;
  let totalGrossPrice = 0;

  const invoiceTaxesArray =
    invoice.additionalReceiptData.invoiceData.invoiceTaxes.map((tax) => {
      const netPrice = tax.base;
      const taxAmount = tax.totalTaxAmount;
      const grossPrice = netPrice + taxAmount;

      // Accumulate totals
      totalNetPrice += netPrice;
      totalTaxAmount += taxAmount;
      totalGrossPrice += grossPrice;

      return {
        taxRate: tax.taxRate.toFixed(1), // e.g. "22.0"
        netPrice: netPrice.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        grossPrice: grossPrice.toFixed(2),
      };
    });

  // Build the HTML table for taxes:
  function buildTaxHtmlTable(
    invoiceTaxes: {
      taxRate: string; // e.g. "22.0"
      netPrice: string; // e.g. "100.00"
      taxAmount: string; // e.g. "22.00"
      grossPrice: string; // e.g. "122.00"
    }[],
    totalNetPrice: number,
    totalTaxAmount: number,
    totalGrossPrice: number,
  ): string {
    // Map each tax line to a <tr> with right-aligned columns
    const taxLines = invoiceTaxes
      .map((t) => {
        return `
        <tr>
          <td style="text-align: right;">${t.taxRate}</td>
          <td style="text-align: right;">${t.netPrice}</td>
          <td style="text-align: right;">${t.taxAmount}</td>
          <td style="text-align: right;">${t.grossPrice}</td>
        </tr>
      `;
      })
      .join("");

    // Totals row
    const totalsRow = `
    <tr style="font-weight: bold; border-top: 1px solid #ccc;">
      <td style="text-align: left;">SKUPAJ:</td>
      <td style="text-align: right;">${totalNetPrice.toFixed(2)}</td>
      <td style="text-align: right;">${totalTaxAmount.toFixed(2)}</td>
      <td style="text-align: right;">${totalGrossPrice.toFixed(2)}</td>
    </tr>
  `;

    // Put it all in one table
    return `
    <table style="width: 100%; border-collapse: collapse; margin-top: 1em;">
      <thead style="border-bottom: 1px solid #ccc;">
        <tr>
          <th style="text-align: right;">DDV %</th>
          <th style="text-align: right;">Neto</th>
          <th style="text-align: right;">DDV</th>
          <th style="text-align: right;">Bruto</th>
        </tr>
      </thead>
      <tbody>
        ${taxLines}
        ${totalsRow}
      </tbody>
    </table>
  `;
  }

  const taxTableHtml = buildTaxHtmlTable(
    invoiceTaxesArray,
    totalNetPrice,
    totalTaxAmount,
    totalGrossPrice,
  );

  const includesMedicineService =
    invoice.additionalReceiptData.invoiceData.items.some(
      (item) => item.custom.isMedicineService,
    );

  // 7. Taxes & Additional Info
  const taxInfo = `
    <div style="margin-top: 1em;">
      ${taxTableHtml}

      <br />

      <p style="font-size: 0.85em;">${invoice.location.city ? invoice.location.city + ", " : ""}${invoice.date}</p>
      ${
        invoice.additionalReceiptData.employeeData?.name
          ? `<p style="font-size: 0.85em;">Racun izdal: ${invoice.additionalReceiptData.employeeData.name}</p>`
          : ``
      }
      ${
        !companyData.isTaxSubject
          ? `<br />
            <p style="font-size: 0.85em; text-align: center;">
              DDV ni obracunan na podlagi 1. odstavka 94. clena ZDDV-1.
            </p>`
          : ``
      }

      ${
        includesMedicineService
          ? `<br />
            <p style="font-size: 0.85em; text-align: center;">
              DDV ni obračunan v skladu z 2. točko 1. odstavka 42. člena ZDDV-1.
            </p>`
          : ``
      }
          
      <br/>

      <p>ZOI: ${invoice.additionalReceiptData.ZOI || ""}</p>
      <p>EOR: ${invoice.additionalReceiptData.EOR || ""}</p>
    </div>
  `;

  // 8. Thank-you message & optional QR
  const qrCode = invoice.additionalReceiptData?.QR
    ? await buildHtmlQrCode(invoice.additionalReceiptData.QR)
    : ``;
  // const qrCode = ``;

  const thanksMessage = `
    <div style="text-align: center; margin-top: 1em;">
      ${qrCode}
      <div>Hvala za obisk!</div>
      <div>www.lime-booking.si</div>
    </div>
  `;

  const printCount = invoice.additionalReceiptData.printCount + 1;

  return `
    <div style="width: 100%;">
      <style>
          :root {
            font-family: Arial;
          }

          p {
            margin: 0;
            font-size: 1em;
          }

          table, td {
            font-size: 1em;
          }

          span {
            font-size: 0.85em;
          }
        </style>

      <div style="width: 100%;">
        ${companyInfo}
        <br />
        ${customerInfo}
        <br />
        ${isCopy ? `<p style="text-align: center;">Kopija ${printCount - 1}</p>` : ``}
        <br />
        ${invoiceHeader}
        <br />
        ${itemsTable}
        <br />
        ${totalsSection}
        ${paymentMethodAmountsHTML}
        <br />
        ${taxInfo}
        <br />
        ${thanksMessage}
      </div>
    </div>
  `;
}

export function renderGiftCardToHtml(giftCardData: GiftCardDocument): string {
  const { organization, giftCard, operatorLabel } = giftCardData.data;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }
        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${organization.name}</p>
      <p>${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}</p>
      <p>${organization.taxNumber}</p>
      <br />
      <div style="text-align: left;">
        <p>Blagajnik: ${operatorLabel}</p>
      </div>
      <br/>
      <div style="text-align: center; font-size: 1.75em;">
        <span>DARILNI BON</span>
        <span>${giftCard.initialAmountFormatted}</span>
      </div>
      ${
        giftCard.amountLeftCents !== giftCard.initialAmountCents
          ? `
            <p>Preostali znesek: ${giftCard.amountLeftFormatted}</p>
        `
          : ``
      }
      <br/>

      <p style="font-weight: bold;">Koda<p>
      <p style="font-weight: bold;">${giftCard.code}</p>

      <br/>

      <div style="text-align: left;">
        <p>Veljavnost od: ${giftCard.issueDate}</p>
        <p>Veljavnost do: ${giftCard.expiryDate}</p>
      </div>
      <br />
      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

export function renderGiftCardReceiptToHtml(
  receiptData: GiftCardReceiptDocument,
): string {
  const {
    organization,
    issuedGiftCards,
    operatorLabel,
    payments,
    dateTime,
    location,
  } = receiptData.data;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }
        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${organization.name}</p>
      <p>${organization.address}${organization.address2 || ""}, ${organization.zip} ${organization.city}</p>
      <p>${organization.taxNumber}</p>
      <br />
      <div style="text-align: left;">
        <p>Blagajnik: ${operatorLabel}</p>
      </div>
      <br/>

      <div style="text-align: left;">
        <p style="border-bottom: 1px solid black;">Prejeta plačilna sredstva</p>
        ${Object.entries(payments)
          .map(
            ([type, data]) => `
          <div style="display: flex; justify-content: space-between;">
            <span>${getPaymentTypeLabel(type as PaymentType)}</span>
            <span>${data.amountFormatted}</span>  
          </div>
        `,
          )
          .join("")}

        <br />

        <p style="border-bottom: 1px solid black;">Izdana plačilna sredstva</p>
        ${issuedGiftCards
          .map(
            (g) => `<div style="display: flex; justify-content: space-between;">
                    <span>DARILNI BON</span>
                    <span>${g.initialAmountFormatted}</span>  
                  </div>`,
          )
          .join("")}
      </div>

      <br/>
      <br/>

      <p>${location}, ${dateTime}</p>

      <br/>
      <br/>

      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

export async function renderTransactionAccountReceiptToHtml(
  doc: TransactionAccountReceiptDocument,
): Promise<string> {
  const { data } = doc;

  const purpose = data.invoiceNumber;
  const htmlQrCode = await buildHtmlQrCode(
    buildUpnQrCodeString({
      invoiceNumber: data.invoiceNumber,
      amount: data.amount,
      dueDate: dayjs(data.dateDue).format("DD.MM.YYYY"),
      paymentPurpose: purpose,
      purposeCode: "OTHR",
      recipientCity: data.city,
      recipientIban: data.IBAN,
      recipientName: data.name,
      recipientReference: data.reference,
      recipientStreet: data.address,
      payerCity: "",
      payerName: "",
      payerStreet: "",
    }),
    "80%",
  );

  const { locale } = i18n;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }

        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${t`Racun`}: ${data.invoiceNumber}</p>
      <p>${t`Znesek`}: ${data.amount.toFixed(2)} ${data.currency}</p>
      <p>${t`Ime`}: ${data.name}</p>
      <p>${t`Naslov`}: ${data.address} ${data.city} ${data.country}</p>
      <p>IBAN: ${data.IBAN}</p>
      <p>SWIFT: ${data.SWIFT}</p>
      <p>${t`Referenca`}: ${data.reference}</p>
      <p>${t`Namen`}: ${purpose}</p>
      <p>${t`Rok placila`}: ${dayjs(data.dateDue).toDate().toLocaleDateString(locale)}</p>

      <br />

      ${htmlQrCode}

      <br />
      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}

export async function renderGiftCardTransactionAccountToHtml(
  doc: GiftCardTransactionAccountDocument,
): Promise<string> {
  const { data } = doc;

  if (!data.organization.IBAN) {
    throw new Error("IBAN is required");
  }

  const purpose = data.giftCardInvoiceId;
  const htmlQrCode = await buildHtmlQrCode(
    buildUpnQrCodeString({
      invoiceNumber: data.giftCardInvoiceId,
      amount: data.amount,
      dueDate: dayjs(data.dateDue).format("DD.MM.YYYY"),
      paymentPurpose: purpose,
      purposeCode: "OTHR",
      recipientCity: data.organization.city,
      recipientIban: data.organization.IBAN,
      recipientName: data.organization.name,
      recipientReference: data.reference,
      recipientStreet: data.organization.address,
      payerCity: "",
      payerName: "",
      payerStreet: "",
    }),
    "80%",
  );

  const { locale } = i18n;

  return `
    <div style="text-align: center;">
      <style>
        p {
          margin: 0;
          font-size: 1em;
          font-family: Arial;
        }

        span {
          font-size: 0.85em;
          font-family: Arial;
        }
      </style>
      <p>${t`TRR plačilo za darilni bon`}</p>
      <p>${t`Znesek`}: ${data.amount.toFixed(2)} ${data.currency}</p>
      <p>${t`Ime`}: ${data.organization.name}</p>
      <p>${t`Naslov`}: ${data.organization.address} ${data.organization.city} ${data.organization.zip}</p>
      <p>IBAN: ${data.organization.IBAN}</p>
      <p>SWIFT: ${data.organization.SWIFT ?? "/"}</p>
      <p>${t`Referenca`}: ${data.reference}</p>
      <p>${t`Namen`}: ${purpose}</p>
      <p>${t`Rok placila`}: ${dayjs(data.dateDue).toDate().toLocaleDateString(locale)}</p>

      <br />

      ${htmlQrCode}

      <br />
      <span style="margin-bottom: 0;">${t`Hvala za obisk!`}</span>
      <br />
      <span style="margin-top: 0;">www.lime-booking.si</span>
    </div>
  `;
}
