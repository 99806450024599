export const SUPPORTED_LOCALES = [
  "en",
  "sl",
  "de",
  "hr",
  "it",
  "es",
  "sr",
  "bs",
  "fr",
] as const;

export type Locale = (typeof SUPPORTED_LOCALES)[number];

export const LOCALE_LABELS: Record<Locale, string> = {
  en: "English",
  sl: "Slovenščina",
  de: "Deutsch",
  hr: "Hrvatski",
  it: "Italiano",
  es: "Español",
  sr: "Srpski",
  bs: "Bosanski",
  fr: "Français",
};

export const LOCALE_COUNTRY_CODES: Record<Locale, string> = {
  en: "GB", // or "US" if you prefer American English
  sl: "SI",
  de: "DE",
  hr: "HR",
  it: "IT",
  es: "ES",
  sr: "RS",
  bs: "BA",
  fr: "FR",
};
