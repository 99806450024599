import { api } from "@/lib/api-client";
import { Snippet } from "@heroui/react";
import { Avatar, Flex, Table } from "@mantine/core";
import { useState } from "react";
import { ImageUploadView } from "../ImageUploadView";

const ResourcesTab = ({ clientId }: { clientId: number }) => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { data: resourcesData, refetch } = api.resources.useGetResources({
    clientId,
  });

  const { mutateAsync: uploadImage, isPending: isUploading } =
    api.admin.useUploadResourceImage();

  const { mutateAsync: deleteImage, isPending: isDeleting } =
    api.admin.useDeleteResourceImage();

  return (
    <>
      {selectedId ? (
        <ImageUploadView
          close={() => setSelectedId(null)}
          existingImage={
            resourcesData?.find((resource) => resource.id === selectedId)
              ?.imageUrl
          }
          onUploadImage={async (formData) => {
            await uploadImage({
              body: formData,
              params: { clientId, resourceId: selectedId },
            });

            setSelectedId(null);
            refetch();
          }}
          isUploadingImage={isUploading}
          onDeleteImage={async () => {
            await deleteImage({
              params: { clientId, resourceId: selectedId },
            });

            setSelectedId(null);
            refetch();
          }}
          isDeletingImage={isDeleting}
          defaultCenterCrop={true}
          aspect={1 / 1}
        />
      ) : (
        <div className="flex flex-col">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th w="15ch">ID</Table.Th>
                <Table.Th>Ime</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {resourcesData?.map((rsc) => (
                <Table.Tr
                  key={rsc.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedId(rsc.id)}
                >
                  <Table.Td>
                    {rsc.imageUrl != null && (
                      <Avatar src={rsc.imageUrl}></Avatar>
                    )}
                  </Table.Td>
                  <Table.Td>
                    <Snippet symbol="" variant="bordered" className="border-0">
                      {rsc.id}
                    </Snippet>
                  </Table.Td>
                  <Table.Td>{rsc.label}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default ResourcesTab;
