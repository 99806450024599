import { t } from "@lingui/core/macro";
import { MobileSidebarToggle } from "@/Components/MobileSidebarToggle";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { SegmentedControl } from "@mantine/core";
import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import styled from "styled-components";
import DatePicker from "../../../../Components/DatePicker";
import { useCalendarStore } from "../store/useCalendarStore";
import Filter from "./Filter";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { AutocompleteItem } from "@heroui/react";
import { MapPin } from "lucide-react";

const Header = ({
  IS_GLOWUP_USER,
  IS_ILLUME_USER,
}: {
  IS_GLOWUP_USER: boolean;
  IS_ILLUME_USER: boolean;
}) => {
  const {
    dateActions,
    calendarView,
    selectedDate,
    locations,
    filterItems,
    locationActions,
    refreshCalendar,
  } = useCalendarStore((state) => state);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const setLocation = (location: number) => {
    locationActions.setSelectedLocationId(location);
    refreshCalendar();
  };

  const selectedLocationName = locations.find(
    (l: { locationId: number }) => l.locationId === filterItems?.FK_locationId,
  )?.name;

  return (
    <Wrapper>
      <div className={"left"}>
        <DatePicker
          selectedDate={selectedDate}
          onDateChange={dateActions.setSelectedDate}
          calendarView={calendarView}
          minDate={undefined}
          maxDate={undefined}
          overrideStartDate={undefined}
          overrideEndDate={undefined}
        />
      </div>
      <div className={"right"}>
        <LimeAutocomplete
          isVirtualized={false} // TODO: set to true after bugs are fixed (https://github.com/heroui-inc/heroui/issues/4494) , (https://github.com/heroui-inc/heroui/issues/4568)
          selectedKey={filterItems.FK_locationId?.toString()}
          size={"sm"}
          placeholder={t`Lokacija`}
          className="hidden w-fit md:block"
          startContent={<MapPin size={16} className="shrink-0" />}
          isClearable={false}
          inputProps={{
            classNames: {
              inputWrapper: "h-[39px] text-default-500",
              input: "text-default-400",
            },
          }}
          scrollShadowProps={{
            isEnabled: false,
          }}
          onSelectionChange={(key) => {
            if (!key) return;

            setLocation(Number(key));
          }}
        >
          {locations?.map((location: { locationId: number; name: string }) => {
            return (
              <AutocompleteItem
                key={location.locationId.toString()}
                textValue={location.name}
              >
                {location.name}
              </AutocompleteItem>
            );
          })}
        </LimeAutocomplete>

        <MobileSidebarToggle withoutRightMargin />

        <SegmentedControl
          data={[
            {
              label: window.innerWidth > 768 ? t`Mesec` : t`M`,
              value: "month",
            },
            { label: window.innerWidth > 768 ? t`Teden` : t`T`, value: "week" },
            { label: window.innerWidth > 768 ? t`Dan` : t`D`, value: "day" },
          ]}
          value={calendarView}
          onChange={(value) => dateActions.setCalendarView(value)}
          color="brand"
        />

        {permissions.restrict_calendar_filter ? null : (
          <Filter
            IS_GLOWUP_USER={IS_GLOWUP_USER}
            IS_ILLUME_USER={IS_ILLUME_USER}
          ></Filter>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #eaebed;
  padding-bottom: 15px;

  grid-column: 1 / -1;

  height: 55px;

  z-index: 6;

  .left {
    display: flex;
  }

  .right {
    display: flex;
    gap: 10px;
  }

  .location-picker-desktop {
    display: flex;
    justify-content: start;
    gap: 10px;
    align-items: center;
    white-space: nowrap;

    color: #969ba9;

    font-size: 14px;

    padding: 6px 12px;
    border-radius: 4px;
    background: #fafafa;
  }
  .mobile-top-row {
    display: none;
    width: 100%;
    justify-content: flex-end;

    border-bottom: 1px solid #eaebed;
    padding-bottom: 20px;

    height: 50px;
  }
  .location-picker-mobile {
    display: none;
    width: fit-content;

    border-radius: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;

    padding: 0 15px;

    padding-bottom: 10px;

    .left {
      order: 2;

      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: white;

      z-index: 5;

      border-top: 1px solid rgb(234, 235, 237);
    }

    .right {
      order: 1;

      justify-content: space-between;
    }

    .location-picker-desktop {
      display: none;
    }
    .mobile-top-row {
      display: flex;
    }
    .location-picker-mobile {
      display: flex;
    }
  }
`;

export default React.memo(Header);
