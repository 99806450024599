import { t } from "@lingui/core/macro";
import { countries } from "countries-list";
import ReactCountryFlag from "react-country-flag";
import { MantineFormValues } from "./shared.types";
import { LimeAutocomplete, LimeAutocompleteProps } from "./LimeAutocomplete";
import { AutocompleteItem } from "@heroui/react";
import { useMemo } from "react";

type LimeCountryPickerProps = Omit<
  MantineFormValues & LimeAutocompleteProps<{ label: string; key: string }>,
  "children" | "items" | "onChange"
> & {
  onChange: (value: string) => void;
};

export const LimeCountryPicker = ({
  value,
  onChange,
  error,
  ...props
}: LimeCountryPickerProps) => {
  const countryItems = useMemo(
    () => [
      {
        label: countries.SI.name,
        key: "SI",
        startContent: (
          <ReactCountryFlag
            countryCode="SI"
            aria-label={`Flag of ${countries.SI.name}`}
          />
        ),
      },
      ...Object.entries(countries)
        .filter(([key]) => key !== "SI")
        .map(([key, country]) => ({
          label: country.name,
          key,
          startContent: (
            <ReactCountryFlag
              countryCode={key}
              aria-label={`Flag of ${country.name}`}
            />
          ),
        })),
    ],
    [],
  );

  return (
    <LimeAutocomplete
      label={t`Država`}
      items={countryItems}
      selectedKey={value}
      onSelectionChange={(value) => {
        if (value) {
          onChange(String(value));
        }
      }}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
    >
      {(item) => (
        <AutocompleteItem
          key={item.key}
          startContent={<ReactCountryFlag countryCode={item.key} />}
        >
          {item.label}
        </AutocompleteItem>
      )}
    </LimeAutocomplete>
  );
};
