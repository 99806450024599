import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import TimeSpanRow from "@/Components/TimeSpanRow";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  Button,
  Combobox,
  Flex,
  NumberInput,
  Select,
  Switch,
  Text,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { BsBuildingFillX } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import { LuCopySlash, LuPuzzle, LuTimerOff } from "react-icons/lu";
import { RiArrowDownSLine } from "react-icons/ri";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";

import { LimeCombobox } from "@/Components/LimeCombobox";
import classes from "./Prescheduled.module.css";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { AutocompleteItem, AutocompleteSection } from "@heroui/react";

const PrescheduledAppointmentModal = ({ onClose }) => {
  const resourceCombobox = useCombobox({});
  const userCombobox = useCombobox({});

  const { isFeatureEnabled } = useFeature();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const {
    mutateAsync: createPrescheduled,
    isLoading: isPostPrescheduledLoading,
  } = api.appointments.usePostPrescheduled();

  const {
    services,
    users,
    resources,
    lastSelectedUserId,
    lastSelectedTime,
    lastSelectedDate,
    selectedLocationId,
    lastSelectedResourceId,
    newPreScheduledAppointmentData,
    setNewPreScheduledAppointmentData,
    calendarData,
    serviceTags,
  } = useCalendarStore((state) => ({
    services: state.services,
    users: state.users,
    resources: state.resources,
    lastSelectedUserId: state.lastSelectedUserId,
    lastSelectedTime: state.lastSelectedTime,
    lastSelectedDate: state.lastSelectedDate,
    selectedLocationId: state.selectedLocationId,
    lastSelectedResourceId: state.lastSelectedResourceId,
    newPreScheduledAppointmentData: state.newPreScheduledAppointmentData,
    calendarData: state.calendarData,
    serviceTags: state.serviceTags,
    setNewPreScheduledAppointmentData: state.setNewPreScheduledAppointmentData,
  }));

  const isEndInNextDay = dayjs(
    newPreScheduledAppointmentData?.endTime,
    "HH:mm",
  ).isSameOrBefore(dayjs(newPreScheduledAppointmentData?.startTime, "HH:mm"));

  const { data: validOptions } = api.appointments.usePostReturnValidOptions(
    {
      selectedServices: newPreScheduledAppointmentData?.selectedServiceId
        ? [
            {
              resources: newPreScheduledAppointmentData?.resources,
              users: newPreScheduledAppointmentData?.selectedUserId
                ? [newPreScheduledAppointmentData?.selectedUserId]
                : [],
              blockAfterMins: 0,
              blockBeforeMins: 0,
              duration:
                dayjs(newPreScheduledAppointmentData?.endTime, "HH:mm")
                  .add(isEndInNextDay ? 1 : 0, "day")
                  .diff(
                    dayjs(newPreScheduledAppointmentData?.startTime, "HH:mm"),
                    "minute",
                  ) || 60,
              serviceId: newPreScheduledAppointmentData?.selectedServiceId || 0,
            },
          ]
        : [
            {
              serviceId: services[0].serviceId,
              users: newPreScheduledAppointmentData?.selectedUserId
                ? [newPreScheduledAppointmentData?.selectedUserId]
                : [],
              resources: newPreScheduledAppointmentData?.resources || [],
              duration: 60,
            },
          ],
      selectedServiceIndex: 0,
      locationId: selectedLocationId,
      startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
        newPreScheduledAppointmentData?.startTime
      }`,
      enabled: newPreScheduledAppointmentData,
    },
    (!!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts) &&
      !!newPreScheduledAppointmentData?.startTime,
  );

  useEffect(() => {
    setNewPreScheduledAppointmentData({
      ...newPreScheduledAppointmentData,
      startTime: dayjs(lastSelectedTime).format("HH:mm"),
      endTime: null,
      numberOfPeople: 1,
      selectedUserId: lastSelectedUserId || null,
      isRepeating: false,
      repeatInterval: 1,
      repeatType: "week",
      repeatDuration: 1,
      resources: lastSelectedResourceId ? [lastSelectedResourceId] : [],
      allowDuplicateCustomerOnAppointment:
        calendarData.defaultAllowDuplicateCustomerOnAppointment,
      showOnFormPreScheduled: true,
    });
  }, []);

  const submitData = async () => {
    if (!newPreScheduledAppointmentData) return;

    const duration = dayjs(newPreScheduledAppointmentData.endTime, "HH:mm")
      .add(isEndInNextDay ? 1 : 0, "day")
      .diff(dayjs(newPreScheduledAppointmentData.startTime, "HH:mm"), "minute");

    try {
      await createPrescheduled({
        locationId: selectedLocationId,
        users: newPreScheduledAppointmentData.selectedUserId
          ? [newPreScheduledAppointmentData.selectedUserId]
          : [],
        resources: newPreScheduledAppointmentData.resources || [],
        repeat: newPreScheduledAppointmentData.isRepeating
          ? {
              count: newPreScheduledAppointmentData.repeatDuration,
              interval: newPreScheduledAppointmentData.repeatInterval,
              type: newPreScheduledAppointmentData.repeatType,
            }
          : undefined,
        numberOfPeople: newPreScheduledAppointmentData.numberOfPeople,
        startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
          newPreScheduledAppointmentData.startTime
        }`,
        serviceId: newPreScheduledAppointmentData.selectedServiceId,
        duration,
        throwIfRestricted: true,
        allowDuplicateCustomerOnAppointment:
          newPreScheduledAppointmentData.allowDuplicateCustomerOnAppointment,
        showOnFormPreScheduled:
          newPreScheduledAppointmentData.showOnFormPreScheduled,
      });

      notifications.show({
        title: t`Termin uspešno ustvarjen`,
        color: "green",
      });

      onClose();
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response.data.canOverride) {
          const items = e.response.data.warnings;

          modals.openConfirmModal({
            title: t`Nekateri termini niso veljavni`,
            children: (
              <Flex direction={"column"}>
                {items.map((item) => (
                  <Flex
                    key={item.dateTime}
                    c={item.isRestricted ? Color.Error : undefined}
                  >
                    <Text>{item.dateTime}</Text>
                  </Flex>
                ))}
              </Flex>
            ),
            labels: {
              confirm: t`Ustvari veljavne`,
              cancel: t`Prekliči ustvarjanje`,
            },
            onConfirm: async () => {
              try {
                await createPrescheduled({
                  locationId: selectedLocationId,
                  users: [newPreScheduledAppointmentData.selectedUserId],
                  serviceId: newPreScheduledAppointmentData.selectedServiceId,
                  duration,
                  resources: newPreScheduledAppointmentData.resources || [],
                  repeat: newPreScheduledAppointmentData.isRepeating
                    ? {
                        count: newPreScheduledAppointmentData.repeatDuration,
                        interval: newPreScheduledAppointmentData.repeatInterval,
                        type: newPreScheduledAppointmentData.repeatType,
                      }
                    : undefined,
                  numberOfPeople: newPreScheduledAppointmentData.numberOfPeople,
                  startTime: `${dayjs(lastSelectedDate).format("YYYY-MM-DD")}T${
                    newPreScheduledAppointmentData.startTime
                  }`,
                  throwIfRestricted: false,
                  allowDuplicateCustomerOnAppointment:
                    newPreScheduledAppointmentData.allowDuplicateCustomerOnAppointment,
                  showOnFormPreScheduled:
                    newPreScheduledAppointmentData.showOnFormPreScheduled,
                });
                notifications.show({
                  message: t`Uspešno ste ustvarili termin`,
                  variant: "success",
                  color: "green",
                });
                useCalendarStore.getState().closeModals();
              } catch (e) {
                notifications.show({
                  message: t`Napaka pri ustvarjanju termina`,
                  color: "red",
                });
              }
            },
          });
        }
      }
      notifications.show({
        message: t`Napaka pri ustvarjanju termina`,
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (
      !!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts
    ) {
      if (!validOptions) return;
    }

    const appointmentStart = newPreScheduledAppointmentData
      ? dayjs(
          `${newPreScheduledAppointmentData.date}T${newPreScheduledAppointmentData.startTime}`,
        )
      : null;
    const appointmentEnd = newPreScheduledAppointmentData?.endTime
      ? dayjs(
          `${newPreScheduledAppointmentData.date}T${newPreScheduledAppointmentData.endTime}`,
        )
      : null;

    if (!appointmentStart || !appointmentEnd) return;

    const validUser = usersWithWarnings.find((u) => u.isValid);
    const validResource = resourcesWithWarnings.find((r) => r.isValid);

    setNewPreScheduledAppointmentData({
      ...newPreScheduledAppointmentData,
      selectedUserId: lastSelectedUserId || validUser?.id || undefined,
      resources: lastSelectedResourceId
        ? [lastSelectedResourceId]
        : validResource?.id
          ? [validResource.id]
          : [] || [],
    });
  }, [newPreScheduledAppointmentData?.selectedServiceId, validOptions]);

  const selectedResources = React.useMemo(
    () =>
      resources.filter((r) =>
        newPreScheduledAppointmentData?.resources?.includes(r.id),
      ),
    [resources, newPreScheduledAppointmentData],
  );

  const selectedUser = React.useMemo(
    () =>
      users.find(
        (u) => newPreScheduledAppointmentData?.selectedUserId === u.userId,
      ),
    [users, newPreScheduledAppointmentData],
  );

  const selectedService = React.useMemo(
    () =>
      services.find(
        (s) =>
          s.serviceId === newPreScheduledAppointmentData?.selectedServiceId,
      ),
    [services, newPreScheduledAppointmentData],
  );

  const servicesWithWarnings = React.useMemo(
    () =>
      services
        .filter((s) => s.permission && !s.isDeleted)
        .filter(
          (s) =>
            !validOptions ||
            validOptions.availableServices.find(
              (ser) => s.serviceId === ser.serviceId && ser.isValid,
            ),
        )
        .toSorted((a, b) => {
          const aTagOrder =
            serviceTags.find((t) => t.tagId === a.tagId)?.sortOrder || 99;
          const bTagOrder =
            serviceTags.find((t) => t.tagId === b.tagId)?.sortOrder || 99;

          if (aTagOrder !== bTagOrder) return aTagOrder - bTagOrder;

          const aServiceOrder = a.sortOrder;
          const bServiceOrder = b.sortOrder;

          return aServiceOrder - bServiceOrder;
        })
        .map((service) => {
          const availableServiceDetails = validOptions?.availableServices?.find(
            (s) => s.serviceId === service.serviceId,
          );

          return {
            ...service,
            id: service.serviceId,
            value: service.serviceId.toString(),
            label: service.name,
            ...availableServiceDetails,
            isValid: availableServiceDetails
              ? availableServiceDetails.isValid
              : true,
            tagId: serviceTags.find((t) => t.tagId === service.tagId)?.tagId,
            tagName: serviceTags.find((t) => t.tagId === service.tagId)
              ?.tagName,
          };
        }),
    [services, serviceTags, validOptions],
  );

  const usersWithWarnings = React.useMemo(
    () =>
      users
        .filter(
          (u) =>
            !validOptions ||
            validOptions.availableUsers.find(
              (usr) => u.userId === usr.userId && usr.isValid,
            ),
        )
        .map((user) => {
          const firstName = user.name?.toUpperCase;
          const lastName = user.lastName?.toUpperCase;

          const availableUserDetails = validOptions?.availableUsers?.find(
            (u) => u.userId === user.userId,
          );

          return {
            id: user.userId,
            value: user.userId.toString(),
            fullName: `${user.name} ${user.lastName}`,
            label: `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`,
            ...availableUserDetails,
            isValid: availableUserDetails ? availableUserDetails.isValid : true,
          };
        }),
    [users, validOptions],
  );

  const resourcesWithWarnings = React.useMemo(
    () =>
      resources
        .filter(
          (rs) =>
            !validOptions ||
            validOptions.availableResources.find(
              (r) => rs.id === r.resourceId && r.isValid,
            ),
        )
        .map((r) => {
          const availableResourceDetails =
            validOptions?.availableResources?.find(
              (res) => res.resourceId === r.id,
            );

          return {
            id: r.id,
            value: r.id.toString(),
            label: r.label,
            ...availableResourceDetails,
            isValid: availableResourceDetails
              ? availableResourceDetails.isValid
              : true,
          };
        }),
    [resources, validOptions],
  );

  const resourceComboboxLabel =
    selectedResources.length > 0
      ? `${selectedResources[0].label} ${
          selectedResources.length > 1
            ? `+ ${selectedResources.length - 1} ${t`ostalih`}`
            : ""
        } `
      : t`Ni izbranih sredstev`;

  const userComboboxLabel = selectedUser
    ? `${selectedUser.name} ${selectedUser.lastName}`
    : t`Ni izbranih zaposlenih`;

  const warnings = {
    serviceWarning: (
      <Tooltip label={t`Storitev se ne izvaja na tem sredstvu`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    scheduleError: (
      <Tooltip label={t`Sredstvo nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    appointmentOverlapError: (
      <Tooltip label={t`Sredstvo je zasedeno`}>
        <div>
          <BsBuildingFillX color={Color.Error}></BsBuildingFillX>
        </div>
      </Tooltip>
    ),
    userServiceWarning: (
      <Tooltip label={t`Zaposleni ne izvaja te storitve`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userScheduleError: (
      <Tooltip label={t`Zaposleni nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    resourceRequired: (
      <Tooltip label={t`Storitev potrebuje sredstvo`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userAppointmentOverlapError: (
      <Tooltip label={t`Zaposleni je zaseden`}>
        <div>
          <LuCopySlash color={Color.Error}></LuCopySlash>
        </div>
      </Tooltip>
    ),
    userRequired: (
      <Tooltip label={t`Storitev potrebuje zaposlenega`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
  };

  return (
    <Wrapper>
      <Flex direction={"column"} gap={"1rem"}>
        <Text fw={500}>{dayjs(lastSelectedDate).format("DD. MM. YYYY")}</Text>

        <LimeAutocomplete
          isVirtualized={false} // TODO: set to true after bugs are fixed (https://github.com/heroui-inc/heroui/issues/4494) , (https://github.com/heroui-inc/heroui/issues/4568)
          selectedKey={selectedService?.serviceId?.toString()}
          size={"sm"}
          placeholder={t`Storitev še ni izbrana`}
          label={t`Storitev`}
          variant="faded"
          data-identifier={"appointment-service-combobox"}
          listBoxProps={{
            itemClasses: {
              base: "h-fit w-fit",
            },
          }}
          scrollShadowProps={{
            isEnabled: false,
          }}
          onSelectionChange={(key) => {
            const val = Number(key);

            const selectedService = services.find((s) => s.serviceId === val);

            if (!selectedService) return;

            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              selectedServiceId: val,
              endTime: dayjs(
                `1970-01-01T${newPreScheduledAppointmentData.startTime}`,
              )
                .add(selectedService.duration, "minutes")
                .format("HH:mm"),
            });
          }}
        >
          {serviceTags
            ?.sort((a, b) => a.sortOrder - b.sortOrder)
            .map((st) => {
              const services =
                st.tagId === -1
                  ? servicesWithWarnings.filter((s) => !s.tagId)
                  : servicesWithWarnings.filter((s) => s.tagId === st.tagId);

              if (services?.length === 0 && st.tagId !== -1) return null;

              return (
                <AutocompleteSection
                  classNames={{
                    heading:
                      "flex w-full sticky top-1 z-20 py-1.5 px-2 bg-default-100 rounded-small",
                  }}
                  showDivider
                  title={st.tagName}
                >
                  {services?.map((s) => (
                    <AutocompleteItem
                      key={s.id.toString()}
                      textValue={s.label}
                      endContent={
                        <div className="flex items-center gap-2">
                          {s.userOverlapWarning ? (
                            <Tooltip
                              content={t`Zaposleni je zaseden`}
                              withArrow={true}
                            >
                              <div>
                                <LuCopySlash color={Color.Error}></LuCopySlash>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {s.resourceOverlapWarning ? (
                            <Tooltip
                              content={t`Sredstvo je zasedeno`}
                              withArrow={true}
                            >
                              <div>
                                <LuCopySlash color={Color.Error}></LuCopySlash>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {s.userShiftWarning ? (
                            <Tooltip
                              content={t`Zaposleni nima urnika`}
                              withArrow={true}
                            >
                              <div>
                                <LuTimerOff color={Color.Error}></LuTimerOff>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {s.resourceShiftWarning ? (
                            <Tooltip
                              content={t`Sredstvo nima urnika`}
                              withArrow={true}
                            >
                              <div>
                                <LuTimerOff color={Color.Error}></LuTimerOff>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {s.userRelationWarning ? (
                            <Tooltip
                              content={t`Zaposleni ne izvaja te storitve`}
                              withArrow={true}
                            >
                              <div>
                                <IoIosWarning
                                  color={Color.Error}
                                ></IoIosWarning>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {s.resourceRelationWarning ? (
                            <Tooltip
                              content={t`Sredstvo ne izvaja te storitve`}
                              withArrow={true}
                            >
                              <div>
                                <IoIosWarning
                                  color={Color.Error}
                                ></IoIosWarning>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {s.combinationWarning ? (
                            <Tooltip
                              content={t`Storitev ni na voljo v kombinaciji z ostalimi izbranimi storitvami.`}
                              withArrow={true}
                            >
                              <div>
                                <LuPuzzle color={Color.Error}></LuPuzzle>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      }
                    >
                      {s.label}
                    </AutocompleteItem>
                  ))}
                </AutocompleteSection>
              );
            })}
        </LimeAutocomplete>

        {/**
         * user combobox
         */}
        <LimeCombobox
          store={userCombobox}
          data-identifier={"appointment-user-combobox"}
          withArrow
          w={"100%"}
          label={t`Zaposleni`}
          justify={"start"}
          onOptionSubmit={(value) => {
            const val = Number(value);
            if (newPreScheduledAppointmentData.selectedUserId !== val) {
              setNewPreScheduledAppointmentData({
                ...newPreScheduledAppointmentData,
                selectedUserId: val,
              });
            } else {
              setNewPreScheduledAppointmentData({
                ...newPreScheduledAppointmentData,
                selectedUserId: null,
              });
            }

            userCombobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <button
              onClick={() => userCombobox.toggleDropdown()}
              className={classes.comboboxTarget}
              type="button"
            >
              <Text variant={TextVariant.Caption}>{userComboboxLabel}</Text>
              <Flex gap={10}>
                <Flex gap={5}>{/**warnings */}</Flex>
                <RiArrowDownSLine className={"arrow-down"} />
              </Flex>
            </button>
          </Combobox.Target>
          <Combobox.Dropdown w={"fit-content"}>
            <Combobox.Options>
              {usersWithWarnings.map((data, index) => {
                return (
                  <Combobox.Option
                    value={data.id}
                    key={data.id}
                    active={index === 0}
                  >
                    <Flex
                      gap={"15px"}
                      align={"center"}
                      justify={"space-between"}
                    >
                      <Text
                        fw={500}
                        style={{ whiteSpace: "pre" }}
                        variant={TextVariant.Body}
                        c={
                          newPreScheduledAppointmentData?.selectedUserId ===
                          data.id
                            ? Color.Success
                            : Color.PrimaryText
                        }
                      >
                        {data.fullName}
                      </Text>
                      <Flex gap={5}>
                        {data.relationWarning
                          ? warnings.userServiceWarning
                          : ""}
                        {data.shiftWarning ? warnings.userScheduleError : ""}
                        {data.overlapWarning
                          ? warnings.userAppointmentOverlapError
                          : ""}
                      </Flex>
                    </Flex>
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          </Combobox.Dropdown>
        </LimeCombobox>

        {isFeatureEnabled("RESOURCES") && (
          <LimeCombobox
            data-identifier={"appointment-resource-combobox"}
            store={resourceCombobox}
            withArrow
            w={"100%"}
            label={t`Sredstva`}
            justify={"start"}
            onOptionSubmit={(val) => {
              if (!newPreScheduledAppointmentData.resources.includes(val)) {
                setNewPreScheduledAppointmentData({
                  ...newPreScheduledAppointmentData,
                  resources: [...newPreScheduledAppointmentData.resources, val],
                });
              } else {
                setNewPreScheduledAppointmentData({
                  ...newPreScheduledAppointmentData,
                  resources: newPreScheduledAppointmentData.resources.filter(
                    (r) => r !== val,
                  ),
                });
              }
            }}
          >
            <Combobox.Target>
              <button
                className={classes.comboboxTarget}
                onClick={() => resourceCombobox.toggleDropdown()}
                type="button"
              >
                <Text variant={TextVariant.Caption}>
                  {resourceComboboxLabel}
                </Text>
                <Flex gap={10}>
                  <Flex gap={5}>{/**warnings */}</Flex>
                  <RiArrowDownSLine className={"arrow-down"} />
                </Flex>
              </button>
              {/* <Box
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "0px 12px ",
                      lineHeight: "34px",
                      cursor: "pointer",
                    }}
                    onClick={() => resourceCombobox.toggleDropdown()}
                  >
                    {resourceComboboxLabel}
                  </Box> */}
            </Combobox.Target>
            <Combobox.Dropdown w={"fit-content"}>
              <Combobox.Options>
                {resourcesWithWarnings.map((data, index) => {
                  return (
                    <Combobox.Option
                      value={data.id}
                      key={data.id}
                      active={index === 0}
                    >
                      <Flex
                        gap={"15px"}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <Text
                          fw={500}
                          style={{ whiteSpace: "pre" }}
                          c={
                            newPreScheduledAppointmentData?.resources?.includes(
                              data.id,
                            )
                              ? Color.Success
                              : Color.PrimaryText
                          }
                        >
                          {data.label}
                        </Text>
                        <Flex gap={5}>
                          {data.relationWarning ? warnings.serviceWarning : ""}
                          {data.shiftWarning ? warnings.scheduleError : ""}
                          {data.overlapWarning
                            ? warnings.appointmentOverlapError
                            : ""}
                        </Flex>
                      </Flex>
                    </Combobox.Option>
                  );
                })}
              </Combobox.Options>
            </Combobox.Dropdown>
          </LimeCombobox>
        )}

        <TimeSpanRow
          fromTimeValue={newPreScheduledAppointmentData?.startTime}
          toTimeValue={newPreScheduledAppointmentData?.endTime}
          onFromTimeChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              startTime: value,
            });
          }}
          onToTimeChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              endTime: value,
            });
          }}
          disableTo={!!permissions.restrict_custom_duration}
          allowAnyTime
        ></TimeSpanRow>

        <NumberInput
          data-identifier={"appointment-number-of-people-input"}
          label={t`Število oseb`}
          min={1}
          max={99999}
          value={newPreScheduledAppointmentData?.numberOfPeople}
          onChange={(value) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              numberOfPeople: value,
            });
          }}
        />

        <Switch
          label={t`Dovoli podvojene stranke na terminu`}
          checked={
            newPreScheduledAppointmentData?.allowDuplicateCustomerOnAppointment
          }
          onChange={(e) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              allowDuplicateCustomerOnAppointment: e.currentTarget.checked,
            });
          }}
        />

        <Switch
          label={t`Ponavljajoč termin`}
          checked={newPreScheduledAppointmentData?.isRepeating}
          data-identifier={"repeating-appointment-switch"}
          onChange={(e) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              isRepeating: e.currentTarget.checked,
            });
          }}
        />
        {newPreScheduledAppointmentData?.isRepeating && (
          <Flex direction={"column"} gap={"1rem"}>
            <Flex gap={"1rem"} align={"center"} justify={"space-between"}>
              <Text style={{ whiteSpace: "pre" }}>
                <Trans>Ponovi vsak</Trans>
              </Text>
              <NumberInput
                data-identifier={"repeat-interval"}
                placeholder={"1"}
                value={newPreScheduledAppointmentData?.repeatInterval}
                onChange={(value) => {
                  setNewPreScheduledAppointmentData({
                    ...newPreScheduledAppointmentData,
                    repeatInterval: value,
                  });
                }}
                variant={"filled"}
                min={1}
              />
              <Select
                data-identifier={"repeat-type-select"}
                data={[
                  { label: t`Dan`, value: "day" },
                  { label: t`Teden`, value: "week" },
                  { label: t`Mesec`, value: "month" },
                ]}
                value={newPreScheduledAppointmentData?.repeatType}
                onChange={(value) => {
                  setNewPreScheduledAppointmentData({
                    ...newPreScheduledAppointmentData,
                    repeatType: value,
                  });
                }}
                variant={"filled"}
                defaultValue={"week"}
                allowDeselect={false}
              />
            </Flex>
            <NumberInput
              data-identifier={"repeat-duration"}
              label={t`Število ponavljanj`}
              placeholder={"1"}
              value={newPreScheduledAppointmentData?.repeatDuration}
              onChange={(value) => {
                setNewPreScheduledAppointmentData({
                  ...newPreScheduledAppointmentData,
                  repeatDuration: value,
                });
              }}
              variant={"filled"}
              min={1}
            />
          </Flex>
        )}

        <Switch
          label={t`Prikaži na spletni formi`}
          checked={newPreScheduledAppointmentData?.showOnFormPreScheduled}
          onChange={(e) => {
            setNewPreScheduledAppointmentData({
              ...newPreScheduledAppointmentData,
              showOnFormPreScheduled: e.currentTarget.checked,
            });
          }}
        />

        <Button
          id={"submit-scheduled-appointment-button"}
          onClick={submitData}
          style={{
            display: "none",
          }}
          disabled={isPostPrescheduledLoading}
        >
          <Trans>Potrdi</Trans>
        </Button>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default PrescheduledAppointmentModal;
