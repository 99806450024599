import { useEffect, useState } from "react";
import { apiGetLocations } from "../../../../apiCalls";
import { useGetServices } from "../../../../lib/api-client/paths/service";
import { useAnalyticsStore } from "../stores/useAnalyticsStore";
import type { GetServices } from "@/server-types";

type Service = GetServices["response"]["services"][number];

interface Location {
  locationId: number;
  label: string;
}

export const useAnalytics = () => {
  const { setSelectedLocations, setSelectedServices } = useAnalyticsStore(
    (state) => state,
  );
  const [locations, setLocations] = useState<Location[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const { data: servicesData } = useGetServices({ enabled: true });

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (servicesData?.services) {
      processServices(servicesData.services);
    }
  }, [servicesData]);

  const loadLocations = async () => {
    try {
      const { data } = await apiGetLocations(localStorage.getItem("usr_token"));
      const { locations } = data;
      const locationsIds = locations.map((l: Location) => l.locationId);
      setSelectedLocations(locationsIds);
      setLocations(locations);
    } catch (error) {
      console.error("Error loading locations:", error);
    }
  };

  const processServices = (apiServices: Service[]) => {
    const sortedServices = [...apiServices].sort((a, b) => {
      const tagA = a.service_tag[0]?.tag?.TagNameLocalized[0]?.name || "";
      const tagB = b.service_tag[0]?.tag?.TagNameLocalized[0]?.name || "";
      return tagA.localeCompare(tagB);
    });

    const servicesIds = sortedServices.map((s) => s.serviceId);
    setSelectedServices(servicesIds);
    setServices(sortedServices);
  };

  return { locations, services };
};
