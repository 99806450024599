import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeColorPicker } from "@/Components/LimeColorPicker";
import { LimeMultiSelect } from "@/Components/LimeMultiSelect";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { LimeTextarea } from "@/Components/LimeTextarea";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Loader,
  Modal,
  Text,
} from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { MdTranslate } from "react-icons/md";
import { RiAddLine } from "react-icons/ri";
import { ManageCategory } from "../ManageCategory";
import type { FormValues } from "../ManageService";

export const GeneralTab = ({
  form,
}: {
  form: UseFormReturnType<FormValues>;
}) => {
  const { isFeatureEnabled } = useFeature();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const [
    categoryDrawerOpened,
    { open: openCategoryDrawer, close: closeCategoryDrawer },
  ] = useDisclosure(false);

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
  } = api.category.useGetCategories({});

  const {
    data: resourceGroups,
    isPending: resourceGroupsIsPending,
    isError: resourceGroupsIsError,
  } = api.resourceGroups.useGetResourceGroups({});

  const {
    data: userData,
    isPending: usersIsPending,
    isError: usersIsError,
  } = api.user.useUserList();

  const selectAllResourceGroups = () => {
    if (
      form.values.resourceGroupIds?.length ===
      resourceGroups?.resourceGroups?.length
    ) {
      form.setFieldValue("resourceGroupIds", []);
    } else {
      form.setFieldValue(
        "resourceGroupIds",
        resourceGroups?.resourceGroups?.map(
          (resourceGroup) => resourceGroup.id,
        ) ?? [],
      );
    }
  };

  const selectAllUsers = () => {
    if (form.values.userIds?.length === userData?.users?.length) {
      form.setFieldValue("userIds", []);
    } else {
      form.setFieldValue(
        "userIds",
        userData?.users?.map((user) => user.userId) ?? [],
      );
    }
  };

  const [
    nameLocalizationDrawerOpened,
    { open: openNameLocalizationDrawer, close: closeNameLocalizationDrawer },
  ] = useDisclosure(false);
  const [
    descriptionLocalizationDrawerOpened,
    {
      open: openDescriptionLocalizationDrawer,
      close: closeDescriptionLocalizationDrawer,
    },
  ] = useDisclosure(false);
  const [savedNameLocalizationData, setSavedNameLocalizationData] = useState<
    FormValues["nameLocalized"]
  >([]);
  const [
    savedDescriptionLocalizationData,
    setSavedDescriptionLocalizationData,
  ] = useState<FormValues["descriptionLocalized"]>([]);

  const handleOpenLocalizationDrawer = ({
    field,
  }: {
    field: "name" | "description";
  }) => {
    if (field === "name") {
      setSavedNameLocalizationData(form.values.nameLocalized);
      openNameLocalizationDrawer();
    } else if (field === "description") {
      setSavedDescriptionLocalizationData(form.values.descriptionLocalized);
      openDescriptionLocalizationDrawer();
    }
  };

  const handleCancelNameLocalizationDrawer = () => {
    form.setValues({ nameLocalized: savedNameLocalizationData });

    closeNameLocalizationDrawer();
  };

  const handleCancelDescriptionLocalizationDrawer = () => {
    form.setValues({ descriptionLocalized: savedDescriptionLocalizationData });

    closeDescriptionLocalizationDrawer();
  };

  const handleSaveLocalizationDrawer = () => {
    closeNameLocalizationDrawer();
    closeDescriptionLocalizationDrawer();
  };

  const defaultLanguageLocalizedNameIndex = form.values.nameLocalized.findIndex(
    (name) => name.language === defaultLanguage,
  );

  const defaultLanguageLocalizedDescriptionIndex =
    form.values.descriptionLocalized?.findIndex(
      (name) => name.language === defaultLanguage,
    );

  return (
    <>
      <Flex direction={"column"}>
        <LimeTextInput
          data-identifier={"service-name-input"}
          {...form.getInputProps(
            `nameLocalized.${defaultLanguageLocalizedNameIndex}.name`,
          )}
          label={t`Ime storitve`}
          px={"md"}
          action={{
            text: t`Nastavitve prevodov`,
            leftSection: <MdTranslate />,
            onClick: () => handleOpenLocalizationDrawer({ field: "name" }),
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <Divider my={"md"} />
        <LimeTextarea
          data-identifier={"service-description-input"}
          {...form.getInputProps(
            `descriptionLocalized.${defaultLanguageLocalizedDescriptionIndex}.description`,
          )}
          label={t`Opis storitve`}
          px={"md"}
          minRows={3}
          autosize
          maxLength={2500}
          action={{
            text: t`Nastavitve prevodov`,
            leftSection: <MdTranslate />,
            onClick: () =>
              handleOpenLocalizationDrawer({ field: "description" }),
          }}
        />
        <Divider my={"md"} />

        <LimeMultiSelect
          data-identifier={"service-category-dropdown"}
          {...form.getInputProps("tagIds")}
          px={"md"}
          data={
            categories?.map((category) => ({
              value: category.tagId.toString(),
              label:
                category.TagNameLocalized.find(
                  (tl) => tl.language === defaultLanguage,
                )?.name ||
                category.TagNameLocalized.find((tl) => tl.language === "sl")
                  ?.name ||
                category.TagNameLocalized.find((tl) => tl.name !== "")?.name ||
                category.tagName ||
                "",
            })) ?? []
          }
          value={form.values.tagIds?.map((id) => id.toString())}
          onChange={(tagIds) => {
            form.setFieldValue(
              "tagIds",
              tagIds?.map((tagId) => parseInt(tagId)),
            );
          }}
          label={t`Kategorija`}
          disabled={categoriesIsLoading || categoriesIsError}
          action={{
            text: t`Dodaj novo`,
            leftSection: <RiAddLine />,
            onClick: openCategoryDrawer,
          }}
          leftSection={categoriesIsLoading && <Loader size={"xs"} />}
          maxValues={1}
        />

        {/* <Flex align={"center"} justify={"space-between"} px={"md"}>
          <Text variant={TextVariant.BodyEmphasized}>
            <Trans>Dodaj med priljubljene</Trans>
          </Text>
          <ActionIcon
            onClick={() =>
              form.setFieldValue("isFavourite", !form.values.isFavourite)
            }
          >
            {form.values.isFavourite ? (
              <RiStarFill size={"2rem"} color={Color.Warning} />
            ) : (
              <RiStarLine size={"2rem"} color={Color.Warning} />
            )}
          </ActionIcon>
        </Flex> */}
        <Divider my={"md"} />
        <LimeColorPicker
          {...form.getInputProps("color")}
          value={form.values.color}
          label={t`Barva storitve`}
          px={"md"}
        />
        <Divider my={"md"} />

        {/** Naročanje preko spleta */}
        <LimeSwitch
          data-identifier={"online-booking-toggle"}
          {...form.getInputProps("shownOnForm", {
            type: "checkbox",
          })}
          label={t`Naročanje preko spleta`}
          // toolTip={t`placeholder`}
          px={"md"}
        />
        <Divider my={"md"} />

        {/** Storitev ima izvajalca */}
        <LimeSwitch
          data-identifier={"service-needs-user-toggle"}
          {...form.getInputProps("doesRequireUser", {
            type: "checkbox",
          })}
          error={form.errors.userIds}
          label={t`Storitev ima izvajalca`}
          // toolTip={t`placeholder`}
          px={"md"}
          pb={"lg"}
        />
        <Collapse in={form.values.doesRequireUser}>
          <LimeMultiSelect
            data-identifier={"users-combobox"}
            {...form.getInputProps("userIds")}
            label={t`Izvajalci storitve (oseba)`}
            px={"md"}
            pb={"md"}
            value={form.values.userIds?.map((id) => id.toString())}
            disabled={usersIsPending || usersIsError}
            data={
              userData?.users?.map((user) => ({
                value: user.userId.toString(),
                label: `${user.name || ""} ${user.lastName || ""}`,
              })) ?? []
            }
            action={{
              text: t`Izberi vse`,
              onClick: () => selectAllUsers(),
              identifier: "select-all-users",
            }}
            toolTip={t`Izberi vse izvajalce storitve`}
            leftSection={usersIsPending && <Loader size={"xs"} />}
          />
        </Collapse>

        <Divider mb={"md"} />

        {isFeatureEnabled("RESOURCES") ? (
          <>
            <LimeMultiSelect
              data-identifier={"resources-combobox"}
              {...form.getInputProps("resourceGroupIds")}
              label={t`Izvajalci storitve (sredstvo)`}
              px={"md"}
              value={form.values.resourceGroupIds?.map((id) => id.toString())}
              disabled={resourceGroupsIsPending || resourceGroupsIsError}
              data={
                resourceGroups?.resourceGroups?.map((resourceGroup) => ({
                  value: resourceGroup.id.toString(),
                  label: resourceGroup.name,
                })) ?? []
              }
              action={{
                text: t`Izberi vse`,
                onClick: selectAllResourceGroups,
                identifier: "select-all-resource-groups",
              }}
              toolTip={t`Izberi vse izvajalce storitve`}
              leftSection={resourceGroupsIsPending && <Loader size={"xs"} />}
            />

            {/* <Divider mt={"md"} /> */}
          </>
        ) : undefined}
      </Flex>

      <CreateCategoryDrawer
        opened={(isMobile || false) && categoryDrawerOpened}
        cancel={closeCategoryDrawer}
        save={(categoryId) => {
          if (categoryId) {
            form.setFieldValue("tagIds", [categoryId]);
          }

          closeCategoryDrawer();
        }}
      />

      <CreateCategoryModal
        opened={!isMobile && categoryDrawerOpened}
        cancel={closeCategoryDrawer}
        save={(categoryId) => {
          if (categoryId) {
            form.setFieldValue("tagIds", [categoryId]);
          }

          closeCategoryDrawer();
        }}
      />

      <NameLanguageSettingsDrawer
        opened={(isMobile || false) && nameLocalizationDrawerOpened}
        form={form}
        cancel={handleCancelNameLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />
      <NameLanguageSettingsModal
        opened={!isMobile && nameLocalizationDrawerOpened}
        form={form}
        cancel={handleCancelNameLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />

      <DescriptionLanguageSettingsDrawer
        opened={(isMobile || false) && descriptionLocalizationDrawerOpened}
        form={form}
        cancel={handleCancelDescriptionLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />
      <DescriptionLanguageSettingsModal
        opened={!isMobile && descriptionLocalizationDrawerOpened}
        form={form}
        cancel={handleCancelDescriptionLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />
    </>
  );
};

const DescriptionLanguageSettingsModal = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  return (
    <Modal
      opened={opened}
      onClose={cancel}
      centered
      withinPortal={false}
      styles={{
        root: {
          position: "fixed",
          left: 0,
          zIndex: 200,
        },
        inner: {
          padding: 0,
        },
      }}
      title={t`Nastavitve prevodov`}
      radius={12}
    >
      <Divider orientation={"horizontal"} />

      <DescriptionLanguageSettingsContent
        form={form}
        cancel={cancel}
        save={save}
      />
    </Modal>
  );
};

const DescriptionLanguageSettingsDrawer = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={cancel}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <DescriptionLanguageSettingsContent
        form={form}
        cancel={cancel}
        save={save}
      />
    </Drawer>
  );
};

const DescriptionLanguageSettingsContent = ({
  cancel,
  save,
  form,
}: {
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  const firstLanguage = i18n.locale;
  const sortedLanguages = form.values.descriptionLocalized?.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  return (
    <Flex direction={"column"} justify={"space-between"} h={"370px"}>
      <Flex direction={"column"} gap={"md"} mt={"sm"}>
        {sortedLanguages?.map((localizedDescription, index) => (
          <LimeTextInput
            key={index}
            label={
              <Text>
                <Text
                  span
                  c={Color.PrimaryText}
                  variant={TextVariant.BodyEmphasized}
                >
                  <Trans>Opis storitve</Trans>
                </Text>{" "}
                <Text
                  span
                  c={Color.SecondaryText}
                  variant={TextVariant.Caption}
                >
                  ({localizedDescription.language.toUpperCase()})
                </Text>
              </Text>
            }
            {...form.getInputProps(`descriptionLocalized.${index}.description`)}
          />
        ))}
      </Flex>

      <Box pb={"lg"}>
        <Divider my={"md"} />

        <Flex justify={"center"} align={"center"} gap={"md"}>
          <Button variant="white" onClick={cancel}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={() => {
              let isValid = true;
              form.values.descriptionLocalized?.forEach((_, index) => {
                const result = form.validateField(
                  `descriptionLocalized.${index}.name`,
                );

                if (result.hasError) {
                  isValid = false;
                }
              });

              if (!isValid) {
                return;
              }

              save();
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

const NameLanguageSettingsModal = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  return (
    <Modal
      opened={opened}
      onClose={cancel}
      centered
      withinPortal={false}
      styles={{
        root: {
          position: "fixed",
          left: 0,
          zIndex: 200,
        },
        inner: {
          padding: 0,
        },
      }}
      title={t`Nastavitve prevodov`}
      radius={12}
    >
      <Divider orientation={"horizontal"} />

      <NameLanguageSettingsContent form={form} cancel={cancel} save={save} />
    </Modal>
  );
};

const NameLanguageSettingsDrawer = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={cancel}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <NameLanguageSettingsContent form={form} cancel={cancel} save={save} />
    </Drawer>
  );
};

const NameLanguageSettingsContent = ({
  cancel,
  save,
  form,
}: {
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<FormValues>;
}) => {
  const firstLanguage = i18n.locale;

  const sortedLanguages = form.values.nameLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  return (
    <Flex direction={"column"} justify={"space-between"} h={"370px"}>
      <Flex direction={"column"} gap={"md"} mt={"sm"}>
        {sortedLanguages.map((localizedName, index) => (
          <LimeTextInput
            key={localizedName.language}
            label={
              <Text>
                <Text
                  span
                  c={Color.PrimaryText}
                  variant={TextVariant.BodyEmphasized}
                >
                  <Trans>Ime storitve</Trans>
                </Text>{" "}
                <Text
                  span
                  c={Color.SecondaryText}
                  variant={TextVariant.Caption}
                >
                  ({localizedName.language.toUpperCase()})
                </Text>
              </Text>
            }
            {...form.getInputProps(`nameLocalized.${index}.name`)}
          />
        ))}
      </Flex>

      <Box pb={"lg"}>
        <Divider my={"md"} />

        <Flex justify={"center"} align={"center"} gap={"md"}>
          <Button variant="white" onClick={cancel}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={() => {
              let isValid = true;
              form.values.nameLocalized.forEach((_, index) => {
                const result = form.validateField(
                  `namesLocalized.${index}.name`,
                );

                if (result.hasError) {
                  isValid = false;
                }
              });

              if (!isValid) {
                return;
              }

              save();
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

const CreateCategoryModal = ({
  opened,
  cancel,
  save,
}: {
  opened: boolean;
  cancel: () => void;
  save: (categoryId: number | undefined) => void;
}) => {
  return (
    <Modal
      opened={opened}
      onClose={cancel}
      centered
      withinPortal={true}
      styles={{
        root: {
          position: "fixed",
          left: 0,
          zIndex: 200,
        },
        inner: {
          padding: 0,
        },
      }}
      title={t`Nastavitve prevodov`}
      radius={12}
    >
      <Divider orientation={"horizontal"} />

      <ManageCategory
        catId={"-1"}
        handleClose={(categoryId) => save(categoryId)}
        withoutHeader
      />
    </Modal>
  );
};

const CreateCategoryDrawer = ({
  opened,
  cancel,
  save,
}: {
  opened: boolean;
  cancel: () => void;
  save: (categoryId: number | undefined) => void;
}) => {
  return (
    <Drawer
      opened={opened}
      onClose={cancel}
      title={t`Dodaj kategorijo`}
      position={"bottom"}
      transitionProps={{
        transition: "slide-up",
      }}
      offset={0}
      padding={0}
      styles={{
        header: {
          padding: "0 1rem",
        },
        content: {
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        },
      }}
      size={"510px"}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
    >
      <ManageCategory
        catId={"-1"}
        withoutHeader
        handleClose={(categoryId) => save(categoryId)}
      />
    </Drawer>
  );
};
