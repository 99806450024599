import { useLingui } from "@lingui/react";
import styled from "styled-components";
import SelectedServiceListItem from "./SelectedServiceListItem";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Button } from "@heroui/react";
import { Plus } from "lucide-react";
import { Trans } from "@lingui/react/macro";
import { formatCurrency } from "../../../../../shared/utils/utils";
import { i18n } from "@lingui/core";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";

const SelectedServiceList = ({
  selectedServices,
  setSelectedServices,
  services,
  isPreScheduled,
  isNewAppointment,
}) => {
  useLingui();

  const { permissions } = useUserPermissionsStore();

  const { locations, selectedLocationId } = useCalendarStore((state) => ({
    locations: state.locations,
    selectedLocationId: state.selectedLocationId,
  }));

  const selectedLocation = locations.find(
    (location) => location.locationId === selectedLocationId,
  );

  const totalDuration = (() => {
    if (selectedServices.length === 0) return 0;

    return selectedServices.reduce(
      (acc, service) =>
        acc +
        (service.timeOffDuration || 0) +
        (service.startDuration || 0) +
        (service.endDuration || 0),
      0,
    );
  })();

  const totalPrice = (() => {
    if (selectedServices.length === 0) return 0;

    return selectedServices.reduce(
      (acc, service) => acc + (service.price || 0),
      0,
    );
  })();

  return (
    <ServiceList>
      {selectedServices.length > 0 &&
        selectedServices.map((selectedService, index) => {
          const service = services.find(
            (service) => service.serviceId === selectedService.serviceId,
          );

          if (service == null) return null;

          const { startDuration, timeOffDuration, endDuration } =
            selectedService;

          const hasOnlyOneInput =
            timeOffDuration == null &&
            (startDuration == null || endDuration == null);

          return (
            <SelectedServiceListItem
              key={index}
              selectedService={selectedService}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              service={service}
              index={index}
              isNewAppointment={isNewAppointment}
              isPreScheduled={isPreScheduled}
              startDuration={startDuration}
              hasOnlyOneInput={hasOnlyOneInput}
              timeOffDuration={timeOffDuration}
              endDuration={endDuration}
              defaultOpen={true}
            ></SelectedServiceListItem>
          );
        })}
      {!isPreScheduled && permissions.manage_appointments ? (
        <Button
          onPress={() => {
            const newServices = [
              ...selectedServices,
              {
                ...structuredClone(selectedServices.at(-1)),
                appointmentId: undefined,
                removed: false,
                defaultOpen: true,
              },
            ];

            setSelectedServices(newServices);
          }}
          variant="light"
          className="mb-2 rounded-full"
          size="sm"
          startContent={<Plus size={16} color={"#61906f"}></Plus>}
        >
          <Trans>Dodaj storitev</Trans>
        </Button>
      ) : null}

      {selectedServices?.length > 0 && (
        <>
          <div className="flex gap-2 text-sm">
            <p className="font-medium">
              <Trans>Skupni čas vseh storitev</Trans>:
            </p>
            <p>
              {totalDuration} <Trans>min</Trans>
            </p>
          </div>

          <div className="flex gap-2 text-sm">
            <p className="font-medium">
              <Trans>Skupna cena vseh storitev</Trans>:
            </p>
            <p>
              {formatCurrency({
                amount: totalPrice,
                currency: selectedLocation?.currency || "EUR",
                locale: i18n.locale,
              })}
            </p>
          </div>
        </>
      )}
    </ServiceList>
  );
};

const ServiceList = styled.div`
  border-bottom: 1px solid #eaebed;
  padding: 10px 0;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  .list-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    .arrow-down {
      color: #868e96;
      font-size: 2rem;
    }
  }

  .list-options {
    z-index: 1000;
    width: 250px;
    min-height: 100px;
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 7px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    padding-bottom: 0;

    @media (max-width: 768px) {
      width: 86vw;
    }

    .list-option {
      width: 100%;
      padding: 10px 10px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
`;

export default SelectedServiceList;
