import { GetLookupCompanyData } from "@/server-types";
import { get } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";

export const useGetLookupCompanyData = () => {
  return useCustomMutation({
    mutationKey: ["companyDataByTaxNumber"],
    mutationFn: (taxNumber: string) =>
      get<GetLookupCompanyData>(`/external/company-data`, {
        provider: "pirs",
        taxNumber,
      }),
  });
};
