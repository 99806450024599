import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import LegalPages from "@/Components/LegalPages";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import type React from "react";
import { useEffect } from "react";
import { Image, Link, Spacer } from "@heroui/react";
import { useSearchParams } from "react-router";
import { LimeLocalePicker } from "@/Components/NextBase/LimeLocalePicker";
import {
  Locale,
  SUPPORTED_LOCALES,
} from "../../../../shared/constants/locales";

type AuthFormWrapperProps = {
  isLogin: boolean;

  children: React.ReactNode;
};

export const AuthFormWrapper = ({
  isLogin,

  children,
}: AuthFormWrapperProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang") ?? "sl";

  if (SUPPORTED_LOCALES.indexOf(lang as Locale) === -1) {
    const params = new URLSearchParams(searchParams);
    params.delete("lang");
    setSearchParams(params);
  }

  const { preferredLanguage, setPreferredLanguage } = usePreferredLanguageStore(
    (state) => state,
  );

  useEffect(() => {
    if (lang) {
      setPreferredLanguage({
        userPreferredLanguage: lang as Locale,
        clientPreferredLanguage: lang as Locale,
      });
    }
  }, [lang]);

  return (
    <>
      <LimeLocalePicker
        value={lang as Locale}
        onChange={(lang) => {
          const params = new URLSearchParams(searchParams);
          params.set("lang", lang);
          setSearchParams(params);
        }}
        className="absolute right-2 top-2 z-20 w-44"
        label={undefined}
      />

      <div className="mx-auto flex h-screen w-full max-w-lg flex-col items-center justify-center px-6">
        <Image
          src="/logo_limebooking_full_new.png"
          alt="logo"
          className="mb-4 h-auto w-72"
        />
        <p className="mb-1 text-center text-xl font-semibold md:text-2xl">
          {isLogin
            ? t`Prijava v Lime Booking račun`
            : t`Ustvari si uporabniški račun`}
        </p>
        <p className="text-sm text-gray-500">
          {isLogin
            ? t`Dobrodošli nazaj!`
            : t`Preizkusi Lime Booking brezplačno!`}
        </p>

        <Spacer y={8} />

        <div className="flex w-full flex-col gap-4">{children}</div>

        <Spacer y={8} />

        <p className="text-sm text-default-500">
          <span className="pr-2">
            {isLogin ? t`Še nimaš računa?` : t`Si že registriran?`}
          </span>
          {isLogin && (
            <Link
              data-identifier="register-redirect"
              className="text-sm font-semibold text-primary"
              href={"/register"}
            >
              <Trans>Registriraj se</Trans>
            </Link>
          )}
        </p>
        <Link
          className="mt-1 text-sm font-medium text-primary"
          href={isLogin ? "/forgot-password" : "/login"}
        >
          {isLogin ? t`Si pozabil geslo?` : t`Prijavi se`}
        </Link>

        <Spacer y={8} />

        <LegalPages
          language={lang || preferredLanguage.userPreferredLanguage || "sl"}
        />
      </div>
    </>
  );
};
