import { Accordion, AccordionProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";

export type LimeAccordionProps = Omit<MantineFormValues, "onChange"> &
  AccordionProps;

export const LimeAccordion = React.forwardRef(
  (
    { error, ...props }: LimeAccordionProps,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <Accordion
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            overflowY: "unset",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease-out",
                duration: 0.2,
              },
            },
          },
          exit: {
            y: 0,
            opacity: 0,
            height: 0,
            overflowY: "hidden",
            transition: {
              height: {
                easings: "spring",
                stiffness: 500,
                damping: 30,
                duration: 0.2,
              },
              opacity: {
                easings: "ease-out",
                duration: 0.2,
              },
            },
          },
        },
      }}
    >
      {props.children}
    </Accordion>
  ),
);
LimeAccordion.displayName = "LimeAccordion";
