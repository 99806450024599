import { cn, Switch, SwitchProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";

type Switch = Omit<
  MantineFormValues &
    SwitchProps & {
      description?: string;
      isCompact?: boolean;
    },
  "children"
>;

export const LimeSwitch = React.forwardRef(
  (
    { error, title, description, isCompact, ...props }: Switch,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <Switch
      className={props.className}
      ref={ref}
      {...props}
      classNames={{
        ...props.classNames,
        base: cn(
          "inline-flex flex-row-reverse w-full max-w-none bg-content1 hover:bg-content2 items-center",
          "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
          "data-[selected=true]:border-default-200",
          { "p-2": isCompact },
          props.classNames?.base,
        ),
        thumb: cn(
          "group-data-[hover=true]:border-primary",
          "group-data-[pressed=true]:w-7",
          "group-data-[selected]:group-data-[pressed]:ml-4",
          props.classNames?.thumb,
        ),
      }}
      isSelected={props.checked}
      onValueChange={props.onChange}
    >
      <div className="flex flex-col gap-1">
        <p
          className={cn("text-medium", {
            "text-sm": isCompact,
          })}
        >
          {title}
        </p>
        {description != null && (
          <p className="text-tiny text-default-400">{description}</p>
        )}
      </div>
    </Switch>
  ),
);
LimeSwitch.displayName = "LimeSwitch";
