import { useSearchParams } from "react-router";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import { ResourceColumn } from "./ResourceColumn/ResourceColumn";
import UserColumn from "./UserColumn";

const DayColumn = ({ day, timeoffs, isOnlyDay, redLineData }) => {
  const { calendarMode, resources } = useCalendarStore((state) => state);
  const [params] = useSearchParams();

  const resourcesInOrder = resources.filter((r) => r.isEnabledInFilter);
  const usersInOrder = day.users;

  return (
    <Wrapper className="dayColumn" isOnlyDay={isOnlyDay}>
      {redLineData && (
        <div
          className="border-b-0.5 pointer-events-none absolute left-0 z-[9999] w-full border-1 border-red-500"
          style={{ top: redLineData.yOffset }}
        >
          <div className="absolute left-[-1px] z-[9999] h-[20px] w-[45px] -translate-x-full -translate-y-1/2 rounded-[5px] bg-red-500 text-center text-[12px] font-bold leading-[20px] text-white">
            {redLineData.currentTime}
          </div>
        </div>
      )}

      {calendarMode === "users" && (
        <div className={"users"}>
          {day.users
            .filter(
              (u) => !params.get("u") || u.userId === Number(params.get("u")),
            )
            .map((user, index) => (
              <UserColumn
                isLastChild={index === day.users.length - 1}
                user={user}
                key={index}
                date={day.date}
                timeoffs={timeoffs.filter((t) => t.userId === user.userId)}
                usersInOrder={usersInOrder.map((u) => u.userId)}
              />
            ))}
        </div>
      )}

      {calendarMode === "resources" && (
        <div className="resources">
          {resourcesInOrder
            .filter((r) => !params.get("r") || r.id === Number(params.get("r")))
            .map((resource) => (
              <ResourceColumn
                resource={resource}
                date={day.date}
                resourcesInOrder={resourcesInOrder.map((r) => r.id)}
                key={resource.id}
              />
            ))}
        </div>
      )}

      {day.disableOnHolidays && <HolidayDisabledOverlay />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  flex: 1;

  ${(props) =>
    !props.isOnlyDay &&
    `
      border-right: 2px solid #c3c5c6;
      :not(:last-child) {
        border-right: 2px solid #8c8c8c;
      }
      @media (max-width: 768px) {
        width: fit-content;
      }
    `}
  .users,
  .resources {
    display: flex;
  }
`;

const HolidayDisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
    159.5deg,
    transparent,
    transparent 8px,
    rgb(243, 244, 247) 8px,
    rgb(243, 244, 247) 18px
  );
  background-color: white;
  pointer-events: none;
  z-index: -2;
`;

export default DayColumn;
