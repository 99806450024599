import { t } from "@lingui/core/macro";
import { Box } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import {
  Button,
  Divider,
  getKeyValue,
  Link,
  Skeleton,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { i18n } from "@lingui/core";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { Trans } from "@lingui/react/macro";
import { preparePrintData } from "@/utils/pos-printer/index";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { useState } from "react";
import { toast } from "sonner";
import { cn } from "@/utils";
import { getPaymentStatusIcon } from "./Util";

export const GiftCardDetails = () => {
  const { locale } = i18n;
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedLocationId, setSelectedLocationId] = useState<null | number>(
    null,
  );

  const giftCardId = id ? Number(id) : undefined;

  const { data, isFetching, processedErrorMessage, refetch } =
    api.giftCard.useGetGiftCard(giftCardId);

  const { data: locations, isFetching: isLocationsFetching } =
    api.location.useGetLocations({
      page: 1,
      perPage: 100,
      sortBy: "labelDesc",
    });

  const {
    mutateAsync: confirmBankPayment,
    isPending: isConfirmingBankPayment,
    processedErrorMessage: confirmBankPaymentErrorMessage,
  } = api.giftCard.usePostConfirmGiftCardBankPayment();

  const { printReceipt, selectedPrinterType } = usePosPrinterStore(
    (state) => state,
  );

  const handlePrintGiftCard = async () => {
    if (!data || !data.printData) return;

    const printerConfig =
      selectedPrinterType === "qztray"
        ? locations?.locations.find(
            (location) => location.locationId == selectedLocationId,
          )?.PosPrinter || null
        : null;

    const printReceiptPromise = async () => {
      const printData = await preparePrintData(
        {
          type: "gift-card",
          data: {
            ...data.printData!,
            giftCard: data.printData!.giftCard,
          },
        },
        printerConfig,
      );

      await printReceipt({
        printData,
        printerConfig,
        shouldThrow: true,
      });
    };

    toast.promise(printReceiptPromise(), {
      loading: t`Pošiljanje darilnega bona na tiskalnik...`,
      success: t`Darilni bon je bil uspešno poslan na tiskalnik`,
      error: (err) => err?.toString() || t`Tiskanje računa ni uspelo`,
    });
  };

  const tableColumns: { key: string; label: string }[] = [
    {
      key: "amount",
      label: t`Vrednost transakcije`,
    },
    {
      key: "date",
      label: t`Datum`,
    },
    {
      key: "source",
      label: t`Vir`,
    },
  ];

  const handleConfirmBankPayment = async () => {
    if (!giftCardId) return;

    await confirmBankPayment(giftCardId);
    refetch();
  };

  const isEditing = giftCardId !== -1;

  return (
    <>
      <LimePageHeader
        title={isEditing ? t`Uredi darilni bon` : t`Dodaj nov darilni bon`}
        subPage={{
          title: `Darilni bon - ${data?.code}`,
          onBackButtonClick: () => navigate(`/dashboard/gift-card`),
        }}
      />

      <Box maw={"550px"} mx={"auto"} mt="md">
        <LimeAlert color="danger" message={processedErrorMessage} />

        <LimeSelect
          label={t`Lokacija`}
          items={
            locations?.locations.map((location) => ({
              key: location.locationId.toString(),
              label: location.label,
            })) || []
          }
          isLoading={isLocationsFetching}
          className="mb-2"
          onChange={setSelectedLocationId}
          value={selectedLocationId?.toString()}
        />

        <Button
          color="primary"
          fullWidth
          onPress={handlePrintGiftCard}
          isDisabled={!selectedLocationId}
        >
          <Trans>Tiskaj darilni bon</Trans>
        </Button>

        {data?.createdByInvoiceId ? (
          <>
            <Divider className="my-4" />

            <p className="text-sm">
              <Trans>Ta darilni bon je povezan na</Trans>{" "}
              <Link
                showAnchorIcon
                href={`/dashboard/tax-register/invoice/${data.createdByInvoiceId}`}
              >
                račun
              </Link>
            </p>
          </>
        ) : undefined}

        <Divider className="my-4" />

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium">
              <Trans>Stanje plačila:</Trans>{" "}
            </p>
            {data ? (
              getPaymentStatusIcon(data?.paymentStatus, true)
            ) : (
              <Spinner size="sm" />
            )}
          </div>

          <div className="flex items-center gap-2 text-sm">
            <p>
              <Trans>Skupna vrednost darilnega bona</Trans>:{" "}
            </p>
            <Skeleton isLoaded={!isFetching} className="rounded-full">
              {data?.initialAmountFormatted}
            </Skeleton>
          </div>

          {data?.paymentStatus === "not_paid" && (
            <Button
              onPress={handleConfirmBankPayment}
              color="primary"
              className="w-fit"
              isLoading={isConfirmingBankPayment}
            >
              <Trans>Potrdi bančno plačilo</Trans>
            </Button>
          )}
        </div>

        <Divider className="my-4" />

        {data?.note ? (
          <>
            <p>
              <Trans>Opomba:</Trans>
            </p>
            <p className="text-sm text-gray-600">{data.note}</p>

            <Divider className="my-4" />
          </>
        ) : undefined}

        <Table
          classNames={{
            wrapper: "p-0",
          }}
          shadow="none"
        >
          <TableHeader columns={tableColumns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            isLoading={isFetching}
            emptyContent={t`Ni transakcij`}
            items={
              data?.transactions.map((transaction) => {
                const userFullName = (() => {
                  if (!transaction.giftCardTransactionCreatedBy) return "/";

                  const firstName =
                    transaction.giftCardTransactionCreatedBy.name || "/";
                  const lastName =
                    transaction.giftCardTransactionCreatedBy.lastName || "/";
                  return `${firstName} ${lastName}`;
                })();

                return {
                  id: transaction.id,
                  amount: transaction.transactionAmountFormatted,
                  date: new Date(transaction.transactionDate).toLocaleString(
                    locale,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    },
                  ),
                  source: userFullName,
                  isDeleted: transaction.isDeleted,
                };
              }) || []
            }
          >
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <span
                      className={cn("", {
                        "text-danger line-through": item.isDeleted,
                      })}
                    >
                      {getKeyValue(item, columnKey)}
                    </span>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
