import LimeDrawer from "@/Components/LimeDrawer/LimeDrawer";
import { Color } from "@/types/colors";
import { Flex, Select, Switch, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { RiArrowDownLine, RiListSettingsFill } from "react-icons/ri";
import { GetClientList } from "../../../../../../server/src/types";

import { useForm } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useAdminStore } from "../admin.store";
import { ShownColumns, columnsArray } from "../types";
import classes from "./FilterRow.module.css";
import { LimeMultiSelect } from "@/Components/LimeMultiSelect";
import { Button } from "@heroui/react";

export const FilterRow = ({
  industries,
  salesPeople,
  query,
  setQuery,
  pricingTiers,
}: {
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
  pricingTiers: NonNullable<GetClientList["response"]["pricingTiers"]>;
  query: string;
  setQuery: (query: string) => void;
}) => {
  const {
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    setSearchQuery,
  } = useAdminStore((state) => state);

  return (
    <div className={classes.filterRow}>
      <TextInput
        label="Iskanje"
        w={"fit-content"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      ></TextInput>

      <Flex align={"flex-end"}>
        <Select
          label="Sortiraj po:"
          data={
            [
              { label: "Ime podjetja", value: "businessName" },
              { label: "Datum registracije", value: "dateCreated" },
              {
                label: "Datum zadnje aktivnosti",
                value: "lastAppointmentDate",
              },
              {
                label: "Datum obračuna",
                value: "billingCycleDate",
              },
            ] as {
              label: string;
              value: GetClientList["query"]["orderBy"];
            }[]
          }
          value={orderBy}
          onChange={(e) => {
            setOrderBy(e as GetClientList["query"]["orderBy"]);
          }}
        ></Select>
        <Button
          endContent={
            <RiArrowDownLine
              style={{
                rotate: orderDirection === "desc" ? "0deg" : "180deg",
                transition: "all 0.3 cubic-bezier(0.4, 0.2, 0.1,0)",
                fontSize: "18px",
              }}
            ></RiArrowDownLine>
          }
          variant="light"
          isIconOnly
          onClick={() =>
            setOrderDirection(orderDirection === "asc" ? "desc" : "asc")
          }
        ></Button>
      </Flex>
      <AdminFilter
        industries={industries}
        salesPeople={salesPeople}
        pricingTiers={pricingTiers}
      ></AdminFilter>
    </div>
  );
};

const AdminFilter = ({
  pricingTiers,
  industries,
  salesPeople,
}: {
  pricingTiers: {
    pricingTierId: string;
    tierName: string;
  }[];
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
}) => {
  const [opened, setOpened] = useState(false);

  const {
    selectedIndustries,
    selectedSalesPeople,
    selectedTiers,
    showDisabled,
    shownColumns,

    setSelectedIndustries,
    setSelectedSalesPeople,
    setSelectedTiers,
    setShowDisabled,
    setShownColumns,
  } = useAdminStore((state) => state);

  const form = useForm({
    initialValues: {
      selectedTiers,
      showDisabled,
      selectedSalesPeople,
      selectedIndustries,
      shownColumns,
    },
  });

  return (
    <>
      <Button
        style={{
          background: Color.TableHeader,
        }}
        className="flex h-full justify-center p-2 px-2"
        onClick={() => setOpened(true)}
        isIconOnly
      >
        <RiListSettingsFill
          style={{ fontSize: "22px" }}
          color={Color.SecondaryText}
        ></RiListSettingsFill>
      </Button>

      <LimeDrawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Filter"
        buttons={{
          save: (
            <Button
              onClick={() => {
                setOpened(false);

                setSelectedIndustries(form.values.selectedIndustries);
                setSelectedSalesPeople(form.values.selectedSalesPeople);
                setSelectedTiers(form.values.selectedTiers);
                setShowDisabled(form.values.showDisabled);
                setShownColumns(form.values.shownColumns);
              }}
              color="primary"
            >
              Shrani
            </Button>
          ),
          cancel: (
            <Button
              variant="bordered"
              onClick={() => {
                setOpened(false);
                form.reset();
              }}
            >
              Prekliči
            </Button>
          ),
        }}
      >
        <div className="flex flex-col gap-4">
          <Switch
            label={"Prikaži onemogočene"}
            {...form.getInputProps("showDisabled", {
              type: "checkbox",
            })}
          ></Switch>

          <div>
            <Button
              onClick={() => {
                if (form.values.selectedTiers.length === pricingTiers.length) {
                  form.setFieldValue("selectedTiers", []);
                } else {
                  form.setFieldValue(
                    "selectedTiers",
                    pricingTiers.map((pt) => pt.pricingTierId),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              Izberi vse
            </Button>
            <LimeMultiSelect
              data={pricingTiers.map((pt) => ({
                label: pt.tierName,
                value: pt.pricingTierId,
              }))}
              {...form.getInputProps("selectedTiers")}
              label="Paketi"
            ></LimeMultiSelect>
          </div>

          <div>
            <Button
              onClick={() => {
                if (
                  form.values.selectedSalesPeople.length === salesPeople.length
                ) {
                  form.setFieldValue("selectedSalesPeople", []);
                } else {
                  form.setFieldValue(
                    "selectedSalesPeople",
                    salesPeople.map((sp) => sp),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              Izberi vse
            </Button>
            <LimeMultiSelect
              data={salesPeople.map((sp) => ({ label: sp, value: sp }))}
              {...form.getInputProps("selectedSalesPeople")}
              label="Sales people"
            ></LimeMultiSelect>
          </div>

          <div>
            {" "}
            <Button
              onClick={() => {
                if (
                  form.values.selectedIndustries.length === industries.length
                ) {
                  form.setFieldValue("selectedIndustries", []);
                } else {
                  form.setFieldValue(
                    "selectedIndustries",
                    industries.map((i) => i),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              Izberi vse
            </Button>
            <LimeMultiSelect
              data={industries.map((i) => i)}
              {...form.getInputProps("selectedIndustries")}
              label="Industrija"
            ></LimeMultiSelect>
          </div>

          <LimeMultiSelect
            data={columnsArray.map((c) => ({ label: c, value: c }))}
            label="Prikazani stolpci"
            {...form.getInputProps("shownColumns")}
          ></LimeMultiSelect>
        </div>
      </LimeDrawer>
    </>
  );
};

// export type FilterFormValues = {
//   selectedTiers: NonNullable<GetClientList["query"]["tiers"]>;
//   showDisabled: boolean;
//   selectedSalesPeople: GetClientList["query"]["selectedSalesPeople"];
//   selectedIndustries: GetClientList["query"]["selectedIndustries"];
//   shownColumns: ShownColumns;
// };
