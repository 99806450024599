import { LimeAccordion } from "@/Components/NextBase/LimeAccordion";
import { api } from "@/lib/api-client";
import { GetClientBillingDetails } from "@/server-types";
import {
  AccordionItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import dayjs from "dayjs";

const BillingDetails = ({ clientId }: { clientId: number }) => {
  const { data, isLoading } = api.admin.useGetClientBillingDetails(clientId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const tierDetails = data.ClientTierBillingRecord[0]
    ? getTierDetails(data.ClientTierBillingRecord[0])
    : null;

  const features = getFeaturesDetails(data.ClientFeatureBillingRecord);

  const additionalItems = getAdditionalItemsDetails(
    data.ClientAdditionalItemBillingRecord,
  );

  const tierPrice = tierDetails?.priceWithDiscount ?? 0;
  const featuresPrice = features.reduce(
    (acc, feature) => acc + feature.priceWithDiscount,
    0,
  );
  const additionalItemsPrice = additionalItems.reduce(
    (acc, item) => acc + item.priceWithDiscount,
    0,
  );

  const totalPrice = tierPrice + featuresPrice + additionalItemsPrice;
  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="text-xl font-bold">
        <Trans>Pregled</Trans>
      </div>
      <Table removeWrapper>
        <TableHeader>
          <TableColumn>
            <Trans>Cena paketa</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena funkcionalnosti</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena dodatkov</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Končna cena</Trans>
          </TableColumn>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>{tierPrice.toFixed(2)} €</TableCell>
            <TableCell>{featuresPrice.toFixed(2)} €</TableCell>
            <TableCell>{additionalItemsPrice.toFixed(2)} €</TableCell>
            <TableCell>{totalPrice.toFixed(2)} €</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <LimeAccordion title={t`Pregled`} isCompact selectionMode="multiple">
        {tierDetails ? (
          <AccordionItem title={t`Paket`}>
            <Table removeWrapper>
              <TableHeader>
                <TableColumn>
                  <Trans>Ime paketa</Trans>
                </TableColumn>
                <TableColumn>
                  <Trans>Datum začetka obračunavanja</Trans>
                </TableColumn>
                <TableColumn>
                  <Trans>Cena paketa brez popusta</Trans>
                </TableColumn>
                <TableColumn>
                  <Trans>Popust</Trans>
                </TableColumn>
                <TableColumn>
                  <Trans>Končna cena</Trans>
                </TableColumn>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>{tierDetails.name}</TableCell>
                  <TableCell>{tierDetails.activationDate}</TableCell>
                  <TableCell>{tierDetails.price} €</TableCell>
                  <TableCell>{tierDetails.discount} %</TableCell>
                  <TableCell>{tierDetails.priceWithDiscount} €</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionItem>
        ) : null}

        <AccordionItem title={t`Funkcionalnosti`}>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime funkcionalnosti</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Datum začetka obračunavanja</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena brez popusta</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Končna cena</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.name}>
                  <TableCell>{feature.name}</TableCell>
                  <TableCell>{feature.activationDate}</TableCell>
                  <TableCell>{feature.price} €</TableCell>
                  <TableCell>{feature.discount} %</TableCell>
                  <TableCell>{feature.priceWithDiscount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionItem>

        <AccordionItem title={t`Dodatki`}>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime dodatka</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Datum začetka obračunavanja</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena brez popusta</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Končna cena</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {additionalItems.map((item) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.activationDate}</TableCell>
                  <TableCell>{item.price} €</TableCell>
                  <TableCell>{item.discount} %</TableCell>
                  <TableCell>{item.priceWithDiscount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionItem>
      </LimeAccordion>
    </div>
  );
};

function getTierDetails(
  tierData: GetClientBillingDetails["response"]["ClientTierBillingRecord"][number],
) {
  const price = tierData.priceCents / 100;
  const discount = Number(tierData.discountAmount);
  const priceWithDiscount = price * (1 - discount / 100);

  const priceWithDiscount2Decimals = Math.ceil(priceWithDiscount * 100) / 100;

  const name = tierData.pricingTier.tierName;
  const activationDate = dayjs(tierData.activationDate).format("DD.MM.YYYY");

  return {
    name,
    price,
    priceWithDiscount: priceWithDiscount2Decimals,
    discount,
    activationDate,
  };
}

const getFeaturesDetails = (
  features: GetClientBillingDetails["response"]["ClientFeatureBillingRecord"],
) => {
  return features.map((feature) => {
    const price = feature.priceCents / 100;
    const discount = Number(feature.discountAmount);
    const priceWithDiscount = price * (1 - discount / 100);

    const priceWithDiscount2Decimals = Math.ceil(priceWithDiscount * 100) / 100;
    return {
      name: feature.feature.name,
      activationDate: dayjs(feature.activationDate).format("DD.MM.YYYY"),
      price,
      discount,
      priceWithDiscount: priceWithDiscount2Decimals,
    };
  });
};

const getAdditionalItemsDetails = (
  additionalItems: GetClientBillingDetails["response"]["ClientAdditionalItemBillingRecord"],
) => {
  return additionalItems.map((item) => {
    const price = item.priceCentsPer / 100;
    const discount = Number(item.discountAmount);
    const priceWithDiscount = price * (1 - discount / 100);

    const priceWithDiscount2Decimals = Math.ceil(priceWithDiscount * 100) / 100;

    return {
      name: item.additionalItemType,
      activationDate: dayjs(item.activationDate).format("DD.MM.YYYY"),
      price,
      discount,
      priceWithDiscount: priceWithDiscount2Decimals,
    };
  });
};

export default BillingDetails;
