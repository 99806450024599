import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { ExportPdfModal } from "@/Components/ExportPdfModal/ExportPdfModal";
import { Color } from "@/types/colors";
import { useLingui } from "@lingui/react";
import {
  Accordion,
  Button,
  Drawer,
  Flex,
  LoadingOverlay,
  Select,
  Space,
  Text,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { IoMdWarning } from "react-icons/io";
import { RiCheckboxCircleLine, RiListSettingsFill } from "react-icons/ri";
import styled from "styled-components";
import StepperNumberInput from "../../../../Components/AppointmentModal/components/StepperNumberInput";
import useFeature from "../../../../hooks/useFeature";
import { useCalendarStore } from "../store/useCalendarStore";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { AutocompleteItem } from "@heroui/react";

const Filter = ({ IS_GLOWUP_USER, IS_ILLUME_USER }) => {
  useLingui();
  const {
    filterItems,
    users,
    services,
    locations,
    refreshWithNewFilter,
    locationActions,
    fetchingCalendarData,
    refreshCalendar,
  } = useCalendarStore((state) => state);
  useLingui();

  const [opened, setOpened] = useState(false);

  const [filter, setFilter] = useState({});

  useEffect(() => {
    setFilter(JSON.parse(JSON.stringify(filterItems)));
  }, [filterItems]);

  const openFilter = () => {
    setFilter(JSON.parse(JSON.stringify(filterItems)));
    setOpened(true);
  };

  const closeFilter = () => {
    setFilter(JSON.parse(JSON.stringify(filterItems)));
    setOpened(false);
  };

  const saveFilter = () => {
    refreshWithNewFilter(filter);
    setOpened(false);
  };

  const hasUsersSelected = filter?.users?.length > 0;
  const hasServiceSelected = filter?.services?.length > 0;
  const hasDaySelected = filter?.days?.length > 0;
  const hasResourcesSelected = filter?.resources?.length > 0;

  const canSave = useMemo(
    () =>
      (filter.calendarMode !== "users" || hasUsersSelected) &&
      hasServiceSelected &&
      hasDaySelected &&
      (filter.calendarMode !== "resources" || hasResourcesSelected),
    [
      filter,
      hasUsersSelected,
      hasServiceSelected,
      hasDaySelected,
      hasResourcesSelected,
    ],
  );

  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <Wrapper>
      <Button
        style={{
          height: "100%",
          background: Color.TableHeader,
        }}
        justify={"center"}
        align={"center"}
        px={"sm"}
        onClick={openFilter}
      >
        <RiListSettingsFill
          style={{ fontSize: "22px" }}
          color={Color.SecondaryText}
        ></RiListSettingsFill>
      </Button>

      {locations?.length > 0 && (
        <DrawerWrapper
          size={isDesktop ? 500 : "80vh"}
          position={isDesktop ? "right" : "bottom"}
          opened={opened}
          onClose={closeFilter}
          title={"Filter"}
        >
          <Drawer.Overlay opacity={0.35} blur={0} />
          <Drawer.Content
            className={"drawer-outer-content"}
            style={{ overflowY: "scroll" }}
          >
            <Drawer.Header>
              <Drawer.Title>
                <Trans>Filter</Trans>
              </Drawer.Title>
              <Drawer.CloseButton></Drawer.CloseButton>
            </Drawer.Header>
            <Drawer.Body style={{ paddingBottom: "0px" }}>
              <FilterContent
                filterItems={filter}
                setFilterItems={setFilter}
                users={users}
                services={services.filter((s) => s.permission)}
                locations={locations}
                locationActions={locationActions}
                isLoading={fetchingCalendarData}
                hasUsersSelected={hasUsersSelected}
                hasServiceSelected={hasServiceSelected}
                hasDaySelected={hasDaySelected}
                refreshCalendar={refreshCalendar}
                hasResourcesSelected={hasResourcesSelected}
              ></FilterContent>
              <Space h="10px"></Space>
              {!IS_GLOWUP_USER && !IS_ILLUME_USER && (
                <>
                  <ExportPdfModal></ExportPdfModal>
                  <Space h="5px"></Space>
                </>
              )}
            </Drawer.Body>
            <Buttons className={"buttons"}>
              <UnstyledButton onClick={closeFilter}>
                <Trans>Prekliči</Trans>
              </UnstyledButton>
              <Button disabled={!canSave} onClick={saveFilter}>
                <Trans>Shrani</Trans>
              </Button>
            </Buttons>
          </Drawer.Content>
        </DrawerWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .icon {
    background: #f8f8f8;
    cursor: pointer;
    /* height: 100%; */
    /* width: auto; */
    padding: 5px;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    aspect-ratio: unset;
    .icon {
      padding: 7.5px;
    }
  }
`;

const Buttons = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;

  align-items: center;

  justify-content: center;
  background: white;
  height: 100px;
  padding: 20px;
  border-top: 1px solid rgb(234, 235, 237);

  gap: 10px;
`;

const DrawerWrapper = styled(Drawer.Root)`
  .mantine-Drawer-body {
    flex: 1;
    overflow: scroll;
  }

  .drawer-outer-content {
    /* display: flex;
    flex-direction: column; */

    border-radius: 20px 20px 0 0;

    //padding-top: 10px;
  }

  .mantine-Drawer-title {
    color: #424b64;
    font-size: 24px;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    .mantine-Drawer-inner {
      right: 20px;
      margin: 20px 0;
    }

    .mantine-Drawer-content {
      border-radius: 12px;

      padding-top: unset;
    }
  }
`;

const FilterContent = ({
  filterItems,
  users,
  services,
  locations,
  setFilterItems,
  locationActions,
  isLoading,
  hasDaySelected,
  hasServiceSelected,
  hasUsersSelected,
  refreshCalendar,
  hasResourcesSelected,
}) => {
  const { resources } = useCalendarStore((state) => state);

  const { isFeatureEnabled } = useFeature();
  const { i18n } = useLingui();
  const locale = i18n.locale;

  const days = [...Array(7).keys()].map((i) => {
    const day = dayjs().locale(locale).startOf("week").add(i, "day");
    return {
      dayId: day.day(),
      name: capitalize(day.format("dddd")),
    };
  });

  if (days[0].dayId === 0) {
    const firstDay = days.shift();
    days.push(firstDay);
  }

  const setLocation = (location) => {
    setFilterItems((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      newState.FK_locationId = location;
      return newState;
    });
    locationActions.setSelectedLocationId(location);
    refreshCalendar();
  };

  const setUser = (user) => {
    setFilterItems((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (newState.users.includes(user)) {
        newState.users = newState.users.filter((s) => s !== user);
      } else {
        newState.users.push(user);
      }
      return newState;
    });
  };

  const setResource = (resource) => {
    setFilterItems((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (newState.resources.includes(resource)) {
        newState.resources = newState.resources.filter((s) => s !== resource);
      } else {
        newState.resources.push(resource);
      }
      return newState;
    });
  };

  const setService = (service) => {
    setFilterItems((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (newState.services.includes(service)) {
        newState.services = newState.services.filter((s) => s !== service);
      } else {
        newState.services.push(service);
      }
      return newState;
    });
  };

  const setDay = (day) => {
    setFilterItems((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (newState.days.includes(day)) {
        newState.days = newState.days.filter((s) => s !== day);
      } else {
        newState.days.push(day);
      }

      return newState;
    });
  };

  const selectAllUsers = () => {
    if (users.every((s) => filterItems.users.includes(s.userId))) {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.users = [];
        return newState;
      });
    } else {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.users = users.map((s) => s.userId);
        return newState;
      });
    }
  };

  const selectAllResources = () => {
    if (resources.every((r) => filterItems.resources.includes(r.id))) {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.resources = [];
        return newState;
      });
    } else {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.resources = resources.map((r) => r.id);
        return newState;
      });
    }
  };

  const selectAllServices = () => {
    if (services.every((s) => filterItems.services.includes(s.serviceId))) {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.services = [];
        return newState;
      });
    } else {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.services = services.map((s) => s.serviceId);
        return newState;
      });
    }
  };

  const selectAllDays = () => {
    if (filterItems.days.length === days.length) {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.days = [];
        return newState;
      });
    } else {
      setFilterItems((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));
        newState.days = days.map((s) => s.dayId);
        return newState;
      });
    }
  };
  useLingui();

  const areResourcesEnabled = isFeatureEnabled("RESOURCES");

  return (
    <WrapperContent>
      <LoadingOverlay visible={isLoading} />

      <AccordionStyled>
        <LimeAutocomplete
          isVirtualized={false} // TODO: set to true after bugs are fixed (https://github.com/heroui-inc/heroui/issues/4494) , (https://github.com/heroui-inc/heroui/issues/4568)
          selectedKey={filterItems.FK_locationId?.toString()}
          size={"sm"}
          placeholder={t`Lokacija`}
          label={t`Lokacija`}
          variant="bordered"
          className="mb-2"
          scrollShadowProps={{
            isEnabled: false,
          }}
          onSelectionChange={(key) => {
            if (!key) return;

            setLocation(Number(key));
          }}
        >
          {locations?.map((location) => {
            return (
              <AutocompleteItem
                key={location.locationId.toString()}
                textValue={location.name}
              >
                {location.name}
              </AutocompleteItem>
            );
          })}
        </LimeAutocomplete>

        {areResourcesEnabled ? (
          <Select
            data={[
              { label: t`Zaposleni`, value: "users" },
              { label: t`Sredstva`, value: "resources" },
            ]}
            value={filterItems.calendarMode}
            onChange={(value) => {
              setFilterItems((prev) => {
                const newState = JSON.parse(JSON.stringify(prev));
                newState.calendarMode = value;

                return newState;
              });
            }}
            label={t`Pogled koledarja`}
            allowDeselect={false}
          />
        ) : null}

        <Space h={"1rem"} />

        <Accordion.Item value={"users"}>
          <Accordion.Control>
            <Flex align={"center"}>
              <Trans>Zaposleni</Trans>

              {WarningIcon(
                <Trans>Izbrati morate vsaj 1 zaposlenega</Trans>,
                filterItems.calendarMode === "users" && !hasUsersSelected,
              )}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <SelectAllButton
              color={
                users.every((u) => filterItems.users.includes(u.userId))
                  ? "#74B587"
                  : "#949494"
              }
              onClick={selectAllUsers}
            >
              <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
              <Trans>Izberi vse zaposlene</Trans>
            </SelectAllButton>
            {users.map((user, index) => (
              <StyledButton
                icon={"none"}
                color={
                  filterItems.users?.includes(user.userId)
                    ? "#74B587"
                    : "#ECEDEF"
                }
                key={index}
                onClick={() => setUser(user.userId)}
              >
                <div className={"color"}></div>
                {`${user.name} ${user.lastName}`}
              </StyledButton>
            ))}
          </Accordion.Panel>
        </Accordion.Item>

        {areResourcesEnabled ? (
          <Accordion.Item value={"resources"}>
            <Accordion.Control>
              <Flex align={"center"}>
                <Trans>Sredstva</Trans>

                {WarningIcon(
                  <Trans>Izbrati morate vsaj 1 sredstvo</Trans>,
                  filterItems.calendarMode === "resources" &&
                    !hasResourcesSelected,
                )}
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <SelectAllButton
                color={
                  resources.every((r) => filterItems.resources?.includes(r.id))
                    ? "#74B587"
                    : "#949494"
                }
                onClick={selectAllResources}
              >
                <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
                <Trans>Izberi vsa sredstva</Trans>
              </SelectAllButton>
              {resources.map((resource, index) => (
                <StyledButton
                  icon={"none"}
                  color={
                    filterItems.resources.includes(resource.id)
                      ? "#74B587"
                      : "#ECEDEF"
                  }
                  key={index}
                  onClick={() => setResource(resource.id)}
                >
                  <div className={"color"}></div>
                  {`${resource.label}`}
                </StyledButton>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        ) : null}

        <Accordion.Item value={"service"}>
          <Accordion.Control>
            <Flex align={"center"}>
              <Trans>Storitve</Trans>
              {WarningIcon(
                "Izbrati morate vsaj 1 storitev",
                !hasServiceSelected,
              )}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <SelectAllButton
              color={
                services.every((s) =>
                  filterItems.services.includes(s.serviceId),
                )
                  ? "#74B587"
                  : "#949494"
              }
              onClick={selectAllServices}
            >
              <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
              <Trans>Izberi vse storitve</Trans>
            </SelectAllButton>
            {services.map((service, index) => (
              <StyledButton
                icon={"none"}
                color={
                  filterItems.services?.includes(service.serviceId)
                    ? (service.color ?? "#74B587")
                    : "#ECEDEF"
                }
                key={index}
                onClick={() => setService(service.serviceId)}
              >
                <div className={"color"}></div>
                {service.name}
              </StyledButton>
            ))}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value={"days"}>
          <Accordion.Control>
            <Flex align={"center"}>
              <Trans>Prikazani dnevi</Trans>
              {WarningIcon(`Izbrati morate vsaj 1 dan`, !hasDaySelected)}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <SelectAllButton
              color={
                filterItems.days.length === days.length ? "#74B587" : "#949494"
              }
              onClick={selectAllDays}
            >
              <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
              <Trans>Izberi vse dneve</Trans>
            </SelectAllButton>
            {days.map((day, index) => (
              <StyledButton
                icon={"none"}
                color={
                  filterItems.days?.includes(day.dayId) ? "#74B587" : "#ECEDEF"
                }
                key={index}
                onClick={() => setDay(day.dayId)}
              >
                <div className={"color"}></div>
                {day.name}
              </StyledButton>
            ))}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value={"calendar_settings"}>
          <Accordion.Control>
            <Trans>Nastavitve koledarja</Trans>
          </Accordion.Control>
          <Accordion.Panel>
            <Flex justify={"space-between"} align={"center"}>
              <Text>
                <Trans>Časovni korak</Trans>
              </Text>
              <StepperNumberInput
                value={filterItems.time_step}
                onChange={(value) => {
                  setFilterItems((prev) => {
                    const newState = JSON.parse(JSON.stringify(prev));
                    newState.time_step = value;
                    return newState;
                  });
                }}
                step={5}
                min={5}
                max={300}
                unitText={"min"}
              />
            </Flex>
            <Space h={"1rem"} />
          </Accordion.Panel>
        </Accordion.Item>
      </AccordionStyled>
    </WrapperContent>
  );
};

const WarningIcon = (tooltip, active) => {
  if (!active) return null;

  return (
    <Tooltip label={tooltip}>
      <div>
        <IoMdWarning
          size={"1.2rem"}
          style={{ marginLeft: "10px", color: "red" }}
        />
      </div>
    </Tooltip>
  );
};

const WrapperContent = styled.div``;

const AccordionStyled = styled(Accordion)`
  .mantine-Accordion-control {
    padding: 0;

    &:hover {
      background: unset;
    }
  }

  .mantine-Accordion-content {
    padding: 0;
  }
`;

const StyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  border-radius: 7px;
  padding: 0.5rem 1rem;

  width: 100%;

  border: 1px solid ${(props) => props.color};
  font-size: 0.92rem;

  margin-bottom: 12px;

  &:hover {
    background-color: ${(props) => props.color}10;
  }

  .color {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`;

const SelectAllButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  gap: 0.5rem;

  border-radius: 7px;
  padding: 0.5rem 1rem;

  width: 100%;

  font-size: 0.92rem;

  margin-bottom: 12px;

  background: ${(props) => props.color}10;

  color: ${(props) => props.color};
`;

export default Filter;
