import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { BillingPeriodType } from "@/server-types";
import { TextVariant } from "@/types/text-variants";
import { cn } from "@/utils";
import { Accordion, ActionIcon, Table, Text } from "@mantine/core";

import { DateInput } from "@mantine/dates";
import { useForm, UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import { LuPlus, LuTrash } from "react-icons/lu";
import {
  Button,
  Accordion as HeroAccordion,
  AccordionItem as HeroAccordionItem,
} from "@heroui/react";
import { LimeNumberInput } from "@/Components/NextBase/LimeNumberInput";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { returnCurrencySymbol } from "../../../../../../../../shared/utils/utils";
import { LimeInput } from "@/Components/NextBase/LimeInput";

type ClientBillingForm = {
  taxNumber: string | null;
  billingStartDate: Date;
  billingPeriodType: BillingPeriodType | null;
  billingPeriodInterval: number;
  pricingTierId: string;
  pricePerLocation: number;
  pricePerUser: number;
  pricePerSms: number;
  pricePerResource: number;
  additionalSms: number;
  billingPaymentMethod: "stripe" | "invoice" | "directDebit";
  billingInvoiceType: "Draft" | "Invoice";
  additionalLocations: {
    id: number | null;
    price: number;
    isRemoved: boolean;
    discountAmount: number;
  }[];
  additionalUsers: {
    id: number | null;
    price: number;
    isRemoved: boolean;
    discountAmount: number;
  }[];
  additionalResources: {
    id: number | null;
    price: number;
    isRemoved: boolean;
    discountAmount: number;
  }[];
  priceForSenderId: number;
  priceBase: number;
  discountAmount: number;
  priceForSenderIdDiscount: number;
  priceBaseDiscount: number;
  pricePerSmsDiscount: number;
  smsInTier: number;
};

export const BillingSettings = ({
  clientId,
  refetchPrice,
}: {
  clientId: number;
  refetchPrice: () => void;
}) => {
  const { data, refetch: refetchBillingData } =
    api.admin.useGetClientBilling(clientId);

  const {
    mutateAsync: updateClientBilling,
    isPending: isUpdatePending,
    isError: isUpdateError,
  } = api.admin.usePutClientBilling();

  const {
    mutateAsync: activateBilling,
    isPending: isActivatePending,
    isError,
  } = api.admin.useActivateBilling();
  const { mutateAsync: deactivateBilling, isPending: isDeactivatePending } =
    api.admin.useDeactivateBilling();

  const form = useForm<ClientBillingForm>({
    initialValues: {
      additionalLocations: [],
      additionalUsers: [],
      additionalResources: [],
      billingPeriodInterval: 1,
      billingPeriodType: null,
      taxNumber: null,
      pricingTierId: "",
      billingStartDate: dayjs().utc(true).toDate(),
      pricePerLocation: 0,
      pricePerUser: 0,
      pricePerSms: 0,
      pricePerResource: 0,
      priceBase: 0,
      priceForSenderId: 0,
      additionalSms: 0,
      discountAmount: 0,
      priceForSenderIdDiscount: 0,
      priceBaseDiscount: 0,
      pricePerSmsDiscount: 0,
      smsInTier: 0,
      billingPaymentMethod: "invoice",
      billingInvoiceType: "Draft",
    },
  });

  useEffect(() => {
    if (data) {
      const { billingInfo } = data;

      form.setValues({
        ...billingInfo,
        pricingTierId: billingInfo.pricingTier.pricingTierId,
        billingPeriodInterval: billingInfo.billingPeriodInterval ?? 1,
        billingStartDate: billingInfo.billingStartDate
          ? dayjs(billingInfo.billingStartDate).utc(true).toDate()
          : dayjs().utc(true).toDate(),
        pricePerLocation: billingInfo.pricePerLocationCents / 100,
        pricePerUser: billingInfo.pricePerUserCents / 100,
        pricePerSms: billingInfo.pricePerSmsCents / 100,
        pricePerResource: billingInfo.pricePerResourceCents / 100,
        additionalLocations: billingInfo.ClientAdditionalLocation.map(
          (loc) => ({
            id: loc.id,
            price: loc.priceCents / 100,
            isRemoved: false,
            discountAmount: loc.discountAmount,
          }),
        ),
        additionalUsers: billingInfo.ClientAdditionalUsers.map((user) => ({
          id: user.id,
          price: user.priceCents / 100,
          isRemoved: false,
          discountAmount: user.discountAmount,
        })),
        additionalResources: billingInfo.ClientAdditionalResources.map(
          (resource) => ({
            id: resource.id,
            price: resource.priceCents / 100,
            isRemoved: false,
            discountAmount: resource.discountAmount,
          }),
        ),
        priceBase: billingInfo.priceBaseCents / 100,
        priceForSenderId: billingInfo.priceForSenderIdCents / 100,
      });
    }
  }, [data]);

  if (!data) return <div>Loading...</div>;

  const billingPeriodTypeData: { label: string; key: string }[] = [
    { label: "Dan", key: "day" },
    { label: "Teden", key: "week" },
    { label: "Mesec", key: "month" },
    { label: "Leto", key: "year" },
  ];

  const handleSaveBillingData = async (values: ClientBillingForm) => {
    await updateClientBilling({
      params: {
        clientId,
      },
      body: {
        ...(values as {
          taxNumber: string;
          billingPeriodType: "day" | "week" | "month" | "year";
          billingPeriodInterval: number;
        }),
        billingStartDate: dayjs(values.billingStartDate)
          .utc(true)
          .format("YYYY-MM-DD"),
        pricingTierId: values.pricingTierId,
        additionalLocations: values.additionalLocations.map((loc) => ({
          ...loc,
          price: Number(loc.price),
          discountAmount: Number(loc.discountAmount),
        })),
        additionalUsers: values.additionalUsers.map((user) => ({
          ...user,
          price: Number(user.price),
          discountAmount: Number(user.discountAmount),
        })),
        additionalResources: values.additionalResources.map((resource) => ({
          ...resource,
          price: Number(resource.price),
          discountAmount: Number(resource.discountAmount),
        })),
        pricePerLocation: values.pricePerLocation,
        pricePerUser: values.pricePerUser,
        pricePerSms: values.pricePerSms,
        pricePerResource: values.pricePerResource,
        priceBase: values.priceBase,
        priceForSenderId: values.priceForSenderId,
        additionalSms: values.additionalSms,
        billingPaymentMethod: values.billingPaymentMethod,
        billingInvoiceType: values.billingInvoiceType,
      },
    });

    notifications.show({ message: "Posodobljeno", color: "green" });

    refetchBillingData();
    refetchPrice();
  };

  console.log(form.values);

  return (
    <form
      onSubmit={form.onSubmit(handleSaveBillingData)}
      className="flex flex-col gap-4 p-4"
    >
      <div>Status obračunavanja: {data.billingInfo.billingStatus}</div>
      <div>Partner ID : {data.billingInfo.externalPartnerId}</div>

      <div className="flex flex-col">
        <Text variant={TextVariant.Subheading}>Default cene za dodatke</Text>
        <div className="flex gap-6">
          <LimeNumberInput
            label={t`Cena za lokacijo (EUR)`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={returnCurrencySymbol({ currency: "EUR" })}
            {...form.getInputProps("pricePerLocation")}
          ></LimeNumberInput>

          <LimeNumberInput
            label={t`Cena za uporabnika (EUR)`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={returnCurrencySymbol({ currency: "EUR" })}
            {...form.getInputProps("pricePerUser")}
          ></LimeNumberInput>

          <LimeNumberInput
            label={t`Cena za resurs (EUR)`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={returnCurrencySymbol({ currency: "EUR" })}
            {...form.getInputProps("pricePerResource")}
          ></LimeNumberInput>
        </div>
      </div>

      <div className="flex flex-col">
        <Text variant={TextVariant.Subheading}>Cena za sender ID</Text>
        <div className="flex flex-col gap-4 md:flex-row">
          <LimeNumberInput
            label={t`Osnovna cena za sender ID (EUR)`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={returnCurrencySymbol({ currency: "EUR" })}
            {...form.getInputProps("priceForSenderId")}
          ></LimeNumberInput>

          <LimeNumberInput
            label={t`Popust`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={"%"}
            isDisabled={form.values.discountAmount > 0}
            {...form.getInputProps("priceForSenderIdDiscount")}
            onValueChange={(val) => {
              if (form.values.discountAmount > 0) {
                form.setFieldValue("priceForSenderIdDiscount", 0);
                return;
              }

              form.setFieldValue("priceForSenderIdDiscount", val);
            }}
          ></LimeNumberInput>
        </div>
      </div>

      <div className="flex flex-col">
        <Text variant={TextVariant.Subheading}>Obvestila</Text>
        <div className="flex flex-col gap-4 md:flex-row">
          <LimeNumberInput
            label={t`Smsjev v paketu`}
            className="flex-1"
            variant="faded"
            hideStepper
            min={0}
            step={1}
            {...form.getInputProps("smsInTier")}
          ></LimeNumberInput>
          <LimeNumberInput
            label={t`Dodatnih SMSjev`}
            className="flex-1"
            variant="faded"
            hideStepper
            {...form.getInputProps("additionalSms")}
          ></LimeNumberInput>

          <LimeNumberInput
            label={t`Cena na SMS`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={returnCurrencySymbol({ currency: "EUR" })}
            {...form.getInputProps("pricePerSms")}
          ></LimeNumberInput>

          <LimeNumberInput
            label={t`Popust na SMS`}
            className="flex-1"
            variant="faded"
            hideStepper
            endContent={"%"}
            isDisabled={form.values.discountAmount > 0}
            {...form.getInputProps("pricePerSmsDiscount")}
            onValueChange={(val) => {
              if (form.values.discountAmount > 0) {
                form.setFieldValue("pricePerSmsDiscount", 0);
                return;
              }

              form.setFieldValue("pricePerSmsDiscount", val);
            }}
          ></LimeNumberInput>
        </div>
      </div>

      <div className="flex gap-6">
        <LimeSelect
          label={t`Paket`}
          items={data.pricingTiers.map((t) => ({
            key: t.pricingTierId,
            label: t.tierName,
          }))}
          variant="faded"
          {...form.getInputProps("pricingTierId")}
          onSingleItemChange={(val) => {
            form.setFieldValue("pricingTierId", val!);
            if (val !== form.values.pricingTierId) {
              const pricingTier = data.pricingTiers.find(
                (t) => t.pricingTierId === val,
              )!;

              form.setFieldValue("smsInTier", pricingTier.smsLimit);

              modals.openConfirmModal({
                title: "Preveri",
                children: (
                  <Text>
                    Spremeni ceno na {pricingTier.tierPriceCents / 100} ?
                  </Text>
                ),
                labels: { confirm: "Potrdi", cancel: "Prekliči" },
                onConfirm: () => {
                  form.setFieldValue(
                    "priceBase",
                    pricingTier.tierPriceCents / 100,
                  );
                },
              });
            }
          }}
        ></LimeSelect>

        <LimeNumberInput
          label={t`Cena osnova (EUR)`}
          variant="faded"
          hideStepper
          endContent={returnCurrencySymbol({ currency: "EUR" })}
          {...form.getInputProps("priceBase")}
        ></LimeNumberInput>

        <LimeNumberInput
          label={t`Popust`}
          variant="faded"
          hideStepper
          endContent={"%"}
          isDisabled={form.values.discountAmount > 0}
          {...form.getInputProps("priceBaseDiscount")}
          onValueChange={(val) => {
            if (form.values.discountAmount > 0) {
              form.setFieldValue("priceBaseDiscount", 0);
              return;
            }

            form.setFieldValue("priceBaseDiscount", val);
          }}
        ></LimeNumberInput>
      </div>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalUsers"
        title="Uporabniki"
        billingInfoField="ClientAdditionalUsers"
        pricingTierItemLimit={data.billingInfo.pricingTier?.maxUserCount ?? 0}
      ></AdditionalsAccordion>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalLocations"
        title="Lokacije"
        billingInfoField="ClientAdditionalLocation"
        pricingTierItemLimit={
          data.billingInfo.pricingTier?.maxLocationCount ?? 0
        }
      ></AdditionalsAccordion>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalResources"
        title="Resourci"
        billingInfoField="ClientAdditionalResources"
        pricingTierItemLimit={
          data.billingInfo.pricingTier?.maxResourceCount ?? 0
        }
      ></AdditionalsAccordion>

      <LimeInput
        variant="faded"
        label={t`Davčna številka`}
        {...form.getInputProps("taxNumber")}
      ></LimeInput>
      <div className="flex flex-col gap-1">
        <Text variant={TextVariant.BodyEmphasized}>Obračunavanje</Text>

        <div className="flex items-center gap-2">
          <LimeNumberInput
            min={1}
            {...form.getInputProps("billingPeriodInterval")}
            className="flex-1"
            label={"Na"}
            variant="faded"
            hideStepper
          ></LimeNumberInput>
          <LimeSelect
            {...form.getInputProps("billingPeriodType")}
            items={billingPeriodTypeData}
            className="flex-1"
            variant="faded"
            label={"."}
          ></LimeSelect>
        </div>
        <LimeNumberInput
          variant="faded"
          hideStepper
          endContent={"%"}
          label={"Popust"}
          isDisabled={
            form.values.priceBaseDiscount > 0 ||
            form.values.priceForSenderIdDiscount > 0 ||
            form.values.pricePerSmsDiscount > 0
          }
          {...form.getInputProps("discountAmount")}
          onValueChange={(val) => {
            if (
              form.values.priceBaseDiscount > 0 ||
              form.values.priceForSenderIdDiscount > 0 ||
              form.values.pricePerSmsDiscount > 0
            ) {
              form.setFieldValue("discountAmount", 0);
              return;
            }

            form.setFieldValue("discountAmount", val);
          }}
          className="flex-1"
        ></LimeNumberInput>
        <LimeSelect
          label={t`Način plačila`}
          items={[
            { label: "Stripe", key: "stripe" },
            { label: "Račun - eračuni", key: "invoice" },
            { label: "Direktni transfer", key: "directDebit" },
          ]}
          variant="faded"
          {...form.getInputProps("billingPaymentMethod")}
        ></LimeSelect>
        <LimeSelect
          label={t`Tip računa`}
          items={[
            { label: t`Osnutek`, key: "Draft" },
            { label: t`Račun`, key: "Invoice" },
          ]}
          variant="faded"
          {...form.getInputProps("billingInvoiceType")}
        ></LimeSelect>
      </div>
      <DateInput
        label={t`Začetek obračunavanja`}
        {...form.getInputProps("billingStartDate")}
        disabled={data.billingInfo.billingStatus === "active"}
      ></DateInput>

      <div className="flex gap-4">
        <Button type="submit" isLoading={isUpdatePending} color="primary">
          Shrani
        </Button>
        {data.billingInfo.billingStatus === "inactive" ? (
          <Button
            onPress={async () => {
              await activateBilling({ params: { clientId } });

              notifications.show({ message: "Aktivirano", color: "green" });

              refetchBillingData();
            }}
            color="primary"
            isLoading={isActivatePending || isDeactivatePending}
            disabled={
              data.billingInfo.billingPeriodInterval === null ||
              data.billingInfo.billingPeriodType === null ||
              data.billingInfo.billingStartDate === null ||
              !["PRO", "BASIC", "ADVANCED", "ENTERPRISE"].includes(
                data.billingInfo.pricingTier.pricingTierId,
              )
            }
          >
            Aktiviraj obračunavanje
          </Button>
        ) : (
          <Button
            color="primary"
            onPress={async () => {
              await deactivateBilling({ params: { clientId } });

              notifications.show({ message: "Deaktivirano", color: "green" });

              refetchBillingData();
            }}
          >
            Deaktiviraj obračunavanje
          </Button>
        )}
      </div>
    </form>
  );
};

const AdditionalsAccordion = ({
  form,
  billingInfo,
  formField,
  title,
  billingInfoField,
  pricingTierItemLimit,
}: {
  form: UseFormReturnType<
    ClientBillingForm,
    (values: ClientBillingForm) => ClientBillingForm
  >;
  formField: "additionalLocations" | "additionalUsers" | "additionalResources";
  title: string;
  billingInfoField:
    | "ClientAdditionalLocation"
    | "ClientAdditionalUsers"
    | "ClientAdditionalResources";
  billingInfo: {
    discountAmount: number;
    ClientAdditionalLocation: {
      id: number | null;
      priceCents: number;
      discountAmount: number;
    }[];
    ClientAdditionalUsers: {
      id: number | null;
      priceCents: number;
      discountAmount: number;
    }[];
    ClientAdditionalResources: {
      id: number | null;
      priceCents: number;
      discountAmount: number;
    }[];
    pricePerLocationCents: number;
  };
  pricingTierItemLimit: number;
}) => {
  return (
    <Accordion>
      <Accordion.Item value="tax-number">
        <Accordion.Control>
          <Text variant={TextVariant.Body}>
            {title}: ( {pricingTierItemLimit} free +{" "}
            {billingInfo[billingInfoField].length} dodatnih ={" "}
            <b>
              {billingInfo[billingInfoField].reduce((acc, loc) => {
                const higherDiscount = Math.max(
                  loc.discountAmount,
                  billingInfo.discountAmount,
                );
                const discount = higherDiscount / 100;

                const priceOfCurrent = Math.ceil(
                  loc.priceCents * (1 - discount),
                );

                return acc + priceOfCurrent / 100;
              }, 0)}
              EUR
            </b>
            )
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <div className="flex flex-col gap-4">
            <Table>
              <Table.Thead>
                <Table.Th>Številka</Table.Th>
                <Table.Th>Osnovna cena v EUR</Table.Th>
                <Table.Th>Popust v %</Table.Th>
                <Table.Th>Izbriši</Table.Th>
              </Table.Thead>
              <Table.Tbody>
                {form.values[formField].map((l, index) => (
                  <Table.Tr
                    key={l.id}
                    className={cn(l.isRemoved ? "opacity-50" : "")}
                  >
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>
                      <LimeNumberInput
                        className="w-50"
                        variant="faded"
                        hideStepper
                        endContent={returnCurrencySymbol({ currency: "EUR" })}
                        {...form.getInputProps(`${formField}.${index}.price`)}
                      ></LimeNumberInput>
                    </Table.Td>
                    <Table.Td>
                      <LimeNumberInput
                        className="w-50"
                        variant="faded"
                        hideStepper
                        endContent={"%"}
                        {...form.getInputProps(
                          `${formField}.${index}.discountAmount`,
                        )}
                        isDisabled={form.values.discountAmount > 0}
                        onValueChange={(val) => {
                          if (form.values.discountAmount > 0) {
                            form.setFieldValue(
                              `${formField}.${index}.discountAmount`,
                              0,
                            );
                            return;
                          }

                          form.setFieldValue(
                            `${formField}.${index}.discountAmount`,
                            val,
                          );
                        }}
                      ></LimeNumberInput>
                    </Table.Td>
                    <Table.Td>
                      <ActionIcon
                        onClick={() => {
                          const item = form.values[formField][index];
                          if (!item.id) {
                            form.setFieldValue(
                              formField,
                              form.values[formField].filter(
                                (loc) => loc !== item,
                              ),
                            );
                          } else {
                            if (item.isRemoved) {
                              item.isRemoved = false;
                            } else {
                              item.isRemoved = true;
                            }
                            form.setFieldValue(
                              formField,
                              form.values[formField].map((loc) => {
                                if (loc === item) {
                                  return item;
                                }
                                return loc;
                              }),
                            );
                          }
                        }}
                      >
                        {l.isRemoved ? <LuPlus /> : <LuTrash />}
                      </ActionIcon>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
          <ActionIcon
            onClick={() => {
              form.insertListItem(formField, {
                id: null,
                price: form.values.pricePerLocation,
                isRemoved: false,
                discountAmount: 0,
              });
            }}
          >
            <LuPlus />
          </ActionIcon>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
