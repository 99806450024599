import "core-js";
import React from "react";

import { PostHogProvider } from "posthog-js/react";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import App from "./App";
import StateProvider from "./context/StateContext";
import reportWebVitals from "./reportWebVitals";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { IconContext } from "react-icons";
import { BrowserRouter } from "react-router";
import { queryClient } from "./lib/api-client";

import "@mantine/core/styles.css";
import "react-phone-number-input/style.css";
import "@mantine/dates/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";

import "./utils/init";
import mantineTheme from "./config/mantine-theme";

import * as Sentry from "@sentry/react";
import posthog from "posthog-js";

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);

if (import.meta.env.VITE_PUBLIC_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const phId = urlParams.get("ph_id");
  if (phId) {
    posthog.identify(phId);
  }
}

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <div>
          Prišlo je do napake. Prosimo{" "}
          <button
            onClick={() => window.location.reload()}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            osvežite stran
          </button>
        </div>
      }
    >
      <PostHogProvider client={posthog}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <I18nProvider i18n={i18n}>
              <MantineProvider theme={mantineTheme}>
                <ModalsProvider>
                  <IconContext.Provider value={{ className: "icons" }}>
                    <Notifications />
                    <StateProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </StateProvider>
                  </IconContext.Provider>
                </ModalsProvider>
              </MantineProvider>
            </I18nProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
