import dayjs, { Dayjs } from "dayjs";

type GetFinalPriceCents = {
  pricingStrategy: "flat" | "bracketed" | "scheduled";
  priceCents: number;
  priceCentsTariff?: number;
  priceCentsBracket?: number;

  overrides: {
    pricingMultiplier: "perPerson" | "perResource";
    attendance: number;
    resourceCount: number;
  };
};

export function getFinalPriceCents({
  pricingStrategy,
  priceCents,
  priceCentsTariff,
  priceCentsBracket,

  overrides,
}: GetFinalPriceCents) {
  if (pricingStrategy === "bracketed" && priceCentsBracket != null) {
    return Math.max(Math.floor(priceCents + priceCentsBracket), 0);
  }

  const price = (() => {
    if (pricingStrategy === "scheduled" && priceCentsTariff != null) {
      return priceCents + priceCentsTariff;
    }

    return priceCents;
  })();

  if (pricingStrategy === "flat" || pricingStrategy === "bracketed") {
    if (overrides?.pricingMultiplier === "perPerson") {
      return Math.max(Math.floor(price * overrides.attendance), 0);
    }

    if (overrides?.pricingMultiplier === "perResource") {
      return Math.max(Math.floor(price * overrides.resourceCount), 0);
    }
  }

  return Math.max(Math.floor(price), 0);
}

type PricingSchedule = {
  dayOfWeek: string;
  fromTime: string;
  toTime: string;
  priceCents: number;
};

type PricingBracket = {
  fromCount: number;
  toCount: number;
  priceCents: number;
};

export function getPriceCents({
  service,
  serviceStart,
  duration,
  baseDuration,
  resourceCount,
  attendance,
  priceBase,
}: {
  service: {
    pricingSchedules: PricingSchedule[];
    attendance: number;
    pricingMultiplier: "perPerson" | "perResource";
    pricingBrackets: PricingBracket[];
    pricingStrategy: "flat" | "bracketed" | "scheduled";
    isExtraTimeAllowed: boolean;
  };
  serviceStart: Dayjs;
  duration: number;
  baseDuration: number;
  resourceCount: number;
  attendance: number;
  priceBase: number;
}) {
  const datesDaysMap: Record<string, string[]> = {};
  const startDay = serviceStart.startOf("day");
  const endDay = serviceStart.add(duration, "minutes").startOf("day");

  let currentDay = startDay.locale("en");
  while (currentDay.isSameOrBefore(endDay)) {
    const dayOfWeek = currentDay.format("dddd").toLowerCase();

    if (!datesDaysMap[dayOfWeek]) {
      datesDaysMap[dayOfWeek] = [];
    }

    datesDaysMap[dayOfWeek].push(currentDay.format("YYYY-MM-DD"));
    currentDay = currentDay.add(1, "day");
  }

  const tariffs = service.pricingSchedules
    .filter((tariff) => {
      if (!datesDaysMap[tariff.dayOfWeek]) return false;

      const possibleDates = datesDaysMap[tariff.dayOfWeek];

      const isAnyTariffRelevant = possibleDates.some((date) => {
        const localDate = dayjs(date).locale("en");
        const startDay = localDate.startOf("day");
        const scheduleStart = startDay.add(dayjs(tariff.fromTime).valueOf());

        const toTimeValueOf =
          dayjs(tariff.toTime).utc(false).format("HH:mm") === "23:59"
            ? dayjs(tariff.toTime).add(1, "minute").valueOf()
            : dayjs(tariff.toTime).valueOf();

        const scheduleEnd = startDay.add(toTimeValueOf);

        const slotDateEnd = serviceStart.add(duration, "minutes");

        const isBetween =
          slotDateEnd.isAfter(scheduleStart) &&
          serviceStart.isBefore(scheduleEnd);
        return isBetween;
      });

      return isAnyTariffRelevant;
    })
    .map((tariff) => {
      const slotStart = serviceStart;
      const slotEnd = serviceStart.add(duration, "minutes");

      const possibleDates = datesDaysMap[tariff.dayOfWeek];

      const totalDurationInTariff = possibleDates.reduce((acc, date) => {
        const localDate = dayjs(date).locale("en");
        const startDay = localDate.startOf("day");
        const scheduleStart = startDay.add(dayjs(tariff.fromTime).valueOf());
        const toTimeValueOf =
          dayjs(tariff.toTime).utc(false).format("HH:mm") === "23:59"
            ? dayjs(tariff.toTime).add(1, "minute").valueOf()
            : dayjs(tariff.toTime).valueOf();
        const scheduleEnd = startDay.add(toTimeValueOf);

        const overlapStart = dayjs.max(slotStart, scheduleStart);
        const overlapEnd = dayjs.min(slotEnd, scheduleEnd);

        if (!overlapStart || !overlapEnd) {
          return acc;
        }

        return acc + overlapEnd.diff(overlapStart, "minute");
      }, 0);

      return {
        priceCents: tariff.priceCents,
        durationInTariff: totalDurationInTariff,
      };
    });

  const priceCentsTariff = tariffs.reduce(
    (acc, { priceCents: priceCentsTariff, durationInTariff }) =>
      acc + priceCentsTariff * (durationInTariff / baseDuration),
    0,
  );

  const attendanceDefault = attendance ?? 1;
  const priceCentsBracket = (() => {
    if (service.pricingMultiplier === "perPerson") {
      return service.pricingBrackets.find(
        ({ fromCount, toCount }) =>
          attendanceDefault >= fromCount && attendanceDefault <= toCount,
      )?.priceCents;
    }

    if (service.pricingMultiplier === "perResource") {
      return service.pricingBrackets.find(
        ({ fromCount, toCount }) =>
          resourceCount >= fromCount && resourceCount <= toCount,
      )?.priceCents;
    }
  })();

  return getFinalPriceCents({
    pricingStrategy: service.pricingStrategy,
    priceCents:
      priceBase * (service.isExtraTimeAllowed ? duration / baseDuration : 1),
    priceCentsTariff,
    priceCentsBracket,

    overrides: {
      pricingMultiplier: service.pricingMultiplier,
      attendance: attendanceDefault,
      resourceCount: resourceCount,
    },
  });
}
