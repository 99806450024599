import { t } from "@lingui/core/macro";
import { i18n } from "@lingui/core";
import { formatCurrency } from "../../../../shared/utils/utils";
import {
  GiftCardDocument,
  GiftCardReceiptDocument,
  GiftCardTransactionAccountDocument,
  InvoiceDocument,
  PrinterConfig,
  TransactionAccountReceiptDocument,
  PaymentType,
} from "./types";
import {
  buildEscPosQrCode,
  buildUpnQrCodeString,
  getPaymentTypeLabel,
} from "./utils";
import dayjs from "dayjs";

export const renderInvoiceToEscPos = ({
  invoiceData: invoice,
  printerConfig,
  isCopy,
}: {
  invoiceData: InvoiceDocument["invoiceData"];
  printerConfig: PrinterConfig;
  isCopy: boolean;
}) => {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const encoder = new TextEncoder();

  const lineWidth = printerConfig.lineWidth;

  const itemNameMaxLength = lineWidth; // Entire line width for item name
  const itemPriceMaxLength = 12; // Length for price (Cena)
  const itemQuantityMaxLength = 4; // Length for quantity (Kol)
  const itemDiscountMaxLength = 7; // Length for discount (Popust)
  const itemTotalMaxLength = 9; // Length for total value (Vrednost)

  const taxRateMaxLength = 7; // Length for tax rate
  const taxNetPriceMaxLength = 9; // Length for tax net price
  const taxTotalMaxLength = 7; // Length for tax gross price
  const taxGrossPriceMaxLength = 9; // Length for tax gross price

  // Format a single item into two lines
  const formatItemLines = (
    name: string,
    price: string,
    quantity: number,
    discount: string,
    total: string,
  ) => {
    const namePadded = name
      .substring(0, itemNameMaxLength - 1)
      .padEnd(itemNameMaxLength - 1); // Ensure the name is left-aligned
    const pricePadded = price.padStart(itemPriceMaxLength);
    const quantityPadded = `${quantity}x`.padStart(itemQuantityMaxLength);
    const discountPadded = (discount || "").padStart(itemDiscountMaxLength);
    const totalPadded = total.padStart(itemTotalMaxLength);
    const secondLine = `${pricePadded}${quantityPadded}${discountPadded}${totalPadded}\n`;
    return `${namePadded}\n${secondLine}`;
  };

  const itemsArray = invoice.additionalReceiptData.invoiceData.items.map(
    (item) =>
      encoder.encode(
        formatItemLines(
          item.name, // Item name
          item.price.toFixed(2), // Cena
          item.quantity, // Kol
          item.discount ?? "", // Popust (empty string if not provided)
          item.totalWithTax.toFixed(2), // Vrednost
        ),
      ),
  );

  const flattenedItemsArray = itemsArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Format the tax details into a properly aligned string
  const formatTaxLine = (
    taxRate: string,
    netPrice: string,
    taxTotal: string,
    grossPrice: string,
  ): string => {
    const taxRatePadded = taxRate.padStart(taxRateMaxLength);
    const netPricePadded = netPrice.padStart(taxNetPriceMaxLength);
    const taxTotalPadded = taxTotal.padStart(taxTotalMaxLength);
    const grossPricePadded = grossPrice.padStart(taxGrossPriceMaxLength);
    return `${taxRatePadded}${netPricePadded}${taxTotalPadded}${grossPricePadded}\n`;
  };

  let totalNetPrice = 0;
  let totalTaxAmount = 0;
  let totalGrossPrice = 0;

  const invoiceTaxesArray =
    invoice.additionalReceiptData.invoiceData.invoiceTaxes.map((tax) => {
      const netPrice = tax.base;
      const taxAmount = tax.totalTaxAmount;
      const grossPrice = netPrice + taxAmount;

      // Accumulate totals
      totalNetPrice += netPrice;
      totalTaxAmount += taxAmount;
      totalGrossPrice += grossPrice;

      return encoder.encode(
        formatTaxLine(
          tax.taxRate.toFixed(1), // DDV %
          netPrice.toFixed(2), // Neto
          taxAmount.toFixed(2), // DDV
          grossPrice.toFixed(2), // Bruto
        ),
      );
    });

  const totalsLine = encoder.encode(
    formatTaxLine(
      "SKUPAJ:", // No tax rate for totals
      totalNetPrice.toFixed(2), // Total Neto
      totalTaxAmount.toFixed(2), // Total DDV
      totalGrossPrice.toFixed(2), // Total Bruto
    ),
  );

  const flattenedInvoiceTaxesArray = invoiceTaxesArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Encode a divider line
  const divider = encoder.encode("-".repeat(lineWidth - 1) + "\n");

  // Concatenate the divider and totals line to the flattened array
  const taxesWithDividerAndTotals = new Uint8Array(
    flattenedInvoiceTaxesArray.length + divider.length + totalsLine.length,
  );
  taxesWithDividerAndTotals.set(flattenedInvoiceTaxesArray);
  taxesWithDividerAndTotals.set(divider, flattenedInvoiceTaxesArray.length);
  taxesWithDividerAndTotals.set(
    totalsLine,
    flattenedInvoiceTaxesArray.length + divider.length,
  );

  const rawQrCodeData = invoice.additionalReceiptData.QR
    ? buildEscPosQrCode(encoder.encode(invoice.additionalReceiptData.QR))
    : [];
  const companyData = invoice.additionalReceiptData._documentIssuer;
  const customerData = invoice.additionalReceiptData.customerData;

  const companyPrintCommands =
    companyData != null
      ? [
          ...encoder.encode(`${companyData.name}\n`),
          ...(companyData.address
            ? encoder.encode(`${companyData.address}\n`)
            : []),
          ...(companyData.zip
            ? encoder.encode(`${companyData.zip} ${companyData.city}\n`)
            : []),
          ...encoder.encode(
            `${companyData.isTaxSubject ? "ID za DDV" : "Davcna stevilka"}: ${companyData.taxNumber || "/"}\n`,
          ),
        ]
      : [];

  const customerPrintCommands =
    customerData != null
      ? [
          ...encoder.encode(`\n\n${customerData.name}\n`),
          ...(customerData.address
            ? encoder.encode(`${customerData.address}\n`)
            : []),
          ...(customerData.zip && customerData.city
            ? encoder.encode(`${customerData.zip} ${customerData.city}\n`)
            : []),
          ...encoder.encode(`ID za DDV: ${customerData.taxNumber || "/"}\n`),
        ]
      : [];

  const paymentMethodAmountsCommands =
    invoice.additionalReceiptData.payments.map((payment) => {
      const typePadded = getPaymentTypeLabel(
        payment.type as PaymentType,
      ).padEnd(lineWidth - 10); // Adjust padding based on your needs
      const amountPadded = payment.amount.toFixed(2).padStart(9); // Right align the amount
      return encoder.encode(`${typePadded}${amountPadded}\n`);
    });

  const flattenedPaymentMethodAmountsArray =
    paymentMethodAmountsCommands.reduce((acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    }, new Uint8Array());

  const printCount = invoice.additionalReceiptData.printCount + 1;

  const includesMedicineService =
    invoice.additionalReceiptData.invoiceData.items.some(
      (item) => item.custom.isMedicineService,
    );

  const commands = [
    0x0a, // New line
    0x0a, // New line

    // Print company info in normal size
    0x1b,
    0x00, // Normal size
    ...companyPrintCommands,

    // Print customer info if exists
    ...customerPrintCommands,

    0x0a, // New line
    0x0a, // New line

    // Print copy if isCopy
    ...(isCopy && printCount > 1
      ? encoder.encode(`Kopija ${printCount - 1}\n\n\n`)
      : []),

    // Print RACUN ST. in bold and normal text size
    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...(invoice.canceled ? encoder.encode("STORNIRAN RACUN\n") : []),
    ...encoder.encode(`RACUN ST.: ${invoice.number}\n`),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    // turn off center alignment
    0x1b,
    0x61,
    0x00,

    0x0a, // New line

    // Print table header with fixed spacing
    ...encoder.encode("Artikel/Cena Kol Popust Vrednost\n"),
    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    ...flattenedItemsArray,

    0x1b,
    0x45,
    0x01, // Turn on bold (ESC E 1)
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
    0x1b,
    0x45,
    0x00, // Turn off bold (ESC E 0)

    // Print total
    ...encoder.encode(
      `SKUPAJ: ${invoice.totalWithoutDiscount.padStart(lineWidth - 9)}\n`,
    ),
    ...(invoice.totalDiscount != 0
      ? encoder.encode(
          `POPUST: ${`-${invoice.totalDiscountFormatted}`.padStart(lineWidth - 9)}\n`,
        )
      : []),
    // Print "ZA PLACILO" in normal size
    // Reset alignment to left
    0x1b,
    0x61,
    0x00, // Left alignment

    // Print "ZA PLACILO" in normal size
    ...encoder.encode(`ZA PLACILO ${invoice.currencyId.padEnd(5)}`),

    // Switch to larger text size for the total amount on the same line
    // 0x1b,
    // 0x21,
    // 0x30, // Double height and double width (ESC ! 0x30)
    ...encoder.encode(invoice.total.padStart(lineWidth - 17)),

    // Reset to normal text size
    0x1b,
    0x21,
    0x00, // Reset to normal text size

    0x0a, // New line
    0x0a, // New line

    ...flattenedPaymentMethodAmountsArray,

    0x0a, // New line

    // Print tax details
    ...encoder.encode(
      `--DDV %-----Neto----DDV----Bruto${"-".repeat(lineWidth - 33)}\n`,
    ),
    ...taxesWithDividerAndTotals,
    0x0a, // New line

    // Print seller info in a smaller size
    0x1b,
    0x21,
    0x01, // Slightly smaller text size
    // Print date and time
    ...encoder.encode(
      `${invoice.location.city ? invoice.location.city + ", " : ""}${invoice.date}\n`,
    ),
    ...(invoice.additionalReceiptData.employeeData?.name
      ? encoder.encode(
          `Racun izdal: ${invoice.additionalReceiptData.employeeData.name}\n`,
        )
      : []),
    0x1b,
    0x21,
    0x00, // Reset to normal text size

    0x0a, // New line

    // Print ZOI and EOR
    ...(invoice.additionalReceiptData.ZOI
      ? encoder.encode(`ZOI: ${invoice.additionalReceiptData.ZOI}\n`)
      : []),
    ...(invoice.additionalReceiptData.EOR
      ? encoder.encode(`EOR: ${invoice.additionalReceiptData.EOR}\n`)
      : []),
    0x0a, // New line

    ...rawQrCodeData,

    ...(companyData.isTaxSubject
      ? []
      : [
          0x01, // Slightly smaller text size
          ...encoder.encode(
            "DDV ni obracunan na podlagi 1. odstavka 94. clena ZDDV-1.",
          ),
          0x00, // Reset to normal text size
          0x0a,
          0x0a,
        ]),

    ...(includesMedicineService
      ? [
          0x01, // Slightly smaller text size
          ...encoder.encode(
            "DDV ni obračunan v skladu z 2. točko 1. odstavka 42. člena ZDDV-1.",
          ),
          0x00, // Reset to normal text size
          0x0a,
          0x0a,
        ]
      : []),

    // Print centered www.lime-booking.si website url
    // Center alignment for the website URL
    0x1b,
    0x61,
    0x01, // Center alignment (ESC a 1)
    ...encoder.encode("Hvala za obisk!"),
    0x0a,
    ...encoder.encode("www.lime-booking.si"),
    0x0a,

    // Add some space at the bottom and cut paper if supported
    0x0a,
    0x0a,
  ];

  return commands;
};

export const renderGiftCardToEscPos = (doc: GiftCardDocument) => {
  const encoder = new TextEncoder();

  const { organization, giftCard, operatorLabel, currencyId } = doc.data;

  const { locale } = i18n;

  const initialAmountFormatted = formatCurrency({
    amount: giftCard.initialAmountCents / 100,
    currency: currencyId,
    locale,
    options: {
      currencyDisplay: "code",
    },
    shouldSanitize: true,
  });

  console.log("initial amount", initialAmountFormatted);

  const amountLeftFormatted = formatCurrency({
    amount: giftCard.amountLeftCents / 100,
    currency: currencyId,
    locale,
    options: {
      currencyDisplay: "code",
    },
    shouldSanitize: true,
  });

  const companyPrintCommands =
    organization != null
      ? [
          ...encoder.encode(`${organization.name}\n`),
          ...(organization.address
            ? encoder.encode(`${organization.address}\n`)
            : []),
          ...(organization.zip
            ? encoder.encode(`${organization.zip} ${organization.city}\n`)
            : []),
          ...encoder.encode(
            `${organization.isTaxSubject ? "ID za DDV" : "Davcna stevilka"}: ${organization.taxNumber || "/"}\n`,
          ),
        ]
      : [];

  return [
    0x0a,
    0x0a,

    0x1b,
    0x61,
    0x01,
    0x00, // Normal size

    ...companyPrintCommands,

    0x0a,

    ...encoder.encode(`Blagajnik: ${operatorLabel}\n`),

    0x0a,

    ...encoder.encode(`DARILNI BON\n`),
    ...encoder.encode(`${initialAmountFormatted}\n`),

    ...(giftCard.initialAmountCents !== giftCard.amountLeftCents
      ? [
          ...encoder.encode(`Preostali znesek\n`),
          ...encoder.encode(`${amountLeftFormatted}\n`),
        ]
      : []),

    0x0a,

    ...encoder.encode(`KODA:\n`),
    ...encoder.encode(`${giftCard.code}\n`),

    0x0a,

    ...encoder.encode(`Veljavnost od: ${giftCard.issueDate}\n`),
    ...encoder.encode(`Veljavnost do: ${giftCard.expiryDate}\n`),

    0x0a,

    ...encoder.encode(`Hvala za obisk!\n`),

    0x0a,

    ...encoder.encode(`www.lime-booking.si\n`),

    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
    0x0a, // New line
  ];
};

export const renderGiftCardReceiptToEscPos = ({
  doc,
  printerConfig,
}: {
  doc: GiftCardReceiptDocument;
  printerConfig: PrinterConfig;
}) => {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const encoder = new TextEncoder();

  const { locale } = i18n;

  const {
    organization,
    operatorLabel,
    payments,
    issuedGiftCards,
    currencyId,
    dateTime,
    location,
  } = doc.data;

  const lineWidth = printerConfig.lineWidth;

  const receivedPaymentsArray = Object.entries(payments).flatMap(
    ([type, data]) => {
      const paymentTypeLabel = getPaymentTypeLabel(type as PaymentType);

      const amountFormatted = formatCurrency({
        amount: data.amountCents / 100,
        currency: currencyId,
        locale,
        options: {
          currencyDisplay: "code",
        },
        shouldSanitize: true,
      });
      return [
        encoder.encode(
          `${paymentTypeLabel}: ${amountFormatted.padStart(lineWidth - paymentTypeLabel.length - 3)}\n`,
        ),
      ];
    },
  );
  const flattenedReceivedPaymentsArray = receivedPaymentsArray.reduce(
    (acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    },
    new Uint8Array(),
  );

  const issuedGiftCardsArray = issuedGiftCards.map((igc) => {
    const initialAmountFormatted = formatCurrency({
      amount: igc.initialAmountCents / 100,
      currency: currencyId,
      locale,
      options: {
        currencyDisplay: "code",
      },
      shouldSanitize: true,
    });

    return encoder.encode(
      `DARILNI BON${initialAmountFormatted.padStart(lineWidth - 12)}\n`,
    );
  });
  const flattenedIssuedGiftCardsArray = issuedGiftCardsArray.reduce(
    (acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    },
    new Uint8Array(),
  );

  const companyPrintCommands =
    organization != null
      ? [
          ...encoder.encode(`${organization.name}\n`),
          ...(organization.address
            ? encoder.encode(`${organization.address}\n`)
            : []),
          ...(organization.zip
            ? encoder.encode(`${organization.zip} ${organization.city}\n`)
            : []),
          ...encoder.encode(
            `${organization.isTaxSubject ? "ID za DDV" : "Davcna stevilka"}: ${organization.taxNumber || "/"}\n`,
          ),
        ]
      : [];

  return [
    // A couple of blank lines at the start
    0x0a,
    0x0a,

    // Center alignment: ESC a 1
    0x1b,
    0x61,
    0x01,
    0x00, // Normal text size (optional)

    // Organization info
    ...companyPrintCommands,

    0x0a, // blank line

    // Left alignment for "Blagajnik"
    0x1b,
    0x61,
    0x00,
    ...encoder.encode(`Blagajnik: ${operatorLabel}\n`),

    0x0a,

    // "Prejeta plačilna sredstva" (payments) in normal size
    ...encoder.encode(`Prejeta placilna sredstva\n`),
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),

    // Print each payment line
    ...flattenedReceivedPaymentsArray,

    0x0a, // blank line

    // "Izdana plačilna sredstva" (issued gift cards)
    ...encoder.encode(`Izdana placilna sredstva\n`),
    ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),

    // Print each gift card
    ...flattenedIssuedGiftCardsArray,

    0x0a, // blank line
    0x0a, // blank line

    ...encoder.encode(`${location}, ${dateTime}\n`),

    0x0a, // blank line
    0x0a, // blank line

    // Center alignment again for "Hvala za obisk!"
    0x1b,
    0x61,
    0x01,
    ...encoder.encode(`Hvala za obisk!\n`),
    ...encoder.encode(`www.lime-booking.si\n`),

    // Some extra new lines at the bottom
    0x0a,
    0x0a,
    0x0a,
    0x0a,
  ];
};

export const renderTransactionAccountReceiptToEscPos = (
  doc: TransactionAccountReceiptDocument,
) => {
  const encoder = new TextEncoder();

  const { data } = doc;
  const purpose = data.invoiceNumber;

  const { locale } = i18n;

  return [
    0x0a,
    0x0a,

    0x1b,
    0x61,
    0x01,
    0x00, // Normal size

    ...encoder.encode(`Racun: ${data.invoiceNumber}\n`),
    ...encoder.encode(`Znesek: ${data.amountFormatted}\n`),
    ...encoder.encode(`Ime: ${data.name}\n`),
    ...encoder.encode(`Naslov: ${data.address} ${data.city} ${data.country}\n`),
    ...encoder.encode(`IBAN: ${data.IBAN}\n`),
    ...encoder.encode(`SWIFT: ${data.SWIFT}\n`),
    ...encoder.encode(`Referenca: ${data.reference}\n`),
    ...encoder.encode(`Namen: ${purpose}\n`),
    ...encoder.encode(
      `Rok placila: ${dayjs(data.dateDue).toDate().toLocaleDateString(locale)}\n`,
    ),

    ...buildEscPosQrCode(
      encoder.encode(
        buildUpnQrCodeString({
          invoiceNumber: data.invoiceNumber,
          amount: data.amount,
          dueDate: dayjs(data.dateDue).format("DD.MM.YYYY"),
          paymentPurpose: purpose,
          purposeCode: "OTHR",
          recipientCity: data.city,
          recipientIban: data.IBAN,
          recipientName: data.name,
          recipientReference: data.reference,
          recipientStreet: data.address,
          payerCity: "",
          payerName: "",
          payerStreet: "",
        }),
      ),
    ),

    0x0a, // New line
    0x0a, // New line
  ];
};

export const renderGiftCardTransactionAccountToEscPos = (
  doc: GiftCardTransactionAccountDocument,
) => {
  const encoder = new TextEncoder();

  const { data } = doc;
  const purpose = data.giftCardInvoiceId;

  if (!data.organization.IBAN) {
    throw new Error("IBAN is required");
  }

  const { locale } = i18n;

  return [
    0x0a,
    0x0a,

    0x1b,
    0x61,
    0x01,
    0x00, // Normal size

    ...encoder.encode(`Racun: ${data.giftCardInvoiceId}\n`),
    ...encoder.encode(`Znesek: ${data.amount.toFixed(2)} ${data.currency}\n`),
    ...encoder.encode(`Ime: ${data.organization.name}\n`),
    ...encoder.encode(
      `Naslov: ${data.organization.address} ${data.organization.city} ${data.organization.zip}\n`,
    ),
    ...encoder.encode(`IBAN: ${data.organization.IBAN ?? "/"}\n`),
    ...encoder.encode(`SWIFT: ${data.organization.SWIFT ?? "/"}\n`),
    ...encoder.encode(`Referenca: ${data.reference ?? "/"}\n`),
    ...encoder.encode(`Namen: ${purpose ?? "/"}\n`),
    ...encoder.encode(
      `Rok placila: ${dayjs(data.dateDue).toDate().toLocaleDateString(locale)}\n`,
    ),

    ...buildEscPosQrCode(
      encoder.encode(
        buildUpnQrCodeString({
          invoiceNumber: data.reference,
          amount: data.amount,
          dueDate: dayjs(data.dateDue).format("DD.MM.YYYY"),
          paymentPurpose: purpose,
          purposeCode: "OTHR",
          recipientCity: data.organization.city,
          recipientIban: data.organization.IBAN,
          recipientName: data.organization.name,
          recipientReference: data.reference,
          recipientStreet: data.organization.address,
          payerCity: "",
          payerName: "",
          payerStreet: "",
        }),
      ),
    ),

    0x0a, // New line
    0x0a, // New line
  ];
};
