import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getDurationsAndPrice } from "@/Utilities";
import { Color } from "@/types/colors";
import { Flex } from "@mantine/core";
import { IoIosWarning } from "react-icons/io";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import React, { useEffect, useState } from "react";
import { LuCopySlash, LuPuzzle, LuTimerOff } from "react-icons/lu";
import { useAppointmentModalStore } from "../useAppointmentModalStore_";
import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../shared/utils/utils";
import {
  AutocompleteItem,
  AutocompleteSection,
  Tooltip,
  useDisclosure,
  Button as HeroButton,
  Divider,
  Button,
} from "@heroui/react";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { LimeCollapse } from "@/Components/NextBase/LimeCollapse";
import {
  ArrowDown,
  Box,
  Boxes,
  CopySlash,
  Timer,
  TimerOff,
  TimerReset,
  Trash,
  TriangleAlert,
  Undo2,
  User,
  Users,
} from "lucide-react";
import { cn } from "@/utils";
import { LimeNumberInput } from "@/Components/NextBase/LimeNumberInput";
import { LimeStepperNumberInput } from "@/Components/NextBase/LimeStepperNumberInput";
import { i18n } from "@lingui/core";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";

const SelectedServiceListItem = ({
  selectedService,
  selectedServices,
  setSelectedServices,
  service,
  index,
  isPreScheduled,
  startDuration,
  hasOnlyOneInput,
  timeOffDuration,
  endDuration,
  defaultOpen,
}) => {
  const [selectMultipleUsers, setSelectMultipleUsers] = useState(false);
  const [selectMultipleResources, setSelectMultipleResources] = useState(true);

  const { appointmentData } = useAppointmentModalStore((s) => s);

  const {
    users,
    resources,
    services,
    doesHaveCombinations,
    selectedLocationId,
    calendarData,
    serviceTags,
  } = useCalendarStore((s) => ({
    users: s.users,
    resources: s.resources,
    services: s.services,
    doesHaveCombinations: s.doesHaveCombinations,
    selectedLocationId: s.selectedLocationId,
    calendarData: s.calendarData,
    serviceTags: s.serviceTags,
  }));

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { data: validOptions, mutateAsync: checkValidOptions } =
    api.appointments.usePostValidOptions();

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    if (
      !(
        doesHaveCombinations ||
        permissions.show_appointment_warnings ||
        permissions.restrict_appointment_overlaps ||
        permissions.restrict_appointment_services ||
        permissions.restrict_appointment_to_shifts
      )
    ) {
      return;
    }
    checkValidOptions({
      selectedServices: selectedServices.map((s) => ({
        ...s,
        duration:
          s.startDuration || 0 + timeOffDuration || 0 + endDuration || 0,
        timeOffDuration: s.timeOffDuration || undefined,
        timeOffStart: s.timeOffDuration ? s.startDuration : undefined,
      })),
      selectedServiceIndex: index,
      locationId: selectedLocationId,
      startTime: appointmentData.startTime,
    });
  }, [
    selectedService.users,
    selectedService.resources,
    selectedService.serviceId,
    selectedServices.length,
  ]);

  const usersDropdown = React.useMemo(
    () =>
      users
        .filter(
          (u) =>
            !validOptions ||
            validOptions.availableUsers.find(
              (usr) => u.userId === usr.userId && usr.isValid,
            ),
        )
        .map((user) => {
          const firstName = user.name?.toUpperCase();
          const lastName = user.lastName?.toUpperCase();

          const availableUserDetails = validOptions?.availableUsers?.find(
            (u) => u.userId === user.userId,
          );

          return {
            id: user.userId,
            value: user.userId.toString(),
            fullName: `${user.name} ${user.lastName}`,
            label: `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`,
            ...availableUserDetails,
            isValid: availableUserDetails ? availableUserDetails.isValid : true,
          };
        }),
    [users, validOptions],
  );

  const servicesDropdown = React.useMemo(
    () =>
      services
        .filter((s) => !s.isDeleted)
        .filter(
          (s) =>
            !validOptions ||
            validOptions.availableServices.find(
              (ser) => s.serviceId === ser.serviceId && ser.isValid,
            ),
        )
        // sort by serviceTags sortOrder first and then service sortOrder inside
        .toSorted((a, b) => {
          const aTagOrder =
            serviceTags.find((t) => t.tagId === a.tagId)?.sortOrder || 99;
          const bTagOrder =
            serviceTags.find((t) => t.tagId === b.tagId)?.sortOrder || 99;

          if (aTagOrder !== bTagOrder) return aTagOrder - bTagOrder;

          const aServiceOrder = a.sortOrder;
          const bServiceOrder = b.sortOrder;

          return aServiceOrder - bServiceOrder;
        })
        .map((service) => {
          const availableServiceDetails = validOptions?.availableServices?.find(
            (s) => s.serviceId === service.serviceId,
          );

          return {
            id: service.serviceId,
            value: service.serviceId.toString(),
            label: service.name,
            ...availableServiceDetails,
            isValid: availableServiceDetails
              ? availableServiceDetails.isValid
              : true,
            tagId: service.tagId,
            tagName: serviceTags.find((t) => t.tagId === service.tagId)
              ?.tagName,
          };
        }),
    [services, validOptions, serviceTags],
  );

  const resourcesDropdown = React.useMemo(
    () =>
      resources
        .filter(
          (rs) =>
            !validOptions ||
            validOptions.availableResources.find(
              (r) => rs.id === r.resourceId && r.isValid,
            ),
        )
        .map((r) => {
          const availableResourceDetails =
            validOptions?.availableResources?.find(
              (res) => res.resourceId === r.id,
            );

          return {
            id: r.id,
            value: r.id.toString(),
            label: r.label,
            ...availableResourceDetails,
            capacityMax: r.capacityMax,
            isValid: availableResourceDetails
              ? availableResourceDetails.isValid
              : true,
          };
        }),
    [resources, validOptions],
  );

  useEffect(() => {
    if (
      !!doesHaveCombinations ||
      !!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts
    ) {
      if (!validOptions) return;
    }

    if (
      selectedService.users.length > 0 &&
      selectedService.resources.length > 0
    )
      return;

    if (selectedService.appointmentId) {
      return;
    }

    const newServices = [...selectedServices];
    if (selectedService.users.length === 0 && service.doesRequireUser) {
      const freeUser = usersDropdown.find((user) => user.isValid);

      if (freeUser) {
        newServices[index].users = [Number(freeUser.value)];
        setSelectedServices(newServices);
      }
    }

    if (
      selectedService.resources.length === 0 &&
      service.serviceResourceGroups.length > 0
    ) {
      const freeResource = resourcesDropdown.find(
        (resource) => resource.isValid,
      );

      if (freeResource) {
        newServices[index].resources = [freeResource.id];
      }
    }

    if (service.isAttendanceExplicit) {
      newServices[index].attendance = newServices[index].attendance || 1;
    } else {
      newServices[index].attendance = undefined;
    }

    setSelectedServices(newServices);
  }, [selectedService.serviceId]);

  useEffect(() => {
    if (defaultOpen) {
      onOpen();
    }
  }, [defaultOpen]);

  const removeServiceFromLinked = () => {
    const newServices = [...selectedServices];
    if (selectedService.length <= 1) {
      return;
    }
    if (newServices[index].removed) {
      newServices[index].removed = false;
    } else {
      if (newServices[index].appointmentId) {
        newServices[index].removed = true;
      } else {
        newServices.splice(index, 1);
      }
    }

    setSelectedServices(newServices);
  };

  const toggleSelectMultipleUsers = (active) => {
    setSelectMultipleUsers(active);

    //  if multiple users selected, remove all users except the first one
    if (!active) {
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index].users = [selectedService.users[0]];
      setSelectedServices(newSelectedServices);
    }
  };
  const toggleSelectMultipleResources = (active) => {
    setSelectMultipleResources(active);

    // if multiple resources selected, remove all resources except the first one
    if (!active) {
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index].resources = selectedService.resources[0]
        ? [selectedService.resources[0]]
        : [];
      setSelectedServices(newSelectedServices);
    }
  };

  const notEnoughResourcesWarning = (() => {
    if (!service.isAttendanceExplicit) return false;
    if (!service.serviceResourceGroups.length) return false;

    const attendance = selectedService.attendance;
    const resourcesMaxCapacity = selectedService.resources.reduce(
      (acc, resource) => {
        const currResource = resources.find((r) => r.id === resource);

        if (!currResource) return acc;

        return acc + currResource.capacityMax;
      },
      0,
    );

    return attendance > resourcesMaxCapacity;
  })();

  const discountedPrice = React.useMemo(() => {
    if (!selectedService.discountAmount) return selectedService.price || 0;

    if (selectedService.discountType === "amount") {
      return selectedService.price - selectedService.discountAmount;
    }

    return (
      selectedService.price -
      (selectedService.price * selectedService.discountAmount) / 100
    );
  }, [
    selectedService.discountAmount,
    selectedService.discountType,
    selectedService.price,
  ]);

  const discountInputError = React.useMemo(() => {
    if (!selectedService.discountAmount) return false;

    if (selectedService.discountType === "amount") {
      if (selectedService.discountAmount > selectedService.price) {
        return t`Popust ne sme biti večji od cene`;
      }

      if (selectedService.discountAmount < 0) {
        return t`Popust ne sme biti manjši od 0`;
      }
    }

    if (selectedService.discountType === "percentage") {
      if (selectedService.discountAmount > 100) {
        return t`Popust ne sme biti večji od 100`;
      }

      if (selectedService.discountAmount < 0) {
        return t`Popust ne sme biti manjši od 0`;
      }
    }
  }, [
    selectedService.discountAmount,
    selectedService.discountType,
    selectedService.price,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (selectedServices?.length === 1) onOpen();
  }, []);

  const _selectedService = services?.find(
    (s) => s.serviceId == selectedService.serviceId,
  );

  return (
    <div
      style={{
        background: selectedService.removed
          ? "#ff000010"
          : "rgba(0, 0, 0, 0.02)",
        padding: "5px 7px",
        borderRadius: "10px",
      }}
    >
      <Flex
        key={selectedService.serviceId}
        justify={"space-between"}
        align={"start"}
        direction={"column"}
      >
        {/**
         * service combobox
         */}

        <Button
          onPress={isOpen ? onClose : onOpen}
          variant={isOpen ? "flat" : "light"}
          className="flex w-full items-center justify-between gap-1 pl-2 text-left"
        >
          <div className="ml-2 flex flex-col gap-0">
            <span className="text-sm font-medium">{_selectedService.name}</span>
            <div className="flex items-center gap-1 text-xs font-normal text-gray-500">
              {selectedService.startDuration != null && (
                <>
                  <Timer size={12} className="inline" />
                  {selectedService.startDuration}min
                </>
              )}
              {selectedService.timeOffDuration != null && (
                <>
                  {selectedService.startDuration != null ? ` ->` : undefined}{" "}
                  <TimerOff size={12} className="inline" />
                  {selectedService.timeOffDuration}min
                </>
              )}
              {selectedService.endDuration != null && (
                <>
                  {selectedService.timeOffDuration != null ||
                  selectMultipleResources.startDuration != null
                    ? ` ->`
                    : undefined}{" "}
                  <TimerReset size={12} className="inline" />
                  {selectedService.endDuration}min
                </>
              )}
            </div>
          </div>

          {selectedService.removed ? (
            <HeroButton
              onPress={removeServiceFromLinked}
              variant={"light"}
              color="danger"
              isIconOnly
            >
              <Undo2 size={16} />
            </HeroButton>
          ) : (
            <ArrowDown
              size={16}
              className={cn("transition-all", {
                "-rotate-180": isOpen,
              })}
            />
          )}
        </Button>

        <LimeCollapse isOpen={!selectedService.removed && isOpen} w={"100%"}>
          <div className="flex flex-col gap-2">
            <LimeAutocomplete
              isVirtualized={false} // TODO: set to true after bugs are fixed (https://github.com/heroui-inc/heroui/issues/4494) , (https://github.com/heroui-inc/heroui/issues/4568)
              selectedKey={selectedService?.serviceId?.toString()}
              size={"sm"}
              placeholder={t`Storitev še ni izbrana`}
              label={t`Storitev`}
              variant="faded"
              listBoxProps={{
                itemClasses: {
                  base: "h-fit w-fit",
                },
              }}
              scrollShadowProps={{
                isEnabled: false,
              }}
              onSelectionChange={(key) => {
                const val = Number(key);

                const newSelectedServices = [...selectedServices];

                const newService = services.find(
                  (service) => service.serviceId === val,
                );

                if (!newService) return;

                const user = newService.users.find(
                  (user) => user.userId === selectedService.userId,
                );

                const { startDuration, timeOffDuration, endDuration, price } =
                  getDurationsAndPrice(newService, user);

                newSelectedServices[index].serviceId = val;
                newSelectedServices[index].price = price;
                newSelectedServices[index].startDuration = startDuration;
                newSelectedServices[index].timeOffDuration = timeOffDuration;
                newSelectedServices[index].endDuration = endDuration;

                setSelectedServices(newSelectedServices);
              }}
            >
              {serviceTags
                ?.sort((a, b) => a.sortOrder - b.sortOrder)
                .map((st) => {
                  const services =
                    st.tagId === -1
                      ? servicesDropdown.filter((s) => !s.tagId)
                      : servicesDropdown.filter((s) => s.tagId === st.tagId);

                  if (services?.length === 0 && st.tagId !== -1) return null;

                  return (
                    <AutocompleteSection
                      classNames={{
                        heading:
                          "flex w-full sticky top-1 z-20 py-1.5 px-2 bg-default-100 rounded-small",
                      }}
                      showDivider
                      title={st.tagName}
                    >
                      {services?.map((s) => (
                        <AutocompleteItem
                          key={s.id.toString()}
                          textValue={s.label}
                          endContent={
                            <div className="flex items-center gap-2">
                              {s.userOverlapWarning ? (
                                <Tooltip
                                  content={t`Zaposleni je zaseden`}
                                  withArrow={true}
                                >
                                  <div>
                                    <LuCopySlash
                                      color={Color.Error}
                                    ></LuCopySlash>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {s.resourceOverlapWarning ? (
                                <Tooltip
                                  content={t`Sredstvo je zasedeno`}
                                  withArrow={true}
                                >
                                  <div>
                                    <LuCopySlash
                                      color={Color.Error}
                                    ></LuCopySlash>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              {s.userShiftWarning ? (
                                <Tooltip
                                  content={t`Zaposleni nima urnika`}
                                  withArrow={true}
                                >
                                  <div>
                                    <LuTimerOff
                                      color={Color.Error}
                                    ></LuTimerOff>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {s.resourceShiftWarning ? (
                                <Tooltip
                                  content={t`Sredstvo nima urnika`}
                                  withArrow={true}
                                >
                                  <div>
                                    <LuTimerOff
                                      color={Color.Error}
                                    ></LuTimerOff>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              {s.userRelationWarning ? (
                                <Tooltip
                                  content={t`Zaposleni ne izvaja te storitve`}
                                  withArrow={true}
                                >
                                  <div>
                                    <IoIosWarning
                                      color={Color.Error}
                                    ></IoIosWarning>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {s.resourceRelationWarning ? (
                                <Tooltip
                                  content={t`Sredstvo ne izvaja te storitve`}
                                  withArrow={true}
                                >
                                  <div>
                                    <IoIosWarning
                                      color={Color.Error}
                                    ></IoIosWarning>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              {s.combinationWarning ? (
                                <Tooltip
                                  content={t`Storitev ni na voljo v kombinaciji z ostalimi izbranimi storitvami.`}
                                  withArrow={true}
                                >
                                  <div>
                                    <LuPuzzle color={Color.Error}></LuPuzzle>
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          }
                        >
                          {s.label}
                        </AutocompleteItem>
                      ))}
                    </AutocompleteSection>
                  );
                })}
            </LimeAutocomplete>

            {/**
             * resource combobox
             */}
            {isFeatureEnabled("RESOURCES") && (
              <>
                <LimeSelect
                  data-identifier="appointment-resource-combobox"
                  selectionMode={
                    selectMultipleResources ? "multiple" : "single"
                  }
                  selectedKeys={
                    selectedServices[index].resources?.map(String) || []
                  }
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];

                    const newValues = val
                      .split(",")
                      .filter(Boolean)
                      .map((v) => Number(v));

                    newSelectedServices[index].resources = newValues;

                    setSelectedServices(newSelectedServices);
                  }}
                  disallowEmptySelection={false}
                  items={
                    resourcesDropdown?.map((r) => {
                      const userOverlapWarning = r.userOverlapWarning ? (
                        <CopySlash size={14} color={Color.Error} />
                      ) : undefined;
                      const resourceOverlapWarning =
                        r.resourceOverlapWarning ? (
                          <CopySlash size={14} color={Color.Error} />
                        ) : undefined;
                      const userShiftWarning = r.userShiftWarning ? (
                        <TimerOff size={14} color={Color.Error} />
                      ) : undefined;
                      const resourceShiftWarning = r.resourceShiftWarning ? (
                        <TimerOff size={14} color={Color.Error} />
                      ) : undefined;
                      const userRelationWarning = r.userRelationWarning ? (
                        <TriangleAlert size={14} color={Color.Error} />
                      ) : undefined;
                      const resourceRelationWarning =
                        r.resourceRelationWarning ? (
                          <TriangleAlert size={14} color={Color.Error} />
                        ) : undefined;

                      return {
                        key: r.id.toString(),
                        label: r.label,
                        endContent: (
                          <>
                            {userOverlapWarning}
                            {resourceOverlapWarning}
                            {userShiftWarning}
                            {resourceShiftWarning}
                            {userRelationWarning}
                            {resourceRelationWarning}
                          </>
                        ),
                      };
                    }) ?? []
                  }
                  label={t`Sredstva`}
                  error={
                    notEnoughResourcesWarning
                      ? t`Za to število oseb potrebujete več sredstev`
                      : undefined
                  }
                  size="sm"
                  endContent={
                    <HeroButton
                      onPress={() => {
                        toggleSelectMultipleResources(!selectMultipleResources);
                      }}
                      size="sm"
                      variant="ghost"
                      isIconOnly
                      className="-mt-4"
                      data-identifier="select-multi-resources-toggle"
                    >
                      {selectMultipleResources ? (
                        <Boxes size={16} />
                      ) : (
                        <Box size={16} />
                      )}
                    </HeroButton>
                  }
                  className={cn({
                    "mt-2": service.isAttendanceExplicit,
                  })}
                />

                {service.isAttendanceExplicit ? (
                  <LimeNumberInput
                    value={selectedService.attendance}
                    label={t`Število oseb`}
                    onValueChange={(val) => {
                      const newSelectedServices = [...selectedServices];
                      newSelectedServices[index].attendance = val;

                      setSelectedServices(newSelectedServices);
                    }}
                    step={1}
                    minValue={1}
                    size="sm"
                    hideStepper
                    labelPlacement="outside-left"
                    className="mb-2 justify-end self-end"
                  ></LimeNumberInput>
                ) : null}
              </>
            )}

            {/**
             * user combobox
             */}
            {(service.doesRequireUser || selectedService.users.length > 0) && (
              <LimeSelect
                data-identifier="appointment-user-combobox"
                selectionMode={selectMultipleUsers ? "multiple" : "single"}
                selectedKeys={selectedServices[index].users?.map(String) || []}
                onChange={(val) => {
                  const newSelectedServices = [...selectedServices];
                  const newValues = val
                    .split(",")
                    .filter(Boolean)
                    .map((v) => Number(v));
                  newSelectedServices[index].users = newValues;
                  setSelectedServices(newSelectedServices);
                }}
                disallowEmptySelection={false}
                items={
                  usersDropdown?.map((u) => {
                    const userOverlapWarning = u.userOverlapWarning ? (
                      <CopySlash size={14} color={Color.Error} />
                    ) : undefined;
                    const userShiftWarning = u.userShiftWarning ? (
                      <TimerOff size={14} color={Color.Error} />
                    ) : undefined;
                    const userRelationWarning = u.userRelationWarning ? (
                      <TriangleAlert size={14} color={Color.Error} />
                    ) : undefined;
                    const resourceOverlapWarning = u.resourceOverlapWarning ? (
                      <CopySlash size={14} color={Color.Error} />
                    ) : undefined;
                    const resourceShiftWarning = u.resourceShiftWarning ? (
                      <TimerOff size={14} color={Color.Error} />
                    ) : undefined;
                    const resourceRelationWarning =
                      u.resourceRelationWarning ? (
                        <TriangleAlert size={14} color={Color.Error} />
                      ) : undefined;

                    return {
                      key: u.id.toString(),
                      label: u.fullName,
                      endContent: (
                        <>
                          {userOverlapWarning}
                          {userShiftWarning}
                          {userRelationWarning}
                          {resourceOverlapWarning}
                          {resourceShiftWarning}
                          {resourceRelationWarning}
                        </>
                      ),
                    };
                  }) ?? []
                }
                label={t`Zaposleni`}
                size="sm"
                endContent={
                  <HeroButton
                    onPress={() => {
                      toggleSelectMultipleUsers(!selectMultipleUsers);
                    }}
                    size="sm"
                    variant="ghost"
                    isIconOnly
                    className="-mt-4"
                    data-identifier="select-multi-users-toggle"
                  >
                    {selectMultipleUsers ? (
                      <Users size={16} />
                    ) : (
                      <User size={16} />
                    )}
                  </HeroButton>
                }
              />
            )}

            <>
              {!isPreScheduled && (
                <>
                  <Divider />

                  <div>
                    <div className="w-44">
                      <LimeStepperNumberInput
                        value={selectedService.price || 0}
                        onValueChange={(val) => {
                          const newSelectedServices = [...selectedServices];
                          newSelectedServices[index].price = val;

                          setSelectedServices(
                            newSelectedServices,
                            false,
                            false,
                          );
                        }}
                        label={t`Cena`}
                        min={0}
                        size="sm"
                        endContent={
                          <p className="text-sm">
                            {returnCurrencySymbol({
                              currency: calendarData.currency || "EUR",
                            })}
                          </p>
                        }
                        steppersProps={{
                          variant: "flat",
                        }}
                      />
                    </div>

                    <div className="mt-1">
                      <LimeNumberInput
                        data-identifier="discount-input"
                        label={t`Popust`}
                        size="sm"
                        value={selectedService.discountAmount ?? 0}
                        onValueChange={(val) => {
                          const newSelectedServices = [...selectedServices];
                          newSelectedServices[index].discountAmount = val;

                          setSelectedServices(
                            newSelectedServices,
                            false,
                            false,
                          );
                        }}
                        step={1}
                        minValue={0}
                        error={discountInputError}
                        isDisabled={
                          !!permissions.restrict_custom_price ||
                          !permissions.manage_appointments
                        }
                        endContent={
                          <HeroButton
                            isIconOnly
                            variant="ghost"
                            size="sm"
                            onPress={() => {
                              const newSelectedServices = [...selectedServices];
                              newSelectedServices[index].discountType =
                                selectedService.discountType === "amount"
                                  ? "percentage"
                                  : "amount";

                              setSelectedServices(
                                newSelectedServices,
                                false,
                                false,
                              );
                            }}
                          >
                            {selectedService.discountType === "amount"
                              ? returnCurrencySymbol({
                                  currency: calendarData.currency || "EUR",
                                })
                              : "%"}
                          </HeroButton>
                        }
                      />
                    </div>
                  </div>

                  {selectedService.discountAmount &&
                  selectedService.discountAmount > 0 ? (
                    <p className="text-xs text-gray-500">
                      <Trans>Končna cena</Trans>:{" "}
                      {formatCurrency({
                        amount: discountedPrice ?? 0,
                        currency: calendarData.currency || "EUR",
                        locale: i18n.locale,
                      })}
                    </p>
                  ) : undefined}
                </>
              )}

              {startDuration != null ||
              timeOffDuration != null ||
              endDuration != null ? (
                <>
                  <Divider />

                  {hasOnlyOneInput ? undefined : (
                    <p className="text-xs font-medium text-gray-500">
                      <Trans>Prosti čas med terminom</Trans>
                    </p>
                  )}

                  {startDuration != null && (
                    <LimeStepperNumberInput
                      value={selectedService.startDuration}
                      onValueChange={(val) => {
                        const newSelectedServices = [...selectedServices];
                        newSelectedServices[index].startDuration = val;
                        setSelectedServices(
                          newSelectedServices,
                          service.isExtraTimeAllowed ? true : false,
                          false,
                        );
                      }}
                      step={5}
                      minValue={1}
                      className="w-full"
                      label={hasOnlyOneInput ? t`Čas trajanja` : t`Začetni čas`}
                      endContent={
                        <span className="text-xs text-gray-500">min</span>
                      }
                      size={"sm"}
                      isDisabled={
                        !!permissions.restrict_custom_duration ||
                        !permissions.manage_appointments
                      }
                    />
                  )}

                  {timeOffDuration != null && (
                    <LimeStepperNumberInput
                      value={selectedService.timeOffDuration}
                      onValueChange={(val) => {
                        const newSelectedServices = [...selectedServices];
                        newSelectedServices[index].timeOffDuration = val;
                        setSelectedServices(
                          newSelectedServices,
                          service.isExtraTimeAllowed ? true : false,
                          false,
                        );
                      }}
                      step={5}
                      minValue={0}
                      className="w-full"
                      label={t`Čas brez osebe`}
                      endContent={
                        <span className="text-xs text-gray-500">min</span>
                      }
                      size={"sm"}
                      isDisabled={!!permissions.restrict_custom_duration}
                    />
                  )}

                  {endDuration != null && (
                    <LimeStepperNumberInput
                      value={selectedService.endDuration}
                      onValueChange={(val) => {
                        const newSelectedServices = [...selectedServices];
                        newSelectedServices[index].endDuration = val;
                        setSelectedServices(
                          newSelectedServices,
                          service.isExtraTimeAllowed ? true : false,
                          false,
                        );
                      }}
                      step={5}
                      minValue={0}
                      className="w-full"
                      label={hasOnlyOneInput ? t`Čas trajanja` : t`Končni čas`}
                      endContent={
                        <span className="text-xs text-gray-500">min</span>
                      }
                      size={"sm"}
                      isDisabled={!!permissions.restrict_custom_duration}
                    />
                  )}
                </>
              ) : undefined}
            </>

            {isFeatureEnabled("SERVICE_BLOCK_BEFORE_AFTER") && (
              <>
                <Divider />
                <p className="text-xs font-medium text-gray-500">
                  <Trans>Blokiranje časa</Trans>
                </p>
                <div className="flex flex-col gap-2 sm:flex-row sm:gap-1">
                  <LimeStepperNumberInput
                    value={selectedService.blockBeforeMins}
                    onValueChange={(val) => {
                      const newSelectedServices = [...selectedServices];
                      newSelectedServices[index].blockBeforeMins = val;
                      setSelectedServices(newSelectedServices);
                    }}
                    step={5}
                    min={0}
                    label={t`Pred terminom`}
                    endContent={
                      <span className="text-xs text-gray-500">min</span>
                    }
                    size={"sm"}
                  />

                  <LimeStepperNumberInput
                    value={selectedService.blockAfterMins}
                    onValueChange={(val) => {
                      const newSelectedServices = [...selectedServices];
                      newSelectedServices[index].blockAfterMins = val;
                      setSelectedServices(newSelectedServices);
                    }}
                    step={5}
                    min={0}
                    label={t`Po terminu`}
                    endContent={
                      <span className="text-xs text-gray-500">min</span>
                    }
                    size={"sm"}
                  />
                </div>
              </>
            )}

            {selectedServices.length > 1 && (
              <HeroButton
                onPress={removeServiceFromLinked}
                variant={"light"}
                color="danger"
                size="sm"
              >
                <Trans>Odstrani storitev</Trans>
                <Trash size={16} />
              </HeroButton>
            )}
          </div>
        </LimeCollapse>
      </Flex>
    </div>
  );
};

export default SelectedServiceListItem;
