import { t } from "@lingui/core/macro";
import dayjs from "dayjs";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { TimeInput } from "@heroui/react";
import { getLocalTimeZone, parseDate, Time } from "@internationalized/date";
import { useEffect, useState } from "react";
import { LimeDatePicker } from "@/Components/NextBase/LimeDatePicker";

const DateTimeSection = ({
  date,
  startTime,
  endTime,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  isPreScheduled,
}: {
  date: string;
  startTime: string;
  endTime: string;
  handleDateChange: (date: Date | null) => void;
  handleStartTimeChange: (time: string) => void;
  handleEndTimeChange: (time: string) => void;
  isPreScheduled: boolean;
}) => {
  const [fromTime, setFromTime] = useState(
    new Time(Number(startTime.split(":")[0]), Number(startTime.split(":")[1])),
  );
  const [toTime, setToTime] = useState(
    new Time(Number(endTime.split(":")[0]), Number(endTime.split(":")[1])),
  );

  useEffect(() => {
    if (!startTime) return;

    setFromTime(
      new Time(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1]),
      ),
    );
  }, [startTime]);

  useEffect(() => {
    if (!endTime) return;

    setToTime(
      new Time(Number(endTime.split(":")[0]), Number(endTime.split(":")[1])),
    );
  }, [endTime]);

  const { permissions } = useUserPermissionsStore();

  return (
    <div className="mb-4 flex items-center gap-1">
      <LimeDatePicker
        label={t`Datum`}
        value={parseDate(dayjs(date).format("YYYY-MM-DD"))}
        onChange={(date) => {
          if (!date) return;

          handleDateChange(date.toDate(getLocalTimeZone()));
        }}
        size="sm"
        className="flex-1"
        isDisabled={!!isPreScheduled || !permissions.manage_appointments}
      />

      <div className="flex gap-1">
        <TimeInput
          data-identifier="time-from-input"
          label={t`Čas od`}
          size="sm"
          value={fromTime}
          onChange={(e) => {
            if (!e) return;

            handleStartTimeChange(`${e.hour}:${e.minute}`);
            setFromTime(e);
          }}
          hourCycle={24}
          isDisabled={!!isPreScheduled || !permissions.manage_appointments}
        />

        <TimeInput
          label={t`Čas do`}
          size="sm"
          value={toTime}
          onChange={(e) => {
            if (!e) return;

            handleEndTimeChange(`${e.hour}:${e.minute}`);
            setToTime(e);
          }}
          hourCycle={24}
          isDisabled={true}
        />
      </div>
    </div>
  );
};

export default DateTimeSection;
