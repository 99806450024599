import { api } from "@/lib/api-client";
import { Table } from "@mantine/core";
import dayjs from "dayjs";
import React, { useState } from "react";
import InvoiceDetails from "./InvoiceDetails";

const InvoicesTab = ({ clientId }: { clientId: number }) => {
  const {
    data,
    refetch: refetchBillingData,
    isFetching,
  } = api.admin.useGetInvoices(clientId);

  const [selectedInvoice, setSelectedInvoice] = useState<number | null>(null);

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>Error</div>;

  if (selectedInvoice) {
    return <InvoiceDetails clientId={clientId} invoiceId={selectedInvoice} />;
  }

  return (
    <div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Invoice ID</Table.Th>
            <Table.Th>Document ID</Table.Th>
            <Table.Th>Invoice type</Table.Th>
            <Table.Th>Date Created</Table.Th>
            {/* <Table.Th>Price tier</Table.Th> */}
            {/* <Table.Th>Price notifications</Table.Th> */}
            {/* <Table.Th>Price additional items</Table.Th> */}
            <Table.Th>Price total</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((invoice) => {
            const priceNotifications = invoice.InvoiceNotification.reduce(
              (acc, notification) => {
                const discountAmountMultiplier =
                  1 - Number(notification.discountAmount) / 100;
                return (
                  acc +
                  ((notification.priceCentsPer * notification.amount) / 100) *
                    discountAmountMultiplier
                );
              },
              0,
            );

            const priceAdditionalItems =
              invoice.AdditionalItems.reduce((acc, additionalItem) => {
                const discountAmountMultiplier =
                  1 - Number(additionalItem.discountAmount) / 100;
                return (
                  acc +
                  additionalItem.priceCentsPer *
                    additionalItem.quantity *
                    discountAmountMultiplier
                );
              }, 0) / 100 || 0;

            const priceTier = invoice.ClientTierPeriod
              ? ((invoice.ClientTierPeriod.priceCents || 0) *
                  (1 - Number(invoice.ClientTierPeriod.discountAmount) / 100)) /
                100
              : 0;

            const taxRegisterFeatures = invoice.Features.filter((feature) =>
              ["TAX_REGISTER", "PRODUCTS"].includes(
                feature.billingRecord.featureId,
              ),
            );

            const nonTaxRegisterFeatures = invoice.Features.filter(
              (feature) =>
                !["TAX_REGISTER", "PRODUCTS"].includes(
                  feature.billingRecord.featureId,
                ),
            );

            const priceFeatures =
              nonTaxRegisterFeatures.reduce((acc, feature) => {
                const discountAmountMultiplier =
                  1 - Number(feature.discountAmount) / 100;
                return acc + feature.priceCents * discountAmountMultiplier;
              }, 0) / 100;

            const priceTaxRegisterFeatures =
              taxRegisterFeatures.reduce((acc, feature) => {
                const discountAmountMultiplier =
                  1 - Number(feature.discountAmount) / 100;
                return acc + feature.priceCents * discountAmountMultiplier;
              }, 0) / 100;

            const invoiceType = (() => {
              const types = [];
              if (invoice.ClientTierPeriod) {
                types.push("Tier");
              }
              if (
                invoice.InvoiceNotification[0] &&
                invoice.InvoiceNotification[0].amount > 0
              ) {
                types.push("SMS");
              }
              if (taxRegisterFeatures.length) {
                types.push("Tax Register");
              }

              return types.join(", ");
            })();

            return (
              <Table.Tr
                key={invoice.id}
                onClick={() => setSelectedInvoice(invoice.id)}
              >
                <Table.Td>{invoice.id}</Table.Td>
                <Table.Td>{invoice.externalInvoiceId}</Table.Td>
                <Table.Td>{invoiceType}</Table.Td>
                <Table.Td>
                  {dayjs(invoice.dateCreated).format("DD. MM. YYYY")}
                </Table.Td>
                <Table.Td>
                  {Math.ceil(
                    (priceTier +
                      priceNotifications +
                      priceAdditionalItems +
                      priceFeatures +
                      priceTaxRegisterFeatures) *
                      100,
                  ) / 100}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </div>
  );
};

export default InvoicesTab;
