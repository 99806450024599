import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  deleteUserAppointment,
  updateUserAppointmentAttendance,
  updateUserAppointmentComment,
} from "../../../../../apiSchedule";
import CustomerAppointment from "./CustomerAppointment";
import { api } from "@/lib/api-client";
import { returnCurrencySymbol } from "../../../../../../../shared/utils/utils";

import { notifications } from "@mantine/notifications";

const AppointmentType = {
  UPCOMING: "UPCOMING",
  COMPLETED: "COMPLETED",
  DELETED: "DELETED",
};

const CustomerAppointments = ({
  customerData,
  isMobile,
  refreshCustomerData,
  currencySymbol,
}) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(
    AppointmentType.UPCOMING,
  );
  const [arrayToDisplay, setArrayToDisplay] = useState([]);

  const listRef = useRef(null);

  const deleteUserAppointmentHandler = async (
    userAppointmentId,
    appointmentId,
  ) => {
    try {
      await deleteUserAppointment(userAppointmentId, appointmentId);
      notifications.show({ title: t`Termin izbrisan` });
      refreshCustomerData();
    } catch (e) {
      notifications.show({ title: t`Prišlo je do napake`, color: "red" });
    }
  };

  const updateUserAppointmentCommentHandler = async (
    userAppointmentId,
    comment,
  ) => {
    const response = await updateUserAppointmentComment(
      userAppointmentId,
      comment,
      localStorage.getItem("usr_token"),
    );
    if (response.status === 200) {
      setArrayToDisplay((prevArray) =>
        prevArray.map((appointment) =>
          appointment.userAppointmentId === userAppointmentId
            ? { ...appointment, comment }
            : appointment,
        ),
      );
      refreshCustomerData();
    }

    return response;
  };

  const updateUserAppointmentAttendanceHandler = async (id, attendance) => {
    const response = await updateUserAppointmentAttendance(id, attendance);
    if (response.status === 200) {
      setArrayToDisplay((prevArray) =>
        prevArray.map((appointment) =>
          appointment.userAppointmentId === id
            ? { ...appointment, customerDidAttend: attendance }
            : appointment,
        ),
      );
      refreshCustomerData();
    }
  };

  useEffect(() => {
    const appointmentsToDisplay = (() => {
      if (selectedAppointmentType === AppointmentType.UPCOMING) {
        return customerData.upcommingAppointments || [];
      } else if (selectedAppointmentType === AppointmentType.COMPLETED) {
        return customerData.completedAppointments || [];
      } else if (selectedAppointmentType === AppointmentType.DELETED) {
        return customerData.deletedAppointments || [];
      }
    })();
    setArrayToDisplay(appointmentsToDisplay);
  }, [customerData]);

  const handleTypeChange = (type, appointments) => {
    setSelectedAppointmentType(type);
    setArrayToDisplay(appointments || []);
  };

  return (
    <CustomerAppointmentsWrapper>
      <AppointmentTypeToggler>
        <AppointmentTypeTogglerTitle
          onClick={() =>
            handleTypeChange(
              AppointmentType.UPCOMING,
              customerData.upcommingAppointments,
            )
          }
          selected={selectedAppointmentType === AppointmentType.UPCOMING}
        >
          <Trans>Prihajajoči termini</Trans>
        </AppointmentTypeTogglerTitle>
        <AppointmentTypeTogglerTitle
          onClick={() =>
            handleTypeChange(
              AppointmentType.COMPLETED,
              customerData.completedAppointments,
            )
          }
          selected={selectedAppointmentType === AppointmentType.COMPLETED}
        >
          <Trans>Pretekli termini</Trans>
        </AppointmentTypeTogglerTitle>
        <AppointmentTypeTogglerTitle
          onClick={() =>
            handleTypeChange(
              AppointmentType.DELETED,
              customerData.deletedAppointments,
            )
          }
          selected={selectedAppointmentType === AppointmentType.DELETED}
        >
          <Trans>Izbrisani termini</Trans>
        </AppointmentTypeTogglerTitle>
      </AppointmentTypeToggler>
      <AppointmentsList ref={listRef}>
        {arrayToDisplay.length === 0 && (
          <p>
            <Trans>Ni terminov</Trans>
          </p>
        )}
        {arrayToDisplay.map((appointment) => (
          <CustomerAppointment
            listRef={listRef}
            currencySymbol={currencySymbol}
            refreshCustomerData={refreshCustomerData}
            key={appointment.appointmentId}
            isMobile={isMobile}
            appointment={appointment}
            deleteUserAppointment={deleteUserAppointmentHandler}
            selectedAppointmentType={selectedAppointmentType}
            updateUserAppointmentAttendance={
              updateUserAppointmentAttendanceHandler
            }
            updateUserAppointmentComment={updateUserAppointmentCommentHandler}
          />
        ))}
      </AppointmentsList>
    </CustomerAppointmentsWrapper>
  );
};

const AppointmentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: scroll;
`;

const AppointmentTypeToggler = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const AppointmentTypeTogglerTitle = styled.div`
  color: ${(props) => (props.selected ? "#74B587" : "#424b64")};
  padding: 0.5rem;
  font-size: 1rem;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #74B587" : "3px solid #E2E4E5"};
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  transition: all 0.4s ease-in-out;
`;

const CustomerAppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export default CustomerAppointments;
