import { Trans } from "@lingui/react/macro";

import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import "dayjs/locale/sl";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Flex, Text } from "@mantine/core";
import { DatePickerInput, MonthPickerInput } from "@mantine/dates";
import _ from "lodash";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCalendarEventFill,
} from "react-icons/ri";

const DatePicker = ({
  selectedDate,
  calendarView,
  onDateChange,
  showTodayButton = true,
  minDate,
  maxDate,
  overrideStartDate,
  overrideEndDate,
}) => {
  const [hovered, setHovered] = React.useState(null);

  const canGoForward =
    !maxDate ||
    selectedDate.startOf(calendarView).add(1, calendarView).isBefore(maxDate);
  const canGoBack =
    !minDate ||
    selectedDate.endOf(calendarView).subtract(1, calendarView).isAfter(minDate);

  const handleNextClick = () => {
    const newDate = selectedDate.add(1, calendarView);

    if (!canGoForward) return;

    onDateChange(newDate);
  };

  const handlePrevClick = () => {
    const newDate = selectedDate.subtract(1, calendarView);

    if (!canGoBack) return;

    onDateChange(newDate);
  };

  const handleDaySelect = (date) => {
    if (_.isArray(date)) {
      date = dayjs(date[0]);
    }

    onDateChange(dayjs(date));
  };

  let isCurrent = false;
  let value = "";
  if (calendarView === "day") {
    isCurrent = selectedDate.isSame(dayjs(), "day");
    value = selectedDate.toDate();
  } else if (calendarView === "week") {
    const start = selectedDate.locale("sl").weekday(0);
    const end = selectedDate.locale("sl").weekday(6);
    isCurrent = selectedDate.isSame(dayjs(), "week");
    value = [start.toDate(), end.toDate()];
  } else if (calendarView === "month") {
    const start = selectedDate.locale("sl").startOf("month");
    isCurrent = selectedDate.isSame(dayjs(), "month");
    value = [start.toDate(), selectedDate.locale("sl").endOf("month").toDate()];
  }

  if (_.isArray(value)) {
    if (overrideStartDate) {
      value[0] = overrideStartDate.toDate();
    }

    if (overrideEndDate) {
      value[1] = overrideEndDate.toDate();
    }
  } else {
    if (overrideStartDate) {
      value = overrideStartDate.toDate();
    }

    if (overrideEndDate) {
      value = overrideEndDate.toDate();
    }
  }

  return (
    <Wrapper isCurrent={isCurrent}>
      {showTodayButton && (
        <TodayLabel
          onClick={() => onDateChange(dayjs(), true)}
          isCurrent={isCurrent}
        >
          <RiCalendarEventFill style={{ fontSize: "18px" }} />
          <Text variant={TextVariant.BodyEmphasized}>
            <Trans>Danes</Trans>
          </Text>
        </TodayLabel>
      )}
      <Flex
        align={"center"}
        gap={{
          400: "5px",
          sm: 0,
        }}
      >
        <IconWrapper
          isDisabled={!canGoBack}
          isLeft
          onClick={handlePrevClick}
          data-identifier="prev-day-button"
        >
          <RiArrowLeftSLine />
        </IconWrapper>
        <Outer data-identifier="date-picker-outer">
          {calendarView === "month" ? (
            <MonthPickerInput
              // locale={navigator.language}
              level={"year"}
              defaultLevel={"year"}
              type={"default"}
              value={selectedDate.toDate()}
              onChange={handleDaySelect}
              variant={"unstyled"}
              valueFormat={"MMMM YYYY"}
              closeOnChange={true}
              minDate={minDate}
              maxDate={maxDate}
              withCellSpacing={false}
              getMonthControlProps={(date) => {
                const isSelected = selectedDate.isSame(date, calendarView);
                const isHovered =
                  hovered && dayjs(hovered).isSame(date, calendarView);

                const dateDayObject = dayjs(date);

                const isBeforeMinDate =
                  minDate && dateDayObject.isBefore(minDate, "day");
                const isAfterMaxDate =
                  maxDate && dateDayObject.isAfter(maxDate, "day");

                const isTodayDate = dateDayObject.isSame(dayjs(), "month");
                const shouldHighlightTodayDate = isTodayDate && !isSelected;

                return {
                  selected: isSelected,
                  onMouseEnter: () => setHovered(date),
                  onMouseLeave: () => setHovered(null),
                  inRange: isHovered || isSelected,
                  onClick: () => {
                    handleDaySelect(date);
                  },
                  disabled: isBeforeMinDate || isAfterMaxDate,
                  bg: shouldHighlightTodayDate ? "#d20d0d" : undefined,
                  c: shouldHighlightTodayDate ? "white" : undefined,
                };
              }}
            />
          ) : (
            <DatePickerInput
              // locale={navigator.language}
              data-identifier="date-picker-input"
              level={"month"}
              defaultLevel={"month"}
              type={calendarView === "day" ? "default" : "range"}
              value={value}
              onChange={handleDaySelect}
              variant={"unstyled"}
              valueFormat={"DD. MM. YY"}
              closeOnChange={true}
              minDate={minDate}
              maxDate={maxDate}
              withCellSpacing={false}
              getDayProps={(date) => {
                const isSelected = selectedDate
                  .locale("sl")
                  .isSame(date, calendarView);
                const isHovered =
                  hovered &&
                  dayjs(hovered).locale("sl").isSame(date, calendarView);

                const dateDayObject = dayjs(date).locale("sl");

                const isBeforeMinDate =
                  minDate && dateDayObject.isBefore(minDate, "day");
                const isAfterMaxDate =
                  maxDate && dateDayObject.isAfter(maxDate, "day");

                const isTodayDate = dateDayObject.isSame(dayjs(), "day");
                const shouldHighlightTodayDate = isTodayDate && !isSelected;

                return {
                  selected: isSelected,
                  onMouseEnter: () => setHovered(date),
                  onMouseLeave: () => setHovered(null),
                  inRange: isHovered || isSelected,
                  onClick: () => {
                    handleDaySelect(date);
                  },
                  disabled: isBeforeMinDate || isAfterMaxDate,
                  bg: shouldHighlightTodayDate ? "red" : undefined,
                  c: shouldHighlightTodayDate ? "white" : undefined,
                  style: shouldHighlightTodayDate
                    ? { borderRadius: "50%" }
                    : undefined,
                };
              }}
            />
          )}
        </Outer>
        <IconWrapper
          isDisabled={!canGoForward}
          isRight
          onClick={handleNextClick}
          data-identifier="next-day-button"
        >
          <RiArrowRightSLine />
        </IconWrapper>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  user-select: none;
  color: white !important;

  display: flex;
  align-items: center;

  justify-content: space-around;

  flex: 1;

  flex-wrap: wrap;

  @media (min-width: 400px) {
    gap: 10px;

    flex-wrap: unset;
  }

  @media (min-width: 768px) {
    margin: 0;
    gap: 15px;
  }

  @media (min-width: 768px) {
    justify-content: unset;
  }
`;

const Outer = styled.div`
  user-select: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px;
  color: #6c7b86;
  padding: 7px 2px;
  transition: all 0.25s cubic-bezier(0.47, 0, 0.75, 1);
  width: auto;

  .mantine-DatePickerInput-input {
    white-space: pre;
  }

  button {
    border: none;
    background: none;
    color: #6c7b86;
    font-weight: 600;
  }

  > .icon {
    font-size: 2rem;
    cursor: pointer;
  }

  label {
    font-size: 1rem;
    color: #6c7b86;
    font-weight: 500;
  }

  @media (min-width: 400px) {
    padding: 7px 10px;
  }

  @media (min-width: 768px) {
    padding: 4px 5px;
  }
`;

const IconWrapper = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  border-radius: 50%;
  padding: 3px;
  color: ${Color.SecondaryText};
  font-size: 1.4rem;

  background: ${({ isDisabled }) =>
    isDisabled ? "rgba(108,123,134,0.15)" : "none"};
`;

const TodayLabel = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  color: ${Color.SecondaryText};

  background: #fafafa;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  padding: 0 15px;

  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: 400px) {
  }

  @media (min-width: 768px) {
    top: -25px;
    font-size: 1.1rem;

    span {
      font-size: 1.2rem;
    }
  }
`;

export default React.memo(DatePicker);
