import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Key, useCallback, useState } from "react";
import { Text } from "@mantine/core";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { useDebouncedState } from "@mantine/hooks";
import { api } from "@/lib/api-client";
import LoadingSpinner from "@/Components/Dashboard/LoadingSpinner/LoadingSpinner";
import { GetGiftCards } from "@/server-types";
import { i18n } from "@lingui/core";
import { LimeSelect } from "@/Components/LimeSelect";
import {
  Button,
  Snippet,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@heroui/react";
import { GiftCardManageModal } from "../TaxRegister/Invoices/POS/GiftCardManageModal";
import { notifications } from "@mantine/notifications";
import { Edit, Trash2 } from "lucide-react";
import { cn } from "@/utils";
import { AlertButton } from "@/Components/NextBase/AlertButton";
import { LimePagination } from "@/Components/NextBase/LimePagination";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { getPaymentStatusIcon } from "./Util";

export const GiftCard = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useDebouncedState("", 200);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState("10");
  const [sort, setSort] =
    useState<GetGiftCards["query"]["sort"]>("createdAt_desc");

  const [editingGiftCardId, setEditingGiftCardId] = useState<
    number | undefined
  >(undefined);

  const {
    isOpen: isGiftCardManageModalOpen,
    onOpen: onOpenGiftCardManageModal,
    onClose: onCloseGiftCardManageModal,
  } = useDisclosure();

  const {
    mutateAsync: updateGiftCard,
    isPending: isUpdatingGiftCard,
    processedErrorMessage: updateErrorMessage,
  } = api.giftCard.usePutGiftCard();

  const {
    data: giftCardsData,
    isPending,
    processedErrorMessage,
    refetch,
  } = api.giftCard.useGetGiftCards({
    search,
    page,
    limit: Number(perPage),
    sort: sort,
  });

  const handleCreateGiftCard = () => {
    navigate("../tax-register/invoice/new?create-gc=true", {
      relative: "path",
    });
  };

  const sortOptions = [
    { value: "createdAt_asc", label: t`Datum ustvarjanja (naraščajoče)` },
    { value: "createdAt_desc", label: t`Datum ustvarjanja (padajoče)` },
    { value: "expiryDate_asc", label: t`Datum poteka (naraščajoče)` },
    { value: "expiryDate_desc", label: t`Datum poteka (padajoče)` },
    { value: "initialAmount_asc", label: t`Začetni znesek (naraščajoče)` },
    { value: "initialAmount_desc", label: t`Začetni znesek (padajoče)` },
    { value: "remainingBalance_asc", label: t`Preostali znesek (naraščajoče)` },
    { value: "remainingBalance_desc", label: t`Preostali znesek (padajoče)` },
  ];

  return (
    <>
      <LimePageHeader
        title={t`Darilni boni`}
        rightSection={{
          label: t`Dodaj nov darilni bon`,
          onClick: handleCreateGiftCard,
        }}
      />

      <div className="flex flex-col items-center justify-center gap-2 px-2 py-4 md:flex-row md:px-6">
        <LimeTextInput
          w={"100%"}
          placeholder={t`Iskanje darilnih kartic`}
          leftSection={<RiSearch2Line size={".85rem"} />}
          defaultValue={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
        <div className="w-full min-w-64 md:w-auto">
          <LimeSelect
            w={"100%"}
            data={sortOptions}
            value={sort}
            onChange={(_, option) =>
              setSort(option.value as GetGiftCards["query"]["sort"])
            }
          />
        </div>
      </div>

      <LimeAlert
        message={processedErrorMessage}
        className="mb-2"
        color="danger"
      />

      {isPending && <LoadingSpinner />}

      {!isPending &&
        giftCardsData?.giftCards &&
        giftCardsData.giftCards.length > 0 && (
          <GiftCardList
            giftCards={giftCardsData.giftCards}
            handleGiftCardClick={(giftCardId) =>
              navigate(`/dashboard/gift-card/${giftCardId}`)
            }
            handleEditGiftCard={(giftCardId) => {
              setEditingGiftCardId(giftCardId);
              onOpenGiftCardManageModal();
            }}
            refetch={refetch}
          />
        )}

      {!isPending && giftCardsData?.giftCards.length === 0 && (
        <NoGiftCardsPlaceholder onNewGiftCardClick={handleCreateGiftCard} />
      )}

      <div className="mt-4 md:px-8">
        <LimePagination
          paginationProps={{
            total: giftCardsData?.totalPages || 1,
            page: page,
            onChange: setPage,
          }}
          perPageProps={{
            label: t`elementov`,
            values: [10, 20, 50, 100],
            onChange: setPerPage,
            value: perPage,
          }}
        />
      </div>

      <GiftCardManageModal
        isOpen={isGiftCardManageModalOpen}
        handleClose={async (data) => {
          if (data.giftCardData) {
            if (data.type === "create") {
              return;
            } else {
              await updateGiftCard({
                id: data.giftCardData.id!,
                body: {
                  ...data.giftCardData,
                  note: data.giftCardData.note || undefined,
                },
              });
            }

            notifications.show({
              message: t`Darilni bon uspešno ustvarjen`,
            });

            refetch();
          }

          onCloseGiftCardManageModal();
          setEditingGiftCardId(undefined);
        }}
        editingGiftCardId={editingGiftCardId}
        isUpdatingGiftCard={isUpdatingGiftCard}
        updateErrorMessage={updateErrorMessage}
      />
    </>
  );
};

const NoGiftCardsPlaceholder = ({
  onNewGiftCardClick,
}: {
  onNewGiftCardClick: () => void;
}) => {
  return (
    <div
      style={{
        height: "calc(100dvh - 220px)",
      }}
      className="flex flex-col items-center justify-center"
    >
      <Text c={"dimmed"}>
        <Trans>Trenutno ni darilnih bonov</Trans>
      </Text>
      <Button className="mt-4" onPress={onNewGiftCardClick}>
        <Trans>Dodaj nov darilni bon</Trans>
      </Button>
    </div>
  );
};

type GiftCardListProps = {
  giftCards: GetGiftCards["response"]["giftCards"];
  handleGiftCardClick: (giftCardId: number) => void;
  handleEditGiftCard: (giftCardId: number) => void;
  refetch: () => void;
};

const GiftCardList = ({
  giftCards,
  handleGiftCardClick,
  handleEditGiftCard,
  refetch,
}: GiftCardListProps) => {
  const { locale } = i18n;

  const {
    mutateAsync: deleteGiftCard,
    isPending: isDeletingGiftCard,
    processedErrorMessage: deleteErrorMessage,
  } = api.giftCard.useDeleteGiftCard();

  const handleDeleteGiftCard = async (giftCardId: number) => {
    await deleteGiftCard({ id: giftCardId });

    notifications.show({
      message: t`Darilni bon je bil uspešno izbrisan`,
    });

    await refetch();
  };

  const desktopOnlyKeys = ["initialAmount", "expiryDate", "createdAt"];

  const columns: {
    key: string;
    label: string;
  }[] = [
    {
      key: "code",
      label: t`Koda`,
    },
    {
      key: "note",
      label: t`Opomba`,
    },
    {
      key: "remainingBalance",
      label: t`Preostali znesek`,
    },
    {
      key: "initialAmount",
      label: t`Začetni znesek`,
    },
    {
      key: "expiryDate",
      label: t`Datum poteka`,
    },
    {
      key: "createdAt",
      label: t`Datum izdaje`,
    },
    {
      key: "actions",
      label: t`Akcije`,
    },
  ];

  const rows = giftCards.map((giftCard) => {
    return {
      id: giftCard.id,
      code: giftCard.code,
      note: giftCard.note,
      remainingBalance: giftCard.remainingBalanceFormatted,
      initialAmount: giftCard.initialAmountFormatted,
      expiryDate: new Date(giftCard.expiryDate).toLocaleDateString(locale),
      createdAt: new Date(giftCard.createdAt).toLocaleDateString(locale),
      paymentStatus: giftCard.paymentStatus,
    };
  });

  const renderCell = useCallback(
    (giftCard: (typeof rows)[number], columnKey: Key) => {
      const cellValue = giftCard[columnKey as keyof typeof giftCard];

      switch (columnKey) {
        case "actions":
          return (
            <div className="relative flex items-center gap-2">
              <Tooltip
                content={t`Uredi darilni bon`}
                classNames={{
                  base: "pointer-events-none",
                }}
                closeDelay={50}
              >
                <Button
                  onPress={() => handleEditGiftCard(giftCard.id)}
                  isIconOnly
                  variant="light"
                >
                  <span className="cursor-pointer text-lg text-default-400 active:opacity-50">
                    <Edit size={20} />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip
                color="danger"
                content={t`Izbriši darilni bon`}
                classNames={{
                  base: "pointer-events-none",
                }}
                closeDelay={50}
              >
                <AlertButton
                  confirmProps={{
                    isLoading: isDeletingGiftCard,
                    onClick: () => handleDeleteGiftCard(giftCard.id),
                    label: t`Izbriši`,
                    dataIdentifier: "confirm-delete-gift-card",
                  }}
                  buttonProps={{
                    isIconOnly: true,
                    variant: "light",
                    dataIdentifier: "delete-gift-card",
                  }}
                  content={t`Ali ste prepričani, da želite izbrisati darilni bon?`}
                  title={t`Izbriši darilni bon`}
                >
                  <span className="cursor-pointer text-lg text-danger active:opacity-50">
                    <Trash2 size={20} />
                  </span>
                </AlertButton>
              </Tooltip>
            </div>
          );
        case "code":
          return (
            <div className="flex flex-row items-center">
              <Tooltip content={giftCard.paymentStatus}>
                {getPaymentStatusIcon(giftCard.paymentStatus)}
              </Tooltip>

              <Snippet
                tooltipProps={{
                  content: t`Kopiraj kodo`,
                }}
                symbol=""
                variant="bordered"
                className="border-0"
              >
                {giftCard.code}
              </Snippet>
            </div>
          );
        case "note":
          if (!giftCard.note) return <span></span>;

          if (giftCard.note.length > 20) {
            return <span>{giftCard.note.slice(0, 20)}...</span>;
          }

          return <span>{giftCard.note}</span>;
        default:
          return cellValue;
      }
    },
    [],
  );

  return (
    <div className="md:px-6">
      <LimeAlert
        message={deleteErrorMessage}
        className="mx-2 mb-2 md:mx-6"
        color="danger"
      />

      <Table
        isStriped
        shadow="none"
        classNames={{
          wrapper: "p-0",
        }}
        className="mx-2 md:mx-auto"
        onRowAction={(key) => {
          const giftCard = giftCards.find((g) => g.code == key.valueOf());
          if (!giftCard) return;

          handleGiftCardClick(giftCard.id);
        }}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn
              key={column.key}
              className={cn({
                "hidden md:table-cell": desktopOnlyKeys.includes(column.key),
              })}
            >
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={rows}>
          {(item) => (
            <TableRow
              key={item.code}
              className="cursor-pointer"
              data-identifier={`gift-card-row-${item.code}`}
            >
              {(columnKey) => (
                <TableCell
                  className={cn({
                    "hidden md:table-cell": desktopOnlyKeys.includes(
                      columnKey.valueOf() as string,
                    ),
                  })}
                >
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
