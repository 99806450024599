import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useState } from "react";
import Turnstile from "react-turnstile";
import { apiLogin, getInfoFromToken } from "@/apiCalls";
import useAccountInfo from "@/hooks/useAccountInfo";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { useLingui } from "@lingui/react";
import { useForm } from "@mantine/form";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import { AuthFormWrapper } from "../AuthFormWrapper";
import { addToast, Button } from "@heroui/react";
import { Eye, EyeOff } from "lucide-react";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeInput } from "@/Components/NextBase/LimeInput";

type LoginProps = {
  setAuth: (value: boolean) => void;
  setUserToken: (value: string) => void;
  setOnboardingDone: (value: boolean) => void;
};

const Login = (props: LoginProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const [turnstileKey, setTurnstileKey] = useState(0);

  const posthog = usePostHog();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim()) ? null : t`Neveljaven e-poštni naslov`,
      password: (value) => (value.length > 0 ? null : t`Geslo je prekratko`),
    },
  });

  const setAccountInfo = useAccountInfo((state) => state.setAccountInfo);
  useLingui();

  const initLoadPermissions = useUserPermissionsStore(
    (state) => state.initLoadPermissions,
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmitForm = async (values: typeof form.values) => {
    setIsLoading(true);
    // if (!turnstileToken && !import.meta.env.VITE_DISABLE_TURNSTILE) {
    //   notifications.show({
    //     title: t`Napaka`,
    //     message: t`Prosimo, potrdite, da niste robot.`,
    //     color: "red",
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    try {
      const data = {
        email: values.email.trim(),
        password: values.password.trim(),
        rememberMe: values.rememberMe ?? false,
        turnstileToken,
      };

      const isAuth = await apiLogin(data);
      const isAuthData = await isAuth.data;
      if (isAuthData !== "") {
        const verify = await getInfoFromToken(isAuthData);
        setAccountInfo(verify.data[0]);

        props.setOnboardingDone(verify.data[0].onboardingDone);
        props.setUserToken(isAuthData);
        await localStorage.setItem("usr_token", JSON.stringify(isAuthData));

        Sentry.setContext("user", {
          clientId: verify.data[0].clientId,
          userId: verify.data[0].userId,
          pricingTierId: verify.data[0].FK_pricingTierId,
          onboardingDone: verify.data[0].onboardingDone,
        });

        posthog?.identify(verify.data[0].userId, {
          name: verify.data[0].userName,
          lastName: verify.data[0].userLastName,
          email: verify.data[0].userEmail,
          gsm: verify.data[0].userPhone,
          clientId: verify.data[0].clientId,
        });

        posthog?.group("client", verify.data[0].clientId);
        Sentry.setTag("clientId", verify.data[0].clientId);

        await initLoadPermissions();
        props.setAuth(true);
      } else {
        localStorage.removeItem("usr_token");
        posthog?.reset();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log("ERR", e);
      localStorage.removeItem("usr_token");

      addToast({
        title: t`Napaka`,
        description: e.response?.data ?? t`Napaka`,
        color: "danger",
      });

      posthog.capture("login_failed", {
        error: e.response?.data ?? "Unknown error",
      });
      posthog?.reset();

      Sentry.captureException(e, {
        tags: {
          page: "Login",
        },
      });

      // Reset Turnstile token to force re-render
      setTurnstileToken(null);
      setTurnstileKey((prev) => prev + 1);
    }
    setIsLoading(false);
  };

  const pageTitle = t`Prijava`;

  return (
    <>
      <title>{pageTitle}</title>
      <AuthFormWrapper isLogin={true}>
        <form
          style={{ display: "contents" }}
          onSubmit={form.onSubmit(onSubmitForm)}
          noValidate
        >
          <LimeInput
            label={t`E-poštni naslov`}
            data-identifier="email-input"
            size="sm"
            className="w-full"
            {...form.getInputProps("email")}
            autoComplete="email"
            name="email"
          />

          <LimeInput
            label={t`Geslo`}
            data-identifier="password-input"
            size="sm"
            className={"w-full"}
            {...form.getInputProps("password")}
            type={showPassword ? "text" : "password"}
            autoComplete="password"
            name="password"
            endContent={
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="focus:outline-none"
                aria-label="Toggle password visibility"
              >
                {showPassword ? (
                  <Eye className="pointer-events-none text-default-400" />
                ) : (
                  <EyeOff className="pointer-events-none text-default-400" />
                )}
              </button>
            }
          />

          <LimeSwitch
            title={t`Ostani prijavljen`}
            {...form.getInputProps("rememberMe", {
              type: "checkbox",
            })}
            size="sm"
            isCompact
          />

          {!import.meta.env.VITE_DISABLE_TURNSTILE && (
            <Turnstile
              key={turnstileKey}
              sitekey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
              onVerify={(token: string) => setTurnstileToken(token)}
            />
          )}
          <Button
            fullWidth
            type="submit"
            isLoading={isLoading}
            color="primary"
            // disabled={
            //   import.meta.env.MODE !== "e2e" &&
            //   import.meta.env.MODE !== "development" &&
            //   !turnstileToken
            // }
            data-identifier="submit-login-button"
          >
            <Trans>Nadaljuj</Trans>
          </Button>
        </form>
      </AuthFormWrapper>
    </>
  );
};

export default Login;
