import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import {
  Button,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SortDescriptor,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { useEffect, useMemo, useState } from "react";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { Combine, PackageMinus, Search } from "lucide-react";
import { useSearchParams } from "react-router";
import { LimeModal } from "@/Components/NextBase/LimeModal";
import { GetProductStocks } from "@/server-types";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { useDebouncedCallback } from "@mantine/hooks";

export const ProductStock = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const locationId = searchParams.has("locationId")
    ? Number(searchParams.get("locationId"))
    : undefined;

  const searchQuery = searchParams.get("search") ?? undefined;

  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);

  // API
  const { data: locations, isFetching: areLocationLoading } =
    api.location.useGetLocations({
      sortBy: "labelDesc",
    });

  const { data: productStocks, isFetching: areStocksLoading } =
    api.product.useGetProductStocks({
      locationId,
      searchQuery,
      sort: sortDescriptor
        ? (sortDescriptor as {
            column: "quantity" | "internalUseQuantity";
            direction: "ascending" | "descending";
          })
        : undefined,
    });

  const handleSearch = useDebouncedCallback(async (query: string) => {
    setSearchParams((params) => {
      params.set("search", query);
      return params;
    });
  }, 500);

  useEffect(() => {
    const locationId = searchParams.get("locationId");
    if (!locationId && locations?.locations.length) {
      setSearchParams({
        locationId: locations.locations[0].locationId.toString(),
      });
    }
  }, [locations, locationId]);

  return (
    <>
      <LimePageHeader
        title={t`Zaloga`}
        rightSection={
          <Popover showArrow placement="bottom-end">
            <PopoverTrigger>
              <Button color="primary">
                <Trans>Dodaj</Trans>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="flex flex-col gap-2 px-1 py-2">
                <Button
                  startContent={<Combine size={16} />}
                  as={Link}
                  className="rounded-lg"
                  variant="flat"
                  href={`/dashboard/products/internal-use/create?locationId=${locationId}`}
                >
                  <Trans>Dodaj lastno porabo</Trans>
                </Button>
                <Button
                  startContent={<PackageMinus size={16} />}
                  as={Link}
                  className="rounded-lg"
                  variant="flat"
                  href={`/dashboard/products/write-off/create?locationId=${locationId}`}
                >
                  <Trans>Dodaj odpis artikla</Trans>
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        }
      ></LimePageHeader>

      <div className="flex flex-col gap-4 px-4 py-4 md:px-8">
        <div className="flex flex-col items-center justify-between gap-2 px-4 md:flex-row">
          <div className="flex w-full gap-2">
            <LimeInput
              label={t`Išči`}
              className="h-full"
              startContent={<Search size={16} />}
              onChange={(e) => handleSearch(e.target.value)}
            />

            <LimeSelect
              label={t`Lokacija`}
              items={
                locations?.locations.map((l) => ({
                  label: l.label,
                  key: l.locationId.toString(),
                })) ?? []
              }
              value={locationId?.toString()}
              onChange={(value: string) => {
                const existingSearchParams = new URLSearchParams(
                  window.location.search,
                );
                existingSearchParams.set("locationId", value);

                setSearchParams(existingSearchParams, {
                  preventScrollReset: true,
                });
              }}
              isLoading={areLocationLoading}
            ></LimeSelect>
          </div>
        </div>

        <Table
          removeWrapper
          isStriped
          className="px-4"
          selectionMode="single"
          onSortChange={setSortDescriptor}
          sortDescriptor={sortDescriptor}
          color="primary"
          onRowAction={(key) => {
            const productId = Number(key);

            const existingSearchParams = new URLSearchParams(
              window.location.search,
            );
            existingSearchParams.set("pid", productId.toString());

            setSearchParams(existingSearchParams, {
              preventScrollReset: true,
            });
          }}
        >
          <TableHeader>
            <TableColumn key="name">
              <Trans>Produkt</Trans>
            </TableColumn>
            <TableColumn allowsSorting key="quantity">
              <Trans>Prodajna zaloga</Trans>
            </TableColumn>
            <TableColumn allowsSorting key="internalUseQuantity">
              <Trans>Lastna poraba</Trans>
            </TableColumn>
          </TableHeader>
          <TableBody isLoading={areStocksLoading}>
            {productStocks?.map((ps) => {
              return (
                <TableRow key={ps.productId}>
                  <TableCell>{ps.name}</TableCell>
                  <TableCell>{ps.quantity}</TableCell>
                  <TableCell>{ps.internalUseQuantity}</TableCell>
                </TableRow>
              );
            }) || []}
          </TableBody>
        </Table>
      </div>

      <ProductDetails />
    </>
  );
};

type StockMovementType =
  GetProductStocks["response"][number]["product"]["StockMovement"][number];

type InternalUseStockMovementType =
  GetProductStocks["response"][number]["product"]["InternalUseStockMovement"][number];

type Movement = StockMovementType | InternalUseStockMovementType;

// Extend the union type with the computed properties.
type ExtendedStockMovement = Movement & {
  toLocationQuantity: number;
  fromLocationQuantity: number;
  toInternalUseQuantity: number;
  fromInternalUseQuantity: number;
  totalToQuantity: number;
  totalFromQuantity: number;
};

const ProductDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const productId = searchParams.has("pid")
    ? Number(searchParams.get("pid"))
    : undefined;

  const {
    data: productsStocks,
    isFetching: isProductsStocksLoading,
    isError: getProductsStocksError,
    // refetch: refetchProductStocks,
  } = api.product.useGetProductStocks({
    productId,
  });

  const {
    data: product,
    isFetching: getProductError,
    isLoading: isProductLoading,
  } = api.product.useGetProductById(productId || -1);

  // const {
  //   mutateAsync: deleteProductInternalUseStockMovement,
  //   isPending: isDeletingProductInternalUseStockMovement,
  //   processedErrorMessage: deleteProductInternalUseStockMovementErrorMessage,
  // } = api.product.useDeleteProductInternalUseStockMovement();

  const productStocks = (productsStocks || []).find(
    (p) => p.productId === productId,
  );

  const stockMovements = useMemo<ExtendedStockMovement[]>(() => {
    if (!productStocks) return [];

    // Start with the current totals.
    let currentLocationQuantity = productStocks.quantity || 0;
    let currentInternalUseQuantity = productStocks.internalUseQuantity || 0;

    // Map each table's records into the common union type.
    const locationMovements: Movement[] =
      productStocks.product.StockMovement?.map((m: StockMovementType) => ({
        ...m,
        type: "location" as const,
      })) || [];

    const internalUseMovements: Movement[] =
      productStocks.product.InternalUseStockMovement?.map(
        (m: InternalUseStockMovementType) => ({
          ...m,
          type: "internalUse" as const,
        }),
      ) || [];

    // Combine and sort them (most recent first).
    const allMovements: Movement[] = [
      ...locationMovements,
      ...internalUseMovements,
    ].sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );

    // Use a map to store computed extended movements.
    const computedMap = new Map<string, ExtendedStockMovement>();

    for (const movement of allMovements) {
      const extendedMovement: ExtendedStockMovement = {
        ...movement,
        // Initialize computed properties.
        toLocationQuantity: 0,
        fromLocationQuantity: 0,
        toInternalUseQuantity: 0,
        fromInternalUseQuantity: 0,
        totalToQuantity: 0,
        totalFromQuantity: 0,
      };

      // Compute values based on the type.
      if (movement.type === "location") {
        extendedMovement.toLocationQuantity = currentLocationQuantity;
        extendedMovement.toInternalUseQuantity = currentInternalUseQuantity;
        extendedMovement.fromLocationQuantity =
          currentLocationQuantity - movement.quantityChange;
        extendedMovement.fromInternalUseQuantity = currentInternalUseQuantity;
        currentLocationQuantity = extendedMovement.fromLocationQuantity;
      } else if (movement.type === "internalUse") {
        extendedMovement.toLocationQuantity = currentLocationQuantity;
        extendedMovement.toInternalUseQuantity = currentInternalUseQuantity;
        extendedMovement.fromInternalUseQuantity =
          currentInternalUseQuantity - movement.quantityChange;
        extendedMovement.fromLocationQuantity = currentLocationQuantity;
        currentInternalUseQuantity = extendedMovement.fromInternalUseQuantity;
      }

      extendedMovement.totalToQuantity =
        extendedMovement.toLocationQuantity +
        extendedMovement.toInternalUseQuantity;
      extendedMovement.totalFromQuantity =
        extendedMovement.fromLocationQuantity +
        extendedMovement.fromInternalUseQuantity;

      // Create a composite key based on type and id.
      const key = `${movement.type}-${movement.id}`;
      computedMap.set(key, extendedMovement);
    }

    // Return the computed extended movements in the original sorted order.
    return allMovements.map((m) => computedMap.get(`${m.type}-${m.id}`)!);
  }, [productStocks]);

  return (
    <LimeModal
      isOpen={productId != null}
      onClose={() => {
        const existingSearchParams = new URLSearchParams(
          window.location.search,
        );
        existingSearchParams.delete("pid");

        setSearchParams(existingSearchParams, {
          preventScrollReset: true,
        });
      }}
    >
      <ModalContent>
        <ModalHeader>{product?.product.name}</ModalHeader>
        <ModalBody>
          <LimeAlert message={getProductsStocksError || getProductError} />

          {isProductsStocksLoading || isProductLoading ? (
            <Spinner />
          ) : undefined}

          <p className="font-semibold">
            <Trans>Aktivnost</Trans>
          </p>

          {stockMovements.map((sm) => {
            const { label, quantityChange, note } = humanizeStockMovement(sm);

            const locationQuantityPrefix = (() => {
              const locationQuantityChange =
                sm.toLocationQuantity - sm.fromLocationQuantity;

              if (locationQuantityChange === 0) return undefined;
              if (locationQuantityChange > 0) return "+";
              if (locationQuantityChange < 0) return "-";
              return undefined;
            })();

            const internalUseQuantityPrefix = (() => {
              const internalUseQuantityChange =
                sm.toInternalUseQuantity - sm.fromInternalUseQuantity;

              if (internalUseQuantityChange === 0) return undefined;
              if (internalUseQuantityChange > 0) return "+";
              if (internalUseQuantityChange < 0) return "-";
              return undefined;
            })();

            return (
              <div className="flex justify-between">
                <div className="flex flex-col justify-between text-sm">
                  <p className="text-xs text-gray-500">
                    {sm.timestampFormatted}
                  </p>
                  <p>{label}</p>
                  {sm.type === "location" ? (
                    <p className="text-xs text-gray-500">
                      <Trans>Sprememba količine (lokacija)</Trans>: Iz{" "}
                      {sm.fromLocationQuantity} v {sm.toLocationQuantity} (
                      {locationQuantityPrefix}
                      {quantityChange})
                    </p>
                  ) : undefined}
                  {sm.type === "internalUse" ? (
                    <p className="text-xs text-gray-500">
                      <Trans>Sprememba količine (lastna poraba)</Trans>: Iz{" "}
                      {sm.fromInternalUseQuantity} v {sm.toInternalUseQuantity}{" "}
                      ({internalUseQuantityPrefix}
                      {quantityChange})
                    </p>
                  ) : undefined}
                  {note ? (
                    <p className="text-xs text-gray-500">
                      <Trans>Opomba</Trans>: {note}
                    </p>
                  ) : undefined}
                </div>

                {/* {sm.InternalUseStockMovement.length > 0 ? (
                  <AlertButton
                    buttonProps={{
                      isIconOnly: true,
                      variant: "light",
                      size: "sm",
                      className: "text-gray-500",
                    }}
                    confirmProps={{
                      onClick: async () => {
                        await deleteProductInternalUseStockMovement(sm.id);
                        await refetchProductStocks();
                      },
                      isLoading: false,
                    }}
                    title={t`Razveljavi premik v lastno porabo`}
                    content={t`Premaknjena zaloga se bo vrnila v zalogo na lokaciji`}
                  >
                    <Undo2 size={18} />
                  </AlertButton>
                ) : undefined} */}
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </LimeModal>
  );
};

const humanizeStockMovement = (sm: {
  quantityChange: number;
  source: string;
  note?: string | null;
}) => {
  const quantityChange = Math.abs(sm.quantityChange);

  switch (sm.source) {
    case "deliveryOrder":
      return {
        label: t`Produkt je bil uvožen prek dobavnice`,
        quantityChange: `${quantityChange}`,
      };
    case "invoice":
      return {
        label: t`Produkt je bil prodan prek računa`,
        quantityChange: `${quantityChange}`,
      };
    case "writeOff":
      return {
        label: t`Produkt je bil odpisan`,
        quantityChange: `${quantityChange}`,
        note: sm.note,
      };
    case "invoiceCancellation":
      return {
        label: t`Produkt je bil vrnjen zaradi storniranja računa`,
        quantityChange: `${quantityChange}`,
      };
    case "stockTakingAdjustment":
      return {
        label: t`Količina produkta je bila popravljena prek inventure`,
        quantityChange: `${quantityChange}`,
      };
    case "locationStock":
      return {
        label: t`Produkt je bil premaknjen iz zaloge na lokaciji`,
        quantityChange: `${quantityChange}`,
        note: sm.note,
      };
    case "internalUse":
      return {
        label: t`Produkt je bil premaknjen v lastno porabo`,
        quantityChange: `${quantityChange}`,
        note: sm.note,
      };
    default:
      return {
        label: "MISSING LABEL",
        quantityChange: `${quantityChange}`,
      };
  }
};
