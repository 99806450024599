import { ActionIcon, CopyButton, Tabs, Text } from "@mantine/core";
import { Route, Routes, useParams } from "react-router";
import FeaturesTab from "./FeaturesTab/FeaturesTab";
import { FormsTab } from "./FormsTab/FormsTab";
import { GeneralTab } from "./GeneralTab/GeneralTab";
import { ImportTab } from "./ImportTab/ImportTab";
import { LocationsTab } from "./LocationsTab/LocationsTab";
import { NotificationsTab } from "./NotificationsTab/NotificationsTab";
import { ServicesTab } from "./ServicesTab/ServicesTab";
import { TimeOffsTab } from "./TimeOffsTab";
import { UsersTab } from "./UsersTab/UsersTab";
import { BillingTab } from "./BillingTab/BillingTab";
import { TextVariant } from "@/types/text-variants";
import { useGetTotalPrice } from "@/lib/api-client/paths/admin";
import { LuArrowLeft, LuCheck, LuCopy } from "react-icons/lu";
import { TaxRegTab } from "./TaxRegTab/TaxRegTab";
import { ClientUrlsTab } from "./ClientUrlsTab/ClientUrlsTab";
import { Link } from "@heroui/react";
import ResourcesTab from "./ResourcesTab/ResourcesTab";

export const ClientDetails = () => {
  const { id } = useParams();

  const selectedClientId = Number(id);
  const { data: totalPrice, refetch: refetchPrice } =
    useGetTotalPrice(selectedClientId);
  if (!id) return null;

  const tabs = (
    <Tabs defaultValue={"general"} keepMounted={false}>
      <Tabs.List>
        <Tabs.Tab value="general">Osnovni podatki podjetja</Tabs.Tab>
        <Tabs.Tab value="billing">Billing</Tabs.Tab>
        <Tabs.Tab value="features">Features</Tabs.Tab>
        <Tabs.Tab value="users">Zaposleni</Tabs.Tab>
        <Tabs.Tab value="notifications">Notifikacije</Tabs.Tab>
        <Tabs.Tab value="forms">Forme</Tabs.Tab>
        <Tabs.Tab value="locations">Lokacije</Tabs.Tab>
        <Tabs.Tab value="services">Storitve</Tabs.Tab>
        <Tabs.Tab value="resources">Sredstva</Tabs.Tab>
        <Tabs.Tab value="timeoffs">Pavze</Tabs.Tab>
        <Tabs.Tab value="import">Uvoz</Tabs.Tab>
        <Tabs.Tab value="taxreg">Davčna!</Tabs.Tab>
        <Tabs.Tab value="client_urls">Client URLji</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="general">
        <GeneralTab
          selectedClientId={selectedClientId}
          refetchPrice={refetchPrice}
        ></GeneralTab>
      </Tabs.Panel>
      <Tabs.Panel value="billing">
        <BillingTab
          clientId={selectedClientId}
          refetchPrice={refetchPrice}
        ></BillingTab>
      </Tabs.Panel>
      <Tabs.Panel value="features">
        <FeaturesTab
          selectedClientId={selectedClientId}
          refetchPrice={refetchPrice}
        ></FeaturesTab>
      </Tabs.Panel>
      <Tabs.Panel value="users">
        <UsersTab clientId={selectedClientId}></UsersTab>
      </Tabs.Panel>
      <Tabs.Panel value="notifications">
        <NotificationsTab clientId={selectedClientId}></NotificationsTab>
      </Tabs.Panel>
      <Tabs.Panel value="forms">
        <FormsTab clientId={selectedClientId}></FormsTab>
      </Tabs.Panel>
      <Tabs.Panel value="locations">
        <LocationsTab clientId={selectedClientId}></LocationsTab>
      </Tabs.Panel>
      <Tabs.Panel value="services">
        <ServicesTab clientId={selectedClientId}></ServicesTab>
      </Tabs.Panel>
      <Tabs.Panel value="timeoffs">
        <TimeOffsTab clientId={selectedClientId}></TimeOffsTab>
      </Tabs.Panel>
      <Tabs.Panel value="resources">
        <ResourcesTab clientId={selectedClientId}></ResourcesTab>
      </Tabs.Panel>
      <Tabs.Panel value="import">
        <ImportTab clientId={selectedClientId}></ImportTab>
      </Tabs.Panel>
      <Tabs.Panel value="taxreg">
        <TaxRegTab clientId={selectedClientId}></TaxRegTab>
      </Tabs.Panel>
      <Tabs.Panel value="client_urls">
        <ClientUrlsTab clientId={selectedClientId} />
      </Tabs.Panel>
    </Tabs>
  );

  return (
    <>
      <div className="flex items-center justify-between gap-2 p-4">
        <div className="flex items-center gap-2">
          <Link href="/dashboard/admin">
            <LuArrowLeft size="24px"></LuArrowLeft>
          </Link>
          <Text variant={TextVariant.Subheading}>
            Client ID: {selectedClientId}
          </Text>
          <CopyButton value={selectedClientId.toString()}>
            {({ copied, copy }) => (
              <ActionIcon onClick={copy}>
                {copied ? <LuCheck /> : <LuCopy />}
              </ActionIcon>
            )}
          </CopyButton>
        </div>
        <div className="flex items-center gap-2">
          <Text variant={TextVariant.Subheading}>
            Cena skupaj {totalPrice?.totalPrice || 0} € (
            {totalPrice?.totalPriceForMonth || 0} € na mesec)
          </Text>
        </div>
      </div>

      <Routes>
        <Route path="" element={tabs}></Route>
      </Routes>
    </>
  );
};
