import { Drawer, type DrawerProps, Flex, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { ReactElement } from "react";
import classes from "./LimeDrawer.module.css";
import { toast } from "sonner";

type LimeDrawerType = {
  opened: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  appointmentId?: number;
  size?: DrawerProps["size"] | "fullScreen";
  buttons?: {
    save?: ReactElement<any>;
    cancel?: ReactElement<any>;
    delete?: ReactElement<any>;
  };
  headerActions?: React.ReactNode;
  form?: {
    form: UseFormReturnType<any>; // eslint-disable-line
    onFormSubmit: (values: any) => void; // eslint-disable-line
  };
};

const LimeDrawer = ({
  opened,
  onClose,
  title,
  children,
  size = "lg",
  buttons,
  headerActions,
  form,
  appointmentId,
  ...props
}: LimeDrawerType & DrawerProps) => {
  const footer = buttons && (
    <div className="sticky bottom-0 z-50 flex w-full flex-col items-center justify-center border-t border-gray-200 bg-white p-5 md:flex-row md:justify-end">
      <div className="flex w-full flex-col items-center justify-between md:w-[500px] md:flex-row">
        <div className="mt-0 flex w-full flex-col justify-center gap-4 md:flex-row md:justify-end">
          {buttons.delete && buttons.delete}
          {buttons.cancel && buttons.cancel}
          {buttons.save && buttons.save}
        </div>
      </div>
    </div>
  );

  return (
    <Drawer.Root
      opened={opened}
      onClose={onClose}
      position={"right"}
      size={size === "fullScreen" ? "100%" : size}
      classNames={size === "fullScreen" ? undefined : classes}
      transitionProps={{
        transition: "slide-left",
        duration: 250,
        timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      }}
      lockScroll={false}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      {...props}
    >
      <Drawer.Overlay opacity={0.35} blur={0} />
      <Drawer.Content
        className={"drawer-outer-content"}
        style={{ overflowY: "scroll", paddingBottom: 0, paddingRight: 0 }}
      >
        <Drawer.Header>
          <Drawer.Title data-identifier="drawer-title">
            {appointmentId ? (
              <button
                className="absolute left-4 top-2 m-0 p-0 text-xs text-gray-400"
                onClick={() => {
                  navigator.clipboard.writeText(appointmentId.toString());
                  toast.success("Številka termina je bila kopirana");
                }}
              >
                # {appointmentId}
              </button>
            ) : undefined}
            <Text fw={500} size={"xl"}>
              {title}
            </Text>
          </Drawer.Title>
          {headerActions}
          {!headerActions && <Drawer.CloseButton />}
        </Drawer.Header>
        {form ? (
          <form
            onSubmit={form.form.onSubmit(form.onFormSubmit)}
            style={{
              height: "100%",
            }}
          >
            <Drawer.Body>{children}</Drawer.Body>

            {footer}
          </form>
        ) : (
          <>
            <Drawer.Body style={{ padding: 0 }}>{children}</Drawer.Body>

            {footer}
          </>
        )}
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default LimeDrawer;
