import { t } from "@lingui/core/macro";
import {
  Locale,
  LOCALE_COUNTRY_CODES,
  LOCALE_LABELS,
  SUPPORTED_LOCALES,
} from "../../../../shared/constants/locales";
import { LimeSelect, type Select } from "./LimeSelect";
import ReactCountryFlag from "react-country-flag";
import { Optional } from "@/types/utils";

type LocalePicker = Omit<Optional<Select, "items">, "onChange" | "value"> & {
  onChange: (value: Locale) => void;
  value?: Locale;
};

export const LimeLocalePicker = ({
  value,
  onChange,
  ...props
}: LocalePicker) => {
  return (
    <LimeSelect
      label={t`Jezik`}
      {...props}
      items={SUPPORTED_LOCALES.map((locale) => ({
        label: LOCALE_LABELS[locale],
        key: locale,
        startContent: (
          <ReactCountryFlag countryCode={LOCALE_COUNTRY_CODES[locale]} />
        ),
      }))}
      selectedKeys={value ? [value] : undefined}
      onSingleItemChange={(val) => onChange(val as Locale)}
      startContent={
        value ? (
          <ReactCountryFlag countryCode={LOCALE_COUNTRY_CODES[value]} />
        ) : undefined
      }
    />
  );
};
