import { i18n } from "@lingui/core";
import dayjs from "dayjs";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { apiGetPreferredLanguage } from "../apiCalls";
import { Locale, SUPPORTED_LOCALES } from "../../../shared/constants/locales";

const getClosestSupportedLanguage = (language: string) => {
  return SUPPORTED_LOCALES.includes(language) ? language : "en";
};

// const getPrimaryBrowserLanguage = () => navigator.language.split("-")[0];

const getPreferredLanguage = async (token: string) => {
  if (token) {
    const response = await apiGetPreferredLanguage(token);
    return response.data.preferredLanguage;
  }

  //const primaryLanguage = getPrimaryBrowserLanguage();
  const primaryLanguage = "sl";
  return {
    userPreferredLanguage: primaryLanguage,
    clientPreferredLanguage: primaryLanguage,
  };
};

type PreferredLanguage = {
  userPreferredLanguage: Locale;
  clientPreferredLanguage: Locale;
};

type PreferredLanguageStore = {
  preferredLanguage: PreferredLanguage;
  activateLocale: (language: string) => Promise<void>;
  setPreferredLanguage: (value: PreferredLanguage) => Promise<void>;
  loadPreferredLanguage: () => Promise<void>;
};

const usePreferredLanguageStore = create<PreferredLanguageStore>()(
  persist(
    (set, get) => ({
      preferredLanguage: {
        userPreferredLanguage: "sl",
        clientPreferredLanguage: "sl",
      },

      activateLocale: async (language: string) => {
        dayjs.locale(language);

        try {
          // Dynamically import the messages file for the given language.
          const { messages } = await import(
            `../locales/${language}/messages.po`
          );

          // Load and activate the locale in your i18n library.
          i18n.load(language, messages);
          i18n.activate(language);
        } catch (error) {
          console.error(
            `Failed to load locale messages for ${language}:`,
            error,
          );
        }
      },

      setPreferredLanguage: async (value) => {
        set({ preferredLanguage: value });
        await get().activateLocale(value.userPreferredLanguage);
      },

      loadPreferredLanguage: async () => {
        const token = localStorage.getItem("usr_token") || "";
        const language = await getPreferredLanguage(token);
        set({ preferredLanguage: language });

        await get().activateLocale(
          getClosestSupportedLanguage(language.userPreferredLanguage),
        );
      },
    }),
    {
      name: "lang",
    },
  ),
);

export default usePreferredLanguageStore;
