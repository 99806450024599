import { t } from "@lingui/core/macro";
import { atom, useAtom } from "jotai";
import { useNavigate, useSearchParams } from "react-router";

export const farthestTabAtom = atom("general");

const useManageResourceGroup = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const [farthestTab, _setFarthestTab] = useAtom(farthestTabAtom);

  const tabs = [
    {
      value: "general",
      label: t`Splošno`,
    },
    {
      value: "services",
      label: t`Storitve`,
    },
    {
      value: "schedule",
      label: t`Urnik`,
    },
    {
      value: "resources",
      label: t`Seznam sredstev`,
    },
  ] as const;

  const setFarthestTab = (tab: string | null) => {
    if (!tab) {
      _setFarthestTab("general");
      return;
    }

    const currentTabIndex = tabs.findIndex((t) => t.value === tab);
    const farthestTabIndex = tabs.findIndex((t) => t.value === farthestTab);

    if (currentTabIndex > farthestTabIndex) {
      _setFarthestTab(tab as (typeof tabs)[number]["value"]);
    }
  };

  const appendOnboarding = (url: string) => {
    const isOnboarding = searchParams.get("onboarding") != null;
    return `${url}${isOnboarding ? "?onboarding" : ""}`;
  };

  const navigateToResource = ({
    resourceGroupId,
    resourceId,
  }: {
    resourceGroupId: number;
    resourceId: number;
  }) => {
    const baseUrl = `/dashboard/resource-groups/${resourceGroupId}/resources/${resourceId}`;
    navigate(appendOnboarding(baseUrl));
  };

  const navigateToTab = ({
    currentTab,
    resourceGroupId,
    direction,
  }: {
    currentTab: (typeof tabs)[number]["value"];
    resourceGroupId?: number;
    direction: "previous" | "next";
  }) => {
    const currentTabIndex = tabs.findIndex((tab) => tab.value === currentTab);
    const nextTabIndex = currentTabIndex + (direction === "next" ? 1 : -1);
    const newTab = tabs[nextTabIndex];

    if (newTab) {
      setFarthestTab(newTab.value);

      const baseUrl = `/dashboard/resource-groups/${resourceGroupId}`;

      const finalUrl = appendOnboarding(`${baseUrl}/${newTab.value}`);
      navigate(finalUrl);
    }
  };

  const handleBackButton = ({
    resourceGroupId,
    tab,
    resourceId,
  }: {
    resourceGroupId?: number;
    tab?: (typeof tabs)[number]["value"];
    resourceId?: string;
  }) => {
    if (resourceId && resourceGroupId) {
      navigate(
        appendOnboarding(
          `/dashboard/resource-groups/${resourceGroupId}/${tab}`,
        ),
      );

      return;
    }

    navigate(appendOnboarding("/dashboard/resource-groups"));
  };

  return {
    navigateToTab,
    handleBackButton,
    navigateToResource,
    appendOnboarding,
    farthestTab,
    setFarthestTab,
    tabs,
  };
};

export default useManageResourceGroup;
