import type {
  GetCustomerList,
  GetUserAppointmentList,
  GetUsersServicesForAppointments,
  PostAppointment,
  PostPrescheduled,
  PutRescheduleAppointment,
  GetPosServicesByUserAppointmentId,
  GetCalculatePaymentPrice,
  GetAppointmentLog,
  PostReturnValidOptions,
} from "@/server-types";
import { useMutation, useQuery, keepPreviousData } from "@tanstack/react-query";
import { get, post, put } from "../request-helpers";
import { useCustomQuery } from "../custom-query";

export const useGetAppointmentByUserAppointmentId = (
  userAppointmentId?: number,
) => {
  return useQuery({
    queryKey: ["getAppointmentByUserAppointmentId", userAppointmentId],
    queryFn: () =>
      get<GetPosServicesByUserAppointmentId>(
        `/appointment/user-appointments/${userAppointmentId!}`,
      ),
    enabled: userAppointmentId != null,
  });
};

export const useGetCalculatePaymentPrice = (
  query: GetCalculatePaymentPrice["query"],
  enabled: boolean = true,
) => {
  return useCustomQuery({
    queryKey: [
      "getCalculatePaymentPrice",
      query,
      query.appointmentParts,
      query.paymentDetails,
    ],
    queryFn: () =>
      get<GetCalculatePaymentPrice>(
        `/appointment/calculatePaymentPrice`,
        query,
      ),
    retry: false,
    displayNotification: false,
    enabled:
      enabled && query?.appointmentParts && query?.appointmentParts.length > 0,
  });
};

/**
 * GET /appointment/getCustomerList
 */
export async function getCustomerList(query: GetCustomerList["query"]) {
  return get<GetCustomerList>("/appointment/getCustomerList", query);
}

export const useCustomerList = (query: GetCustomerList["query"]) => {
  return useQuery({
    queryKey: ["getCustomerList", query],
    queryFn: () => getCustomerList(query),
  });
};

export const useUserAppointmentList = (
  query: GetUserAppointmentList["query"],
  disable?: boolean,
) => {
  return useQuery({
    queryKey: ["getCustomerList", query],
    queryFn: () =>
      get<GetUserAppointmentList>("/appointment/user_appointments/list", query),
    enabled: !disable,
  });
};

export const useUsersServicesForAppointments = (
  query: GetUsersServicesForAppointments["query"],
) => {
  return useQuery({
    queryKey: ["getUsersServices", query],
    queryFn: () =>
      get<GetUsersServicesForAppointments>(
        "/appointment/user_appointments/users_services",
        query,
      ),
  });
};

/**
 * PUT /appointment
 */
export const useRescheduleAppointment = () => {
  return useMutation({
    mutationKey: ["rescheduleAppointment"],
    mutationFn: ({
      appointmentId,
      body,
    }: {
      appointmentId: number;
      body: PutRescheduleAppointment["body"];
    }) =>
      put<PutRescheduleAppointment>(
        `/appointment/${appointmentId}/reschedule`,
        body,
      ),
  });
};

/**
 * POST /appointment
 */
export const usePostAppointment = (body: PostAppointment["body"]) => {
  return post<PostAppointment>("/appointment", body);
};

/**
 * POST /appointment/prescheduled
 */
export const usePostPrescheduled = () => {
  return useMutation({
    mutationKey: ["postPrescheduled"],
    mutationFn: (body: PostPrescheduled["body"]) =>
      post<PostPrescheduled>(`/appointment/prescheduled`, body),
  });
};

/**
 * POST /appointment/validOptions
 */
export const usePostValidOptions = () => {
  return useMutation({
    mutationKey: ["validOptions"],
    mutationFn: (body: PostReturnValidOptions["body"]) =>
      post<PostReturnValidOptions>(`/appointment/validOptions`, body),
  });
};

/**
 * POST /appointment/validOptions
 */
export const usePostReturnValidOptions = (
  body: PostReturnValidOptions["body"],
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [
      "returnValidOptions",
      body.selectedServices.length,
      body.selectedServices.map((s) => s.serviceId),
      body.selectedServices.map((s) => s.users),
      body,
    ],
    queryFn: () =>
      post<PostReturnValidOptions>(`/appointment/validOptions`, body),
    enabled,
    placeholderData: keepPreviousData,
  });
};

/**
 * GET /appointment/appointment/{appointmentId}/log
 */
export const useAppointmentLog = (appointmentId: number) => {
  return useCustomQuery({
    queryKey: ["getAppointmentLog", appointmentId],
    queryFn: () => get<GetAppointmentLog>(`/appointment/${appointmentId}/log`),
    enabled: typeof appointmentId === "number" && appointmentId !== -1,
  });
};
