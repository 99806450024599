import { Flex, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { useSearchParams } from "react-router";
import styled from "styled-components";
import CalendarDate from "../../../../../Components/CalendarDate";
import { useCalendarStore } from "../../store/useCalendarStore";
import LimeAvatar from "./LimeAvatar";

const DayName = ({ day }) => {
  const { dateActions, calendarMode, resources } = useCalendarStore(
    (state) => state,
  );

  const [params, setParams] = useSearchParams();

  return (
    <Wrapper style={{ display: "flex" }}>
      <div
        style={{
          paddingTop: "5px",
          margin: "0 auto",
          position: "sticky",
          top: "0",
          background: "white",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Flex direction={"column"} style={{ width: "100%" }} align={"center"}>
          {day.isHoliday && (
            <Tooltip
              label={day.isHoliday}
              color="#7e96cd"
              withArrow
              withinPortal
            >
              <div className="holiday_span">{day.isHoliday}</div>
            </Tooltip>
          )}
          <CalendarDate
            date={day.date}
            onClick={() =>
              dateActions.setSelectedDateAndView(dayjs(day.date), "day")
            }
          />
        </Flex>
      </div>
      {calendarMode === "users" && (
        <div className={"users"}>
          {day.users
            .filter(
              (u) => !params.get("u") || u.userId === Number(params.get("u")),
            )
            .map((user, _index) => {
              return (
                <LimeAvatar
                  user={user}
                  key={user.userId}
                  onClick={() => {
                    params.get("u")
                      ? setParams({})
                      : setParams({ u: user.userId });
                  }}
                />
              );
            })}
        </div>
      )}

      {calendarMode === "resources" && (
        <div className="resources">
          {resources
            .filter((r) => r.isEnabledInFilter)
            .filter((r) => !params.get("r") || r.id === Number(params.get("r")))
            .map((resource, _i) => (
              <LimeAvatar
                user={{ name: resource.label, image: resource.imageUrl }}
                key={resource.id}
                onClick={() => {
                  params.get("r")
                    ? setParams({})
                    : setParams({ r: resource.id });
                }}
              />
            ))}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  flex: 1;

  justify-content: end;

  gap: 10px;

  border-right: 2px solid transparent;

  &:not(:last-child) {
    border-right: 2px solid transparent;
  }

  .holiday_span {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    background-color: #7e96cd;

    line-break: anywhere;

    color: #fafafa;
    width: 100%;
    border-radius: 12px;

    display: block;
    justify-content: left;
    align-items: center;
    padding: 0 15px;

    cursor: default;
  }

  .users,
  .resources {
    display: flex;
    width: 100%;

    .userName {
      display: block;
      height: 45px;

      flex: 1;

      text-overflow: ellipsis;
      overflow: hidden;
      line-break: anywhere;

      cursor: default;
      font-size: 0.8rem;

      font-weight: 500;
      color: #6c7386;

      background: white;

      min-width: 80px;

      @media (min-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }

  @media (min-width: 768px) {
    gap: 5px;
  }
`;

export default DayName;
