export type ShownColumns = (
  | "Ime podjetja"
  | "ID"
  | "Račun ustvarjen"
  | "Paket"
  | "Zadnja aktivnost"
  | "Datum obračuna"
  | "Admin email"
  | "Admin GSM"
  | "Sales guy"
  | "Client language"
)[];

export const columnsArray: ShownColumns = [
  "Ime podjetja",
  "ID",
  "Račun ustvarjen",
  "Paket",
  "Zadnja aktivnost",
  "Datum obračuna",
  "Admin email",
  "Admin GSM",
  "Sales guy",
  "Client language",
];
