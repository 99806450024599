import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { TextVariant } from "@/types/text-variants";
import { useLingui } from "@lingui/react";
import {
  Button,
  Checkbox,
  Flex,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useEffect } from "react";
import { FaCircleXmark } from "react-icons/fa6";
import { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import LimePhoneInputField from "../../../../Components/LimePhoneInputField";
import { numberAndCountryCodeToFullPhoneNumber } from "../../../../Utilities";
import { Col, CustomIcon } from "./CustomerCard";
import { LimeLocalePicker } from "@/Components/NextBase/LimeLocalePicker";

const EditingCustomerForm = ({
  isMobile,
  customerData,
  fields,
  setFields,
  setValues,
  setIsEditingCustomer,
  submitCustomerFormHandler,
}) => {
  useEffect(() => {
    setFields({
      name: customerData.name,
      lastName: customerData.lastName,
      email: customerData.email,
      gsm:
        customerData.gsm &&
        isValidPhoneNumber(
          numberAndCountryCodeToFullPhoneNumber(
            customerData.gsm,
            customerData.countryCode,
          ),
        )
          ? numberAndCountryCodeToFullPhoneNumber(
              customerData.gsm,
              customerData.countryCode,
            )
          : "",
      notes: customerData.notes,
      formBlocked: customerData.formBlocked,
      language: customerData.language || "en",
    });
  }, [customerData]);

  useLingui();

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      gap={"md"}
      justify={"space-between"}
      align={"flex-start"}
    >
      <Col>
        <Col>
          <TextInput
            value={fields["name"]}
            onChange={(e) => {
              setValues(e.target.value, "name");
            }}
            label={t`Ime`}
            variant="filled"
            w={"100%"}
          />
        </Col>

        <Col>
          <TextInput
            value={fields["lastName"]}
            onChange={(e) => {
              setValues(e.target.value, "lastName");
            }}
            label={t`Priimek`}
            variant="filled"
            w={"100%"}
          />
        </Col>
        <Col>
          <TextInput
            value={fields["email"]}
            onChange={(e) => {
              setValues(e.target.value, "email");
            }}
            label={t`Email`}
            variant="filled"
            w={"100%"}
          />
        </Col>
        <Col>
          <DateInput
            value={fields["birthday"]}
            onChange={(e) => {
              setValues(e, "birthday");
            }}
            label={t`Datum rojstva`}
            valueFormat="D.M.YYYY"
            variant="filled"
            w={"100%"}
          />
        </Col>

        <Col>
          <LimeLocalePicker
            value={fields["language"]}
            onChange={(value) => {
              setValues(value, "language");
            }}
          />
        </Col>

        <Flex
          direction={isMobile ? "column" : "row"}
          gap={"md"}
          justify={"space-between"}
          align={"flex-start"}
          w={"100%"}
        >
          <Col>
            <Text variant={TextVariant.BodyEmphasized}>
              <Trans>Telefonska številka</Trans>
            </Text>
            <LimePhoneInputField
              value={fields.gsm}
              onChange={(e) => {
                setValues(e, "gsm");
              }}
            ></LimePhoneInputField>
            <Checkbox
              mt={"sm"}
              size="xs"
              checked={fields["formBlocked"]}
              onChange={(e) => {
                setValues(e.target.checked, "formBlocked");
              }}
              label={t`Blokiraj stranko za spletno naročanje`}
            />
          </Col>
        </Flex>
      </Col>
      <Col
        style={{
          marginBottom: "0",
          paddingBottom: "0",
        }}
      >
        <Col
          style={{
            marginBottom: "0",
            paddingBottom: "0",
          }}
        >
          <Textarea
            value={fields["notes"]}
            onChange={(e) => {
              setValues(e.target.value, "notes");
            }}
            label={t`Komentar`}
            variant="filled"
            w={"100%"}
            autosize
            minRows={4}
          />
        </Col>
      </Col>
      {isMobile && (
        <>
          <MobileEditActions>
            <CustomIcon
              id="cancel-customer-save-button"
              style={{
                padding: "0px",
              }}
              onClick={() => {
                setIsEditingCustomer(false);
              }}
            >
              <FaCircleXmark />
            </CustomIcon>
            <Button
              id="save-customer-button"
              onClick={() => {
                submitCustomerFormHandler();
              }}
              disabled={
                !fields["name"] ||
                !fields["lastName"] ||
                (fields["gsm"] && !isValidPhoneNumber(fields["gsm"]))
              }
            >
              <Trans>Shrani</Trans>
            </Button>
          </MobileEditActions>
        </>
      )}
    </Flex>
  );
};

const MobileEditActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const CustomInput = styled.input`
  width: 100%;

  border-radius: 8px;
  outline: none;
  border: none;
  padding: 15px 20px;
  background: #f8f8f8;
`;

const CustomTextArea = styled.textarea`
  width: 90%;

  height: 7rem;

  border-radius: 8px;
  outline: none;
  border: none;
  padding: 15px 20px;
  background: #f8f8f8;
`;

export default EditingCustomerForm;
