import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { usePostServiceSortOrder } from "@/lib/api-client/paths/service";
import { GetCategories, GetServices } from "@/server-types";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Accordion,
  ActionIcon,
  Button,
  Drawer,
  Flex,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { CSSProperties, useEffect, useState } from "react";
import { LuArrowDown, LuArrowUp } from "react-icons/lu";
import { RiDraggable, RiListUnordered, RiServiceFill } from "react-icons/ri";
import { useSearchParams } from "react-router";

export const ServiceList = ({
  services,
  categories,
  onServiceClick,
  mode,
  search,
  setMode,
  drawerOpened,
  closeDrawer,
}: {
  services: GetServices["response"]["services"];
  categories: GetCategories["response"];
  onServiceClick: (serviceId: number) => void;
  mode: "view" | "edit";
  search: string;
  setMode: React.Dispatch<React.SetStateAction<"view" | "edit">>;
  drawerOpened: boolean;
  closeDrawer: () => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [searchParams] = useSearchParams();

  const { isOpen: isSidebarOpen } = useSidebarStore((state) => state);

  const [servicesByCategories, setServicesByCategories] = useState(
    categories
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((c) => ({
        ...c,
        services: services
          .filter((s) => s.service_tag.at(0)?.tag.tagId === c.tagId)
          .map((s) => ({ ...s, id: s.serviceId })),
      }))
      .concat({
        tagId: -1,
        TagNameLocalized: [{ language: "sl", name: "Ostalo" }],
        sortOrder: -1,
        tagName: "Ostalo",
        clientId: 0,
        appointmentType: "adhoc",
        services: services
          .filter((service) => service.service_tag.length === 0)
          .map((s) => ({ ...s, id: s.serviceId })),
        localizedName: "Ostalo",
        color: null,
      }),
  );

  const firstCategory = servicesByCategories.find((c) => c.services.length > 0);
  const [activeCategory, setActiveCategory] = useState(
    searchParams.get("opened") || firstCategory?.tagId.toString() || null,
  );

  const { mutateAsync: saveOrder } = usePostServiceSortOrder(
    servicesByCategories.flatMap((c) => c.services.map((s) => s.serviceId)),
  );

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  /**
   * When entering edit mode select the first category that has more than one service
   * if the current category has only one service
   */
  useEffect(() => {
    if (mode !== "edit") return;

    const selectedCategory = servicesByCategories.find(
      (c) => c.tagId.toString() === activeCategory,
    );

    if (!selectedCategory || selectedCategory.services.length < 2) {
      const newCategory = servicesByCategories.find(
        (c) => c.services.length > 1,
      );

      if (newCategory) {
        setActiveCategory(newCategory.tagId.toString());
      }
    }
  }, [mode]);

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  if (services.length === 0 && search === "") {
    return <NoServices onNewServiceClick={() => onServiceClick(-1)} />;
  }

  function handleDragEnd(event: DragEndEvent, categoryId: number) {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      setServicesByCategories((items) => {
        const newItems = structuredClone(items);

        const foundCategory = newItems.find((i) => i.tagId === categoryId);
        if (!foundCategory) return items;

        const oldIndex = foundCategory.services.findIndex(
          (s) => s.serviceId === active.id,
        );
        const newIndex = foundCategory.services.findIndex(
          (s) => s.serviceId === over.id,
        );

        foundCategory.services = arrayMove(
          foundCategory.services,
          oldIndex,
          newIndex,
        );

        return newItems;
      });
    }
  }

  return (
    <>
      <Flex direction={"column"} px={isMobile ? "md" : undefined} mt={"md"}>
        <Accordion
          styles={{
            content: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          value={activeCategory}
          onChange={(value) => setActiveCategory(value)}
        >
          {servicesByCategories.map((category, index) => {
            // if (category.services.length === 0) {
            //   return null;
            // }

            return (
              <Accordion.Item
                value={category.tagId.toString()}
                key={category.tagId}
              >
                <Accordion.Control>
                  <Text variant={TextVariant.BodyEmphasized}>
                    {category.TagNameLocalized.find(
                      (tl) => tl.language === defaultLanguage,
                    )?.name ||
                      category.TagNameLocalized.find(
                        (tl) => tl.language === "sl",
                      )?.name ||
                      category.TagNameLocalized.find((tl) => tl.name !== "")
                        ?.name ||
                      category.tagName ||
                      ""}{" "}
                    <Text
                      span
                      c={Color.SecondaryText}
                      variant={TextVariant.Caption}
                    >
                      ({category.services.length})
                    </Text>
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <DndContext
                    onDragEnd={(e) => handleDragEnd(e, category.tagId)}
                    collisionDetection={closestCenter}
                    sensors={sensors}
                  >
                    <SortableContext
                      items={category.services.map((s) => s.serviceId)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Flex direction={"column"} gap={"md"}>
                        {category.services.map((service) => (
                          <ServiceListItem
                            key={service.serviceId}
                            service={service}
                            onClick={() => onServiceClick(service.serviceId)}
                            id={service.id}
                            mode={mode}
                            onMoveItemDirection={(direction: "up" | "down") => {
                              const currentIndex = category.services.findIndex(
                                (s) => s.serviceId === service.serviceId,
                              );
                              const nextIndex =
                                direction === "up"
                                  ? currentIndex - 1
                                  : currentIndex + 1;

                              if (
                                nextIndex < 0 ||
                                nextIndex >= category.services.length
                              ) {
                                return;
                              }

                              setServicesByCategories((prev) => {
                                const newItems = structuredClone(prev);
                                const foundCategory = newItems.find(
                                  (i) => i.tagId === category.tagId,
                                );
                                if (!foundCategory) return prev;

                                foundCategory.services = arrayMove(
                                  foundCategory.services,
                                  currentIndex,
                                  nextIndex,
                                );

                                return newItems;
                              });
                            }}
                            isFirstItem={
                              category.services[0].serviceId ===
                              service.serviceId
                            }
                            isLastItem={
                              category.services[category.services.length - 1]
                                .serviceId === service.serviceId
                            }
                          />
                        ))}
                      </Flex>
                    </SortableContext>
                  </DndContext>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Flex>
      {mode === "edit" && (
        <Flex
          pos={"fixed"}
          bottom={0}
          left={isMobile ? 0 : isSidebarOpen ? 270 : 60}
          w={isMobile ? "100%" : `calc(100% - ${isSidebarOpen ? 270 : 60}px)`}
          justify={"center"}
          pt={15}
          pb={15}
          bg={"white"}
          gap={15}
        >
          <Button
            onClick={() => {
              setMode("view");
              setServicesByCategories(
                categories
                  .map((c) => ({
                    ...c,
                    services: services
                      .filter((s) => s.service_tag.at(0)?.tag.tagId === c.tagId)
                      .map((s) => ({ ...s, id: s.serviceId })),
                  }))
                  .concat({
                    tagId: -1,
                    TagNameLocalized: [{ language: "sl", name: "Ostalo" }],
                    sortOrder: 0,
                    tagName: "Ostalo",
                    clientId: 0,
                    appointmentType: "adhoc",
                    services: services
                      .filter((service) => service.service_tag.length === 0)
                      .map((s) => ({ ...s, id: s.serviceId })),
                    localizedName: "Ostalo",
                    color: null,
                  }),
              );
            }}
            variant="outline"
            c={Color.SecondaryText}
            styles={{
              root: {
                borderColor: Color.SecondaryText,
              },
            }}
          >
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={async () => {
              try {
                await saveOrder();
                setMode("view");

                notifications.show({
                  message: t`Vrstni red storitev je usprešno posodobljen`,
                  color: "green",
                });
              } catch (e) {
                notifications.show({
                  message: t`Napaka pri posodabljanju`,
                  color: "red",
                });
              }
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      )}

      {isMobile ? (
        <>
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            position="bottom"
            size={"sm"}
            styles={{
              content: {
                borderRadius: "16px 16px 0 0",
              },
            }}
            title={t`Nastavitve`}
          >
            <Flex>
              <Button
                leftSection={<RiListUnordered />}
                variant="transparent"
                onClick={() => {
                  setMode((prev) => (prev === "view" ? "edit" : "view"));
                  closeDrawer();
                }}
              >
                <Text c={Color.SecondaryText}>
                  <Trans>Uredi zaporedje</Trans>
                </Text>
              </Button>
            </Flex>
          </Drawer>
        </>
      ) : null}
    </>
  );
};

const ServiceListItem = ({
  service,
  onClick,
  id,
  mode,
  onMoveItemDirection,
  isFirstItem,
  isLastItem,
}: {
  service: GetServices["response"]["services"][number];
  onClick: () => void;
  id: number;
  mode: "view" | "edit";
  onMoveItemDirection: (direction: "up" | "down") => void;
  isFirstItem?: boolean;
  isLastItem?: boolean;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const isMobile = useMediaQuery("(max-width: 768px)");

  // const style = transform
  //   ? {
  //       transform: `translate3d(0, ${transform.y}px, 0)`,
  //       zIndex: 100,
  //     }
  //   : {};

  const style: CSSProperties = {
    opacity: isDragging ? 0.75 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : undefined,
  };

  return (
    <div
      style={style}
      {...(mode === "edit" ? attributes : undefined)}
      {...(mode === "edit" ? listeners : undefined)}
      ref={setNodeRef}
    >
      <Button
        variant="white"
        fullWidth
        style={{
          border: `1px solid ${service.color}`,
          borderLeftWidth: "4px",
          borderRadius: "6px",
          cursor: mode === "edit" ? "grab" : "pointer",
          display: "block",
          background: "transparent",
          // ...style,
          // transform: CSS.Transform.toString(transform),
          // transition,
        }}
        pl={0}
        justify="space-between"
        h={"fit-content"}
        onClick={mode === "edit" ? undefined : onClick}
        display={"flex"}
        styles={{
          label: {
            justifyContent: "space-between",
            width: "100%",
          },
          inner: {
            width: "100%",
          },
        }}
      >
        <Flex p={"xs"} direction={"column"} gap={0} align={"flex-start"}>
          <Text
            mb={5}
            variant={TextVariant.BodyEmphasized}
            c={Color.PrimaryText}
          >
            {service.name}
          </Text>

          <Text c={Color.SecondaryText} variant={TextVariant.Caption}>
            Trajanje:{" "}
            <Text span fw={"bold"} variant={TextVariant.Caption}>
              {service.baseDuration} min
            </Text>
          </Text>

          <Text c={Color.SecondaryText} variant={TextVariant.Caption}>
            Cena:{" "}
            <Text span fw={"bold"} variant={TextVariant.Caption}>
              {service.priceBaseCentsFormatted}
            </Text>
          </Text>
        </Flex>
        {mode === "edit" && (
          <Flex direction={"column"} style={{ flexGrow: 0 }} align={"center"}>
            {!isMobile && !isFirstItem ? (
              <ActionIcon onClick={() => onMoveItemDirection("up")}>
                <LuArrowUp />
              </ActionIcon>
            ) : null}
            <RiDraggable />
            {!isMobile && !isLastItem ? (
              <ActionIcon onClick={() => onMoveItemDirection("down")}>
                <LuArrowDown />
              </ActionIcon>
            ) : null}
          </Flex>
        )}
      </Button>
    </div>
  );
};

const NoServices = ({
  onNewServiceClick,
}: {
  onNewServiceClick: () => void;
}) => {
  return (
    <>
      <Flex
        h={"calc(100% - 60px)"}
        direction={"column"}
        justify={"center"}
        align={"center"}
      >
        <Flex
          direction={"column"}
          align={"center"}
          gap={4}
          mt={30}
          mb={30}
          w={"80%"}
        >
          <RiServiceFill />
          <Text variant={TextVariant.BodyEmphasized} c={Color.PrimaryText}>
            <Trans>Storitve še niso dodane.</Trans>
          </Text>
          <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
            <Trans>
              Za uporabo strani storitve je treba dodati vsaj eno storitev.
            </Trans>
          </Text>
        </Flex>
      </Flex>
      <Button fullWidth onClick={onNewServiceClick}>
        <Trans>Dodaj storitev</Trans>
      </Button>
    </>
  );
};
