import {
  Button,
  ButtonProps,
  NumberInput,
  NumberInputProps,
} from "@heroui/react";
import { Minus, Plus } from "lucide-react";

type LimeStepperNumberInput = Omit<NumberInputProps, "onChange"> & {
  hideSteppers?: boolean;
  steppersProps: ButtonProps;
};

export const LimeStepperNumberInput = ({
  step = 1,
  hideSteppers,
  steppersProps,
  label,
  ...props
}: LimeStepperNumberInput) => {
  return (
    <div className="h-full w-full">
      {label != null && <p className="text-xs text-gray-500">{label}</p>}
      <div className="flex h-full items-center gap-1">
        {hideSteppers ? null : (
          <Button
            isIconOnly
            onPress={() => {
              if (!props.value) {
                props.onValueChange?.(props.minValue ?? 0);
                return;
              }

              if (props.minValue && props.value - step < props.minValue) {
                props.onValueChange?.(props.minValue);
                return;
              }

              props.onValueChange?.(props.value - step);
            }}
            size={props.size}
            className="h-full min-h-9"
            variant={steppersProps?.variant ?? "flat"}
            {...steppersProps}
          >
            <Minus size={16} />
          </Button>
        )}
        <NumberInput
          hideStepper
          className="w-fit"
          classNames={{
            mainWrapper: "h-full flex-1",
            base: "h-full",
            inputWrapper: "h-full",
          }}
          aria-label={
            (typeof label === "string" ? label : undefined) ??
            "Stepper number input"
          }
          {...props}
        />
        {hideSteppers ? null : (
          <Button
            isIconOnly
            onPress={() => {
              if (!props.value) {
                props.onValueChange?.((props.minValue ?? 0) + step);
                return;
              }

              if (props.maxValue && props.value + step > props.maxValue) {
                props.onValueChange?.(props.maxValue);
                return;
              }

              props.onValueChange?.(props.value + step);
            }}
            size={props.size}
            className="h-full min-h-9"
            variant={steppersProps?.variant ?? "flat"}
            {...steppersProps}
          >
            <Plus size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};
