import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { apiGetAppointment } from "@/Components/AppointmentModal/apiAppointment";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { addToast } from "@heroui/react";
import {
  Button,
  Checkbox,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
} from "@heroui/react";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import { RiUserLine } from "react-icons/ri";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getStartTimeOfServiceInLinked } from "../../../utils";
import { LimeCollapse } from "@/Components/NextBase/LimeCollapse";

type AppointmentDetailsType = {
  appointmentParts: {
    serviceId: number;
    blockBeforeMins: number;
    blockAfterMins: number;
    duration: number;
    resources: number[];
    oldResources: number[];
    users: number[];
    oldUsers: number[];
    appointmentId: number;
  }[];
};

export const RescheduleModal = () => {
  const {
    rescheduleData,
    setRescheduleData,
    refreshCalendar,
    hasRescheduleNotification,
    calendarMode,
    users,
    resources,
    services,
  } = useCalendarStore((state) => state);

  const rescheduleForm = useForm({
    initialValues: {
      rescheduleAllLinked: true,
      allLinkedSameUserOrResource: false,
      sendNotifications: true,
      overrideUserService: false,
      overrideResourceService: false,
    },
  });

  const { mutateAsync: rescheduleAppointment } =
    api.appointments.useRescheduleAppointment();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const [appointment, setAppointment] = useState<AppointmentDetailsType | null>(
    null,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!rescheduleData?.appointment?.appointmentId) {
      setAppointment(null);
      return;
    }

    checkRescheduleValidity();
  }, [rescheduleData]);

  const checkRescheduleValidity = async () => {
    if (!rescheduleData.appointment) return;

    const { date, startTime, appointmentId, users } =
      rescheduleData.appointment;

    try {
      await rescheduleAppointment({
        appointmentId,
        body: {
          startTime: `${date}T${startTime}`,
          allLinkedSameUserOrResources:
            rescheduleForm.values.allLinkedSameUserOrResource,
          rescheduleAllLinked: rescheduleForm.values.rescheduleAllLinked,
          sendRescheduleNotifications: rescheduleForm.values.sendNotifications,
          users,
          resources: rescheduleData.appointment.resources,
          checkOnly: true,
        },
      });

      fetchAppointment();
    } catch (e) {
      addToast({
        title: t`Napaka`,
        color: "danger",
      });

      setRescheduleData(null);
      await refreshCalendar();
    }
  };

  const fetchAppointment = async () => {
    try {
      const response = await apiGetAppointment(
        rescheduleData.appointment.appointmentId,
      );

      const app = response.data.appointment;

      setAppointment(app);
    } catch (e) {
      console.log("error");
    }
  };

  const handleSubmit = async () => {
    const { date, startTime, appointmentId, users } =
      rescheduleData.appointment;

    setIsSubmitting(true);

    try {
      await rescheduleAppointment({
        appointmentId,
        body: {
          startTime: `${date}T${startTime}`,
          allLinkedSameUserOrResources:
            rescheduleForm.values.allLinkedSameUserOrResource,
          rescheduleAllLinked: rescheduleForm.values.rescheduleAllLinked,
          sendRescheduleNotifications: rescheduleForm.values.sendNotifications,
          users,
          resources: rescheduleData.appointment.resources,
        },
      });

      addToast({
        title: t`Termin uspešno prestavljen`,
        color: "success",
      });

      setRescheduleData(null);
      await refreshCalendar();
    } catch (e) {
      addToast({
        title: t`Napaka pri prestavljanju termina`,
        color: "danger",
      });
    } finally {
      setIsSubmitting(false);
      setRescheduleData(null);
      await refreshCalendar();
    }
  };

  if (!rescheduleData || !appointment) return null;
  const newAppointmentsWithUsersAndResources = appointment.appointmentParts
    .filter(
      (appPart) =>
        rescheduleForm.values.rescheduleAllLinked ||
        appPart.appointmentId === rescheduleData.appointment.appointmentId,
    )
    .map((part) => {
      return {
        ...part,
        oldResources: part.resources,
        oldUsers: part.users,
        users:
          rescheduleForm.values.allLinkedSameUserOrResource ||
          rescheduleData.appointment.appointmentId === part.appointmentId
            ? rescheduleData.appointment.users || part.users
            : part.users,
        resources: rescheduleForm.values.allLinkedSameUserOrResource
          ? rescheduleData.appointment.resources || part.resources
          : part.resources,
        startDuration: part.duration,
        endDuration: null,
        timeOffDuration: null,
        removed: false,
      };
    });

  const newAppointmentsWithTimes = newAppointmentsWithUsersAndResources.map(
    (part, i) => {
      const service = services.find(
        (s: { serviceId: number }) => s.serviceId === part.serviceId,
      );

      const isUserValidForService =
        calendarMode === "users" && service.doesRequireUser
          ? part.users.every((usr: number) => {
              const userDetails = users.find(
                (u: { userId: number }) => u.userId === usr,
              );

              return userDetails?.userServices.find(
                (us: { serviceId: number }) => us.serviceId === part.serviceId,
              );
            })
          : true;

      const isResourceValidForService =
        calendarMode === "resources"
          ? part.resources.every((res: number) => {
              const resourceDetails = resources.find(
                (r: { resourceId: number }) => r.resourceId === res,
              );

              return resourceDetails?.resourceGroup.resourceGroupServices.find(
                (rgs: { serviceId: number }) =>
                  rgs.serviceId === part.serviceId,
              );
            })
          : true;

      return {
        ...part,
        startTime: getStartTimeOfServiceInLinked({
          selectedServices: newAppointmentsWithUsersAndResources,
          index: i,
          startTime: `${rescheduleData.appointment.date}T${rescheduleData.appointment.startTime}`,
        }),
        isUserValidForService,
        isResourceValidForService,
        serviceName: service.name,
      };
    },
  );

  const rescheduleUserServiceWarning = newAppointmentsWithTimes.some(
    (app) => !app.isUserValidForService,
  );

  const isButtonDisabledRelations = (() => {
    if (permissions.restrict_appointment_services) {
      if (
        rescheduleForm.values.overrideUserService ||
        rescheduleForm.values.overrideResourceService
      ) {
        return true;
      }
      return false;
    }

    if (newAppointmentsWithTimes.some((a) => !a.isUserValidForService)) {
      if (
        rescheduleForm.values.overrideUserService ||
        rescheduleForm.values.overrideResourceService
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  })();

  return (
    <Modal
      isOpen={!!rescheduleData}
      onClose={() => {
        if (rescheduleData) {
          setRescheduleData(null);
          refreshCalendar();
        }
      }}
      title={t`Prestavitev termina`}
    >
      <ModalContent>
        <ModalHeader>{t`Prestavitev termina`}</ModalHeader>
        <ModalBody>
          <form onSubmit={rescheduleForm.onSubmit(handleSubmit)}>
            <p className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem]">
              Termin bo prestavljen na{" "}
              <span className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                {rescheduleData?.appointment.formattedData.date}
              </span>{" "}
              ob{" "}
              <span className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                {rescheduleData?.appointment.formattedData.startTime}
              </span>
              .
            </p>

            <div className="h-4" />

            <p className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem]">
              Prestavljate termin za naslednje osebe:
            </p>
            {rescheduleData?.appointment?.customers.map(
              (customer: { name: string; lastName: string; id: number }) => (
                <div key={customer.id} className="flex items-center gap-2 py-1">
                  <RiUserLine size={".9rem"} />
                  <p className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem]">
                    {customer.name} {customer.lastName}
                  </p>
                </div>
              ),
            )}

            {hasRescheduleNotification && (
              <>
                <Divider className="my-4" />
                <Checkbox
                  isSelected={rescheduleForm.values.sendNotifications}
                  onValueChange={(checked) =>
                    rescheduleForm.setFieldValue("sendNotifications", checked)
                  }
                >
                  {t`Pošlji obvestila?`}
                </Checkbox>
              </>
            )}

            {rescheduleData?.appointment?.linkedAppointmentId && (
              <>
                <Divider className="my-4" />
                <div className="flex flex-col gap-2">
                  <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                    Termin je povezan z ostalimi.
                  </p>
                  <Checkbox
                    isSelected={rescheduleForm.values.rescheduleAllLinked}
                    onValueChange={(checked) =>
                      rescheduleForm.setFieldValue(
                        "rescheduleAllLinked",
                        checked,
                      )
                    }
                  >
                    {t`Prestavi vse povezane termine?`}
                  </Checkbox>
                  <LimeCollapse
                    isOpen={rescheduleForm.values.rescheduleAllLinked}
                  >
                    <Checkbox
                      isSelected={
                        rescheduleForm.values.allLinkedSameUserOrResource
                      }
                      onValueChange={(checked) =>
                        rescheduleForm.setFieldValue(
                          "allLinkedSameUserOrResource",
                          checked,
                        )
                      }
                    >
                      {t`Prestavi vse povezane termine k istemu zaposlenemu?`}
                    </Checkbox>
                  </LimeCollapse>
                </div>
              </>
            )}

            <Divider className="my-4" />

            <div className="flex flex-col gap-5">
              <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                Storitve v terminu
              </p>

              <LimeCollapse isOpen={rescheduleUserServiceWarning}>
                <div className="flex flex-wrap justify-between rounded-lg border border-red-500 bg-red-50 p-2">
                  <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem] text-red-500">
                    <Trans>Zaposleni ne izvajajo storitev</Trans>
                  </p>
                  {!permissions.restrict_appointment_services ? (
                    <Checkbox
                      isSelected={rescheduleForm.values.overrideUserService}
                      onValueChange={(checked) =>
                        rescheduleForm.setFieldValue(
                          "overrideUserService",
                          checked,
                        )
                      }
                    >
                      {t`Prestavi`}
                    </Checkbox>
                  ) : null}
                </div>
              </LimeCollapse>

              {newAppointmentsWithTimes.map((a) =>
                calendarMode === "users" ? (
                  <div
                    key={a.appointmentId}
                    className="flex flex-col gap-0 bg-gray-50 p-2"
                  >
                    <LimeCollapse isOpen={!a.isUserValidForService}>
                      <div className="mb-2 flex gap-2 text-red-500">
                        <MdErrorOutline />
                        <p className="text-[0.75rem] leading-[1rem] tracking-[-0.015rem]">
                          <Trans>Vsi zaposleni ne izvajajo storitve</Trans>
                        </p>
                      </div>
                    </LimeCollapse>
                    <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                      <Trans>Storitev</Trans>: {a.serviceName}
                    </p>
                    <div className="flex justify-between">
                      <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                        <Trans>Izvajalci</Trans>
                      </p>
                    </div>
                    <div className="flex items-center gap-2 text-gray-700">
                      <div className="flex flex-col">
                        {a.oldUsers.map((user: number) => (
                          <p
                            className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem] line-through"
                            key={user}
                          >
                            {`${
                              users.find(
                                (u: { userId: number }) => u.userId === user,
                              )?.name || ""
                            } ${
                              users.find(
                                (u: { userId: number }) => u.userId === user,
                              )?.lastName || ""
                            }`}
                          </p>
                        ))}
                      </div>
                      {"->"}
                      <div className="flex flex-col">
                        {a.users.map((user: number) => (
                          <p
                            className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem]"
                            key={user}
                          >
                            {`${
                              users.find(
                                (u: { userId: number }) => u.userId === user,
                              )?.name || ""
                            } ${
                              users.find(
                                (u: { userId: number }) => u.userId === user,
                              )?.lastName || ""
                            }`}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <p className="text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.0175rem]">
                      <Trans>Sredstva</Trans>
                    </p>
                    <div className="flex items-center gap-2 text-gray-700">
                      <div className="flex flex-col">
                        {rescheduleData?.appointment?.oldResources.map(
                          (resource: number) => (
                            <p
                              className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem] line-through"
                              key={resource}
                            >
                              {
                                resources.find(
                                  (r: { id: number }) => r.id === resource,
                                ).label
                              }
                            </p>
                          ),
                        )}
                      </div>
                      {"->"}
                      <div className="flex flex-col">
                        {rescheduleData?.appointment?.resources.map(
                          (resource: number) => (
                            <p
                              className="text-[0.875rem] leading-[1.25rem] tracking-[-0.0175rem]"
                              key={resource}
                            >
                              {
                                resources.find(
                                  (r: { id: number }) => r.id === resource,
                                ).label
                              }
                            </p>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>

            <div className="h-5" />
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="sticky bottom-0 flex justify-end gap-4 border-t border-gray-200 bg-white p-2">
            <Button
              variant="light"
              onPress={() => {
                setRescheduleData(null);
                refreshCalendar();
              }}
              isDisabled={isSubmitting}
            >
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              onPress={handleSubmit}
              isDisabled={isButtonDisabledRelations || isSubmitting}
              isLoading={isSubmitting}
              color="primary"
              data-identifier="confirmRescheduleButton"
            >
              <Trans>Prestavi</Trans>
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
