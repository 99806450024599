import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useState } from "react";
import { useSearchParams } from "react-router";
import { apiResetPassword } from "../../../apiCalls";

import { LimeInput } from "@/Components/NextBase/LimeInput";
import { useForm } from "@mantine/form";
import { addToast, Button, cn, Image, Link } from "@heroui/react";

const ResetPassword = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordLongEnough, setPasswordLongEnough] = useState(false);
  const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
  const [
    passwordContainsUppercaseAndLowercase,
    setPasswordContainsUppercaseAndLowercase,
  ] = useState(false);

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validate: {
      password: (value) => {
        if (!value) return t`Vnesite geslo`;
        if (value.length < 8) return t`Geslo je prekratko`;
        if (!/\d/.test(value)) return t`Geslo mora vsebovati vsaj eno številko`;
        if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
          return t`Geslo mora vsebovati vsaj eno veliko in eno malo slovo`;
        }
      },
      confirmPassword: (value, values) => {
        if (!value) return t`Ponovno vnesite geslo`;
        if (value !== values.password) return t`Gesli se ne ujemata`;
      },
    },

    onValuesChange: ({ password, confirmPassword }) => {
      // check with regex if password is long enough
      if (password.length >= 8) {
        setPasswordLongEnough(true);
      } else {
        setPasswordLongEnough(false);
      }
      //check if password contains number
      if (/\d/.test(password)) {
        setPasswordContainsNumber(true);
      } else {
        setPasswordContainsNumber(false);
      }
      //check if password contains uppercase and lowercase
      if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
        setPasswordContainsUppercaseAndLowercase(true);
      } else {
        setPasswordContainsUppercaseAndLowercase(false);
      }
      //check if passwords match
      if (password === confirmPassword && password !== "") {
        setPasswordsMatch(true);
      } else {
        setPasswordsMatch(false);
      }
    },
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get("user");

  const handleSubmit = async (values: typeof form.values) => {
    if (
      passwordsMatch &&
      passwordLongEnough &&
      passwordContainsNumber &&
      passwordContainsUppercaseAndLowercase
    ) {
      try {
        setIsLoading(true);
        await apiResetPassword({ password: values.password, token });
        setIsSuccess(true);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        addToast({
          title: t`Napaka`,
          color: "danger",
        });
      }
    }
  };

  return (
    <div className="mx-auto flex h-screen w-full max-w-lg flex-col items-center justify-center px-6">
      <Image
        src="/logo_limebooking_full_new.png"
        alt="logo"
        className="mb-4 h-auto w-72"
      />

      {!isSuccess ? (
        <form onSubmit={form.onSubmit(handleSubmit)} className="w-full">
          <div className="mt-2 flex flex-col gap-4">
            <h1 className="mb-2 text-center text-xl font-semibold md:text-2xl">
              <Trans>Ponastavi svoje geslo</Trans>
            </h1>

            <LimeInput
              label={t`Novo geslo`}
              type="password"
              {...form.getInputProps("password")}
            />
            <LimeInput
              label={t`Ponovi novo geslo`}
              type="password"
              {...form.getInputProps("confirmPassword")}
            />
            <ul className="mb-4 flex flex-col">
              <PasswordRule isValid={passwordLongEnough}>
                <Trans>Geslo mora vsebovati vsaj 8 znakov.</Trans>
              </PasswordRule>
              <PasswordRule isValid={passwordContainsNumber}>
                <Trans>Geslo mora vsebovati vsaj eno številko.</Trans>
              </PasswordRule>
              <PasswordRule isValid={passwordContainsUppercaseAndLowercase}>
                <Trans>
                  Geslo mora vsebovati vsaj eno veliko in eno malo črko.
                </Trans>
              </PasswordRule>
              <PasswordRule isValid={passwordsMatch}>
                <Trans>Gesli se ujemata.</Trans>
              </PasswordRule>
            </ul>

            <Button type="submit" color="primary" isLoading={isLoading}>
              <Trans>Nadaljuj</Trans>
            </Button>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          </div>
        </form>
      ) : (
        <>
          <div className="mt-2 flex flex-col px-2">
            <h1 className="mb-4 font-semibold">
              <Trans>Uspešno ste ponastavili svoje geslo.</Trans>
            </h1>
            <Link href="/login" className="text-sm font-semibold">
              <Trans>Nadaljujte na prijavo.</Trans>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

const PasswordRule = ({
  isValid,
  children,
}: {
  isValid: boolean;
  children: React.ReactNode;
}) => {
  return (
    <li>
      <p className={cn("text-sm text-error", { "text-success": isValid })}>
        - {children}
      </p>
    </li>
  );
};

export default ResetPassword;
