import { Tabs } from "@mantine/core";
import { BillingSettings } from "./BillingSettings";
import { BillingTierPeriods } from "./BillingTierPeriods";
import InvoicesTab from "./InvoicesTab";
import BillingDetails from "../BillingDetails";

export const BillingTab = ({
  clientId,
  refetchPrice,
}: {
  clientId: number;
  refetchPrice: () => void;
}) => {
  return (
    <div>
      <Tabs
        orientation="vertical"
        defaultValue={"settings"}
        keepMounted={false}
        className="gap-2"
      >
        <Tabs.List>
          <Tabs.Tab value="settings">Nastavitve</Tabs.Tab>
          <Tabs.Tab value="details">Podrobnosti</Tabs.Tab>
          <Tabs.Tab value="periods">Periode</Tabs.Tab>
          <Tabs.Tab value="invoices">Izdani računi</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="settings">
          <BillingSettings
            clientId={clientId}
            refetchPrice={refetchPrice}
          ></BillingSettings>
        </Tabs.Panel>
        <Tabs.Panel value="details">
          <BillingDetails clientId={clientId}></BillingDetails>
        </Tabs.Panel>
        <Tabs.Panel value="periods">
          <BillingTierPeriods clientId={clientId}></BillingTierPeriods>
        </Tabs.Panel>
        <Tabs.Panel value="invoices">
          <InvoicesTab clientId={clientId}></InvoicesTab>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
