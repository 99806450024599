import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useState } from "react";
import Turnstile from "react-turnstile";
import { api } from "@/lib/api-client";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { useForm } from "@mantine/form";
import { AuthFormWrapper } from "../AuthFormWrapper";
import posthog from "posthog-js";
import { Button } from "@heroui/react";
import { LimeInput } from "@/Components/NextBase/LimeInput";

export const Register = () => {
  const {
    mutateAsync: register,
    isPending,
    error,
    processedErrorMessage,
  } = api.auth.useRegister();
  const { preferredLanguage } = usePreferredLanguageStore((state) => state);

  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      email: "",
      language: "sl",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim()) ? null : t`Neveljaven e-poštni naslov`,
    },
  });

  const onSubmitForm = async (values: typeof form.values) => {
    if (
      !turnstileToken &&
      import.meta.env.MODE !== "development" &&
      import.meta.env.MODE !== "e2e"
    ) {
      alert("Please verify you're not a robot.");
      return;
    }

    const registerData = {
      email: values.email.trim(),
      language: preferredLanguage.userPreferredLanguage,
      turnstileToken: turnstileToken || "",
    };

    const response = await register(registerData);

    posthog.capture("register_success", {
      email: values.email.trim(),
      language: preferredLanguage.userPreferredLanguage,
    });

    localStorage.setItem("usr_token", response.token);
    window.location.href = "/";
  };

  const pageTitle = t`Registracija`;

  return (
    <>
      <title>{pageTitle}</title>
      <AuthFormWrapper isLogin={false}>
        <form
          style={{ display: "contents" }}
          onSubmit={form.onSubmit(onSubmitForm)}
          noValidate
        >
          <LimeInput
            label={t`E-poštni naslov`}
            data-identifier={"register-email-input"}
            size="sm"
            className="w-full"
            {...form.getInputProps("email")}
            autoComplete="email"
            name="email"
            error={form.errors.email ?? processedErrorMessage}
          />

          {/* Cloudflare Turnstile Widget */}
          {import.meta.env.MODE !== "development" &&
            import.meta.env.MODE !== "e2e" && (
              <Turnstile
                sitekey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
                onVerify={(token) => setTurnstileToken(token)}
              />
            )}

          <Button
            className="mt-2 w-full"
            type="submit"
            isLoading={isPending}
            data-identifier="continue-button"
            color="primary"
          >
            <Trans>Nadaljuj</Trans>
          </Button>
        </form>
      </AuthFormWrapper>
    </>
  );
};
