import { t } from "@lingui/core/macro";
import QRCode from "qrcode";
import { PaymentType } from "./types";

export const getPaymentTypeLabel = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "bank":
      return t`Bancno nakazilo`;
    case "online":
      return t`Spletno placilo`;
    case "paypal":
      return t`PayPal`;
    case "cash":
      return t`Gotovina`;
    case "card":
      return t`Kartica`;
    case "coupon":
      return t`Unovčen darilni bon`;
    case "crypto":
      return t`Kriptovaluta`;
    case "missing":
      return t`Manjkajoča plačila`;
    case "other":
      return t`Ostalo`;
  }
};

export const buildHtmlQrCode = async (
  rawQrCodeData?: string,
  width?: string,
) => {
  // Generate QR Code as a Base64 Data URL (PNG image)
  const qrDataUrl = await QRCode.toDataURL(rawQrCodeData || "", {
    width: 10,
  });

  return `<img src="${qrDataUrl}" style="${width ? `width: ${width}` : "width: 50%"}; height: auto;" />`;
};

export function amountToUPNString(inputAmount: number) {
  // Convert "4,1" to "4.1" if needed
  const normalized = inputAmount.toString().replace(",", ".");

  // Parse as float
  const amount = parseFloat(normalized);

  // Convert to cents and round
  const amountCents = Math.round(amount * 100);

  // Convert to an 11-digit zero-padded string
  return amountCents.toString().padStart(11, "0");
}

export const buildUpnQrCodeString = ({
  invoiceNumber,
  payerName,
  payerStreet,
  payerCity,
  amount,
  purposeCode,
  paymentPurpose,
  dueDate, // "dd.MM.yyyy" or ""
  recipientIban,
  recipientReference,
  recipientName,
  recipientStreet,
  recipientCity,
}: {
  invoiceNumber: string;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  amount: number; // The payment amount in EUR
  purposeCode: string; // A four-letter code, e.g. "RENT"
  paymentPurpose: string; // A textual description of the payment
  dueDate: string; // "dd.MM.yyyy" format or an empty string if not used
  recipientIban: string; // The recipient's IBAN (no spaces)
  recipientReference: string; // The recipient's reference (e.g. "SI12...")
  recipientName: string; // The recipient's name
  recipientStreet: string; // The recipient's street and number
  recipientCity: string; // The recipient's city
}) => {
  const leadingStyle = "UPNQR"; // 1
  const payerIban = ""; // 2
  const polog = ""; // 3
  const dvig = ""; // 4
  const payerReference = ""; // 5

  // Convert amount to an 11-digit string without decimal point.
  // For example, 81.05 => "0000008105".
  const amountStr = amountToUPNString(amount);

  const paymentDate = ""; // 10 (often empty)
  const urgent = ""; // 11
  const paymentPurposeCode = purposeCode.trim(); // 12 (e.g. "RENT" - 4 BIG letters)
  const paymentPurposeField = paymentPurpose.trim(); // 13
  const paymentDueDate = dueDate; // 14 (e.g. "01.04.2017" or "")

  // recipientIban and recipientReference should be given without spaces
  // and already in correct format.

  const controlSum = "000"; // 20 (initially "000")
  const reserve = ""; // 21

  const fields = [
    leadingStyle, // 1
    payerIban, // 2
    polog, // 3
    dvig, // 4
    payerReference, // 5
    payerName ?? "", // 6
    payerStreet ?? "", // 7
    payerCity ?? "", // 8
    amountStr, // 9
    paymentDate, // 10
    urgent, // 11
    paymentPurposeCode, // 12
    paymentPurposeField, // 13
    paymentDueDate, // 14
    recipientIban, // 15
    recipientReference, // 16
    recipientName, // 17
    recipientStreet, // 18
    recipientCity, // 19
    controlSum, // 20 - placeholder
    reserve, // 21
  ];

  // Compute the control sum:
  // Join all fields with newline
  const dataString = fields.join("\n");

  // Calculate sum of ASCII values
  let sum = 0;
  for (let i = 0; i < dataString.length; i++) {
    sum += dataString.charCodeAt(i);
  }

  // Modulo 1000
  const computed = (sum % 1000).toString().padStart(3, "0");

  // Replace control sum field with the computed value
  fields[19] = computed;

  return fields.join("\n");
};

// https://en.wikipedia.org/wiki/EPC_QR_code
export const buildEpcQrCodeString = ({
  BIC,
  name,
  IBAN,
  amount,
  currencyId,
  reason,
  invoiceNumber,
  reference,
}: {
  BIC: string;
  name: string;
  IBAN: string;
  amount: number;
  currencyId: string;
  reason: string;
  invoiceNumber: string;
  reference?: string;
}) => {
  const SERVICE_TAG = "BCD";
  const VERSION = "002";
  const CHAR_SET = 1;
  const ID_CODE = "SCT";

  const AMOUNT = `${currencyId}${amount.toFixed(2)}`;
  const PURPOSE_CODE = "OTHR";
  const STRUCTURED_REFERENCE = "";
  const UNSTRUCTURED_REFERENCE = reference || invoiceNumber || reason;
  const INFORMATION = "TEST EPC QR CODE";

  return [
    SERVICE_TAG,
    VERSION,
    CHAR_SET,
    ID_CODE,
    BIC,
    name,
    electronicFormat(IBAN),
    AMOUNT,
    PURPOSE_CODE,
    STRUCTURED_REFERENCE,
    UNSTRUCTURED_REFERENCE,
    INFORMATION,
  ].join("\n");
};

export function electronicFormat(iban: string) {
  return iban.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
}

export const buildEscPosQrCode = (
  encodedData?: Uint8Array<ArrayBufferLike>,
) => {
  if (!encodedData) return [];

  const qrDataLength = encodedData.length + 3;
  return Uint8Array.from([
    // Center alignment
    0x1b,
    0x61,
    0x01,

    // Set QR code model
    0x1d,
    0x28,
    0x6b,
    0x04,
    0x00,
    0x31,
    0x41,
    0x32,
    0x00, // Model 2 (standard)

    // Set QR code size (increase to make it bigger)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x43,
    0x06, // Size 6

    // Set QR code error correction level (49 = M)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x45,
    0x31,

    // Store QR code data
    0x1d,
    0x28,
    0x6b,
    qrDataLength & 0xff, // pL
    (qrDataLength >> 8) & 0xff, // pH
    0x31,
    0x50,
    0x30, // cn, fn, m
    ...encodedData,

    // Print QR code
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x51,
    0x30,

    // Reset alignment to left
    0x1b,
    0x61,
    0x00, // Left alignment
  ]);
};
