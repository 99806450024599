import {
  AddCustomer,
  PostCustomer2FA,
  GetCustomers,
  PutCustomer,
} from "@/server-types";
import { post, get, put } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

/**
 * POST /customers
 * /
 */

export const useAddCustomer = () => {
  return useCustomMutation({
    mutationFn: (body: AddCustomer["query"]) =>
      post<AddCustomer>("/customer", body),
  });
};

export const useConfirmCustomer2FA = () => {
  return useCustomMutation({
    mutationFn: ({
      customerId,
      body,
    }: {
      customerId: number;
      body: PostCustomer2FA["body"];
    }) => post<PostCustomer2FA>(`/customer/${customerId}/2fa`, body),
  });
};

export const useGetCustomers = (query: GetCustomers["query"]) => {
  return useCustomQuery({
    queryKey: ["customers", query],
    queryFn: () => get<GetCustomers>("/customer", query),
  });
};

export const useGetCustomersMutation = () => {
  return useCustomMutation({
    mutationFn: (body: GetCustomers["query"]) =>
      get<GetCustomers>("/customer", body),
  });
};

export const usePutCustomer = () => {
  return useCustomMutation({
    mutationFn: ({
      customerId,
      body,
    }: {
      customerId: number;
      body: PutCustomer["body"];
    }) => put<PutCustomer>(`/customer/${customerId}`, body),
  });
};
