import { SelectProps, Select, SelectItem, SelectSection } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import { t } from "@lingui/core/macro";

export type Select = Omit<MantineFormValues & SelectProps, "children"> & {
  items: {
    label: string;
    key: string;
    sectionName?: string;
    startContent?: React.ReactNode;
    endContent?: React.ReactNode;
  }[];
  onSingleItemChange?: (value: string) => void;
  emptyContentMessage?: string;
};

export const LimeSelect = ({
  emptyContentMessage = t`Ni rezultatov`,
  error,
  value,
  ...props
}: Select) => {
  const itemsBySection = props.items.reduce(
    (acc, item) => {
      const section = item.sectionName || "default";
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    },
    {} as Record<string, typeof props.items>,
  );

  const renderItems = () => {
    return Object.keys(itemsBySection)
      .map((sectionName) => {
        const items = itemsBySection[sectionName];

        if (sectionName === "default") {
          return items.map((item) => (
            <SelectItem
              key={item.key}
              startContent={item.startContent}
              endContent={item.endContent}
              aria-label={item.label}
            >
              {item.label}
            </SelectItem>
          ));
        }

        return (
          <SelectSection key={sectionName} title={sectionName} showDivider>
            {items.map((item) => (
              <SelectItem
                key={item.key}
                startContent={item.startContent}
                endContent={item.endContent}
                aria-label={item.label}
              >
                {item.label}
              </SelectItem>
            ))}
          </SelectSection>
        );
      })
      .flat(); // Flatten the resulting array
  };

  return (
    <Select
      errorMessage={error}
      isInvalid={!!error}
      selectedKeys={value ? [value.toString()] : []}
      disallowEmptySelection
      selectionMode="single"
      aria-label={typeof props.label === "string" ? props.label : "select"}
      {...props}
      onChange={(e) => {
        props.onChange?.(e.target.value);
        props.onSingleItemChange?.(e.target.value);
      }}
      listboxProps={{
        ...(props.listboxProps || {}),
        emptyContent: emptyContentMessage,
      }}
    >
      {renderItems()}
    </Select>
  );
};
