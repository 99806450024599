import { TextVariant } from "@/types/text-variants";
import { Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import DOMPurify from "dompurify";

type NotificationLog = {
  trigger: string;
  message_content: string;
  notification_type: string;
  timeSent: string;
  status: "success" | "error" | "PENDING_ACCEPTED";
};

const colors = {
  success: "#22c55e",
  PENDING_ACCEPTED: "orange",
  error: "red",
};

export const NotificationTriggerSection = ({
  triggers,
  notificationLog,
  label,
}: {
  triggers: string[];
  notificationLog: NotificationLog[];
  label: string;
}) => {
  if (
    !notificationLog ||
    !notificationLog.find((nl) => triggers.includes(nl.trigger))
  ) {
    return null;
  }

  return (
    <>
      <Text variant={TextVariant.BodyEmphasized}>{label}</Text>
      {notificationLog
        .filter((nl) => triggers.includes(nl.trigger))
        .map((notification) => {
          return <NotificationPreviewRow notification={notification} />;
        })}
    </>
  );
};

const NotificationPreviewRow = ({
  notification,
}: {
  notification: NotificationLog;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  const hoverContent = (() => {
    if (notification.message_content.indexOf("<html") >= 0) {
      const content = DOMPurify.sanitize(notification.message_content);
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      );
    } else {
      return <Text c={"black"}>{notification.message_content}</Text>;
    }
  })();

  return (
    <>
      <button
        type="button"
        className="flex w-full cursor-pointer items-center justify-between p-1 hover:bg-gray-100"
        onClick={open}
      >
        <Text variant={TextVariant.Body}>{notification.notification_type}</Text>

        <div className="flex items-center gap-2">
          <Text variant={TextVariant.Body}>{notification.timeSent}</Text>
          <div
            className="h-3 w-3 rounded-full"
            style={{ backgroundColor: colors[notification.status] }}
          ></div>
        </div>
      </button>

      <Modal
        opened={opened}
        onClose={close}
        title={`${notification.notification_type} - ${notification.timeSent}`}
      >
        <div className="rounded-lg border-1 border-gray-400 p-2">
          {hoverContent}
        </div>
      </Modal>
    </>
  );
};
