import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import {
  Button,
  AutocompleteItem,
  Chip,
  Divider,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  RangeValue,
  SharedSelection,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
  DateValue,
} from "@heroui/react";
import { useCallback, useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { useDebouncedValue } from "@mantine/hooks";
import { cn } from "@/utils";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { getLocalTimeZone, parseDate, today } from "@internationalized/date";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import {
  ClosureColumnEnum,
  IssuedGiftCardColumnEnum,
  TotalsColumnEnum,
  TaxTotalColumnEnum,
  GetOrganizationBusinessPremiseAccountingExport,
  GetOrganizationInvoices,
  GetPosToken,
  By,
  FileFormat,
} from "@/server-types";
import { Trans } from "@lingui/react/macro";
import { LimeModal } from "@/Components/NextBase/LimeModal";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";
import { FileOutput, Filter, FilterX, X } from "lucide-react";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeDateRangePicker } from "@/Components/NextBase/LimeDateRangePicker";

type RowInvoice = {
  id: string;
  key: string;
  number: string;
  amount: string;
  time: string;
  status: GetOrganizationInvoices["response"]["invoices"][number]["status"];
  canceled: boolean;
};

const perPage = 15;
export const TaxRegisterInvoices = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get("start");
  const endDateParam = searchParams.get("end");
  const pageParam = searchParams.get("page");
  const selectedUserId = searchParams.get("uid");
  const selectedBusinessPremiseId = searchParams.get("bid");
  const selectedServiceId = searchParams.get("sid");
  const selectedProductId = searchParams.get("pid");
  const selectedCustomerId = searchParams.get("cid");
  const { invoiceId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const {
    isOpen: isExportModalOpen,
    onOpen: onOpenExportModal,
    onOpenChange: onOpenChangeExportModal,
  } = useDisclosure();

  const startDate = startDateParam ? parseDate(startDateParam) : undefined;
  const endDate = endDateParam ? parseDate(endDateParam) : undefined;

  const page = pageParam ? parseInt(pageParam) : 1;

  const { data: posAuthData, isFetching: isFetchingPosAuthData } =
    api.cookie.useGetPosToken();

  const { data: permissions } = api.user.useGetUserPermissions({
    query: {
      tokenType: "POS",
    },
    disabled: posAuthData == null || isFetchingPosAuthData,
  });

  const { data, refetch, isPending, processedErrorMessage } =
    api.taxRegister.useGetOrganizationInvoices({
      query: debouncedSearchQuery,
      page,
      perPage,
      startDate: startDate?.toString(),
      endDate: endDate?.toString(),
      disabled: !posAuthData || isFetchingPosAuthData,
      userId: selectedUserId ? parseInt(selectedUserId) : undefined,
      businessPremiseId: selectedBusinessPremiseId ?? undefined,
      serviceId: selectedServiceId ? parseInt(selectedServiceId) : undefined,
      productId: selectedProductId ? parseInt(selectedProductId) : undefined,
      customerId: selectedCustomerId ? parseInt(selectedCustomerId) : undefined,
    });
  const invoices = data?.invoices;

  const {
    data: businessPremises,
    isFetching: isBusinessPremisesFetching,
    processedErrorMessage: businessPremisesErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremise({
    disabled: !posAuthData?.userId,
  });

  const {
    mutateAsync: getOrganizationBusinessPremiseAccountingExport,
    isPending: isExporting,
    processedErrorMessage: exportErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremiseAccountingExport();

  const {
    data: accountingExportValues,
    isFetching: isAccountingExportValuesFetching,
  } = api.values.useGetAccountingExportValues();

  /**
   * This is required for pagination to work correctly,
   * since it breaks if pageCount goes undefined between
   */
  const [pageCount, setPageCount] = useState(page);
  useEffect(() => {
    if (data && data.pageCount != pageCount) {
      setPageCount(data.pageCount > 0 ? data.pageCount : 1);

      if (page > data.pageCount) {
        handlePageChange(1);
      }
    }
  }, [data]);

  const handleDateChange = (range: RangeValue<DateValue> | null) => {
    if (!range) return;

    const newStartDate = range.start.toString();
    const newEndDate = range.end.toString();

    const existingSearchParams = new URLSearchParams(window.location.search);
    existingSearchParams.set("start", newStartDate);
    existingSearchParams.set("end", newEndDate);
    existingSearchParams.set("page", page.toString());

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page) return;

    const existingSearchParams = new URLSearchParams(window.location.search);
    existingSearchParams.set("page", newPage.toString());

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const columns = [
    {
      key: "number",
      label: t`Številka računa`,
    },
    {
      key: "amount",
      label: t`Znesek`,
    },
    {
      key: "time",
      label: t`Ura`,
    },
    {
      key: "status",
      label: t`Stanje`,
    },
  ];

  const rows: RowInvoice[] = isPending
    ? Array(perPage)
        .fill(null)
        .map((_, index) => {
          return {
            id: "",
            key: `loading-${index}`,
            number: "XXXX-XXXXX",
            amount: "XX,XX X",
            time: "XX. XX. XXXX XX:XX",
            status: {
              label: "loading",
              value: "unknown",
            },
            canceled: false,
          };
        })
    : invoices?.map((invoice) => {
        return {
          id: invoice.id,
          key: invoice.number,
          number: invoice.number,
          amount: invoice.totalFormatted,
          time: invoice.dateFormatted,
          status: invoice.status,
          canceled: invoice.status.value === "canceled",
        };
      }) || [];

  const renderCell = useCallback(
    (item: RowInvoice, columnKey: React.Key) => {
      const cellValue = item[columnKey as keyof typeof item];

      switch (columnKey) {
        case "status": {
          const castedValue =
            cellValue as GetOrganizationInvoices["response"]["invoices"][number]["status"];

          const color = (() => {
            switch (castedValue.value) {
              case "paid":
                return "success";
              case "paid_partially":
                return "warning";
              case "canceled":
                return "danger";
              case "not_paid":
                return "danger";
              default:
                return "default";
            }
          })();

          return (
            <Chip size="sm" color={color} variant="flat">
              {castedValue.label}
            </Chip>
          );
        }
        default:
          return cellValue as string;
      }
    },
    [invoices],
  );

  const exportForm = useForm<{
    startDate: string;
    endDate: string;
    businessPremiseId: string | undefined;
    closureColumns: ClosureColumnEnum[];
    issuedGiftCardColumns: IssuedGiftCardColumnEnum[];
    totalColumns: TotalsColumnEnum[];
    taxTotalColumns: TaxTotalColumnEnum[];
    format:
      | GetOrganizationBusinessPremiseAccountingExport["query"]["format"]
      | undefined;
    by:
      | GetOrganizationBusinessPremiseAccountingExport["query"]["by"]
      | undefined;
  }>({
    initialValues: {
      startDate: dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      businessPremiseId: undefined,
      closureColumns: [],
      issuedGiftCardColumns: [],
      totalColumns: [],
      taxTotalColumns: [],
      format: accountingExportValues?.fileFormat.XLSX || ("xlsx" as FileFormat),
      by: accountingExportValues?.by.DAY || ("day" as By),
    },

    validate: {
      businessPremiseId: (value) => {
        if (!value) {
          return t`Poslovni prostor je obvezen`;
        }
      },

      closureColumns: (value) => {
        if (!value) return null;

        if (columns.length === 0) return t`Izberite vsaj en stolpec`;

        return null;
      },
    },
  });

  useEffect(() => {
    if (!accountingExportValues) {
      return;
    }

    exportForm.setFieldValue(
      "closureColumns",
      accountingExportValues.closureColumns.map((column) => column.value),
    );

    exportForm.setFieldValue(
      "issuedGiftCardColumns",
      accountingExportValues.issuedGiftCardColumns.map(
        (column) => column.value,
      ),
    );

    exportForm.setFieldValue(
      "totalColumns",
      accountingExportValues.totalColumns.map((column) => column.value),
    );

    exportForm.setFieldValue(
      "taxTotalColumns",
      accountingExportValues.taxTotalColumns.map((column) => column.value),
    );
  }, [accountingExportValues]);

  const handleSubmitExport = async (values: typeof exportForm.values) => {
    console.log("submit", values);

    if (!values.format || !values.by) {
      return;
    }

    const exportData = await getOrganizationBusinessPremiseAccountingExport({
      businessPremiseId: values.businessPremiseId!,
      query: {
        ...values,
        format: values.format,
        by: values.by,
      },
    });

    exportData.forEach((ed) => {
      const content = (() => {
        // If format is xlsx or pdf, convert base64 string back to Uint8Array
        if (
          ed.format === accountingExportValues?.fileFormat.XLSX ||
          ed.format === accountingExportValues?.fileFormat.PDF
        ) {
          const binaryString = atob(ed.data);

          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          return bytes;
        }

        return ed.data;
      })();

      downloadFile({
        content,
        type: ed.fileType,
        format: ed.format,
        startDate: values.startDate,
        endDate: values.endDate,
        prefix: ed.prefix,
      });
    });
  };

  const downloadFile = ({
    content,
    type,
    format,
    startDate,
    endDate,
    prefix,
  }: {
    content: string | Uint8Array<ArrayBuffer>;
    type: GetOrganizationBusinessPremiseAccountingExport["response"][number]["fileType"];
    format: GetOrganizationBusinessPremiseAccountingExport["query"]["format"];
    startDate: string;
    endDate: string;
    prefix: string;
  }) => {
    console.log("downloading", prefix);

    const finalFormat = (() => {
      if (format === "eSLOG2.0" || format === "csv") {
        return "zip";
      }

      return format;
    })();
    const suffix = format === "eSLOG2.0" ? "_eSLOG2_0" : "";

    // const fileType = `${type};charset=utf-8;`;
    // const blob = new Blob([content], { type: fileType });
    // const url = URL.createObjectURL(blob);

    const file = (() => {
      if (content instanceof Uint8Array) {
        return new Blob([content], { type });
      }

      // Handle ZIP files (application/zip)
      if (type === "application/zip") {
        // Convert base64 to binary array
        const binaryString = atob(content);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type });
      }

      // Handle XML or other text formats
      return new Blob([content], { type });
    })();

    const url = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${prefix}_${startDate}_${endDate}${suffix}.${finalFormat}`;

    document.body.appendChild(link);

    link.click();
    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const selectBusinessPremises = (() => {
    if (!businessPremises) return [];

    const validPremises = businessPremises.filter(
      (bp) => bp.locationId != null,
    );

    const restrictToLocation =
      permissions?.invoice_archive_restrict_to_location_permission;
    const authorizedLocations = permissions?.locations || [];

    if (restrictToLocation) {
      const hasAuthorizationToAllLocations = permissions.calendar_locations;
      return validPremises.filter((bp) => {
        if (hasAuthorizationToAllLocations) {
          return true;
        }

        return authorizedLocations.includes(bp.locationId!);
      });
    }

    return validPremises;
  })();

  return (
    <>
      <TaxRegisterAuthorizationDialog />

      {permissions?.allow_invoice_export ? (
        <LimeModal
          isOpen={isExportModalOpen}
          onOpenChange={onOpenChangeExportModal}
        >
          <form onSubmit={exportForm.onSubmit(handleSubmitExport)}>
            <ModalContent>
              <ModalHeader>
                <Trans>Izvozi račune</Trans>
              </ModalHeader>
              <ModalBody>
                <LimeDateRangePicker
                  maxValue={today(getLocalTimeZone())}
                  value={{
                    start: parseDate(exportForm.getValues().startDate),
                    end: parseDate(exportForm.getValues().endDate),
                  }}
                  onChange={(range) => {
                    if (!range) return;

                    exportForm.setValues({
                      startDate: range.start.toString(),
                      endDate: range.end.toString(),
                    });
                  }}
                  errorMessage={
                    exportForm.errors.startDate || exportForm.errors.endDate
                  }
                />

                <LimeSelect
                  label={t`Poslovni prostor`}
                  items={
                    selectBusinessPremises.map((businessPremise) => {
                      return {
                        key: businessPremise.id.toString(),
                        label: businessPremise.businessPremiseId,
                      };
                    }) ?? []
                  }
                  {...exportForm.getInputProps("businessPremiseId")}
                  isLoading={isBusinessPremisesFetching}
                  disallowEmptySelection={false}
                />

                <LimeSelect
                  label={t`Format`}
                  selectedKeys={
                    exportForm.getValues().format
                      ? [exportForm.getValues().format!]
                      : []
                  }
                  onSingleItemChange={(exportFormat) => {
                    exportForm.setFieldValue(
                      "format",
                      exportFormat as (typeof exportForm)["values"]["format"],
                    );
                  }}
                  items={[
                    {
                      key: "csv",
                      label: "CSV",
                    },
                    {
                      key: "xlsx",
                      label: "XLSX (Excel)",
                    },
                    {
                      key: "eSLOG2.0",
                      label: "eSLOG 2.0",
                    },
                    {
                      key: "pdf",
                      label: "PDF",
                    },
                  ]}
                  selectionMode="single"
                />

                <LimeAlert
                  message={
                    exportForm.getValues().format === "eSLOG2.0"
                      ? t`Izvoz v formatu eSLOG 2.0 bo izvozil samo račune.`
                      : undefined
                  }
                  color="warning"
                />

                <LimeAlert
                  message={
                    exportForm.getValues().format === "xlsx"
                      ? t`Izvoz v formatu XLSX bo izvozil datoteko z več listi.`
                      : undefined
                  }
                  color="primary"
                />

                <Divider />

                <p className="text-sm text-gray-500">
                  <Trans>Izberite stolpce, ki jih želite izvoziti</Trans>
                </p>

                <LimeSelect
                  label={t`Stolpci zaključkov`}
                  selectedKeys={exportForm.getValues().closureColumns}
                  // onChange={(e: string) => exportForm.setFieldValue("columns", e)}
                  onSelectionChange={(keys: SharedSelection) => {
                    if (keys === "all") {
                      // Either set to an empty array, or perhaps "all columns"
                      exportForm.setFieldValue(
                        "closureColumns",
                        accountingExportValues?.closureColumns?.map(
                          ({ value }) => value,
                        ) || [],
                      );
                    } else {
                      // keys is a Set<Key> here
                      exportForm.setFieldValue(
                        "closureColumns",
                        Array.from(keys) as ClosureColumnEnum[],
                      );
                    }
                  }}
                  items={
                    accountingExportValues?.closureColumns?.map(
                      ({ value, label }) => ({
                        key: value,
                        label,
                      }),
                    ) || []
                  }
                  selectionMode="multiple"
                  isLoading={isAccountingExportValuesFetching}
                />

                {exportForm.getValues().format !==
                  accountingExportValues?.fileFormat.ESLOG2_0 && (
                  <>
                    <LimeSelect
                      label={t`Stolpci izdanih darilnih bonov`}
                      selectedKeys={
                        exportForm.getValues().issuedGiftCardColumns
                      }
                      onSelectionChange={(keys: SharedSelection) => {
                        exportForm.setFieldValue(
                          "issuedGiftCardColumns",
                          Array.from(keys) as IssuedGiftCardColumnEnum[],
                        );
                      }}
                      items={
                        accountingExportValues?.issuedGiftCardColumns?.map(
                          ({ value, label }) => ({
                            key: value,
                            label,
                          }),
                        ) || []
                      }
                      selectionMode="multiple"
                      isLoading={isAccountingExportValuesFetching}
                    />

                    <LimeSelect
                      label={t`Stolpci skupnih zneskov po načinu plačila`}
                      selectedKeys={exportForm.getValues().totalColumns}
                      onSelectionChange={(keys: SharedSelection) => {
                        exportForm.setFieldValue(
                          "totalColumns",
                          Array.from(keys) as TotalsColumnEnum[],
                        );
                      }}
                      items={
                        accountingExportValues?.totalColumns?.map(
                          ({ value, label }) => ({
                            key: value,
                            label,
                          }),
                        ) || []
                      }
                      selectionMode="multiple"
                      isLoading={isAccountingExportValuesFetching}
                    />

                    <LimeSelect
                      label={t`Stolpci skupnih zneskov po DDV`}
                      selectedKeys={exportForm.getValues().taxTotalColumns}
                      onSelectionChange={(keys: SharedSelection) => {
                        exportForm.setFieldValue(
                          "taxTotalColumns",
                          Array.from(keys) as TaxTotalColumnEnum[],
                        );
                      }}
                      items={
                        accountingExportValues?.taxTotalColumns?.map(
                          ({ value, label }) => ({
                            key: value,
                            label,
                          }),
                        ) || []
                      }
                      selectionMode="multiple"
                      isLoading={isAccountingExportValuesFetching}
                    />
                  </>
                )}

                <Divider />

                <LimeSelect
                  label={t`Izvozi po`}
                  selectedKeys={
                    exportForm.getValues().by
                      ? [exportForm.getValues().by!]
                      : []
                  }
                  onSingleItemChange={(by) => {
                    exportForm.setFieldValue(
                      "by",
                      by as (typeof exportForm)["values"]["by"],
                    );
                  }}
                  items={[
                    {
                      key: "day",
                      label: "Dnevih",
                    },
                    {
                      key: "document",
                      label: "Dokumentih",
                    },
                  ]}
                  selectionMode="single"
                />
              </ModalBody>
              <ModalFooter>
                <LimeAlert message={exportErrorMessage} />

                <Button color="primary" type="submit" isLoading={isExporting}>
                  <Trans>Izvozi</Trans>
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </LimeModal>
      ) : undefined}

      <LimePageHeader
        title={t`Arhiv računov`}
        rightSection={
          permissions?.allow_invoice_export ? (
            <Button
              color="primary"
              onPress={onOpenExportModal}
              startContent={<FileOutput size={16} />}
            >
              <Trans>Izvozi račune</Trans>
            </Button>
          ) : undefined
        }
      />

      <div className="md:px-8">
        <div className="my-2 flex flex-col gap-2">
          <div className="flex flex-col items-center justify-between gap-2 px-4 md:flex-row">
            <div className="flex w-full gap-2">
              <LimeInput
                className="w-full"
                startContent={<LuSearch />}
                placeholder={t`Išči`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <div className="block md:hidden">
                <InvoiceFilter posAuthData={posAuthData} />
              </div>
            </div>

            <LimeDateRangePicker
              maxValue={today(getLocalTimeZone())}
              defaultValue={{
                start: today(getLocalTimeZone()).subtract({ days: 1 }),
                end: today(getLocalTimeZone()),
              }}
              value={{
                start: startDate,
                end: endDate,
              }}
              onChange={handleDateChange}
              className="md:max-w-64"
            />

            <div className="hidden md:block">
              <InvoiceFilter posAuthData={posAuthData} />
            </div>
          </div>
        </div>

        <Divider />

        {processedErrorMessage && (
          <LimeAlert
            color="danger"
            className="mx-4 mt-4"
            message={processedErrorMessage}
          />
        )}

        <Table
          selectionMode="single"
          onRowAction={(key) => {
            if (isPending || !invoices) return;

            const invoice = invoices.find((invoice) => invoice.number === key);
            if (!invoice) return;

            navigate(`./${invoice.id}?${searchParams.toString()}`, {
              relative: "path",
            });
          }}
          isStriped
          aria-label={t`Tabela arhiviranih računov`}
          className="p-4"
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                page={page}
                total={pageCount}
                onChange={(page) => handlePageChange(page)}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={rows}
            emptyContent={isPending ? undefined : t`Niste še izdali računov`}
          >
            {(item) => (
              <TableRow
                key={item.key}
                data-identifier={`invoice-row-${item.id}`}
              >
                {(columnKey) => (
                  <TableCell
                    className={cn("", {
                      "text-red-600 line-through": item.canceled,
                    })}
                  >
                    <Skeleton isLoaded={!isPending} className="rounded-xl">
                      {/* {getKeyValue(item, columnKey)} */}
                      {renderCell(item, columnKey)}
                    </Skeleton>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <InvoiceDetailsModal
        isAuthenticated={posAuthData?.userId != null}
        invoiceId={invoiceId || undefined}
        onClose={() => {
          if (invoiceId) {
            navigate(`..?${searchParams.toString()}`, { relative: "path" });
          }

          refetch();
        }}
      />
    </>
  );
};

const InvoiceFilter = ({
  posAuthData,
}: {
  posAuthData?: GetPosToken["response"];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUserId = searchParams.get("uid");
  const selectedBusinessPremiseId = searchParams.get("bid");
  const selectedServiceId = searchParams.get("sid");
  const selectedProductId = searchParams.get("pid");
  const selectedCustomerId = searchParams.get("cid");

  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [debouncedCustomerSearchQuery] = useDebouncedValue(
    customerSearchQuery,
    200,
  );

  const {
    data: users,
    isFetching: isUsersFetching,
    processedErrorMessage: usersErrorMessage,
  } = api.user.useUserList({});

  const {
    data: businessPremises,
    isFetching: isBusinessPremisesFetching,
    processedErrorMessage: businessPremisesErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremise({
    disabled: !posAuthData?.userId,
  });

  const {
    data: services,
    isFetching: isServicesFetching,
    processedErrorMessage: servicesErrorMessage,
  } = api.service.useGetServices({});

  const {
    data: products,
    isFetching: isProductsFetching,
    processedErrorMessage: productsErrorMessage,
  } = api.product.useGetProducts({});

  const {
    data: customers,
    isLoading: isFetchingCustomers,
    processedErrorMessage: customersErrorMessage,
  } = api.customer.useGetCustomers({
    searchQuery: debouncedCustomerSearchQuery,
    customerId: selectedCustomerId ? parseInt(selectedCustomerId) : undefined,
  });

  const setSelectedUserId = (userId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (userId) {
      existingSearchParams.set("uid", userId);
    } else {
      existingSearchParams.delete("uid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedBusinessPremiseId = (businessPremiseId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (businessPremiseId) {
      existingSearchParams.set("bid", businessPremiseId);
    } else {
      existingSearchParams.delete("bid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedServiceId = (serviceId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (serviceId) {
      existingSearchParams.set("sid", serviceId);
    } else {
      existingSearchParams.delete("sid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedProductId = (productId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (productId) {
      existingSearchParams.set("pid", productId);
    } else {
      existingSearchParams.delete("pid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const setSelectedCustomerId = (customerId?: string) => {
    const existingSearchParams = new URLSearchParams(window.location.search);

    if (customerId) {
      existingSearchParams.set("cid", customerId);
    } else {
      existingSearchParams.delete("cid");
    }

    setSearchParams(existingSearchParams, {
      preventScrollReset: true,
    });
  };

  const isFiltered = Boolean(
    selectedUserId ||
      selectedBusinessPremiseId ||
      selectedServiceId ||
      selectedProductId ||
      selectedCustomerId,
  );

  const selectedCustomer = selectedCustomerId
    ? customers?.find(
        (customer) => customer.customerId === parseInt(selectedCustomerId),
      )
    : undefined;

  return (
    <Popover showArrow offset={10} placement="bottom-end">
      <PopoverTrigger>
        <Button isIconOnly variant="flat">
          {isFiltered ? <Filter /> : <FilterX />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px] p-4">
        {(titleProps) => (
          <div className="w-full">
            <p {...titleProps}>
              <Trans>Filter</Trans>
            </p>
            <div className="mt-2 flex flex-col gap-2">
              <LimeSelect
                label={t`Zaposleni`}
                items={
                  users?.users.map((user) => {
                    return {
                      key: user.userId.toString(),
                      label: `${user.name} ${user.lastName}`,
                    };
                  }) ?? []
                }
                isLoading={isUsersFetching}
                error={usersErrorMessage}
                disallowEmptySelection={false}
                selectedKeys={selectedUserId ? [selectedUserId] : []}
                onChange={(uid?: string) => setSelectedUserId(uid)}
              />

              <LimeSelect
                label={t`Poslovni prostor`}
                items={
                  businessPremises
                    ?.filter((bp) => bp.locationId != null)
                    .map((businessPremise) => {
                      return {
                        key: businessPremise.id.toString(),
                        label: businessPremise.businessPremiseId,
                      };
                    }) ?? []
                }
                isLoading={isBusinessPremisesFetching}
                error={businessPremisesErrorMessage}
                selectedKeys={
                  selectedBusinessPremiseId ? [selectedBusinessPremiseId] : []
                }
                onChange={(id?: string) => setSelectedBusinessPremiseId(id)}
                disallowEmptySelection={false}
              />

              <LimeSelect
                label={t`Storitev`}
                items={
                  services?.services.map((service) => {
                    return {
                      key: service.serviceId.toString(),
                      label: service.name,
                    };
                  }) ?? []
                }
                selectedKeys={selectedServiceId ? [selectedServiceId] : []}
                isLoading={isServicesFetching}
                error={servicesErrorMessage}
                onChange={(id?: string) => setSelectedServiceId(id)}
                disallowEmptySelection={false}
              />

              <LimeSelect
                label={t`Produkt`}
                items={
                  products?.products.map((product) => {
                    return {
                      key: product.id.toString(),
                      label: product.name,
                    };
                  }) ?? []
                }
                selectedKeys={selectedProductId ? [selectedProductId] : []}
                isLoading={isProductsFetching}
                error={productsErrorMessage}
                onChange={(id?: string) => setSelectedProductId(id)}
                disallowEmptySelection={false}
              />

              <Divider />

              <LimeAutocomplete
                inputValue={customerSearchQuery}
                onInputChange={setCustomerSearchQuery}
                isLoading={isFetchingCustomers}
                error={customersErrorMessage}
                items={customers || []}
                label={t`Išči stranke`}
                onSelectionChange={(value) => {
                  setSelectedCustomerId(value?.toString());
                  setCustomerSearchQuery("");
                }}
                selectedKey={selectedCustomerId}
              >
                {(item) => (
                  <AutocompleteItem key={item.customerId.toString()}>
                    <div className="flex flex-col">
                      <span className="text-sm">{`${item.name} ${item.lastName}`}</span>
                      {item.email ? (
                        <span className="py-0 text-xs text-gray-500">
                          {item.email}
                        </span>
                      ) : undefined}
                      {item.gsm ? (
                        <span className="py-0 text-xs text-gray-500">
                          {item.gsm}
                        </span>
                      ) : undefined}
                    </div>
                  </AutocompleteItem>
                )}
              </LimeAutocomplete>

              {selectedCustomer && selectedCustomerId && (
                <div className="relative rounded-lg bg-default-100 px-3 py-2 text-sm">
                  <Button
                    className="absolute right-0 top-0"
                    variant="light"
                    isIconOnly
                    onPress={() => setSelectedCustomerId(undefined)}
                  >
                    <X size={18} />
                  </Button>

                  <p className="mb-1 font-medium">
                    <Trans>Izbrana stranka:</Trans>
                  </p>
                  <p>
                    {selectedCustomer.name} {selectedCustomer.lastName}
                  </p>
                  <p>{selectedCustomer.email}</p>
                  <p>{selectedCustomer.gsm}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
