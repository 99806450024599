import { PostCompany, GetCompanies, PutCompany } from "@/server-types";
import { post, get, put } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

export const usePostCompany = () => {
  return useCustomMutation({
    mutationFn: (body: PostCompany["body"]) =>
      post<PostCompany>("/company", body),
  });
};

export const useGetCompanies = (query: GetCompanies["query"]) => {
  return useCustomQuery({
    queryKey: ["companies", query],
    queryFn: () => get<GetCompanies>("/company", query),
  });
};

export const usePutCompany = () => {
  return useCustomMutation({
    mutationFn: ({
      companyId,
      body,
    }: {
      companyId: number;
      body: PutCompany["body"];
    }) => put<PutCompany>(`/company/${companyId}`, body),
  });
};
