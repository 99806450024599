import { get } from "../request-helpers";
import { useCustomQuery } from "../custom-query";
import {
  GetStripePaymentCurrency,
  GetLanguages,
  GetCurrency,
  GetPosPrinterTypes,
  GetTaxAccountTypes,
  GetAccountingExportValues,
} from "@/server-types";
import { keepPreviousData } from "@tanstack/react-query";

export const useGetStripePaymentCurrencies = () => {
  return useCustomQuery({
    queryKey: ["getStripePaymentCurrencies"],
    queryFn: () =>
      get<GetStripePaymentCurrency>(`/values/stripe-payment-currency`),
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};

export const useGetLanguages = () => {
  return useCustomQuery({
    queryKey: ["getLanguages"],
    queryFn: () => get<GetLanguages>(`/values/languages`),
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};

export const useGetCurrency = (query: GetCurrency["query"]) => {
  return useCustomQuery({
    queryKey: ["locationCurrency", query],
    queryFn: () => get<GetCurrency>("/values/currency", query),
    placeholderData: keepPreviousData,
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};

export const useGetPosPrinterTypes = () => {
  return useCustomQuery({
    queryKey: ["posPrinterType"],
    queryFn: () => get<GetPosPrinterTypes>("/values/pos-printer-types"),
    placeholderData: keepPreviousData,
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};

export const useGetAccountingExportValues = () => {
  return useCustomQuery({
    queryKey: ["accountingExportValues"],
    queryFn: () =>
      get<GetAccountingExportValues>("/values/accounting_export_values"),
    placeholderData: keepPreviousData,
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};

export const useGetTaxAccountTypes = () => {
  return useCustomQuery({
    queryKey: ["taxAccountTypes"],
    queryFn: () => get<GetTaxAccountTypes>("/values/tax-account-type"),
    placeholderData: keepPreviousData,
    staleTime: Infinity, // Data is always fresh
    gcTime: Infinity, // Data stays in cache indefinitely
  });
};
