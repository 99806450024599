import { LimeAccordion } from "@/Components/NextBase/LimeAccordion";
import { api } from "@/lib/api-client";
import {
  AccordionItem,
  Button,
  Link,
  Snippet,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import dayjs from "dayjs";
import { escape } from "lodash";
import { Download, View } from "lucide-react";

const InvoiceDetails = ({
  clientId,
  invoiceId,
}: {
  clientId: number;
  invoiceId: number;
}) => {
  const { data, isLoading } = api.admin.useGetInvoiceDetails({
    clientId,
    invoiceId,
  });

  if (isLoading) return <div>Loading...</div>;

  if (!data) return <div>Error</div>;
  const tierPeriod = data.tier;
  const features = data.features;
  const additionalItems = data.additionalItems;

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="text-xl font-bold">
        <Trans>Račun</Trans>
      </div>
      <Table removeWrapper>
        <TableHeader>
          <TableColumn>
            <Trans>Številka računa</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Datum izdaje</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena paketa</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena funkcionalnosti</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena dodatkov</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Cena obvestil</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Končna cena</Trans>
          </TableColumn>
          <TableColumn>
            <Trans>Končna cena s popustom</Trans>
          </TableColumn>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>{data.id}</TableCell>
            <TableCell>
              {dayjs(data.dateCreated).format("DD.MM.YYYY")}
            </TableCell>
            <TableCell>{data.tier?.priceWithDiscount} €</TableCell>
            <TableCell>{data.featuresPriceWithDiscount.toFixed(2)} €</TableCell>
            <TableCell>
              {data.additionalItemsPriceWithDiscount.toFixed(2)} €
            </TableCell>
            <TableCell>
              {data.notificationsPriceWithDiscount.toFixed(2)} €
            </TableCell>
            <TableCell>{data.totalPrice.toFixed(2)} €</TableCell>
            <TableCell>{data.totalPriceWithDiscount.toFixed(2)} €</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {data.pdf && (
        <div className="flex gap-2">
          <span>
            Eksterni ID :{" "}
            <Snippet symbol="" variant="bordered" className="border-0">
              {data.externalInvoiceId}
            </Snippet>
          </span>
          <Button
            as={Link}
            color="primary"
            href={`data:application/pdf;base64,${escape(data.pdf.pdfFile)}`}
            target="_blank"
            rel="noopener noreferrer"
            endContent={<View />}
          >
            Odperi račun
          </Button>
          <Button
            color="primary"
            as={Link}
            download={`racun-${data.id}.pdf`}
            href={`data:application/pdf;base64,${data.pdf.pdfFile}`}
            target="_blank"
            rel="noopener noreferrer"
            endContent={<Download />}
          >
            Cukni račun
          </Button>
        </div>
      )}
      <LimeAccordion title="table">
        <AccordionItem title="Detaili">
          <div className="text-xl font-bold">
            <Trans>Paket</Trans>
          </div>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime paketa</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Obdobje</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena paketa</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena s popustom</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {tierPeriod ? (
                <TableRow>
                  <TableCell>{tierPeriod.name}</TableCell>
                  <TableCell>
                    {dayjs(tierPeriod.periodFrom).format("DD.MM.YYYY")} -{" "}
                    {dayjs(tierPeriod.periodTo).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>{tierPeriod.price} €</TableCell>
                  <TableCell>{tierPeriod.discountAmount} %</TableCell>
                  <TableCell>{tierPeriod.priceWithDiscount} €</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>

          <div className="text-xl font-bold">
            <Trans>Funkcionalnosti</Trans>
          </div>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime funkcionalnosti</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Obdobje</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena s popustom</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.id}>
                  <TableCell>{feature.name}</TableCell>
                  <TableCell>
                    {feature.periodFrom} - {feature.periodTo}
                  </TableCell>
                  <TableCell>{feature.price} €</TableCell>
                  <TableCell>{feature.discountAmount} %</TableCell>
                  <TableCell>{feature.priceWithDiscount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="text-xl font-bold">
            <Trans>Dodatki</Trans>
          </div>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime dodatka</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Obdobje</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena s popustom</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {additionalItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.additionalItemType}</TableCell>
                  <TableCell>
                    {item.periodFrom} - {item.periodTo}
                  </TableCell>
                  <TableCell>{item.price} €</TableCell>
                  <TableCell>{item.discountAmount} %</TableCell>
                  <TableCell>{item.priceWithDiscount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="text-xl font-bold">
            <Trans>Obvestila</Trans>
          </div>
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Ime kanala</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Obdobje</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena na obvestilo</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Število obvestil</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Popust</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Cena skupaj s popustom</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {data.notifications.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.notificationChannel}</TableCell>
                  <TableCell>
                    {item.periodFrom} - {item.periodTo}
                  </TableCell>
                  <TableCell>{item.priceCentsPer / 100} €</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.discountAmount} %</TableCell>
                  <TableCell>{item.priceWithDiscount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionItem>

        {/* {data.invoice ? (
          <AccordionItem title="invoice">
            <Table removeWrapper>
              <TableHeader>
                <TableColumn>
                  <Trans>Številka računa</Trans>
                </TableColumn>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>{data.invoice?.number}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionItem>
        ) : (
          <></>
        )} */}
      </LimeAccordion>
    </div>
  );
};

export default InvoiceDetails;
