import { t } from "@lingui/core/macro";
import { LimeSelect } from "@/Components/LimeSelect";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { Collapse, Divider, Flex } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "../ManageService";
import { client } from "@/server-types";
import { Trans } from "@lingui/react/macro";

export const AdditionalTab = ({
  form,
  taxAccountType,
}: {
  form: UseFormReturnType<FormValues>;
  taxAccountType: NonNullable<client["taxAccountType"]>;
}) => {
  return (
    <Flex direction={"column"}>
      {taxAccountType === "medicine" ? (
        <LimeSwitch
          {...form.getInputProps("isMedicineService", {
            type: "checkbox",
          })}
          label={t`Medicinska storitev`}
          // toolTip="PLACEHOLDER"
          size="md"
          px={"md"}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        />
      ) : undefined}

      <LimeSwitch
        data-identifier={"tax-toggle"}
        {...form.getInputProps("shouldCalculateTax", {
          type: "checkbox",
        })}
        label={t`Obračun davka`}
        size="md"
        px={"md"}
        autoFocus={taxAccountType !== "medicine"}
      />
      <p className="mb-4 px-4 text-xs text-gray-500">
        <Trans>Se obračuna samo, če ste davčni zavezanec</Trans>
      </p>

      <Collapse in={form.getValues().shouldCalculateTax}>
        <LimeSelect
          data-identifier={"tax-percentage-dropdown"}
          {...form.getInputProps("taxPercentage")}
          value={form.getValues().taxPercentage.toString()}
          label={t`Davčna stopnja`}
          data={[
            { label: t`Brez DDV (0%)`, value: "0" },
            { label: t`Znižana stopnja (9.5%)`, value: "9.5" },
            { label: t`Standardna stopnja (22%)`, value: "22" },
          ]}
          allowDeselect={false}
          px={"md"}
        />
      </Collapse>

      <Divider my={"md"} />
    </Flex>
  );
};
