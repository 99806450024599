import { api, queryClient } from "@/lib/api-client";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

function RouteChangeListener() {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  const { mutateAsync: deleteCookie } = api.cookie.useDeleteHttpCookie();

  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    const currentLocation = location;
    prevLocationRef.current = currentLocation;

    const wasOnPosAuthorizedScreen =
      prevLocation.pathname.includes("tax-register") ||
      prevLocation.pathname.includes("products/internal-use") ||
      prevLocation.pathname.includes("products/write-off");

    const wasOnCashRegister = prevLocation.pathname.includes("cash-register");
    const isCashRegisterSubPage =
      wasOnCashRegister && currentLocation.pathname.includes("cash-register");

    const wasOnInvoicesPage = prevLocation.pathname.includes("invoice");
    const isInvoicesSubPage =
      wasOnInvoicesPage && currentLocation.pathname.includes("invoice");

    const wasOnTaxRegSetup = prevLocation.pathname.includes("setup");
    const isTaxRegSetupSubPage =
      wasOnTaxRegSetup && currentLocation.pathname.includes("setup");

    const wasOnProductsInternalUse = prevLocation.pathname.includes(
      "products/internal-use",
    );
    const isProductsInternalUseSubPage =
      wasOnProductsInternalUse &&
      currentLocation.pathname.includes("products/internal-use");

    const wasOnProductWriteOff =
      prevLocation.pathname.includes("products/write-off");
    const isProductWriteOffSubPage =
      wasOnProductWriteOff &&
      currentLocation.pathname.includes("products/write-off");

    const isLeavingPosAuthorizedScreen =
      wasOnPosAuthorizedScreen &&
      !isCashRegisterSubPage &&
      !isInvoicesSubPage &&
      !isTaxRegSetupSubPage &&
      !isProductsInternalUseSubPage &&
      !isProductWriteOffSubPage;

    if (
      prevLocation &&
      isLeavingPosAuthorizedScreen &&
      prevLocation.pathname !== currentLocation.pathname
    ) {
      deleteCookie({ cookieName: "pos-token" });
      queryClient.invalidateQueries({ queryKey: ["getPosToken"] });
    }
  }, [location]);

  return null; // This component doesn't render anything
}

export default RouteChangeListener;
