import { AlertButton } from "@/Components/NextBase/AlertButton";
import { TextVariant } from "@/types/text-variants";
import { Divider, Slider, Spinner } from "@heroui/react";
import { Button, Flex, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDebouncedCallback, useDebouncedState } from "@mantine/hooks";
import { useCallback, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiUpload2Fill } from "react-icons/ri";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type PixelCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Helper function to format bytes into a readable string.
function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const ImageUploadView = ({
  existingImage,
  close,
  onUploadImage,
  isUploadingImage,
  onDeleteImage,
  isDeletingImage,
  circularCrop,
  defaultCenterCrop,
  aspect,
  format = "webp",
}: {
  existingImage?: string | null;
  close: () => void;
  onUploadImage: (formData: FormData) => void;
  isUploadingImage?: boolean;
  onDeleteImage?: () => void;
  isDeletingImage?: boolean;
  circularCrop?: boolean;
  defaultCenterCrop?: boolean;
  aspect?: number;
  format?: "webp" | "jpeg";
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const blobUrlRef = useRef("");
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState<string | null>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const [qualityLevel, setQualityLevel] = useState(0.8);
  const [finalFileSize, setFinalFileSize] = useState<string>("");
  const [isCalculatingFileSize, setIsCalculatingFileSize] = useState(false);
  const calculateFinalFileSize = useDebouncedCallback(async () => {
    const image = imgRef.current;
    if (!image || !completedCrop) return;

    setIsCalculatingFileSize(true);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      setIsCalculatingFileSize(false);
      return;
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const blob = await offscreen.convertToBlob({
      type: `image/${format}`,
      quality: qualityLevel,
    });

    setFinalFileSize(formatBytes(blob.size));
    setIsCalculatingFileSize(false);
  }, 500);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      file: null as File | null,
    },

    validate: {
      file: (value) => (value === null ? "File is required" : null),
    },

    onValuesChange: (values) => {
      if (values.file) {
        calculateFinalFileSize();
      }
    },
  });

  const handleUpload = async () => {
    const image = imgRef.current;

    if (!image || !completedCrop) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get 2d context");
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const blob = await offscreen.convertToBlob({
      type: `image/${format}`,
      quality: qualityLevel,
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    const formData = new FormData();
    formData.append("image", blob);

    onUploadImage(formData);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    if (defaultCenterCrop) {
      const crop = centerCrop(
        makeAspectCrop(
          {
            // You don't need to pass a complete crop into
            // makeAspectCrop or centerCrop.
            unit: "%",
            width: 50,
          },
          aspect ?? 1 / 1,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop(crop);
    } else {
      setCrop(
        makeAspectCrop(
          {
            // You don't need to pass a complete crop into
            // makeAspectCrop or centerCrop.
            unit: "%",
            width: 100,
            height: 100,
          },
          aspect ?? 1 / 1,
          width,
          height,
        ),
      );
    }

    calculateFinalFileSize();
  };

  return (
    <>
      <Button onClick={close}>Zapri</Button>
      <Dropzone
        accept={IMAGE_MIME_TYPE}
        // maxSize={10 * 1024 * 1024}
        onDrop={(files) => {
          form.setFieldValue("file", files[0]);

          if (files && files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              setImgSrc(reader.result as string);
            });

            reader.readAsDataURL(files[0]);
          }
        }}
        maxFiles={1}
      >
        <Dropzone.Accept>
          <Flex align={"center"} gap={"xs"}>
            <RiUpload2Fill />
            <Text variant={TextVariant.BodyEmphasized}>UPLOAD</Text>
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex align={"center"} gap={"xs"}>
            <AiOutlineClose />
            <Text variant={TextVariant.BodyEmphasized}>
              Dovoljene so samo slike pod 10MB
            </Text>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex align={"center"} gap={"xs"}>
            <RiUpload2Fill />
            <Text variant={TextVariant.BodyEmphasized}>
              Drag an image or click to choose
            </Text>
          </Flex>
        </Dropzone.Idle>
      </Dropzone>

      {form.getValues().file && (
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => {
            setCompletedCrop(c);
            calculateFinalFileSize();
          }}
          aspect={aspect}
          circularCrop={circularCrop}
        >
          <img
            ref={imgRef}
            src={imgSrc ?? ""}
            alt="User"
            onLoad={onImageLoad}
            style={{ maxHeight: "60vh" }}
          />
        </ReactCrop>
      )}

      {form.getValues().file && (
        <>
          <Slider
            minValue={0}
            maxValue={1}
            value={qualityLevel}
            onChange={(qualityLevel) => {
              setQualityLevel(qualityLevel as number);
              calculateFinalFileSize();
            }}
            step={0.1}
            label={"Quality Level (ce je prevelka slika, zmanjsaj)"}
          />

          <div className="flex items-center gap-1">
            <span>
              Final file size{" "}
              <span className="font-bold text-red-500">(MAX 10MB)</span>:
            </span>
            {isCalculatingFileSize && <Spinner size="sm" />}{" "}
            <span>~{finalFileSize}</span>
          </div>

          <p>Format: {format}</p>

          <Button loading={isUploadingImage} onClick={handleUpload}>
            Shrani
          </Button>

          <Divider className="my-2" />
        </>
      )}

      {completedCrop && (
        <div
          style={{
            display: "none",
          }}
        >
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        </div>
      )}

      <a
        href="#hidden"
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>

      {existingImage != null && (
        <>
          <p>Existing image:</p>
          <img src={existingImage} style={{ height: "200px" }} alt="User" />
          <AlertButton
            title="Izbriši sliko"
            content="Ste prepricani, da zelite izbrisati sliko?"
            confirmProps={{
              onClick: async () => onDeleteImage?.(),
              isLoading: isDeletingImage,
            }}
          >
            Izbriši
          </AlertButton>
        </>
      )}
    </>
  );
};
