import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import { Color } from "@/types/colors";
import { useLingui } from "@lingui/react";
import { Accordion, Flex, Tooltip, UnstyledButton } from "@mantine/core";
import React, { useEffect } from "react";
import { FaUserAltSlash } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import { LuCopySlash, LuOctagonMinus, LuTimerOff } from "react-icons/lu";
import styled from "styled-components";
import { useAppointmentModalStore } from "../useAppointmentModalStore_";

const ServiceSelectList = ({
  serviceTags: tags,
  services,
  onSelectService,
  selectedServices,
}) => {
  const { lastSelectedUserId, lastSelectedResourceId, selectedLocationId } =
    useCalendarStore((state) => ({
      lastSelectedUserId: state.lastSelectedUserId,
      lastSelectedResourceId: state.lastSelectedResourceId,
      selectedLocationId: state.selectedLocationId,
    }));

  const serviceTags = tags.toSorted((a, b) => a.sortOrder - b.sortOrder);

  const { appointmentData, setRequiredTags } = useAppointmentModalStore(
    (s) => ({
      appointmentData: s.appointmentData,
      setRequiredTags: s.setRequiredTags,
    }),
  );

  const { data: nextWarnings } = api.calendar.useGetNextServiceWarnings({
    locationId: selectedLocationId,
    selectedServices: [
      ...JSON.parse(JSON.stringify(selectedServices)).map((ss) => ({
        ...ss,
      })),
    ],
    users: lastSelectedUserId ? [lastSelectedUserId] : [],
    resources: lastSelectedResourceId ? [lastSelectedResourceId] : [],
    startTime: appointmentData.startTime,
  });

  // REQUIRED SERVICES
  useEffect(() => {
    setRequiredTags(nextWarnings?.requiredTags || []);
  }, [nextWarnings]);

  useLingui();
  // if (serviceTags?.length === 0) {
  //   serviceTags.push({ name: t`Ostale storitve`, tagId: -1 });
  // }

  const servicesWithWarnings = services
    .filter(
      (s) =>
        !nextWarnings ||
        nextWarnings.availableServices.find(
          (ser) => s.serviceId === ser.serviceId && ser.isValid,
        ) ||
        selectedServices.find((ss) => ss.serviceId === s.serviceId),
    )
    .toSorted((a, b) => a.sortOrder - b.sortOrder)
    .map((service) => {
      const availableServiceDetails = nextWarnings?.availableServices.find(
        (s) => s.serviceId === service.serviceId,
      );

      return {
        ...service,
        id: service.serviceId,
        value: service.serviceId.toString(),
        label: service.name,
        ...availableServiceDetails,
        isValid: availableServiceDetails
          ? availableServiceDetails.isValid
          : true,
      };
    });

  return (
    <Wrapper>
      <Accordion
        defaultValue={
          serviceTags.length === 1 ? serviceTags[0].tagId.toString() : null
        }
      >
        {serviceTags?.map((tag) => {
          const tagServices = servicesWithWarnings.filter(
            (service) =>
              (service.tagId === tag.tagId && service.permission) ||
              (tag.tagId === -1 && !service.tagId),
          );

          if (tagServices.length === 0) return null;

          const tagName = tag.name;

          return (
            <Accordion.Item
              className="service-list-item"
              key={tag.tagId}
              label={tag.tagId}
              value={tag.tagId.toString()}
            >
              <Accordion.Control>{tagName}</Accordion.Control>
              <Accordion.Panel>
                <Flex direction={"column"} gap={"10px"}>
                  {tagServices.map((service) => {
                    const isSelected = selectedServices.some(
                      (selectedService) =>
                        selectedService.serviceId === service.serviceId,
                    );

                    const selectedItem = selectedServices.find(
                      (selectedService) =>
                        selectedService.serviceId === service.serviceId,
                    );

                    return (
                      <ServiceItem
                        key={service.serviceId}
                        color={service.color}
                        isSelected={isSelected}
                        onClick={() => onSelectService(service, isSelected)}
                        // disabled={true}
                      >
                        <div className={"color"}></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            alignItems: "center",
                          }}
                        >
                          {service.name}

                          <Flex gap={5} align={"center"} h={"fit-content"}>
                            {(
                              selectedItem
                                ? selectedItem.userRelationWarning
                                : service.userRelationWarning
                            ) ? (
                              <Tooltip
                                label={t`Izbran zaposleni ne izvaja te storitve`}
                              >
                                <Flex align={"center"}>
                                  <FaUserAltSlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.resourceRelationWarning
                                : service.resourceRelationWarning
                            ) ? (
                              <Tooltip
                                label={t`Izbrano sredstvo ne izvaja te storitve`}
                              >
                                <Flex align={"center"}>
                                  <FaUserAltSlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.userOverlapWarning
                                : service.userOverlapWarning
                            ) ? (
                              <Tooltip label={t`Zaposleni se prekriva`}>
                                <Flex align={"center"}>
                                  <LuCopySlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.resourceOverlapWarning
                                : service.resourceOverlapWarning
                            ) ? (
                              <Tooltip label={t`Sredstvo se prekriva`}>
                                <Flex align={"center"}>
                                  <LuCopySlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.userShiftWarning
                                : service.userShiftWarning
                            ) ? (
                              <Tooltip label={t`Zaposleni nima urnika`}>
                                <Flex align={"center"}>
                                  <LuTimerOff color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}
                            {(
                              selectedItem
                                ? selectedItem.resourceShiftWarning
                                : service.resourceShiftWarning
                            ) ? (
                              <Tooltip label={t`Sredstvo nima urnika`}>
                                <Flex align={"center"}>
                                  <LuTimerOff color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.restrictionWarning
                                : service.restrictionWarning
                            ) ? (
                              <Tooltip label={t`Omejitev storitve`}>
                                <Flex align={"center"}>
                                  <LuOctagonMinus color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {
                              <GoCheck
                                style={{
                                  color: "black",
                                  fontSize: "1.1rem",
                                  visibility: isSelected ? undefined : "hidden",
                                  marginLeft: "20px",
                                }}
                              />
                            }
                          </Flex>
                        </div>
                      </ServiceItem>
                    );
                  })}
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mantine-Accordion-label {
    padding: 0.65rem 0;
  }

  .mantine-Accordion-control {
    padding: 0 0.5rem;
  }

  .mantine-Accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ServiceItem = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  border-radius: 7px;
  padding: 0.5rem 1rem;

  border: 1px solid ${(props) => props.color};
  font-size: 0.92rem;

  &:hover {
    background-color: ${(props) => props.color}10;
  }

  .color {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`;

export default React.memo(ServiceSelectList);
