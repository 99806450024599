import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { api } from "@/lib/api-client";
import { PutClientUrls } from "@/server-types";
import { useForm, UseFormReturnType } from "@mantine/form";
import { Button, Divider, Input, Spinner } from "@heroui/react";
import { Plus, Trash } from "lucide-react";
import { useEffect } from "react";
import { t } from "@lingui/core/macro";

export const ClientUrlsTab = ({ clientId }: { clientId: number }) => {
  const { data, isFetching, refetch } = api.admin.useClientDetails(clientId);

  const { mutateAsync: updateClientUrls, isPending: isUpdating } =
    api.admin.usePutClientUrls();

  const form = useForm<{
    urls: NonNullable<PutClientUrls["body"]["clientUrls"]>;
  }>({
    initialValues: {
      urls: [],
    },
  });

  useEffect(() => {
    if (data) {
      form.setValues({
        urls:
          data?.client?.ClientUrl?.map((clientUrl) => ({
            urls: clientUrl.urlsLocalized,
            isPrivacyPolicy: clientUrl.isPrivacyPolicy,
          })) || [],
      });
    }
  }, [data]);

  const addUrl = () => {
    form.insertListItem("urls", {
      urls: [],
      isPrivacyPolicy: false,
    });
  };

  const submitUrls = async (values: typeof form.values) => {
    console.log("VALUES", values.urls);

    await updateClientUrls({
      body: {
        clientUrls: values.urls,
      },
      params: {
        clientId,
      },
    });

    refetch();
  };

  if (isFetching) return <Spinner />;

  console.log("form", form.getValues());

  return (
    <form onSubmit={form.onSubmit(submitUrls)}>
      {form.getValues().urls?.map((clientUrl, index) => {
        return (
          <>
            <RenderUrl form={form} index={index} />
            <Divider />
          </>
        );
      })}

      <div className="mt-2 flex items-center gap-2">
        <Button onPress={addUrl} startContent={<Plus />} isLoading={isUpdating}>
          Dodaj URL
        </Button>
        <Button color="primary" type="submit" isLoading={isUpdating}>
          Shrani
        </Button>
      </div>
    </form>
  );
};

const RenderUrl = ({
  index,
  form,
}: {
  index: number;
  form: UseFormReturnType<{
    urls: NonNullable<PutClientUrls["body"]["clientUrls"]>;
  }>;
}) => {
  const { data: languages, isFetching: isFetchingLanguages } =
    api.values.useGetLanguages();

  if (isFetchingLanguages) return <Spinner />;

  return (
    <div className="my-4 rounded-lg bg-gray-100 p-2">
      <Button isIconOnly onPress={() => form.removeListItem("urls", index)}>
        <Trash />
      </Button>

      {/* <Input label="URL" {...form.getInputProps(`urls.${index}.url`)} /> */}

      {languages?.map((language) => {
        return (
          <>
            <div className="flex w-full flex-col gap-1 md:flex-row">
              <Input
                label={language}
                size="sm"
                value={
                  form
                    .getValues()
                    .urls[index]?.urls.find((url) => url.language === language)
                    ?.label || ""
                }
                variant="bordered"
                onChange={(e) => {
                  const val = e.target.value;
                  const currentUrls = form.getValues().urls;

                  // Ensure `labelLocalized` array exists for this URL
                  const updatedLabels = [...(currentUrls[index].urls || [])];

                  // Check if a label for the current language already exists
                  const labelIndex = updatedLabels.findIndex(
                    (label) => label.language === language,
                  );

                  if (labelIndex >= 0) {
                    // Update the existing label
                    updatedLabels[labelIndex].label = val;
                  } else {
                    // Add a new label for the current language
                    updatedLabels.push({ language, label: val, url: "" });
                  }

                  // Update the form values
                  form.setFieldValue(`urls.${index}.urls`, updatedLabels);
                }}
              />

              <Input
                label={t`URL`}
                size="sm"
                value={
                  form
                    .getValues()
                    .urls[index]?.urls.find((url) => url.language === language)
                    ?.url || ""
                }
                variant="bordered"
                onChange={(e) => {
                  const val = e.target.value;
                  const currentUrls = form.getValues().urls;

                  // Ensure `labelLocalized` array exists for this URL
                  const updatedLabels = [...(currentUrls[index].urls || [])];

                  // Check if a label for the current language already exists
                  const labelIndex = updatedLabels.findIndex(
                    (label) => label.language === language,
                  );

                  if (labelIndex >= 0) {
                    // Update the existing label
                    updatedLabels[labelIndex].url = val;
                  } else {
                    // Add a new label for the current language
                    updatedLabels.push({ language, label: "", url: val });
                  }

                  // Update the form values
                  form.setFieldValue(`urls.${index}.urls`, updatedLabels);
                }}
              />
            </div>
            <Divider className="my-2 bg-black" />
          </>
        );
      })}

      <LimeSwitch
        title={"To je link do njihove politike zasebnosti (skrije naše)"}
        {...form.getInputProps(`urls.${index}.isPrivacyPolicy`, {
          type: "checkbox",
        })}
      />
    </div>
  );
};
