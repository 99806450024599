import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { LimeSelect, Select } from "./LimeSelect";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

export const LimeTimeZoneSelect = ({
  value,
  onChange,
  ...props
}: {
  value?: string;
  onChange: (value: string) => void;
} & Omit<Select, "items">) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  return (
    <LimeSelect
      {...props}
      value={value}
      onChange={(option: string) => onChange(parseTimezone(option).value)}
      items={options.map((option) => {
        return {
          key: option.value,
          label: option.label,
        };
      })}
    />
  );
};
