import { t } from "@lingui/core/macro";
import { Tab, Tabs } from "@heroui/react";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { Key, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { toast } from "sonner";

export const CashRegister = () => {
  const { businessPremiseId } = useParams(); // Fetch params from URL
  const navigate = useNavigate();

  const tab = businessPremiseId
    ? location.pathname.split("/").pop()
    : undefined;

  const { data: posAuthData } = api.cookie.useGetPosToken();

  const { data: permissions } = api.user.useGetUserPermissions({
    query: { tokenType: "POS" },
    disabled: !posAuthData?.userId,
  });

  const {
    data: businessPremises,
    isFetching: isBusinessPremisesFetching,
    processedErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremise({
    disabled: !posAuthData?.userId || !permissions,
  });

  // When the business premise is selected or the page is loaded, sync the URL.
  useEffect(() => {
    if (!posAuthData?.userId) {
      return;
    }

    if (businessPremiseId && !tab) {
      navigate(
        `/dashboard/tax-register/cash-register/${businessPremiseId}/${tab || "status"}`,
        {
          replace: true,
        },
      );
      return;
    }

    if (!businessPremiseId && businessPremises && businessPremises.length > 0) {
      const firstPremiseId = businessPremises.find(
        (bp) => bp.locationId != null,
      )?.id;

      if (!firstPremiseId) {
        toast.error(t`Ne obstaja poslovni prostor s povezano lokacijo`);
        return;
      }

      const _tab = (() => {
        if (tab) return tab;

        const hasStatusPermission =
          permissions?.view_cash_register_status_summary_view;
        if (hasStatusPermission) return "status";
        return "closure";
      })();

      navigate(
        `/dashboard/tax-register/cash-register/${firstPremiseId}/${_tab || "status"}`,
      );
    }
  }, [posAuthData, businessPremiseId, businessPremises]);

  const handleTabChange = (key: Key) => {
    if (!businessPremiseId) return;

    navigate(
      `/dashboard/tax-register/cash-register/${businessPremiseId}/${key}`,
    );
  };

  const handlePremiseChange = (value: string) => {
    navigate(
      `/dashboard/tax-register/cash-register/${value}/${tab || "status"}`,
    ); // Ensure the selected tab is retained when premise changes
  };

  return (
    <>
      <TaxRegisterAuthorizationDialog />

      <LimePageHeader title={t`Blagajna`} />

      <div className="mx-auto mt-2 max-w-xl px-2 md:px-8">
        <LimeSelect
          items={
            businessPremises?.map((businessPremise) => ({
              key: businessPremise.id,
              label: `${businessPremise.businessPremiseId} - ${businessPremise.locationLabel}`,
            })) || []
          }
          onChange={handlePremiseChange}
          value={businessPremiseId}
          className="mb-2"
          label={t`Poslovni prostor`}
          isLoading={isBusinessPremisesFetching}
          errorMessage={processedErrorMessage}
        />

        <Tabs
          aria-label="Options"
          selectedKey={tab || "status"}
          onSelectionChange={handleTabChange}
          isDisabled={
            isBusinessPremisesFetching || processedErrorMessage != null
          }
          fullWidth
          className="mb-2"
        >
          {permissions?.view_cash_register_status_summary_view ? (
            <Tab key="status" title={t`Stanje blagajne`}></Tab>
          ) : undefined}
          {permissions?.view_cash_register_closures ||
          permissions?.allow_closing_cash_register ? (
            <Tab key="closure" title={t`Zaključek blagajne`}></Tab>
          ) : undefined}
        </Tabs>

        <Outlet />
      </div>
    </>
  );
};
