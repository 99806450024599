import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeLoader } from "@/Components/LimeLoader";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  TextInput,
} from "@mantine/core";
import {
  useDebouncedState,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { useEffect, useState } from "react";
import { RiListUnordered, RiSearch2Line } from "react-icons/ri";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { ServiceCategories } from "./ServiceCategories";
import { ServiceList } from "./ServiceList";

export const Services = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const [mode, setMode] = useState<"view" | "edit">("view");

  const [serviceSearch, setServiceSearch] = useDebouncedState("", 200);

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const {
    data: categories,
    isPending: categoriesIsPending,
    isError: categoriesIsError,
  } = api.category.useGetCategories({});

  const {
    data: serviceData,
    isPending: servicesIsPending,
    isError: servicesIsError,
    refetch: refetchServices,
  } = api.service.useGetServices({
    includeDeleted: false,
    search: serviceSearch,
  });

  if (servicesIsError || categoriesIsError) {
    return <div>Error</div>;
  }

  const services = serviceData?.services;
  const total = serviceData?.total;

  const onServiceClick = (serviceId: number) => {
    navigate(`/dashboard/services/${serviceId}`);
  };

  return (
    <>
      <LimePageHeader
        title={t`Storitve`}
        rightSection={
          total && total > 0
            ? {
                label: t`Dodaj storitev`,
                onClick: () => onServiceClick(-1),
                identifier: "add-service",
              }
            : undefined
        }
      />

      <LimePageTabs
        activeTab={"services"}
        onChangeTab={async (tab) => {
          if (tab === "categories") {
            navigate("/dashboard/services/categories");
          } else {
            await refetchServices();
          }
        }}
        tabs={[
          {
            label: t`Vse storitve`,
            value: "services",
          },
          {
            label: t`Kategorije`,
            value: "categories",
          },
        ]}
        rightSection={
          <Flex
            justify={"space-between"}
            gap={"md"}
            align={"center"}
            p={isMobile ? "xs" : 0}
          >
            <TextInput
              placeholder={t`Išči storitve`}
              defaultValue={serviceSearch}
              onChange={(event) => setServiceSearch(event.currentTarget.value)}
              leftSection={<RiSearch2Line />}
              w={isMobile ? "100%" : undefined}
            />
            {isMobile ? (
              <ActionIcon onClick={openDrawer}>
                <RiListUnordered
                  size={20}
                  color={mode === "view" ? "black" : Color.Brand}
                />
              </ActionIcon>
            ) : (
              <Button
                variant="light"
                radius={20}
                size="xs"
                w={"fit-content"}
                style={{
                  flexShrink: 0,
                  outline:
                    mode === "edit"
                      ? "2px solid var(--mantine-color-brand-light-color)"
                      : undefined,
                }}
                onClick={() =>
                  setMode((prev) => (prev === "view" ? "edit" : "view"))
                }
              >
                <Trans>Uredi zaporedje</Trans>
              </Button>
            )}
          </Flex>
        }
      />

      {isMobile ? <Divider /> : undefined}

      {servicesIsPending || categoriesIsPending ? <LimeLoader /> : undefined}
      {!servicesIsPending && !categoriesIsPending ? (
        <Box px={isMobile ? undefined : "xl"}>
          <ServiceList
            categories={categories ?? []}
            search={serviceSearch}
            services={services ?? []}
            onServiceClick={onServiceClick}
            mode={mode}
            setMode={setMode}
            drawerOpened={drawerOpened}
            closeDrawer={closeDrawer}
          />
        </Box>
      ) : undefined}
    </>
  );
};
