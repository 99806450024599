import { t } from "@lingui/core/macro";
import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { Collapse, Divider, Flex } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "../ManageService";

export const ResourcesTab = ({
  form,
}: {
  form: UseFormReturnType<FormValues>;
}) => {
  return (
    <Flex direction={"column"}>
      <LimeSwitch
        data-identifier={"number-of-customers-on-form-toggle"}
        label={t`Izbira števila strank preko spletne forme `}
        {...form.getInputProps("isAttendanceExplicit", {
          type: "checkbox",
        })}
        toolTip={t`Stranka lahko preko spletne forme izbere, koliko oseb bo prišlo na to storitev. V kolikor preseže kapaciteto sredstva, se avtomatsko aktivira novo (v kolikor je to prosto). V primeru ne-prostih sredstev je lahko maksimalno št. strank manjše od določenega v sistemu.`}
        px={"md"}
        size="md"
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      />

      <Collapse in={form.values.isAttendanceExplicit}>
        <EasyNumberInput
          {...form.getInputProps("minAttendance")}
          label={t`Določite minimalno št. strank`}
          min={1}
          px={"md"}
          mt={"md"}
        />

        <Divider my={"md"} />

        <EasyNumberInput
          {...form.getInputProps("maxAttendance")}
          label={t`Določite maksimalno št. strank`}
          min={1}
          px={"md"}
        />
      </Collapse>

      {/* <Divider my={"md"} /> */}

      <LimeSwitch
        data-identifier={"additional-resources-on-form-toggle"}
        label={t`Rezervacija dodatnih sredstev preko spletne forme `}
        {...form.getInputProps("areExtraResourcesExplicit", {
          type: "checkbox",
        })}
        toolTip={t`Stranka lahko preko spleta rezervira dodatna sredstva, tudi če ta niso povsem zapolnjena. To vpliva na končno ceno, ki jo stranka plača.`}
        px={"md"}
        size="md"
      />

      <Collapse in={form.values.areExtraResourcesExplicit}>
        <EasyNumberInput
          {...form.getInputProps("totalResourcesMax")}
          label={t`Določite maksimalno št. sredstev`}
          min={1}
          px={"md"}
          mt={"md"}
        />
      </Collapse>

      <Divider my={"md"} />

      {form.values.resourceGroupIds.length > 0 && (
        <>
          <LimeSwitch
            label={"Odprt tip spletne rezervacije  "}
            {...form.getInputProps("allowsMultipleAppointments", {
              type: "checkbox",
            })}
            toolTip={t`Odprt tip rezervacije pomeni, da se lahko na termin naroči več različnih oseb, ki morajo vsaka posebej opraviti postopek rezervacije preko spleta.`}
            px={"md"}
            size="md"
            disabled={form.values.resourceGroupIds.length === 0}
          />

          <Divider my={"md"} />

          {form.values.handsOffEnabled && (
            <>
              <LimeSwitch
                label={t`Sredstvo je blokirano med časom brez izvajalca`}
                {...form.getInputProps("isTimeOffBlocking", {
                  type: "checkbox",
                })}
                px={"md"}
                size="md"
                disabled={form.values.resourceGroupIds.length === 0}
              />

              <Divider my={"md"} />
            </>
          )}
        </>
      )}
    </Flex>
  );
};
