import { t } from "@lingui/core/macro";
import { forwardRef } from "react";
import { ModalBody } from "@heroui/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  useDisclosure,
  ButtonProps,
  ModalProps,
} from "@heroui/react";

export const AlertButton = forwardRef(
  (
    {
      children,
      title,
      content,
      confirmProps,
      cancelProps,
      buttonProps,
      modalProps,
    }: {
      children: React.ReactNode;
      title: string;
      content: React.ReactNode;
      confirmProps: {
        label?: string;
        icon?: React.ReactNode;
        onClick: () => Promise<void>;
        isLoading?: boolean;
        dataIdentifier?: string;
      };
      cancelProps?: {
        label?: string;
        icon?: React.ReactNode;
        onClick?: () => void;
      };
      buttonProps?: ButtonProps & {
        dataIdentifier?: string;
      };
      modalProps?: ModalProps;
    },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    return (
      <>
        <Button
          color="primary"
          {...buttonProps}
          data-identifier={buttonProps?.dataIdentifier}
          onPress={onOpen}
          ref={ref}
        >
          {children}
        </Button>
        <Modal
          placement="top-center"
          {...modalProps}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  {title}
                </ModalHeader>
                <ModalBody>{content}</ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    variant="flat"
                    onPress={() => {
                      if (cancelProps?.onClick) cancelProps.onClick();
                      onClose();
                    }}
                  >
                    {cancelProps?.label != null
                      ? cancelProps.label
                      : t`Prekliči`}
                  </Button>
                  <Button
                    color="primary"
                    onPress={async () => {
                      await confirmProps.onClick();
                      onClose();
                    }}
                    isLoading={confirmProps.isLoading}
                    data-identifier={confirmProps.dataIdentifier}
                  >
                    {confirmProps.label != null
                      ? confirmProps.label
                      : t`Potrdi`}
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  },
);

AlertButton.displayName = "AlertButton";
