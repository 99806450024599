import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getStatusIcon } from "@/pages/Dashboard/Calendar/utils";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { useLingui } from "@lingui/react";
import { Accordion, Flex, Text } from "@mantine/core";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useCalendarStore } from "../../pages/Dashboard/Calendar/store/useCalendarStore";
import LoadingSpinner from "../Dashboard/LoadingSpinner/LoadingSpinner";
import { CustomerList } from "./components/CustomerList";
import DateTimeSection from "./components/DateTimeSection";
import Form from "./components/Form";
import SelectedServiceList from "./components/SelectedServiceList";
import useAppointmentModal from "./useAppointmentModal";
import { getAppointmentEndTime, isAppointmentInPast } from "./utils";
import { useAppointmentModalStore } from "./useAppointmentModalStore_";
import ServiceSelect from "./components/ServiceSelect";
import { AccordionItem, Alert } from "@heroui/react";
import { AlertCircle, Check, CheckCircle, TriangleAlert } from "lucide-react";
import { LimeAccordion } from "../NextBase/LimeAccordion";
import { cn } from "@/utils";

const AppointmentModal = ({
  appointmentId,
  openCustomerCard,
  IS_GLOWUP_USER = false,
  IS_ILLUME_USER = false,
}) => {
  useLingui();
  const { setIsExpandedModal, serviceTags, calendarData } = useCalendarStore(
    (state) => ({
      setIsExpandedModal: state.setIsExpandedModal,
      serviceTags: state.serviceTags,
      calendarData: state.calendarData,
    }),
  );

  const requiredTags = useAppointmentModalStore((state) => state.requiredTags);

  const {
    appointmentData,
    isFetchingData,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    services,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    handleConfirmServiceSelection,
    selectedUserAppointment,
    handleUserAppointmentSelect,
    handleSubmitAppointmentForm,
    fetchAppointmentData,
  } = useAppointmentModal(appointmentId);

  const isNewAppointment = appointmentId === -1;
  const dateObject = dayjs(appointmentData?.startTime);

  const isInPast = useMemo(
    () =>
      isAppointmentInPast(
        appointmentData ? dateObject.format("YYYY-MM-DD HH:mm") : undefined,
      ),
    [appointmentData?.startTime],
  );

  const endTime = useMemo(
    () => getAppointmentEndTime(appointmentData?.startTime, selectedServices),
    [appointmentData?.startTime, selectedServices],
  );

  const isPreScheduled = appointmentData?.isPreScheduled;
  useEffect(() => {
    setIsExpandedModal(isPreScheduled);
  }, [appointmentData?.isPreScheduled]);

  const getContent = () => {
    if (isFetchingData) return <LoadingSpinner />;
    if (!appointmentData) return <div>Invalid appointment</div>;

    if (isNewAppointment && !confirmedServiceSelection) {
      return (
        <ServiceSelect
          services={services}
          serviceTags={serviceTags}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          setConfirmedServiceSelection={handleConfirmServiceSelection}
        />
      );
    }

    const disableFormInputs = selectedUserAppointment != null;

    const selectedCustomerId = appointmentData.customers.find(
      (customer) => customer.userAppointmentId === selectedUserAppointment,
    )?.customerId;

    return (
      <Form
        disableUnEditableFields={disableFormInputs}
        customerData={appointmentData.customers.find(
          (customer) => customer.userAppointmentId === selectedUserAppointment,
        )}
        notificationSchedule={appointmentData.customers
          .filter((c) => c.customerId === selectedCustomerId)
          .flatMap((c) => c.notificationSchedule)}
        notificationLog={appointmentData.customers
          .filter((c) => c.customerId === selectedCustomerId)
          .flatMap((c) => c.notificationLog)}
        handleSubmitAppointmentForm={handleSubmitAppointmentForm}
        isNewAppointment={isNewAppointment}
        selectedServices={selectedServices}
        IS_GLOWUP_USER={IS_GLOWUP_USER}
        IS_ILLUME_USER={IS_ILLUME_USER}
        dayObject={dateObject}
      />
    );
  };

  const successes = [];
  const warnings = [];
  const errors = [];
  if (isInPast) {
    if (isNewAppointment) {
      errors.push({
        message: t`Termin bo ustvarjen v preteklosti!`,
        variant: "filled",
      });
    } else {
      errors.push({
        message: t`Termin je v preteklosti!`,
        variant: "filled",
      });
    }
  }

  // REQUIRED SERVICES
  if (requiredTags.length) {
    errors.push({
      message: t`Potrebujete še storitev iz ${requiredTags.map((t) => serviceTags.find((st) => st.tagId === t)?.name).join(", ")}`,
    });
  }

  const { days } = calendarData;

  if (appointmentData?.startTime) {
    const date = dayjs(appointmentData.startTime, "YYYY-MM-DD HH:mm").format(
      "YYYY-MM-DD",
    );

    const selectedDay = days.find((day) => day.date === date);

    if (selectedDay?.isHoliday) {
      errors.push({
        message: t`Termin je na praznik!`,
        variant: "filled",
      });
    }
  }

  const alerts = {
    successes,
    warnings,
    errors,
  };

  const selectedPreScheduledCustomer = React.useMemo(
    () =>
      appointmentData?.customers?.find(
        (customer) => customer.userAppointmentId === selectedUserAppointment,
      ),
    [appointmentData, selectedUserAppointment],
  );

  return (
    <>
      <div className="my-1 mt-4 flex w-full flex-wrap gap-1">
        {alerts && (
          <>
            {alerts.errors.map((alert, index) => (
              <Alert
                key={index}
                title={alert.message}
                className="w-full px-0 py-0 text-center"
                classNames={{
                  base: "flex items-center justify-center",
                  title: "text-xs",
                  mainWrapper: "min-h-7",
                }}
                color="danger"
                hideIcon
                endContent={<AlertCircle size={16} className="mr-3" />}
              />
            ))}
            {alerts.warnings.map((alert, index) => (
              <Alert
                key={index}
                title={alert.message}
                className="w-full px-0 py-0 text-center"
                classNames={{
                  base: "flex items-center justify-center",
                  title: "text-xs",
                  mainWrapper: "min-h-7",
                }}
                color="warning"
                hideIcon
                endContent={<TriangleAlert size={16} className="mr-3" />}
              />
            ))}
            {alerts.successes.map((alert, index) => (
              <Alert
                key={index}
                title={alert.message}
                className="w-full px-0 py-0 text-center"
                classNames={{
                  base: "flex items-center justify-center",
                  title: "text-xs",
                  mainWrapper: "min-h-7",
                }}
                color="success"
                hideIcon
                endContent={<CheckCircle size={16} className="mr-3" />}
              />
            ))}
          </>
        )}
      </div>

      {((isPreScheduled && selectedUserAppointment) || !isPreScheduled) && (
        <PaymentDetails
          customer={
            isPreScheduled
              ? selectedPreScheduledCustomer
              : appointmentData?.customers[0]
          }
        />
      )}

      {isFetchingData || appointmentData == null ? (
        <LoadingSpinner />
      ) : (
        <Flex
          direction={{
            base: "column",
            sm: "row",
          }}
          gap={"2rem"}
        >
          <div className="flex max-w-full flex-1 flex-col">
            {(confirmedServiceSelection || !isNewAppointment) && (
              <SelectedServiceList
                services={services}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                isPreScheduled={appointmentData.isPreScheduled}
                isNewAppointment={isNewAppointment}
              />
            )}
            <DateTimeSection
              date={appointmentData.startTime}
              startTime={dateObject?.format("HH:mm") ?? "00:00"}
              endTime={endTime ?? "00:00"}
              handleDateChange={handleDateChange}
              handleStartTimeChange={handleStartTimeChange}
              handleEndTimeChange={handleEndTimeChange}
              isPreScheduled={appointmentData.isPreScheduled}
            />
            {getContent()}
          </div>
          {!!isPreScheduled && (
            <CustomerList
              appointmentId={appointmentId}
              customers={appointmentData.customers}
              selectedUserAppointment={selectedUserAppointment}
              handleUserAppointmentSelect={handleUserAppointmentSelect}
              IS_GLOWUP_USER={IS_GLOWUP_USER || IS_ILLUME_USER}
              openCustomerCard={openCustomerCard}
              fetchAppointmentData={fetchAppointmentData}
            />
          )}
        </Flex>
      )}
    </>
  );
};

const PaymentDetails = ({ customer }) => {
  if (customer == null || customer.paymentStatus == null) {
    return null;
  }

  return (
    <LimeAccordion
      itemClasses={{
        base: cn("px-3 rounded-xl", {
          "bg-success-50": customer.paymentInfo.paymentStatus === "succeeded",
          "bg-warning-50":
            customer.paymentInfo.paymentStatus === "requires_payment_method",
        }),
      }}
    >
      <AccordionItem
        key={"payment"}
        title={
          <div
            className={cn("flex items-center gap-1 text-xs font-medium", {
              "text-success-800":
                customer.paymentInfo.paymentStatus === "succeeded",
              "text-warning-800":
                customer.paymentInfo.paymentStatus ===
                "requires_payment_method",
            })}
          >
            {customer.paymentInfo.paymentStatus === "succeeded" ? (
              <Check size={16} className="mr-3" />
            ) : (
              <TriangleAlert size={16} className="mr-3" />
            )}
            <p>
              <Trans>Spletno plačilo</Trans>
            </p>
          </div>
        }
      >
        <div className="flex flex-col gap-1">
          <div className="flex gap-2">
            <p className="text-xs font-medium">Stanje</p>
            <p className="text-xs">
              {customer.paymentInfo.paymentStatus === "succeeded"
                ? t`Termin potrjen s plačilom preko spleta.`
                : t`Plačilo termina v obdelavi`}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="text-xs font-medium">Številka</p>
            <p className="text-xs">{customer.userAppointmentId}</p>
          </div>
          {customer.paymentInfo.amountPaid != null &&
          customer.paymentInfo.leftToPay != null ? (
            <>
              <div className="flex gap-2">
                <p className="text-xs font-medium">Že plačano</p>
                <p className="text-xs">{customer.paymentInfo.amountPaid}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-xs font-medium">Ostanek za plačilo</p>
                <p className="text-xs">{customer.paymentInfo.leftToPay}</p>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-2">
                <p className="text-error-800 text-xs font-medium">
                  <Trans>Podrobnosti o plačilu niso na voljo</Trans>
                </p>
              </div>
            </>
          )}
        </div>
      </AccordionItem>
    </LimeAccordion>
  );
};

export default AppointmentModal;
