import {
  DateRangePicker,
  DateRangePickerProps,
  DateValue,
} from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";
import { t } from "@lingui/core/macro";

type LimeDateRangePickerProps<T extends DateValue> = Omit<
  MantineFormValues,
  "onChange"
> &
  DateRangePickerProps<T>;

export const LimeDateRangePicker = React.forwardRef(
  <T extends DateValue>(
    { error, ...props }: LimeDateRangePickerProps<T>,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <DateRangePicker<T>
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
      aria-label={
        (typeof props.label === "string" ? props.label : undefined) ??
        t`Razpon datuma`
      }
    />
  ),
);

LimeDateRangePicker.displayName = "LimeDateRangePicker";
