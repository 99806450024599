import { Text } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import styled from "styled-components";
import { calculateYOffsetForTime } from "../../../../../Utilities";
import { useCalendarStore } from "../../store/useCalendarStore";
import { getCalendarTimes } from "../../utils";
import DayColumn from "./DayColumn";
import DayName from "./DayName";

const DayWeekCalendar = () => {
  const { calendarData, filterItems, timeoffs, selectedLocationId } =
    useCalendarStore((state) => state);

  const [redLineData, setRedLineData] = useState({
    currentTime: dayjs().format("HH:mm"),
    yOffset: 0,
  });

  const updateCurrentTimeLineData = () => {
    const currentTime = dayjs().format("HH:mm");
    const newYoffset = calculateYOffsetForTime({
      time: currentTime,
      slotHeight: calendarData.slotHeight,
      startTime: calendarData.startTime,
      endTime: calendarData.endTime,
      timeStep: calendarData.timeStep,
    });
    setRedLineData({
      currentTime,
      yOffset: newYoffset,
    });
  };

  useEffect(() => {
    updateCurrentTimeLineData();
  }, [filterItems]);

  const redLineInterval = useInterval(() => {
    updateCurrentTimeLineData();
  }, 60000);

  const { startTime, endTime, timeStep, slotHeight } = calendarData;
  const times = useMemo(
    () => getCalendarTimes(startTime, endTime, timeStep),
    [startTime, endTime, timeStep],
  );

  useEffect(() => {
    updateCurrentTimeLineData();
    redLineInterval.start();
    return redLineInterval.stop;
  }, [calendarData]);

  return (
    <>
      <ScrollSync>
        <>
          <div className="top_row empty"></div>
          <ScrollSyncPane>
            <div className="top_row scroll_row" id="users_row">
              <DaysUsers isOnlyDay={calendarData.days?.length === 1}>
                {calendarData?.days?.map((day, index) => (
                  <DayName day={day} key={index} />
                ))}
              </DaysUsers>
            </div>
          </ScrollSyncPane>

          <div className="times_row">
            <TimeColumn slotHeight={slotHeight}>
              {times.map((time, index) => (
                <Text
                  h={"20px"}
                  key={index}
                  size={"xs"}
                  c={"#6c7386"}
                  fw={500}
                  pr={"4px"}
                >
                  {time}
                </Text>
              ))}
            </TimeColumn>
          </div>

          <ScrollSyncPane>
            <div
              style={{ paddingTop: "7px", position: "relative" }}
              className={"calendar_row scroll_row"}
              id={"calendar_row"}
            >
              <Days>
                {calendarData?.days?.map((day, index) => {
                  const isToday = dayjs(day.date).isSame(dayjs(), "day");
                  return (
                    <DayColumn
                      key={index}
                      day={day}
                      timeoffs={
                        timeoffs?.timeoffs?.filter((t) =>
                          dayjs(day.date).isBetween(
                            t.timeFrom,
                            t.timeTo,
                            "day",
                            "[]",
                          ),
                        ) || []
                      }
                      isOnlyDay={calendarData.days.length === 1}
                      redLineData={isToday ? redLineData : null}
                      selectedLocationId={selectedLocationId}
                    />
                  );
                })}
              </Days>
            </div>
          </ScrollSyncPane>
        </>
      </ScrollSync>
    </>
  );
};

const DaysUsers = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    !props.isOnlyDay &&
    `
      @media (max-width: 768px) {
        width: fit-content;
      }
    `}
`;

const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(${(props) => props.slotHeight}px - 20px);
  position: sticky;
  left: 0;
  background: white;
  z-index: 3;
  width: fit-content;
  padding-left: 5px;
`;

const Days = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export default DayWeekCalendar;
