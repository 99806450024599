import { getUserAppointmentCancelReasonOptions } from "@/constants/cancelReasons";
import { CustomerAppointmentCancelReason } from "@/server-types";
import { useState } from "react";
import { Flex, Button, Modal, Checkbox, TextInput } from "@mantine/core";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { apiRemoveCustomerFromPreScheduledAppointment } from "@/pages/Dashboard/Calendar/api/apiCalendar";
import { notifications } from "@mantine/notifications";
import { Select } from "@mantine/core";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";

export const RemoveCustomerFromAppointmentModal = ({
  appointmentId,
  userAppointmentId,
  isOpen,
  onClose,
}: {
  appointmentId: number;
  userAppointmentId: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { hasCancelNotification } = useCalendarStore((store) => store);
  const cancelReasonOptions = getUserAppointmentCancelReasonOptions();
  const [cancelReason, setCancelReason] =
    useState<CustomerAppointmentCancelReason>("NoReasonProvided");
  const [sendCancelNotifications, setSendCancelNotifications] = useState(true);
  const [customCancelReason, setCustomCancelReason] = useState("");

  const handleConfirm = async () => {
    const response = await apiRemoveCustomerFromPreScheduledAppointment(
      appointmentId,
      {
        userAppointmentIds: [userAppointmentId],
        sendCancelNotifications,
        cancelReason,
        customCancelReason,
      },
    ).catch((err: Error) => {
      notifications.show({
        message: err.message || t`Napaka pri odstranjevanju stranke`,
        color: "red",
      });
    });

    if (response && response.status !== 200) {
      notifications.show({
        message: t`Napaka pri odstranjevanju stranke`,
        color: "red",
      });
    } else {
      notifications.show({
        message: t`Stranka uspešno odstranjena`,
        color: "green",
      });
    }

    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t`Ali ste prepričani, da želite odstraniti stranko s termina?`}
      centered
      withCloseButton={false}
    >
      <Flex direction="column" align="flex-start" gap={10}>
        <Select
          label={t`Razlog za odstranitev`}
          data={cancelReasonOptions}
          value={cancelReason}
          onChange={(value) =>
            setCancelReason(value as CustomerAppointmentCancelReason)
          }
        />
        {hasCancelNotification && (
          <div className="mt-2 flex flex-col">
            <Checkbox
              label={t`Pošlji obvestila?`}
              checked={sendCancelNotifications}
              onChange={(e) => setSendCancelNotifications(e.target.checked)}
            />
          </div>
        )}

        {cancelReason === "Custom" && (
          <TextInput
            mt={"sm"}
            label={t`Vnesite razlog`}
            value={customCancelReason}
            onChange={(e) => setCustomCancelReason(e.currentTarget.value)}
          />
        )}
        <Flex mt="md" justify="flex-end" gap="sm">
          <Button variant="outline" onClick={onClose}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button color="red" onClick={handleConfirm} data-identifier="confirm-remove-customer">
            <Trans>Odstrani stranko</Trans>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default RemoveCustomerFromAppointmentModal;
