import { useState } from "react";
import { Modal, Flex } from "@mantine/core";
import { Select, SelectItem, Input, Checkbox, Button } from "@heroui/react";
import { notifications } from "@mantine/notifications";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { AppointmentCancelReason } from "@/server-types";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getAppointmentCancelReasonOptions } from "@/constants/cancelReasons";

type CancelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  appointmentId: number;
  isRepeating: boolean;
  isLinked: boolean;
};

export const AppointmentCancelModal = ({
  isOpen,
  onClose,
  appointmentId,
  isRepeating = false,
  isLinked = false,
}: CancelModalProps) => {
  const { hasCancelNotification, deleteAppointment } = useCalendarStore(
    (store) => store,
  );

  const cancelReasonOptions = getAppointmentCancelReasonOptions();

  const [deleteAllRepeating, setDeleteAllRepeating] = useState(false);
  const [deleteAllLinked, setDeleteAllLinked] = useState(true);
  const [sendCancelNotifications, setSendCancelNotifications] = useState(
    hasCancelNotification,
  );
  const [cancelReason, setCancelReason] =
    useState<AppointmentCancelReason>("NoReasonProvided");

  const [customCancelReason, setCustomCancelReason] = useState("");

  const handleConfirm = async () => {
    try {
      await deleteAppointment(
        appointmentId,
        deleteAllRepeating,
        deleteAllLinked,
        sendCancelNotifications,
        cancelReason,
        customCancelReason,
      );
      notifications.show({
        message: t`Termin uspešno izbrisan`,
      });
      onClose();
    } catch (e) {
      notifications.show({
        message: t`Napaka pri brisanju termina`,
        color: "red",
      });
    }
  };

  return (
    <Modal
      radius="md"
      opened={isOpen}
      onClose={onClose}
      title={t`Ali ste prepričani, da želite izbrisati termin?`}
      centered
      withCloseButton={false}
    >
      <Flex direction="column" align="flex-start" gap={10}>
        <Select
          className="max-w-xs"
          selectedKeys={[cancelReason]}
          maxListboxHeight={500}
          onChange={(e) =>
            setCancelReason(e.target.value as AppointmentCancelReason)
          }
          label={t`Izberite razlog za izbris`}
        >
          {cancelReasonOptions.map((option) => (
            <SelectItem key={option.value}>{option.label}</SelectItem>
          ))}
        </Select>
        {cancelReason === "Custom" && (
          <Input
            label={t`Vnesite razlog za odpoved`}
            value={customCancelReason}
            onChange={(e) => setCustomCancelReason(e.currentTarget.value)}
          />
        )}
        {isRepeating && (
          <Checkbox
            data-identifier="delete-all-appointments-checkbox"
            checked={deleteAllRepeating}
            onChange={(e) => setDeleteAllRepeating(e.currentTarget.checked)}
          >
            <Trans>Izbriši vse nadaljnje ponavljajoče termine?</Trans>
          </Checkbox>
        )}
        {isLinked && (
          <Checkbox
            checked={deleteAllLinked}
            onChange={(e) => setDeleteAllLinked(e.currentTarget.checked)}
          >
            <Trans>Izbriši vse povezane termine?</Trans>
          </Checkbox>
        )}
        {hasCancelNotification && (
          <Checkbox
            checked={sendCancelNotifications}
            onChange={(e) =>
              setSendCancelNotifications(e.currentTarget.checked)
            }
          >
            <Trans>Pošlji obvestila?</Trans>
          </Checkbox>
        )}
      </Flex>

      <Flex justify="flex-end" gap="md" mt="lg">
        <Button onPress={onClose}>
          <Trans>Prekliči</Trans>
        </Button>
        <Button
          color="danger"
          onPress={handleConfirm}
          disabled={!cancelReason}
          data-identifier="confirm-cancel-appointment-button"
        >
          <Trans>Izbriši</Trans>
        </Button>
      </Flex>
    </Modal>
  );
};

export default AppointmentCancelModal;
