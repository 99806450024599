import { GetGiftCards } from "@/server-types";
import { Chip } from "@heroui/react";
import { Trans } from "@lingui/react/macro";
import { Check, OctagonAlert, X } from "lucide-react";

export const getPaymentStatusIcon = (
  status: GetGiftCards["response"]["giftCards"][number]["paymentStatus"],
  showLabel?: boolean,
) => {
  switch (status) {
    case "paid":
      return (
        <Chip
          color="success"
          variant="flat"
          size="sm"
          startContent={showLabel ? <Check size={16} /> : undefined}
        >
          {showLabel ? (
            <span>
              <Trans>Plačano</Trans>
            </span>
          ) : (
            <Check size={16} />
          )}
        </Chip>
      );
    case "partially_paid":
      return (
        <Chip
          color="warning"
          variant="flat"
          size="sm"
          startContent={showLabel ? <OctagonAlert size={16} /> : undefined}
        >
          {showLabel ? (
            <span>
              <Trans>Delno plačano</Trans>
            </span>
          ) : (
            <OctagonAlert size={16} />
          )}
        </Chip>
      );
    case "not_paid":
      return (
        <Chip
          color="danger"
          variant="flat"
          size="sm"
          startContent={showLabel ? <X size={16} /> : undefined}
        >
          {showLabel ? (
            <span>
              <Trans>Ni plačano</Trans>
            </span>
          ) : (
            <X size={16} />
          )}
        </Chip>
      );
    default:
      return null;
  }
};
