import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { cn } from "@/utils";
import { motion, AnimatePresence } from "framer-motion";
import { Button, Divider, Skeleton, useDisclosure } from "@heroui/react";
import { Edit, Ellipsis, Pencil } from "lucide-react";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { ExtraMenuButtons } from "./POS/ExtraMenuModal";
import { CategoryServicesDrawerProps } from "./POS/CategoryServicesDrawer";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { Article, POSForm } from "./POS/POS";
import { UseFormReturnType } from "@mantine/form";
import useFeature from "@/hooks/useFeature";
import { LimeCollapse } from "@/Components/NextBase/LimeCollapse";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { api } from "@/lib/api-client";
import {
  EntityType,
  ManageCustomerModal,
} from "@/Components/NextBase/ManageCustomerModal";

export type BottomActionsProps = {
  bottomActionsRef: React.RefObject<HTMLDivElement | null>;
  handleCategoryClick: (
    categoryId?: number,
    categoryType?: CategoryServicesDrawerProps["preSelectedCategoryType"],
  ) => void;
  categories?: {
    tagId: number;
    localizedName: string;
    color: string | null;
    type: "service" | "product";
  }[];
  selectedArticle?: Article;
  form: UseFormReturnType<POSForm>;
  customerName?: string;
  locationLabel?: string;
  canCreateInvoice: boolean;
  isCreatingInvoice: boolean;
  totalDiscountedInvoicePriceCents: number | undefined;
  totalInvoicePriceFormatted: string;
  totalDiscountedInvoicePriceFormatted: string | undefined;

  handleDeleteArticle: () => void;
  handleChangeArticleQuantity: (shouldIncrease: boolean) => void;
  handleDiscountArticle: () => void;
  handleOpenExtraMenu: () => void;
  handleAddDiscountToInvoice: () => void;
  handlePickCustomer: () => void;
  handleSplitPayment: () => void;
  onGiftCardsClick: () => void;
  handleEntityUpdated: ({ type }: { type: EntityType }) => void;

  isPendingCategories: boolean;
  isPendingServices: boolean;

  postInvoiceErrorMessage: string | null;
};

export const BottomActions = ({
  bottomActionsRef,

  handleCategoryClick,
  categories,
  selectedArticle,
  customerName,
  canCreateInvoice,
  isCreatingInvoice,

  form,

  totalDiscountedInvoicePriceCents,
  totalInvoicePriceFormatted,
  totalDiscountedInvoicePriceFormatted,

  handleDeleteArticle,
  handleChangeArticleQuantity,
  handleDiscountArticle,
  handleOpenExtraMenu,
  handleAddDiscountToInvoice,
  handlePickCustomer,
  handleSplitPayment,
  onGiftCardsClick,
  handleEntityUpdated,

  isPendingCategories,
  isPendingServices,

  postInvoiceErrorMessage,
}: BottomActionsProps) => {
  const { isFeatureEnabled } = useFeature();

  const { isPrinting } = usePosPrinterStore((state) => state);

  const { data: posAuthData, isFetching: isFetchingPosAuth } =
    api.cookie.useGetPosToken();

  const {
    isOpen: isManageCustomerModalOpen,
    onOpen: onOpenManageCustomerModal,
    onClose: onCloseManageCustomerModal,
  } = useDisclosure();

  const { data: organization, isPending: isFetchingOrganization } =
    api.taxRegister.useGetOrganization({
      disabled: posAuthData == null || isFetchingPosAuth,
    });

  const isTrrReady =
    organization &&
    organization.IBAN &&
    organization.SWIFT &&
    organization.bank;

  const {
    entityType,
    entityId,
  }: {
    entityType: EntityType | null;
    entityId: number | null;
  } = (() => {
    const { customerId, companyId } = form.getValues();

    if (!customerId && !companyId)
      return {
        entityType: null,
        entityId: null,
      };

    if (customerId) {
      return {
        entityType: "physical",
        entityId: customerId,
      };
    }

    if (companyId) {
      return {
        entityType: "legal",
        entityId: companyId,
      };
    }

    return {
      entityType: null,
      entityId: null,
    };
  })();

  return (
    <>
      <div
        ref={bottomActionsRef}
        className={cn(
          "ease-lime-bezier-ease min-h-20 w-full bg-background transition-[width] duration-300",
          "lg:relative lg:block lg:h-full lg:flex-1", // desktop
        )}
      >
        <AnimatePresence>
          {selectedArticle != null && (
            <motion.div
              className="flex gap-1 p-2"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: 5,
                transition: {
                  type: "spring",
                  bounce: 0.4,
                  stiffness: 200,
                  damping: 20,
                },
              }}
              transition={{
                type: "spring",
                bounce: 0.6,
                stiffness: 250,
              }}
            >
              <Button
                variant="bordered"
                className="h-8 w-full lg:h-12"
                onPress={handleDeleteArticle}
                data-identifier="delete-selected-article"
              >
                <Trans>Izbriši</Trans>
              </Button>
              {selectedArticle.type !== "gift-card" ? (
                <>
                  <Button
                    variant="bordered"
                    className="h-8 w-8 lg:h-12"
                    data-identifier="selected-article-increase-quantity"
                    onPress={() => handleChangeArticleQuantity(false)}
                  >
                    -
                  </Button>
                  <Button
                    variant="bordered"
                    className="h-8 w-8 lg:h-12"
                    data-identifier="selected-article-decrease-quantity"
                    onPress={() => handleChangeArticleQuantity(true)}
                  >
                    +
                  </Button>
                  <Button
                    variant="bordered"
                    className="h-8 w-full lg:h-12"
                    data-identifier="add-discount-selected-article"
                    onPress={handleDiscountArticle}
                  >
                    <Trans>Popust</Trans>
                  </Button>
                </>
              ) : undefined}
            </motion.div>
          )}
        </AnimatePresence>

        {customerName && (
          <Button
            className="px-4 py-1 text-sm"
            variant="light"
            data-identifier="manage-assigned-customer"
            onPress={onOpenManageCustomerModal}
          >
            <Trans>Stranka</Trans>: {customerName}
          </Button>
        )}

        <Divider />

        <div className="flex justify-between px-4 py-2 md:px-8 md:py-4">
          <p className="font-medium">
            <Trans>Skupaj</Trans>:
          </p>
          <div className="flex items-center gap-2">
            <p
              className={cn("text-xl font-bold", {
                "text-md text-gray-500 line-through":
                  totalDiscountedInvoicePriceCents != null,
              })}
            >
              {totalInvoicePriceFormatted}
            </p>
            {totalDiscountedInvoicePriceFormatted != null && (
              <p className="text-xl font-bold">
                {totalDiscountedInvoicePriceFormatted}
              </p>
            )}
          </div>
        </div>

        <Divider />

        <div className="px-2 md:px-4">
          <LimeSwitch
            title={t`Pošlji račun prek e-pošte`}
            description={t`Fizična stranka mora biti dodeljena na račun`}
            isCompact
            data-identifier="send-receipt-via-email-toggle"
            {...form.getInputProps("sendReceiptViaEmail", {
              type: "checkbox",
            })}
          />

          <LimeCollapse
            isOpen={form.validateField("sendReceiptViaEmail").hasError}
          >
            <p className="text-sm font-medium text-danger">
              {form.errors.sendReceiptViaEmail}
            </p>
          </LimeCollapse>
        </div>

        <div className="grid w-full grid-cols-3 gap-1 px-2 py-4 md:px-4 lg:hidden">
          <ActionCategoryBlock
            name={t`Vsi artikli`}
            onClick={
              isPendingCategories || isPendingServices
                ? undefined
                : () => handleCategoryClick(undefined, "all")
            }
            dataIdentifier="mobile-category-all"
            color={null}
          />
          {isFeatureEnabled("GIFTCARD") ? (
            <ActionCategoryBlock
              name={t`Ustvari darilni bon`}
              onClick={
                isPendingCategories || isPendingServices
                  ? undefined
                  : () => onGiftCardsClick()
              }
              dataIdentifier="mobile-create-gift-card"
              color={null}
            />
          ) : undefined}
          {!isPendingCategories &&
            !isPendingServices &&
            categories
              ?.slice(0, 4)
              .map((category) => (
                <ActionCategoryBlock
                  key={`${category.type}-${category.tagId}`}
                  name={category.localizedName}
                  dataIdentifier={`mobile-category-${category.type}-${category.tagId}`}
                  onClick={() =>
                    handleCategoryClick(category.tagId, category.type)
                  }
                  color={category.color}
                />
              ))}

          {(isPendingCategories || isPendingServices) &&
            Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                className="h-12 w-full rounded-xl"
                key={`cat-skel-${index}`}
              />
            ))}
        </div>

        {postInvoiceErrorMessage && (
          <>
            <div className="px-2 py-4 md:px-4">
              <LimeAlert color="danger" message={postInvoiceErrorMessage} />
            </div>

            <Divider />
          </>
        )}

        <div
          className={
            "flex gap-2 border-t border-t-divider px-2 py-2 md:border-t-0 md:px-4 lg:grid lg:grid-cols-3"
          }
        >
          <Button
            color="default"
            className="h-12 p-0 lg:hidden"
            isIconOnly
            onPress={handleOpenExtraMenu}
            data-identifier="open-extra-menu"
          >
            <Ellipsis />
          </Button>
          <Button
            className="h-12 w-full lg:h-16"
            type="submit"
            isDisabled={!canCreateInvoice}
            isLoading={isCreatingInvoice || isPrinting}
            value={"card"}
            color="primary"
            data-identifier="issue-invoice-with-card"
          >
            <Trans>Kartica</Trans>
          </Button>
          <Button
            className="h-12 w-full lg:h-16"
            type="submit"
            isDisabled={!canCreateInvoice}
            isLoading={isCreatingInvoice || isPrinting}
            value={"cash"}
            color="primary"
            data-identifier="issue-invoice-with-cash"
          >
            <Trans>Gotovina</Trans>
          </Button>
          {isTrrReady ? (
            <Button
              className="h-12 w-full lg:h-16"
              type="submit"
              isDisabled={!canCreateInvoice || !isTrrReady}
              isLoading={isCreatingInvoice || isPrinting}
              value={"bank"}
              color="primary"
              data-identifier="issue-invoice-with-bank"
            >
              <Trans>TRR</Trans>
            </Button>
          ) : undefined}
          <ExtraMenuButtons
            className="hidden lg:block lg:h-16"
            canCreateInvoice={canCreateInvoice}
            handleAddDiscountToInvoice={handleAddDiscountToInvoice}
            handlePickCustomer={handlePickCustomer}
            handleSplitPayment={handleSplitPayment}
          />
        </div>
      </div>

      <ManageCustomerModal
        handleClose={({ customerId, companyId }) => {
          onCloseManageCustomerModal();

          if (!customerId && !companyId) return;

          handleEntityUpdated({
            type: customerId != null ? "physical" : "legal",
          });
        }}
        isOpen={isManageCustomerModalOpen}
        selectedEntityType={entityType ?? "physical"}
        editingEntityId={entityId ?? undefined}
      />
    </>
  );
};

const ActionCategoryBlock = ({
  name,
  onClick,
  color,
  dataIdentifier,
}: {
  name: string;
  onClick?: () => void;
  color: string | null;
  dataIdentifier?: string;
}) => {
  return (
    <Button
      className={cn(
        "h-12 w-full bg-slate-200 px-4 text-xs font-medium leading-3",
      )}
      onPress={onClick}
      isDisabled={onClick == null}
      style={{
        backgroundColor: color || undefined,
        textWrap: "wrap",
      }}
      data-identifier={dataIdentifier}
    >
      <p>{name}</p>
    </Button>
  );
};
