import { Trans } from "@lingui/react/macro";
import { TextVariant } from "@/types/text-variants";
import { useDraggable } from "@dnd-kit/core";
import { useLingui } from "@lingui/react";
import { Flex, Popover, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { BsCurrencyEuro } from "react-icons/bs";
import styled from "styled-components";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getStatusIcon } from "../../../utils";
import useDrag from "../../hooks/useDrag";

import {
  returnHeightOfEventBlock,
  returnYoffsetForEvent,
} from "@/utils/appointment-utils";
import { HoverContent } from "./HoverContent";
import { FiGlobe } from "react-icons/fi";

function getInitials(name) {
  if (!name) return "";
  const cleanName = name.replace(/[^a-zA-Z0-9\s]/g, "");
  const words = cleanName.trim().split(/\s+/);
  return words
    .slice(0, 2)
    .map((word) => word[0].toUpperCase())
    .join("");
}

const AppointmentBlock = ({
  appointment,
  resource,
  user = null,
  usersInOrder = null,
  resourcesInOrder,
  columnDate,
}) => {
  const {
    appointmentId,
    formattedData,
    serviceColor,
    customers,
    serviceId,
    isPreScheduled,
    overlapIndex,
    permission,
    managePermission,
    numberOfNextResources,
    numberOfNextUsers,
    blockBeforeMins,
    attendance,
    isTimeoffBlockingResource,
    hoverDate: appointmentDate,
  } = appointment;

  const {
    calendarView,
    setSelectedAppointmentId,
    viewMode,
    setHideModalTabs,
    services,
    users,
    resources,
    calendarMode,
    setLastClickYOffset,
    lastClickYOffset,
  } = useCalendarStore((state) => state);

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const { startTime, endTime } = formattedData;

  const service = useCalendarStore((state) =>
    state.services.find((service) => service.serviceId === serviceId),
  );

  const { calendarData, draggingId } = useCalendarStore((state) => state);

  const { calculateTimeForDragYOffset } = useDrag();

  const isShown = appointment.filterEnabled;

  const locationStart = dayjs(`${columnDate}T${calendarData.startTime}`);
  const locationEnd = dayjs(`${columnDate}T${calendarData.endTime}`);
  const appointmentStart = dayjs(`${appointment.date}T${startTime}`);

  const isAppointmentOverMultipleDays =
    appointmentStart
      .add(appointment.duration, "minutes")
      .format("YYYY-MM-DD") !== appointmentStart.format("YYYY-MM-DD");

  const appointmentStartInDay = (() => {
    if (dayjs(`${appointment.date}T${startTime}`).isBefore(locationStart)) {
      return locationStart;
    }

    return dayjs(`${appointment.date}T${startTime}`);
  })();

  const yOffset = returnYoffsetForEvent({
    calendarStartTime: calendarData.startTime,
    startTimeEvent: appointmentStartInDay,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${appointmentId}-${user || resource}-${columnDate}`,
    data: {
      appointment,
      currentResourceId: resource,
      currentResources: resourcesInOrder?.slice(
        resourcesInOrder.indexOf(resource),
        resourcesInOrder.indexOf(resource) + numberOfNextResources,
      ),
      currentUsers: usersInOrder?.slice(
        usersInOrder.indexOf(user),
        usersInOrder.indexOf(user) + numberOfNextUsers,
      ),
      currentUserId: user,
      isOverMultipleResources: numberOfNextResources > 1,
      isOverMultipleUsers: numberOfNextUsers > 1,
      yOffset,
    },
    disabled: !managePermission,
  });

  if (draggingId === appointmentId && !transform) {
    return null;
  }

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y + lastClickYOffset}px, 0)`,
        zIndex: 99999,
      }
    : undefined;

  const MAX_TOTAL_WIDTH = 90;
  const {
    width: initialWidth,
    xOffset: initialXOffset,
    zIndex: initialZIndex,
  } = (() => {
    if (appointment.overlapCount === 1) {
      return {
        width: MAX_TOTAL_WIDTH,
        xOffset: 0,
        zIndex: 100,
      };
    } else {
      return {
        width: MAX_TOTAL_WIDTH - overlapIndex * 25,
        xOffset: appointment.overlapIndex * 25,
        zIndex: appointment.overlapIndex,
      };
    }
  })();

  const width = (() => {
    if (numberOfNextResources > 1) {
      return 100 - initialXOffset + (numberOfNextResources - 1) * 100;
    }
    if (numberOfNextUsers > 1) {
      return 100 - initialXOffset + (numberOfNextUsers - 1) * 100;
    }
    return initialWidth;
  })();

  const xOffset = initialXOffset;
  const zIndex = initialZIndex;

  const spanCount =
    numberOfNextResources > 1
      ? numberOfNextResources
      : numberOfNextUsers > 1
        ? numberOfNextUsers
        : 1;

  const hoverWidth = spanCount * 100;

  const serviceName =
    services.find((s) => s.serviceId === serviceId)?.name || "";

  if (serviceName) {
    appointment.serviceName = serviceName;
  }

  const onClickAppointment = (e) => {
    e.stopPropagation();

    if (!permission) {
      return;
    }

    setHideModalTabs(true);
    setSelectedAppointmentId(appointmentId);
    setPopoverOpen(false);
  };

  const appointmentEndInDay = (() => {
    if (
      dayjs(`${appointment.date}T${startTime}`)
        .add(appointment.duration, "minutes")
        .isAfter(locationEnd)
    ) {
      return locationEnd;
    }

    return dayjs(`${appointment.date}T${startTime}`).add(
      appointment.duration,
      "minutes",
    );
  })();

  const calculatedDuration = Math.max(
    appointmentEndInDay.diff(appointmentStartInDay, "minutes"),
    0,
  );

  const startDuration = Math.max(
    dayjs
      .min([
        appointmentStart.add(appointment.timeOffStart, "minutes"),
        locationEnd,
      ])
      .diff(appointmentStartInDay, "minutes"),
    0,
  );

  const timeoffDuration = appointment.timeOffDuration
    ? dayjs
        .min([
          appointmentStart
            .add(appointment.timeOffStart, "minutes")
            .add(appointment.timeOffDuration, "minutes"),
          locationEnd,
        ])
        .diff(appointmentStartInDay.add(startDuration, "minutes"), "minutes")
    : 0;

  const timeoffEndDuration = appointment.timeOffDuration
    ? dayjs
        .min([
          appointmentStart
            .add(appointment.timeOffStart, "minutes")
            .add(appointment.timeOffDuration, "minutes")
            .add(appointment.timeOffEnd, "minutes"),
          locationEnd,
        ])
        .diff(
          appointmentStartInDay
            .add(timeoffDuration, "minutes")
            .add(startDuration, "minutes"),
          "minutes",
        )
    : 0;

  const upperBlockHeight =
    transform && isAppointmentOverMultipleDays
      ? 100
      : returnHeightOfEventBlock({
          duration: startDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const shouldDisplayServiceName = upperBlockHeight > 50;
  const shouldDisplayTime = upperBlockHeight > 30;
  const timeoffHeight =
    transform && isAppointmentOverMultipleDays
      ? 0
      : returnHeightOfEventBlock({
          duration: timeoffDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const lowerBlockHeight =
    transform && isAppointmentOverMultipleDays
      ? 0
      : returnHeightOfEventBlock({
          duration: timeoffEndDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const totalHeight =
    transform && isAppointmentOverMultipleDays
      ? 100
      : returnHeightOfEventBlock({
          duration: calculatedDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const blockBeforeHeight = returnHeightOfEventBlock({
    duration: appointment.blockBeforeMins,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  const blockAfterHeight = returnHeightOfEventBlock({
    duration: appointment.blockAfterMins,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  let upperContent;
  let lowerContent = null;

  const isTimeoffBlockingRelevant =
    isTimeoffBlockingResource && calendarMode === "resources";

  const heightTotal = totalHeight;
  const topBlockHeight = isTimeoffBlockingRelevant
    ? heightTotal
    : upperBlockHeight;
  const bottomBlockHeight = isTimeoffBlockingRelevant ? null : lowerBlockHeight;
  const bottomYOffset = isTimeoffBlockingRelevant ? null : timeoffHeight;

  const dragTime = calculateTimeForDragYOffset(
    yOffset + transform?.y + lastClickYOffset,
  );

  const draggingContent = (
    <div className="z-[999999] flex h-full w-full items-start justify-start p-2 text-[0.8rem] font-medium md:items-center md:justify-center md:p-0">
      <div>
        {isAppointmentOverMultipleDays ? (
          <div className="flex flex-col items-center gap-0">
            <span>
              {dayjs(`${appointmentDate}T${dragTime}`).format("DD. MM. HH:mm")}
            </span>
            <span>-</span>
            <span>
              {dayjs(`${appointmentDate}T${dragTime}`)
                .add(appointment.duration, "minutes")
                .format("DD. MM. HH:mm")}
            </span>
          </div>
        ) : (
          <span>{dragTime}</span>
        )}
      </div>
    </div>
  );

  if (transform) {
    upperContent = draggingContent;
  } else if (isPreScheduled) {
    upperContent = (
      <PreScheduledAppointmentContent
        appointment={appointment}
        view={calendarView}
        color={appointment ? serviceColor : "grey"}
        shown={isShown}
        showUsers={permission}
      />
    );
  } else {
    const { upperBlockContent, lowerBlockContent } =
      SingleUserAppointmentContent({
        shouldDisplayTime,
        shouldDisplayServiceName,
        onClickAppointment,
        appointment,
        view: calendarView,
        color: appointment ? serviceColor : "grey",
        shown: isShown,
        showUsers: permission,
        calendarMode,
        topBlockHeight,
      });
    upperContent = upperBlockContent;
    lowerContent = lowerBlockContent;
  }

  const dateTime = appointment.date + "T" + startTime;
  const isInPast = Date.parse(dateTime) < Date.now();

  if (service == null) {
    return null;
  }

  let cursor = "pointer";
  if (transform) cursor = "move";
  if (!permission) cursor = "default";

  return (
    <>
      {blockBeforeMins > 0 && (
        <BlockTime
          xOffset={`${xOffset}%`}
          transformStyle={
            transform
              ? `translate3d(${transform.x}px, ${transform.y + lastClickYOffset}px, 0)`
              : undefined
          }
          top={yOffset - blockBeforeHeight}
          height={blockBeforeHeight}
          width={`${width}%`}
          serviceColor={serviceColor}
        />
      )}
      <div
        role="presentation"
        onMouseDown={(event) => {
          if (!isAppointmentOverMultipleDays) return;
          const parentElement = event.target.parentElement;
          const parentRect = parentElement.getBoundingClientRect();
          const offsetY = event.clientY - parentRect.top;
          setLastClickYOffset(offsetY);
        }}
      >
        <Popover
          opened={popoverOpen}
          radius={8}
          position="right-start"
          disabled={transform || window.innerWidth < 768}
          shadow={"md"}
          withinPortal={true}
          withArrow
        >
          <Popover.Target>
            <BlockWrapper
              hasTimeOff={appointment.timeOffDuration > 0}
              overlapIndex={overlapIndex}
              width={width}
              hoverWidth={hoverWidth}
              zIndex={zIndex}
              xOffset={xOffset}
              ref={setNodeRef}
              style={style}
              {...listeners}
              {...attributes}
              height={heightTotal}
              lowerBlockHeight={bottomBlockHeight}
              lowerYoffset={bottomYOffset}
              upperBlockHeight={topBlockHeight}
              yOffset={yOffset}
              color={serviceColor}
              isShown={viewMode === "appointments" && isShown}
              cursor={cursor}
              isPreScheduled={isPreScheduled}
              isPreScheduledFull={
                isPreScheduled &&
                appointment.customers.length >= appointment.numberOfPeople
              }
              isInPast={isInPast}
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const clickY = e.clientY - rect.top;
                const upperBlockEnd = topBlockHeight;
                const lowerBlockStart = upperBlockEnd + bottomYOffset;

                if (clickY > upperBlockEnd && clickY < lowerBlockStart) {
                  return;
                }

                e.stopPropagation();
                if (!permission) return;
                setHideModalTabs(true);
                setSelectedAppointmentId(appointmentId);
                setPopoverOpen(false);
              }}
              className={popoverOpen ? "hovered" : ""}
            >
              {appointment.customers.length === 1 &&
                appointment.customers[0].customerDidAttend === -1 && (
                  <NoShowOverlay>
                    <Text
                      variant={TextVariant.CaptionEmphasized}
                      fw={"bolder"}
                      c="black"
                      className="uppercase"
                    >
                      <Trans>Neprihod</Trans>
                    </Text>
                  </NoShowOverlay>
                )}

              <UpperBlock
                role={"presentation"}
                className={"block-part"}
                onMouseEnter={(e) => {
                  const rect = e.currentTarget.getBoundingClientRect();
                  const mouseY = e.clientY - rect.top;
                  if (mouseY <= topBlockHeight) {
                    setPopoverOpen(true);
                  }
                }}
                onMouseLeave={() => setPopoverOpen(false)}
                height={topBlockHeight}
                zIndex={zIndex}
                color={serviceColor}
                isShown={viewMode === "appointments" && isShown}
                isPreScheduled={isPreScheduled}
              >
                {upperContent}
              </UpperBlock>
              <Space h={`${bottomYOffset}px`} className="time-off-block" />
              <LowerBlock
                role="presentation"
                className={"block-part"}
                onMouseEnter={(e) => {
                  const rect = e.currentTarget.getBoundingClientRect();
                  const mouseY = e.clientY - rect.top;
                  if (mouseY >= topBlockHeight + bottomYOffset) {
                    setPopoverOpen(true);
                  }
                }}
                onMouseLeave={() => setPopoverOpen(false)}
                height={bottomBlockHeight}
                zIndex={zIndex}
                color={serviceColor}
                isShown={viewMode === "appointments" && isShown}
                isPreScheduled={isPreScheduled}
              >
                {lowerContent}
              </LowerBlock>
            </BlockWrapper>
          </Popover.Target>
          <Popover.Dropdown
            style={{
              borderLeft: "1px solid #eaeaea",
            }}
          >
            <HoverContent
              resources={resources}
              users={users}
              appointment={appointment}
              viewCustomersPermission={permission}
            />
          </Popover.Dropdown>
        </Popover>
      </div>

      {blockAfterHeight > 0 && (
        <BlockTime
          xOffset={`${xOffset}%`}
          transformStyle={
            transform
              ? `translate3d(${transform.x}px, ${transform.y + lastClickYOffset}px, 0)`
              : undefined
          }
          top={yOffset + heightTotal}
          height={blockAfterHeight}
          width={`${width}%`}
          serviceColor={serviceColor}
        />
      )}
    </>
  );
};

const UserResourceAvatar = ({ appointment, calendarMode }) => {
  const shouldDisplayResources =
    calendarMode === "users" && appointment.resources?.length > 0;
  const shouldDisplayUsers =
    calendarMode === "resources" && appointment.userIds?.length > 0;

  if (!shouldDisplayResources && !shouldDisplayUsers) {
    return null;
  }

  const items =
    calendarMode === "users" ? appointment.resources : appointment.userIds;

  return (
    <div className="absolute bottom-1 right-1 z-[1000]">
      <div className="flex items-center gap-[1px]">
        {items.map((id, index) => {
          const foundItem =
            calendarMode === "users"
              ? useCalendarStore.getState().resources.find((r) => r.id === id)
              : useCalendarStore.getState().users.find((u) => u.userId === id);

          const initials =
            calendarMode === "users"
              ? getInitials(foundItem?.label)
              : getInitials(`${foundItem?.name} ${foundItem?.lastName || ""}`);

          return (
            <div
              key={index}
              className="relative -ml-1.5 flex h-5 w-5 items-center justify-center overflow-hidden rounded-full bg-[#EBF8EF] text-[10px] font-medium text-gray-700 first:ml-0"
            >
              {calendarMode === "resources" && foundItem?.fullImageUrl ? (
                <img
                  src={foundItem.fullImageUrl}
                  alt={`${foundItem.name} ${foundItem.lastName || ""}`}
                  className="h-full w-full object-cover"
                />
              ) : (
                <span className="text-[10px]">{initials}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SingleUserAppointmentContent = ({
  onClickAppointment,
  appointment,
  view,
  color,
  shown,
  showUsers,
  calendarMode,
  shouldDisplayServiceName,
  topBlockHeight,
  shouldDisplayTime,
}) => {
  const customer = appointment?.customers?.at(0);
  const { startTime, endTime } = appointment.formattedData;

  const bookingIcon = customer?.bookedFromForm ? (
    <FiGlobe size=".8rem" />
  ) : null;

  const paymentIcon = customer?.paymentStatus ? (
    customer.paymentStatus === "succeeded" ? (
      <BsCurrencyEuro size=".8rem" />
    ) : (
      getStatusIcon(
        customer.paymentStatus === "succeeded" ? "success" : "warning",
      )
    )
  ) : null;

  const fullTimeRange = `${startTime} - ${endTime}`;

  let upperTimeRange = fullTimeRange;
  let lowerTimeRange = "";
  if (appointment.timeOffStart && appointment.timeOffDuration) {
    const serviceStartTime = startTime;
    const timeOffStartTime = dayjs(serviceStartTime, "HH:mm")
      .add(appointment.timeOffStart, "minute")
      .format("HH:mm");
    const timeOffEndTime = dayjs(serviceStartTime, "HH:mm")
      .add(appointment.timeOffStart + appointment.timeOffDuration, "minute")
      .format("HH:mm");
    const serviceEndTime = endTime;
    upperTimeRange = `${serviceStartTime} - ${timeOffStartTime}`;
    lowerTimeRange = `${timeOffEndTime} - ${serviceEndTime}`;
  }

  const lowerBlockTimeDisplay =
    appointment.timeOffStart && appointment.timeOffDuration
      ? lowerTimeRange
      : "";

  const upperBlockContent = (
    <>
      <AppointmentText
        view={view}
        color={appointment ? appointment.serviceColor : color}
      >
        {shown && appointment && (
          <>
            {appointment.hideData && (
              <span className="truncate text-[10px] leading-[1px] text-[#262626]">
                {shouldDisplayServiceName && appointment.serviceName}{" "}
              </span>
            )}
            <Flex w={"100%"} align={"flex-start"}>
              {!appointment.hideData && showUsers && (
                <div className="leading-none">
                  <span
                    className={`text-[12px] font-semibold leading-[1px] text-[#262626] ${topBlockHeight <= 60 ? "truncate" : ""}`}
                  >
                    {`${customer?.name} ${customer?.lastName || ""}`}
                  </span>
                </div>
              )}
            </Flex>
            {shouldDisplayTime && (
              <span className="flex w-full items-center gap-1 whitespace-nowrap text-left text-[10px]">
                {paymentIcon && <span>{paymentIcon}</span>}
                {bookingIcon && <span>{bookingIcon}</span>}
                {upperTimeRange}
              </span>
            )}

            {!lowerBlockTimeDisplay && (
              <>
                <span className="truncate text-[10px] text-[#262626]">
                  {shouldDisplayServiceName && appointment.serviceName}{" "}
                </span>

                <Text
                  size={"10px"}
                  ta={"left"}
                  style={{
                    padding: "0px 2px",
                    backgroundColor: `${appointment.serviceColor}50`,
                    display: "block",
                    width: "100%",
                  }}
                  fw={500}
                  c={"#262626"}
                >
                  {customer?.comment}
                </Text>
              </>
            )}
          </>
        )}
      </AppointmentText>
      <UserResourceAvatar
        appointment={appointment}
        calendarMode={calendarMode}
      />
    </>
  );

  const lowerBlockContent = (
    <AppointmentText>
      {shown && appointment && (
        <>
          {lowerBlockTimeDisplay && shouldDisplayTime && (
            <span className="truncate text-[10px] text-[#424b64]">
              {lowerBlockTimeDisplay} {appointment.serviceName}
            </span>
          )}
        </>
      )}
    </AppointmentText>
  );

  return { upperBlockContent, lowerBlockContent };
};

const PreScheduledAppointmentContent = ({
  appointment,
  view,
  color,
  shown,
  showUsers,
}) => {
  const { startTime, endTime } = appointment.formattedData;
  return (
    <AppointmentText
      view={view}
      color={appointment ? appointment.serviceColor : color}
    >
      {shown && appointment && (
        <Flex direction={"column"} style={{ height: "100%" }}>
          <div>
            <Flex align={"center"} gap={".25rem"}>
              <Text size={"11px"} fw={600}>
                {appointment.customers.length}/{appointment.numberOfPeople}
              </Text>
              <span
                className={"w-full whitespace-nowrap text-left text-[10px]"}
                style={{ width: "fit-content" }}
              >
                {`${startTime} - ${endTime}`}
              </span>
            </Flex>
            <div>
              <Text size={"10px"} ta={"left"} fw={600} c={"#797878"}>
                {appointment.serviceName}
              </Text>
            </div>

            {!appointment.hideData && showUsers && (
              <div className="flex w-full flex-col items-start justify-start">
                {appointment.customers.map((customer, customerIndex) => {
                  const paymentIcon = customer?.paymentStatus ? (
                    customer.paymentStatus === "succeeded" ? (
                      <BsCurrencyEuro size=".8rem" />
                    ) : (
                      getStatusIcon(
                        customer.paymentStatus === "succeeded"
                          ? "success"
                          : "warning",
                      )
                    )
                  ) : null;

                  const bookingIcon = customer?.bookedFromForm ? (
                    <FiGlobe size=".7rem" />
                  ) : null;
                  return (
                    <div key={customerIndex} className="flex items-center">
                      <span className="whitespace-nowrap text-[10px] font-semibold leading-3 text-[#262626]">
                        {`${customer?.name} ${customer?.lastName}`}{" "}
                      </span>
                      {(paymentIcon != null || bookingIcon != null) && (
                        <div className="ml-1 flex h-[18px] items-center justify-center rounded-full text-[0.7rem]">
                          {paymentIcon} {bookingIcon}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Flex>
      )}
    </AppointmentText>
  );
};

const AppointmentText = styled.div`
  overflow: hidden;
  color: #424b64;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0;
  font-size: 12px;
  width: 100%;
  z-index: 3;
  position: relative;
  ${(props) =>
    props.view === "month" &&
    "display: -webkit-box;\n  -webkit-box-orient: vertical;\n    -webkit-line-clamp: 1;"};
  @media (max-width: 768px) {
    ${(props) => props.view === "month" && "font-size: 8px;"}
  }
`;

const UpperBlock = styled.div`
  z-index: ${(props) => props.zIndex};
  background: white;
  position: relative;
  height: ${(props) => `${props.height}px`};
  padding-left: 1px;
  border-radius: 4px;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  padding: 5px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 100%;
  transition: all 0.05s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${(props) => (props.isShown ? `${props.color}90` : "#bbbbbb")};
    ${({ isPreScheduled, color }) =>
      isPreScheduled &&
      `
        background: ${color}80;
      `}
    z-index: 1;
    transform: translateZ(0);
    transition: inherit;
  }
`;

const LowerBlock = styled.div`
  z-index: ${(props) => props.zIndex};
  background: white;
  position: relative;
  height: ${(props) =>
    props.height && props.height > 0 ? `${props.height}px` : 0};
  padding-left: 1px;
  border-radius: 4px;
  padding: clamp(
    0px,
    5px,
    ${(props) =>
      `clamp(0px, 5px, ${props.isShown && props.height > 10 ? "1%" : 0})`}
  );
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 100%;
  transition: all 0.05s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${(props) => (props.isShown ? `${props.color}90` : "#bbbbbb")};
    ${({ isPreScheduled, color }) =>
      isPreScheduled &&
      `
        background: ${color}80;
      `}
    z-index: 1;
    transform: translateZ(0);
    transition: inherit;
  }
`;

const BlockWrapper = styled.button`
  position: absolute;
  outline: 1px solid white;
  width: ${(props) => props.width}%;
  height: ${(props) => `${props.height}px`};
  top: ${(props) => `${props.yOffset}px`};
  left: ${(props) => `${props.xOffset}%`};
  overflow: visible;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform-origin: top left;
  transition: all 0.05s ease-in-out;

  z-index: ${(props) => props.zIndex};
  border-radius: 4px;
  pointer-events: ${(props) => (props.isShown ? "auto" : "none")};
  cursor: ${(props) => props.cursor};
  user-select: none;
  ${(props) => (!props.isShown ? "opacity: 0.3; border-radius: 0;" : "")}

  ${({ isPreScheduled, color, isPreScheduledFull }) =>
    isPreScheduled &&
    `
      outline: 2px solid ${color};
      outline-width: 2px 2px 2px 2px;
      outline-style: ${isPreScheduledFull ? "solid" : "dashed"};
      outline-radius: 4px;
    `};

  .time-off-block {
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${(props) => props.zIndex + 999999};
    background: #e7e7e8;
    opacity: ${(props) => (props.isInPast ? "0.4" : "0")};
    border-radius: 6px;
    pointer-events: none;
  }

  border-left: ${(props) =>
    props.hasTimeOff ? `3px solid ${props.color}` : "none"};

  &.hovered {
    width: ${(props) => props.hoverWidth}%;
    left: 0;
    outline: 1px solid black;
    z-index: ${(props) => props.zIndex + 1000};
  }
`;

const NoShowOverlay = ({ children }) => (
  <div className="absolute left-0 top-0 z-[99999] flex h-full w-full items-center justify-center rounded-sm bg-black bg-opacity-40">
    {children}
  </div>
);

const BlockTime = ({
  top,
  height,
  width,
  serviceColor,
  transformStyle,
  xOffset,
}) => {
  return (
    <div
      style={{
        top,
        left: xOffset,
        height,
        width,
        transform: transformStyle,
        borderRadius: "4px",
        backgroundImage: `repeating-linear-gradient(159.5deg, transparent, transparent 8px, ${serviceColor}30 8px, ${serviceColor}30 18px)`,
      }}
      className="absolute"
    />
  );
};

export default React.memo(AppointmentBlock);
