import { t } from "@lingui/core/macro";
import {
  LimePageHeader,
  SubPageRightSection,
} from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { api, queryClient } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { Box } from "@mantine/core";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router";
import useManageResourceGroup from "./useManageResourceGroup";

const getCurrentTab = (pathName: string) => {
  const splitPath = pathName.split("/");
  const resourcesIndex = splitPath.findIndex((p) => p === "resource-groups");
  const tab = splitPath[resourcesIndex + 2];

  return tab;
};

export const ManageResourceGroup = () => {
  const { id, resourceId } = useParams();
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const isOnboarding = searchParams.get("onboarding") != null;
  const navigate = useNavigate();
  const isEditing = id !== "-1" && !isOnboarding;

  const { alertModal } = useLimeAlertModal();

  const { handleBackButton, farthestTab, tabs } = useManageResourceGroup();

  const {
    mutateAsync: deleteResourceGroup,
    isPending: isDeletingResourceGroup,
  } = api.resourceGroups.useDeleteResourceGroup();

  const { mutateAsync: deleteResource, isPending: isDeletingResource } =
    api.resources.useDeleteResource();

  const { mutateAsync: putResource, isPending: isPuttingResource } =
    api.resourceGroups.usePutResourceGroup();

  const { data: resourceGroup } = api.resourceGroups.useGetResourceGroup(
    parseInt(id ?? "-1"),
  );

  const confirmResourceGroupDeletion = () => {
    if (!id || id === "-1") {
      return;
    }

    alertModal({
      title: resourceId
        ? t`Ali ste prepričani da želite izbrisati sredstev?`
        : t`Ali ste prepričani da želite izbrisati skupino sredstev?`,
      onConfirm: async () => {
        if (resourceId) {
          await deleteResource(parseInt(resourceId));
        } else {
          await deleteResourceGroup(parseInt(id));
        }

        handleBackButton({
          resourceId,
          tab: "resources",
          resourceGroupId: parseInt(id),
        });
      },
      confirmLabel: t`Izbriši`,
      isDanger: true,
    });
  };

  const onChangeTab = (newTab: string | null, resourceGroupId?: number) => {
    if (!newTab) {
      return;
    }

    const baseUrl = `/dashboard/resource-groups/${resourceGroupId ?? id}`;
    const isOnboarding =
      searchParams.get("onboarding") != null || resourceGroupId != null;
    navigate(`${baseUrl}/${newTab}${isOnboarding ? "?onboarding" : ""}`);
  };

  const tabsAfterFarthestTab = farthestTab
    ? tabs.slice(tabs.findIndex((t) => t.value === farthestTab) + 1)
    : tabs.filter((t) => t.value !== "general");

  const rightSectionOptions: SubPageRightSection["options"] = resourceId
    ? [
        {
          icon: <VscTrash />,
          label: t`Izbriši sredstvo`,
          color: Color.Error,
          onClick: confirmResourceGroupDeletion,
          loading: isDeletingResource,
        },
      ]
    : [
        {
          icon: resourceGroup?.isEnabled ? <BsEye /> : <BsEyeSlash />,
          label: resourceGroup?.isEnabled
            ? t`Deaktiviraj sredstvo`
            : t`Aktiviraj sredstvo`,
          onClick: async () => {
            if (!id) return;

            await putResource({
              resourceGroupId: parseInt(id),
              resourceGroup: {
                isEnabled: !resourceGroup?.isEnabled,
              },
            });

            queryClient.invalidateQueries({ queryKey: ["resourceGroups"] });
          },
          loading: isPuttingResource,
          closeMenuOnClick: false,
        },
        {
          icon: <VscTrash />,
          label: t`Izbriši sredstvo`,
          color: Color.Error,
          onClick: confirmResourceGroupDeletion,
          loading: isDeletingResourceGroup,
        },
      ];

  return (
    <>
      <LimePageHeader
        title={""}
        subPage={{
          title: isEditing ? t`Uredi sredstvo` : t`Dodaj sredstvo`,
          onBackButtonClick: () =>
            handleBackButton({
              resourceId,
              tab: "resources",
              resourceGroupId: id != null ? parseInt(id) : undefined,
            }),
          rightSection:
            id === "-1"
              ? undefined
              : {
                  options: rightSectionOptions,
                },
        }}
      />

      {!resourceId && (
        <LimePageTabs
          activeTab={getCurrentTab(location.pathname)}
          onChangeTab={onChangeTab}
          tabs={tabs.map((tab) => ({
            ...tab,
            disabled: !isEditing
              ? tabsAfterFarthestTab.findIndex((t) => t.value === tab.value) !==
                -1
              : false,
          }))}
        />
      )}

      <Box maw={"450px"} mx={"auto"}>
        <Outlet />
      </Box>
    </>
  );
};
