import { Trans } from "@lingui/react/macro";
import { getLegalPages } from "@/utils";
import { Divider, Link } from "@heroui/react";

const LegalPages = ({ language }) => {
  const { termsAndConditionsUrl, privacyPolicyUrl } = getLegalPages({
    language,
  });

  return (
    <div className="flex gap-3">
      <LegalPageLink href={"https://lime-booking.com/"}>
        © Lime Booking
      </LegalPageLink>
      <Divider orientation="vertical" />
      <LegalPageLink href={privacyPolicyUrl}>
        <Trans>Politika zasebnosti</Trans>
      </LegalPageLink>
      <Divider orientation="vertical" />
      <LegalPageLink href={termsAndConditionsUrl}>
        <Trans>Splošni pogoji</Trans>
      </LegalPageLink>
    </div>
  );
};

const LegalPageLink = ({ href, children }) => {
  return (
    <Link href={href} className="text-xs text-default-400" target="_blank">
      <Trans>{children}</Trans>
    </Link>
  );
};

export default LegalPages;
