import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  Button,
  DatePicker,
  Spinner,
} from "@heroui/react";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { api } from "@/lib/api-client";
import { notifications } from "@mantine/notifications";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import {
  DateValue,
  parseDate,
  getLocalTimeZone,
  today,
} from "@internationalized/date";
import { NewGiftCardData } from "./POS";
import { Check, X } from "lucide-react";
import dayjs from "dayjs";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

type GiftCardManageModalProps = {
  editingGiftCardId?: number;
  isOpen: boolean;
  handleClose: ({
    giftCardData,
    type,
  }: {
    giftCardData?: NewGiftCardData & {
      id?: number;
    };
    type?: "create" | "update";
  }) => void;
  isCreatingGiftCard?: boolean;
  createErrorMessage?: string | null;
  isUpdatingGiftCard?: boolean;
  updateErrorMessage?: string | null;
};

type Form = {
  code: string;
  initialAmount: number;
  note?: string;
  expiryDate: DateValue | null;
};

const defaultData: Form = {
  code: "",
  initialAmount: 0,
  note: "",
  expiryDate: parseDate(
    dayjs().add(1, "year").toDate().toISOString().split("T")[0],
  ),
};

export const GiftCardManageModal = ({
  editingGiftCardId,
  isOpen,
  handleClose,
  isCreatingGiftCard,
  createErrorMessage,
  isUpdatingGiftCard,
  updateErrorMessage,
}: GiftCardManageModalProps) => {
  const isEditing = !!editingGiftCardId;

  const {
    data: uniqueCodeData,
    refetch: refetchUniqueCode,
    isFetching: isFetchingUniqueCode,
    processedErrorMessage: uniqueCodeErrorMessage,
  } = api.giftCard.useGetUniqueGiftCardCode(isEditing || !isOpen);

  const {
    data: existingGiftCardData,
    isFetching: isFetchingGiftCard,
    processedErrorMessage: getGiftCardErrorMessage,
  } = api.giftCard.useGetGiftCard(editingGiftCardId);

  const giftCardForm = useForm<Form>({
    initialValues: defaultData,
    validateInputOnChange: true,
    validate: {
      code: (value) =>
        value.length < 3 ? t`Koda mora biti dolga vsaj 3 znake` : null,
      initialAmount: (value) =>
        value > 0 ? null : t`Začetni znesek mora biti večji od 0`,
      expiryDate: (value) => {
        if (!value) return t`Datum je obvezen`;

        if (value.compare(today(getLocalTimeZone())) < 0) {
          return t`Datum poteka ne sme biti v preteklosti`;
        }
      },
    },
  });

  const [debouncedCode] = useDebouncedValue(giftCardForm.values.code, 500);
  const {
    data: isGiftCodeUniqueData,
    isFetching: isVerifyingCode,
    processedErrorMessage: verifyCodeErrorMessage,
  } = api.giftCard.useGetVerifyCode(debouncedCode, isEditing);

  useEffect(() => {
    if (!isOpen || isEditing) return;

    refetchUniqueCode();
  }, [isOpen]);

  useEffect(() => {
    if (!uniqueCodeData || isEditing) return;

    giftCardForm.setFieldValue("code", uniqueCodeData.code);
  }, [uniqueCodeData]);

  useEffect(() => {
    if (!existingGiftCardData) return;

    giftCardForm.setValues({
      code: existingGiftCardData.code,
      initialAmount: existingGiftCardData.initialAmountCents / 100,
      note: existingGiftCardData.note || undefined,
      expiryDate: parseDate(
        dayjs(existingGiftCardData.expiryDate).format("YYYY-MM-DD"),
      ),
    });
  }, [existingGiftCardData]);

  const handleSubmit = async (values: Form) => {
    try {
      const newGiftCard: NewGiftCardData & {
        id?: number;
      } = {
        id: editingGiftCardId,
        key: "giftcard-" + values.code,
        code: values.code,
        initialAmountCents: values.initialAmount * 100,
        note: values.note || null,
        expiryDate: values.expiryDate!.toDate(getLocalTimeZone()),
      };

      handleClose({
        giftCardData: newGiftCard,
        type: isEditing ? "update" : "create",
      });

      giftCardForm.reset();
    } catch (e) {
      notifications.show({
        message: t`Napaka pri ustvarjanju darilnega bona.`,
        color: "red",
      });
    }
  };

  const getCodeEndContent = () => {
    if (isVerifyingCode || isFetchingUniqueCode) return <Spinner size="sm" />;

    if (!isGiftCodeUniqueData || giftCardForm.getValues().code.length < 5)
      return undefined;

    if (isGiftCodeUniqueData.isUnique) {
      return <Check color="green" size={20} />;
    }

    return <X color="red" size={20} />;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose({})}>
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            giftCardForm.validate();

            if (giftCardForm.isValid()) {
              handleSubmit(giftCardForm.getValues());
            }
          }}
        >
          <ModalHeader>
            {isEditing ? (
              <Trans>Uredi darilni bon</Trans>
            ) : (
              <Trans>Ustvari darilni bon</Trans>
            )}
          </ModalHeader>
          <ModalBody>
            <LimeInput
              label={t`Koda`}
              placeholder={t`Vnesite kodo darilnega bona`}
              {...giftCardForm.getInputProps("code")}
              required
              endContent={getCodeEndContent()}
              isDisabled={isEditing}
              data-identifier="manage-gift-card-code-input"
            />
            {isGiftCodeUniqueData && !isGiftCodeUniqueData.isUnique && (
              <p className="text-right text-xs text-danger">
                <Trans>Darilni bon s to kodo že obstaja</Trans>
              </p>
            )}
            <LimeAlert
              color="danger"
              message={verifyCodeErrorMessage}
              className="mt-2"
            />
            <LimeAlert
              color="danger"
              message={uniqueCodeErrorMessage}
              className="mt-2"
            />
            <LimeInput
              label={t`Vrednost`}
              {...giftCardForm.getInputProps("initialAmount")}
              type="number"
              required
              data-identifier="manage-gift-card-amount-input"
            />

            <DatePicker
              label={t`Datum poteka`}
              value={giftCardForm.values.expiryDate}
              onChange={(date) =>
                giftCardForm.setFieldValue("expiryDate", date)
              }
              minValue={today(getLocalTimeZone())}
            />

            <LimeInput
              label={t`Opomba`}
              {...giftCardForm.getInputProps("note")}
            />

            <LimeAlert
              color="danger"
              message={createErrorMessage || updateErrorMessage}
            />
            <LimeAlert color="danger" message={getGiftCardErrorMessage} />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              isLoading={isCreatingGiftCard || isUpdatingGiftCard}
              isDisabled={isFetchingGiftCard}
              data-identifier="manage-gift-card-confirm"
            >
              {isEditing ? (
                <Trans>Uredi bon</Trans>
              ) : (
                <Trans>Ustvari darilni bon</Trans>
              )}
            </Button>
            <Button
              color="default"
              onPress={() => handleClose({})}
              isDisabled={
                isCreatingGiftCard || isUpdatingGiftCard || isFetchingGiftCard
              }
            >
              <Trans>Prekliči</Trans>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
