import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { cn } from "@/utils";
import { getPaymentTypeLabel, PaymentType } from "@/utils/pos-printer/index";
import {
  Divider,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  Skeleton,
  Link,
} from "@heroui/react";

export const ClosingDetailsModal = ({
  businessPremiseId,
  closureId,
  handleClose,
}: {
  businessPremiseId: string;
  closureId?: number;
  handleClose: () => void;
}) => {
  const {
    data: closureData,
    isFetching: isFetchingClosureData,
    processedErrorMessage: getClosureErrorMessage,
  } = api.taxRegister.useGetCashRegisterClosure({
    businessPremiseId,
    closureId,
  });

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const hasData =
    closureData?.totalsByPaymentMethodFormatted ||
    Object.values(closureData?.documentsByUserAndPaymentMethodFormatted ?? {})
      .length > 0;

  const documentsByUserAndPaymentMethod = closureData
    ? Object.values(
        closureData.documentsByUserAndPaymentMethodFormatted || {},
      ).map((doc) => ({
        ...doc,
        isSkeleton: false,
      }))
    : [
        {
          name: "Loading",
          lastName: "User",
          payments: {
            card: {
              formatted: "",
            },
            cash: {
              formatted: "",
            },
            coupon: {
              formatted: "",
            },
            total: {
              formatted: "",
            },
          },
          products: {},
          productsTotal: {
            formatted: "",
          },
          services: {},
          servicesTotal: {
            formatted: "",
          },
          giftCards: {},
          giftCardsTotal: {
            formatted: "",
          },
          totalByTaxRate: {},
          isSkeleton: true,
        },
      ];

  const totalsByPaymentMethod = closureData?.totalsByPaymentMethodFormatted
    ? Object.entries(closureData?.totalsByPaymentMethodFormatted)
    : [];

  const totalsByTaxRateFormatted = closureData?.totalsByTaxRateFormatted
    ? closureData.totalsByTaxRateFormatted
    : {};

  const date =
    closureData?.info?.dateFrom === closureData?.info?.dateTo
      ? closureData?.info?.dateFrom
      : `${closureData?.info?.dateFrom} - ${closureData?.info?.dateTo}`;

  return (
    <Modal
      isOpen={closureId != null}
      onClose={handleClose}
      size="xl"
      scrollBehavior="outside"
    >
      <ModalContent>
        <ModalHeader>
          <Trans>Podrobnosti zaključka</Trans>
        </ModalHeader>
        <ModalBody className="mb-4">
          <LimeAlert color="danger" message={getClosureErrorMessage} />

          <Skeleton isLoaded={!isFetchingClosureData} className="rounded-lg">
            <p className="text-sm">
              <Trans>Datum:</Trans> {date}
            </p>
            <p className="text-sm">
              <Trans>Prvi račun:</Trans>{" "}
              {closureData?.info?.firstDocument?.number} -{" "}
              {closureData?.info?.firstDocument?.date}
            </p>
            <p className="text-sm">
              <Trans>Zadnji račun:</Trans>{" "}
              {closureData?.info?.lastDocument?.number} -{" "}
              {closureData?.info?.lastDocument?.date}
            </p>
          </Skeleton>

          {!hasData ? (
            <p className="text-sm text-gray-500">
              <Trans>Ni podatkov</Trans>
            </p>
          ) : undefined}

          <div className="flex flex-col gap-2">
            {permissions.view_cash_register_status_detailed_view ? (
              <>
                {documentsByUserAndPaymentMethod.map((doc) => (
                  <div
                    className="mb-4 rounded-lg bg-divider px-4 pb-4 pt-3"
                    key={`${doc.name} ${doc.lastName}`}
                  >
                    <Skeleton isLoaded={!doc.isSkeleton}>
                      <p className="mb-2 font-semibold">
                        {doc.name} {doc.lastName}
                      </p>
                    </Skeleton>
                    <div className="flex flex-col gap-1">
                      <p className="mt-2 text-sm font-semibold">
                        <Trans>Storitve</Trans>
                      </p>
                      {Object.values(doc.services).map((value) => (
                        <PaymentRow
                          label={value.name}
                          value={`${value.amount}x ${value.piecePrice} ; ${value.totalPrice}`}
                          isSkeleton={doc.isSkeleton}
                          key={`${value.name} ${value.piecePrice}`}
                        />
                      ))}
                      <PaymentRow
                        label={t`Skupaj storitve`}
                        value={doc.servicesTotal.formatted}
                        isSkeleton={doc.isSkeleton}
                        isTotal
                      />

                      <Divider className="mt-2 bg-gray-200" />

                      <p className="mt-2 text-sm font-semibold">
                        <Trans>Produkti</Trans>
                      </p>
                      {Object.values(doc.products).map((value) => (
                        <PaymentRow
                          label={value.name}
                          value={`${value.amount}x ${value.piecePrice} ; ${value.totalPrice}`}
                          isSkeleton={doc.isSkeleton}
                          key={`${value.name} ${value.piecePrice}`}
                        />
                      ))}
                      <PaymentRow
                        label={t`Skupaj produkti`}
                        value={doc.productsTotal.formatted}
                        isSkeleton={doc.isSkeleton}
                        isTotal
                      />

                      <Divider className="mt-2 bg-gray-200" />

                      <p className="mt-2 text-sm font-semibold">
                        <Trans>Izdani darilni boni</Trans>
                      </p>
                      {Object.values(doc.giftCards)
                        .filter((value) => !value.isThirdParty)
                        .map((value) => (
                          <PaymentRow
                            label={value.code}
                            value={`${value.initialAmount}`}
                            isSkeleton={doc.isSkeleton}
                            cardAmount={value.cardAmount}
                            cashAmount={value.cashAmount}
                            giftCardId={value.id}
                          />
                        ))}
                      <PaymentRow
                        label={t`Skupaj izdani darilni boni`}
                        value={doc.giftCardsTotal.formatted}
                        isSkeleton={doc.isSkeleton}
                        isTotal
                      />

                      <Divider className="my-2 bg-gray-300" />

                      {Object.entries(doc.payments)
                        .filter(
                          ([paymentMethod, _]) => paymentMethod !== "total",
                        )
                        .map(([paymentMethod, value]) => (
                          <PaymentRow
                            label={getPaymentTypeLabel(
                              paymentMethod as PaymentType,
                            )}
                            value={value.formatted}
                            isSkeleton={doc.isSkeleton}
                            key={paymentMethod}
                          />
                        ))}

                      <PaymentRow
                        label={t`Skupaj`}
                        value={doc.payments["total"]?.formatted}
                        isTotal
                        isSkeleton={doc.isSkeleton}
                      />

                      <Divider className="my-2 bg-gray-300" />

                      {/** Total by tax rate */}
                      <p className="text-sm font-bold">
                        <Trans>Davki</Trans>
                      </p>
                      {Object.keys(doc.totalByTaxRate).map((taxRate) => (
                        <>
                          <p className="font-bold">{taxRate}%</p>

                          <PaymentRow
                            label={t`Osnova`}
                            value={
                              Object.values(doc.totalByTaxRate).find(
                                (v) => v.rate === taxRate,
                              )!.baseAmount
                            }
                            isSkeleton={doc.isSkeleton}
                          />

                          <PaymentRow
                            label={t`DDV`}
                            value={
                              Object.values(doc.totalByTaxRate).find(
                                (v) => v.rate === taxRate,
                              )!.taxAmount
                            }
                            isSkeleton={doc.isSkeleton}
                          />

                          <Divider className="my-2 bg-gray-300" />
                        </>
                      ))}
                    </div>
                  </div>
                ))}

                <Divider className="mb-3" />
              </>
            ) : undefined}

            <div className="px-2">
              {totalsByPaymentMethod
                .filter(([paymentMethod, _]) => paymentMethod !== "total")
                .map(([paymentMethod, value]) => (
                  <PaymentRow
                    label={getPaymentTypeLabel(paymentMethod as PaymentType)}
                    value={value.formatted}
                  />
                ))}

              <PaymentRow
                label={t`Skupaj`}
                value={
                  closureData?.totalsByPaymentMethodFormatted?.["total"]
                    ?.formatted || "/"
                }
                isTotal
              />

              <Divider className="my-2 bg-gray-300" />

              {/** Total by tax rate */}
              <p className="text-sm font-bold">
                <Trans>Davki</Trans>
              </p>

              {Object.keys(totalsByTaxRateFormatted).map((taxRate) => (
                <>
                  <p className="font-bold">{taxRate}%</p>

                  <PaymentRow
                    label={t`Osnova`}
                    value={
                      Object.values(totalsByTaxRateFormatted).find(
                        (v) => v.rate === taxRate,
                      )!.baseAmount
                    }
                  />

                  <PaymentRow
                    label={t`DDV`}
                    value={
                      Object.values(totalsByTaxRateFormatted).find(
                        (v) => v.rate === taxRate,
                      )!.taxAmount
                    }
                  />

                  <Divider className="my-2 bg-gray-300" />
                </>
              ))}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PaymentRow = ({
  label,
  value,
  cashAmount,
  cardAmount,
  isTotal,
  isSkeleton,
  giftCardId,
  isThirdPartyGiftCard,
}: {
  label: string;
  value: string;
  cashAmount?: string;
  cardAmount?: string;
  isTotal?: boolean;
  isSkeleton?: boolean;
  giftCardId?: number;
  isThirdPartyGiftCard?: boolean;
}) => {
  if (giftCardId) {
    return (
      <div className="flex justify-between gap-2 text-sm">
        <Link
          href={`/dashboard/gift-card/${giftCardId}`}
          showAnchorIcon
          className="ml-1 w-fit text-left"
          size="sm"
          color="secondary"
        >
          {label}
        </Link>

        <p>{value}</p>
      </div>
    );
  }

  return (
    <Skeleton isLoaded={!isSkeleton}>
      <div>
        <div
          className={cn("flex justify-between gap-2 text-sm", {
            "mt-2": isTotal,
          })}
        >
          <p
            className={cn({
              "font-semibold": isTotal,
              "w-1/2": !isTotal,
            })}
          >
            {label}
          </p>
          <p>{value}</p>
        </div>
        {cashAmount || cardAmount ? (
          <div className="mb-2 ml-4 text-right">
            {cashAmount ? (
              <p className="text-xs">Gotovina: {cashAmount}</p>
            ) : undefined}
            {cardAmount ? (
              <p className="text-xs">Kartica: {cardAmount}</p>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
    </Skeleton>
  );
};
