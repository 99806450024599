import dayjs from "dayjs";

export function assignZIndexes(
  appointments: {
    startTime: string;
    overlapIndex: number | null;
    duration: number;
    endTime: string;
    appointmentId: number;
    isOnPreviousResource: boolean;
    timeOffStart?: number | null;
    timeOffDuration?: number | null;
    timeOffEnd?: number | null;
    formattedData: {
      startTime: string;
    };
    appointmentStart?: dayjs.Dayjs;
    appointmentEndWithoutTimeOff?: dayjs.Dayjs;
  }[],
  timeStep: number,
) {
  const sideBySideThreshold = timeStep / 2;
  const sorted = structuredClone(appointments).map((a) => ({
    ...a,
    overlapIndex: null as number | null,
    overlapCount: 1,
  }));

  for (const appointment of sorted) {
    const appointmentStart = dayjs(appointment.startTime, "HH:mm");
    const timeOffStartTime = appointmentStart.add(
      appointment.timeOffStart ?? appointment.duration,
      "minutes",
    );
    appointment.appointmentStart = appointmentStart;
    appointment.appointmentEndWithoutTimeOff = timeOffStartTime;
  }

  sorted.sort((a, b) =>
    dayjs(a.appointmentStart).diff(dayjs(b.appointmentStart), "minutes"),
  );

  let active: typeof sorted = [];
  for (const appointment of sorted) {
    const currentStart = appointment.appointmentStart;

    active = active.filter((a) => {
      const endTime = a.appointmentEndWithoutTimeOff;
      if (!endTime) return false;

      const overlapDuration = endTime.diff(currentStart, "minutes");
      return overlapDuration > sideBySideThreshold;
    });

    const usedArray = active
      .map((a) => a.overlapIndex)
      .filter((x): x is number => x !== null);
    const used = new Set<number>(usedArray);

    let index = 0;
    while (used.has(index)) {
      index++;
    }

    appointment.overlapIndex = index;
    active.push(appointment);

    const currentCount = active.length;
    for (const a of active) {
      a.overlapCount = Math.max(a.overlapCount, currentCount);
    }
  }

  return sorted;
}

export const returnYoffsetForEvent = ({
  startTimeEvent,
  calendarStartTime,
  timeStep,
  slotHeight,
}: {
  startTimeEvent: string;
  calendarStartTime: string;
  timeStep: number;
  slotHeight: number;
}) => {
  const minutes = dayjs(dayjs(startTimeEvent).format("HH:mm"), "HH:mm").diff(
    dayjs(calendarStartTime, "HH:mm"),
    "minute",
  );
  return Math.round((minutes / timeStep) * slotHeight);
};

export const returnHeightOfEventBlock = ({
  duration,
  timeStep,
  slotHeight,
}: {
  duration: number;
  timeStep: number;
  slotHeight: number;
}) => {
  const ratio = duration / timeStep;
  return Math.round(ratio * slotHeight);
};
