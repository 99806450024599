import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  ActionIcon,
  Box,
  Button,
  ButtonVariant,
  Divider,
  Flex,
  Popover,
  Tabs,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { RiAddFill, RiArrowLeftLine } from "react-icons/ri";
import { MobileSidebarToggle } from "../MobileSidebarToggle";

import classes from "./PageHeader.module.css";

type Icon = "add" | React.ReactElement<any>;

export type PageHeaderSubHeaderTab = {
  label: string;
  value: string;
  rightSection?: React.ReactNode;
};

type ActionButton = {
  label: string;
  onClick: () => void;
  icon?: Icon;
  isLoading?: boolean;
  popoverOptions?: {
    icon?: Icon;
    label: string;
    onClick: () => void;
  }[];
  variant?: ButtonVariant;
};

type SubPageHeader = {
  title: string;
  onBackButtonClick: () => void;
  rightSection?: React.ReactNode;
};

type SubHeader = {
  selectedTab?: string;
  onSelectTab?: (tab: string) => void;
  tabs?: PageHeaderSubHeaderTab[];
};

export type PageHeaderProps = {
  title: string;
  extraPadding?: boolean;
  actionButton?: ActionButton;

  subPageHeader?: SubPageHeader;

  subHeader?: SubHeader;

  isNewPage?: boolean;
};

const getIcon = (icon: string | React.ReactNode) => {
  if (typeof icon === "string") {
    switch (icon) {
      case "add":
        return <RiAddFill size="24" />;
      default:
        return <RiAddFill size="24" />;
    }
  } else return icon;
};

/**
 * @deprecated Use LimePageHeader and LimePageTabs instead
 */
export const PageHeader = ({
  title,
  extraPadding,
  actionButton,
  subPageHeader,
  subHeader,
  isNewPage = false,
}: PageHeaderProps) => {
  const [actionButtonPopoverOpened, setActionButtonPopoverOpened] =
    useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const selectedTabObject =
    subHeader?.tabs?.length === 1
      ? subHeader?.tabs[0]
      : subHeader?.tabs?.find((tab) => tab.value === subHeader.selectedTab);

  const popoverContent = actionButton?.popoverOptions?.map((option, i) => {
    return (
      <Button
        key={i}
        onClick={() => {
          option.onClick();
          setActionButtonPopoverOpened(false);
        }}
        variant="light"
        leftSection={getIcon(option.icon)}
      >
        {option.label}
      </Button>
    );
  });

  let finalTitle = title;
  if (isMobile && subPageHeader) finalTitle = subPageHeader.title;

  return (
    <Box
      px={isNewPage && !isMobile ? "xl" : 0}
      mt={isNewPage && !isMobile ? "lg" : 0}
    >
      <Flex
        justify={"space-between"}
        align={"center"}
        style={{
          height: isMobile ? (subPageHeader ? "40px" : "70px") : "40px",
          transition: "height 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        }}
        px={extraPadding ? "md" : undefined}
      >
        <Flex
          align={"center"}
          w={"100%"}
          pos={"relative"}
          justify={subPageHeader ? "space-between" : "flex-start"}
        >
          <MobileSidebarToggle
            onMobileBackButtonClick={subPageHeader?.onBackButtonClick}
          />
          {(!isMobile || !subPageHeader) && (
            <Text
              variant={
                subPageHeader && isMobile
                  ? TextVariant.BodyEmphasized
                  : TextVariant.Heading
              }
              fw={600}
              flex={1}
            >
              {finalTitle}
            </Text>
          )}

          {subPageHeader && isMobile && (
            <Text
              variant={
                subPageHeader && isMobile
                  ? TextVariant.BodyEmphasized
                  : TextVariant.Heading
              }
              fw={600}
              pos={"absolute"}
              left={0}
              right={0}
              ta={"center"}
              style={{ pointerEvents: "none" }}
              flex={1}
            >
              {finalTitle}
            </Text>
          )}
          {subPageHeader?.rightSection ? subPageHeader.rightSection : undefined}
          {actionButton && (
            <Popover
              width={isMobile ? "fit-content" : "target"}
              position="bottom"
              shadow="md"
              opened={actionButtonPopoverOpened}
              onChange={setActionButtonPopoverOpened}
              disabled={!actionButton?.popoverOptions}
            >
              <Popover.Target>
                <div>
                  <Button
                    leftSection={getIcon(actionButton.icon)}
                    loading={actionButton.isLoading}
                    onClick={() => {
                      if (actionButton?.popoverOptions)
                        setActionButtonPopoverOpened(true);
                      else actionButton.onClick();
                    }}
                    variant={actionButton.variant ?? "brand"}
                    styles={{
                      section: {
                        margin: isMobile ? 0 : undefined,
                      },
                      root: {
                        paddingRight: isMobile
                          ? "var(--_button-padding-left,var(--button-padding-x,var(--button-padding-x-sm)))"
                          : undefined,
                        paddingLeft: isMobile
                          ? "var(--_button-padding-left,var(--button-padding-x,var(--button-padding-x-sm)))"
                          : undefined,
                      },
                    }}
                  >
                    {isMobile ? null : actionButton.label}
                  </Button>
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex direction={"column"} gap={"xs"}>
                  {popoverContent}
                </Flex>
              </Popover.Dropdown>
            </Popover>
          )}
        </Flex>
      </Flex>
      <Divider mt={isMobile ? 0 : 10} />
      {subPageHeader && !isMobile && (
        <>
          <Flex justify={"space-between"} align={"center"} h={"40px"}>
            <Flex align={"center"} justify={"space-between"}>
              <ActionIcon onClick={subPageHeader.onBackButtonClick} mr={"md"}>
                <RiArrowLeftLine
                  style={{ fontSize: "1.2rem", color: Color.PrimaryText }}
                />
              </ActionIcon>
              <Text variant={TextVariant.BodyEmphasized} fw={600}>
                {subPageHeader.title}
              </Text>
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
        </>
      )}
      {!subPageHeader && subHeader && (
        <>
          <Flex
            justify={"space-between"}
            align={"center"}
            h={"54px"}
            gap={"sm"}
            px={extraPadding ? "md" : undefined}
          >
            <Tabs
              value={selectedTabObject?.value}
              orientation="horizontal"
              keepMounted={false}
              onChange={(value) => subHeader.onSelectTab?.(value ?? "")}
              classNames={{
                root: classes.tabs_root,
                list: classes.tabs_list,
              }}
            >
              <Tabs.List grow>
                {subHeader.tabs?.map((tab) => (
                  <Tabs.Tab key={tab.value} value={tab.value}>
                    <Text
                      variant="bodyEmphasized"
                      c={
                        selectedTabObject?.value === tab.value
                          ? Color.Brand
                          : Color.SecondaryText
                      }
                    >
                      {tab.label}
                    </Text>
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Tabs>
            {!isMobile && selectedTabObject?.rightSection}
          </Flex>
          <Divider
            size={2}
            pos={"relative"}
            style={{
              transform: "translateY(-4px)",
              zIndex: -1,
              borderTopColor: "#dee2e6",
            }}
          />
        </>
      )}
      {isMobile && (
        <Flex direction={"column"} gap={"xs"} mt={"xs"}>
          {isMobile && selectedTabObject?.rightSection && (
            <>
              {selectedTabObject?.rightSection}
              <Divider />
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};
