import useAccountInfo from "@/hooks/useAccountInfo";
import { api } from "@/lib/api-client";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import styled from "styled-components";
import Calendar from "./components/calendar/Calendar";
import CalendarModalWrapper from "./components/calendar/CalendarModalWrapper";
import { useCalendarStore } from "./store/useCalendarStore";
import Header from "./components/Header";

/**
 * BOTH do:
 * - restrict filter
 * - restrict customer card on appt modal
 * - hide GSM and Email in customer search on appt modal
 *
 * GLOWUP:
 * - Hide email field
 *
 * ILLUME:
 * - Hide GSM field on existing appt; show on new
 */

const GLOWUP_USERS = [
  381, 382, 471, 472, 268, 761, 763, 764, 3434, 3435, 3436, 3437, 3483, 3620,
  3297, 3298, 3299, 3300, 4048, 4389, 4525, 764, 4048, 382, 5012, 3164, 3166,
  3172, 4489, 1009, 3559, 764, 3162, 3167, 3168, 3169, 3174, 3175, 3183, 3478,
  5030, 5118, 5119, 2432,
];

const ILLUME_USERS = [
  1264, 1268, 976, 977, 1265, 382, 3629, 3902, 1015, 3751, 4475,
];

export const isGlowupUser = (userId: number) => GLOWUP_USERS.includes(userId);
export const isIllumeUser = (userId: number) => ILLUME_USERS.includes(userId);

const CalendarPage = () => {
  const { accountInfo } = useAccountInfo();
  const loggedInUserId = accountInfo?.userId;

  const {
    dateActions,
    refreshCalendar,
    selectedLocationId,
    setTimeoffs,
    calendarView,
    selectedDate,
  } = useCalendarStore((state) => state);

  const [isMonthCalendar, setIsMonthCalendar] = React.useState(false);

  const {
    data: timeoffs,
    refetch: refetchTimeoffs,
    isLoading: isFetchingTimeoffs,
  } = api.timeoff.useGetTimeoff({
    locationId: selectedLocationId,
    dateFrom: dayjs(selectedDate)
      .startOf(calendarView)
      .format("YYYY-MM-DD HH:mm"),
    dateTo: dayjs(selectedDate).endOf(calendarView).format("YYYY-MM-DD HH:mm"),
  });

  useEffect(() => {
    setTimeoffs(timeoffs);
  }, [timeoffs]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      dateActions.setCalendarView("day");
    } else {
      refreshCalendar().catch(console.log);
    }

    const refreshCalendarInterval = setInterval(
      () => {
        refreshCalendar(true).catch(console.log);
      },
      5 * 60 * 1000,
    );

    return () => {
      clearInterval(refreshCalendarInterval);
    };
  }, []);

  return (
    <>
      <Wrapper isMonthCalendar={isMonthCalendar}>
        <Header
          IS_GLOWUP_USER={isGlowupUser(loggedInUserId)}
          IS_ILLUME_USER={isIllumeUser(loggedInUserId)}
        />

        <Calendar
          setIsMonthCalendar={setIsMonthCalendar}
          isFetchingTimeoffs={isFetchingTimeoffs}
        ></Calendar>
      </Wrapper>

      <CalendarModalWrapper
        refetchTimeoffs={refetchTimeoffs}
        IS_GLOWUP_USER={isGlowupUser(loggedInUserId)}
        IS_ILLUME_USER={isIllumeUser(loggedInUserId)}
      />
    </>
  );
};

const Wrapper = styled.div<{ isMonthCalendar: boolean }>`
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
  height: fit-content;

  @media (min-width: 768px) {
    padding: 20px 10px;
  }

  display: grid;

  grid-template-columns: ${({ isMonthCalendar }) =>
    isMonthCalendar ? "" : "auto 1fr"};

  grid-template-rows: auto;
  grid-auto-rows: minmax(100px, auto);
  width: 100%;

  .top_row {
    position: sticky;
    top: 0;

    background: white;
    z-index: 5;
  }

  .times_row {
    position: sticky;
    left: 0;

    background: white;
    z-index: 3;

    /*  border-right: 1px solid #eaebed; */
    width: 45px;
    text-align: right;
  }

  .scroll_row {
    overflow-x: scroll;
    overflow-y: hidden;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .empty {
    z-index: 4;

    left: 0;
  }

  .calendar_row {
    z-index: 1;
  }

  #users_row {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  #calendar_row {
    border: 1px solid #d8d8d8;
    border-top: none;
  }
`;

export default CalendarPage;
