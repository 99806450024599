import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useState } from "react";
import { apiForgotPassword } from "../../../apiCalls";
import { Button, Image, Input, Link } from "@heroui/react";
import { useForm } from "@mantine/form";

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim()) ? null : t`Neveljaven e-poštni naslov`,
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    await apiForgotPassword(values);
    setSubmitted(true);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)} className="w-full">
      <div className="mx-auto flex h-screen w-full max-w-lg flex-col items-center justify-center px-2">
        <Link href={"/login"}>
          <Image
            src="/logo_limebooking_full_new.png"
            alt="logo"
            className="mb-4 h-auto w-72"
          />
        </Link>

        {!submitted ? (
          <>
            <div className="mt-2 flex flex-col gap-4">
              <h1 className="text-center text-xl font-semibold md:text-2xl">
                <Trans>Ponastavi svoje geslo</Trans>
              </h1>
              <p className="text-sm">
                <Trans>
                  Vnesite elektronski naslov, povezan z vašim računom, in
                  poslali vam bomo povezavo za ponastavitev gesla.
                </Trans>
              </p>
              <Input
                label={t`E-poštni naslov`}
                {...form.getInputProps("email")}
                className="mb-2"
                type="email"
              />

              <Button className="w-full" color="primary" type="submit">
                <Trans>Nadaljuj</Trans>
              </Button>

              <div className="flex w-full flex-col items-center gap-1">
                <Link href="/login" className="mt-4 text-sm font-semibold">
                  <Trans>Nazaj na prijavo</Trans>
                </Link>
                <Link href="/register" className="text-sm">
                  <Trans>Še nimate računa? Registrirajte se!</Trans>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-4 flex flex-col gap-4 px-2">
              <h1 className="font-semibold">
                <Trans>
                  Hvala, preverite svoj elektronski naslov za navodila za
                  ponastavitev gesla.
                </Trans>
              </h1>
              <p className="text-sm">
                <Trans>
                  Če niste prejeli elektronskega sporočila v 5 minutah,
                  preverite med vsiljeno pošto ali pošljite ponovno
                </Trans>
              </p>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default ForgotPassword;
