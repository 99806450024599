import { FaAngleDown, FaEdit } from "react-icons/fa";
import useUserPermissionsStore from "../../../../stores/useUserPermissionsStore";
import { Menu, Button, Flex, Text } from "@mantine/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useState } from "react";

const AppointmentActions = (props) => {
  const {
    onClickDelete,
    isEditing,
    setIsEditing,
    updateUserAppointmentComment,
    updateUserAppointmentAttendance,
    appointment,
    selectedAppointmentType,
    listRef,
  } = props;
  useLingui();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const [menuOpened, setMenuOpened] = useState(false);

  const appointmentActionsMenuTitle = (() => {
    if (appointment.customerDidAttend === -1) {
      return {
        text: t`Neprihod`,
        color: "orange",
      };
    }
    if (selectedAppointmentType === "UPCOMING") {
      return {
        text: t`Rezerviran`,
        color: "green",
      };
    }
    if (selectedAppointmentType === "COMPLETED") {
      return {
        text: t`Zaključen`,
        color: "blue",
      };
    }
  })();

  // useeffect on page scroll
  useEffect(() => {
    console.log("scroll", listRef);
    const handleScroll = () => {
      setMenuOpened(false);
    };
    listRef?.current?.addEventListener("scroll", handleScroll);
    return () => listRef?.current?.removeEventListener("scroll", handleScroll);
  }, [listRef]);

  return (
    <Flex direction="column" align="flex-end" ml={"auto"} mr={"md"} gap={"md"}>
      <Menu
        shadow="md"
        radius={"lg"}
        opened={menuOpened}
        onChange={setMenuOpened}
      >
        <Menu.Target>
          <Button
            variant="outline"
            radius="md"
            color={appointmentActionsMenuTitle.color}
          >
            <Flex align="center">
              <Text>{appointmentActionsMenuTitle.text}</Text>
              <FaAngleDown
                style={{
                  marginLeft: "5px",
                  marginTop: "2px",
                }}
              />
            </Flex>
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {!isEditing && permissions.manage_appointments && (
            <>
              <Menu.Item icon={<FaEdit />} onClick={() => setIsEditing(true)}>
                <Trans>Uredi komentar</Trans>
              </Menu.Item>
              <Menu.Item color="red" onClick={() => onClickDelete()}>
                <Trans>Izbriši termin</Trans>
              </Menu.Item>
              <Menu.Item
                color="orange"
                onClick={() => {
                  const newStatus =
                    appointment.customerDidAttend === 1 ? -1 : 1;
                  updateUserAppointmentAttendance(
                    appointment.userAppointmentId,
                    newStatus,
                  );
                }}
              >
                {appointment.customerDidAttend === 1 ? (
                  <Trans>Označi neprihod</Trans>
                ) : (
                  <Trans>Razveljavi neprihod</Trans>
                )}
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>

      {isEditing && (
        <Flex direction="column" justify="space-between" gap="sm" mt="sm">
          <Button
            variant="outline"
            radius="lg"
            color="green"
            onClick={() => {
              updateUserAppointmentComment();
              setIsEditing(false);
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
          <Button
            variant="outline"
            radius="lg"
            color="red"
            onClick={() => setIsEditing(false)}
          >
            <Trans>Prekliči</Trans>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AppointmentActions;
