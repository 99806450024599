import { LimeDatePicker } from "@/Components/NextBase/LimeDatePicker";
import { LimeTextarea } from "@/Components/NextBase/LimeTextarea";
import { CalendarDateTime } from "@internationalized/date";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams } from "react-router";
import { TaxRegisterAuthorizationDialog } from "../TaxRegister/AuthorizationDialog";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  Spinner,
  useDisclosure,
} from "@heroui/react";
import { CornerDownRight, Minus, Plus, Trash } from "lucide-react";
import { api } from "@/lib/api-client";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { cn } from "@/utils";
import { useEffect } from "react";
import { ManagePageWrapper } from "@/Components/NextBase/ManagePageWrapper";
import { LimeRadioGroup } from "@/Components/NextBase/LimeRadioGroup";

type Form = {
  locationId?: number;
  note: string;
  date: CalendarDateTime;
  products: {
    productId: number;
    quantity: number;
    name: string;
  }[];
};

export const CreateProductInternalUse = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    isOpen: isTemplatePopupOpen,
    onOpenChange: onTemplateOpenChange,
    onClose: closeTemplatePopup,
  } = useDisclosure();

  const {
    isOpen: isAddProductModalOpen,
    onOpen: openAddProductModal,
    onOpenChange: openAddProductModalChange,
    onClose: closeAddProductModal,
  } = useDisclosure();

  const form = useForm<Form>({
    initialValues: {
      date: new CalendarDateTime(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
      ),
      note: "",
      products: [],
      locationId: undefined,
    },

    validate: {
      locationId: (value) => {
        if (!value) {
          return t`Lokacija je obvezna`;
        }
      },
      date: (value) => {
        if (!value) {
          return t`Datum je obvezan`;
        }
      },
      note: (value) => {
        if (!value) {
          return t`Opomba je obvezna`;
        }
      },
      products: (value) => {
        if (value.length === 0) {
          return t`Produkti so obvezni`;
        }
      },
    },

    onValuesChange: (values, previous) => {
      const didLocationChange = values.locationId !== previous.locationId;
      if (didLocationChange) {
        form.setFieldValue("products", []);
      }
    },
  });

  useEffect(() => {
    const locationId = searchParams.get("locationId");
    if (!locationId) return;

    const location = locations?.locations.find(
      (location) => location.locationId === Number(locationId),
    );
    if (!location) return;

    form.setFieldValue("locationId", Number(locationId));
  }, []);

  const {
    data: locations,
    isFetching: isFetchingLocations,
    processedErrorMessage: fetchLocationsErrorMessage,
  } = api.location.useGetLocations({
    sortBy: "labelDesc",
  });

  const {
    mutateAsync: createProductInternalUse,
    isPending: isCreatingProductInternalUse,
    processedErrorMessage: createProductInternalUseErrorMessage,
  } = api.product.usePostProductInternalUse();

  const handleSubmit = async (data: Form) => {
    await createProductInternalUse({
      ...data,
      locationId: data.locationId!,
      date: data.date.toString(),
    });

    goBack();
  };

  const goBack = () => {
    navigate(`/dashboard/products/stock?${searchParams.toString()}`);
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ManagePageWrapper
          pageHeaderProps={{
            title: "",
            subPage: {
              title: t`Dodajanje lastne porabe`,
              onBackButtonClick: goBack,
            },
          }}
          actionsProps={{
            negativeAction: {
              label: t`Preklici`,
              onClick: goBack,
            },
            positiveAction: {
              label: t`Potrdi`,
              isLoading: isCreatingProductInternalUse,
            },
          }}
          errorMessage={createProductInternalUseErrorMessage}
        >
          <TaxRegisterAuthorizationDialog />

          <div className="mt-4 flex flex-col gap-4 px-4 md:px-8">
            <LimeSelect
              label={t`Lokacija`}
              items={
                locations?.locations.map((location) => ({
                  label: location.label,
                  key: location.locationId.toString(),
                })) || []
              }
              error={fetchLocationsErrorMessage}
              {...form.getInputProps("locationId")}
              isLoading={isFetchingLocations}
            />

            <div className="flex flex-col gap-2">
              <p className="text-sm font-medium">
                <Trans>Opis in datum</Trans>
              </p>

              <LimeTextarea
                label={t`Opis`}
                {...form.getInputProps("note")}
                startContent={
                  <Popover
                    showArrow
                    isOpen={isTemplatePopupOpen}
                    onOpenChange={onTemplateOpenChange}
                  >
                    <PopoverTrigger>
                      <Button isIconOnly size="sm" variant="light">
                        <CornerDownRight size={16} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <div className="flex flex-col items-end px-1 py-2">
                        <LimeRadioGroup
                          onValueChange={(val) => {
                            form.setFieldValue("note", val);
                            closeTemplatePopup();
                          }}
                        >
                          <Radio value={t`Poraba blaga v sklopu storitev`}>
                            <Trans>Poraba blaga v sklopu storitev</Trans>
                          </Radio>
                        </LimeRadioGroup>
                      </div>
                    </PopoverContent>
                  </Popover>
                }
              />

              <LimeDatePicker {...form.getInputProps("date")} hideTimeZone />
            </div>

            <div className="mt-4 flex items-center justify-between">
              <p className="text-sm font-semibold">Artikel</p>
              <p className="text-xs">KOLIČINA</p>
            </div>

            <LimeAlert message={form.errors.products} color="danger" />
            {form.getValues().products.map((product, index) => (
              <div
                key={index}
                className="mt-2 flex items-center justify-between"
              >
                <div className="flex items-center gap-2">
                  <Button
                    isIconOnly
                    onPress={() => form.removeListItem("products", index)}
                    variant="light"
                  >
                    <Trash size={16} />
                  </Button>
                  <p className="text-xs font-semibold">{product.name}</p>
                </div>

                <div className="flex gap-2">
                  <Button
                    isIconOnly
                    onPress={() => {
                      const quantity =
                        form.getValues().products[index].quantity;

                      if (quantity === 1) {
                        return;
                      }

                      form.setFieldValue(
                        `products.${index}.quantity`,
                        quantity - 1,
                      );
                    }}
                  >
                    <Minus size={16} />
                  </Button>
                  <Input
                    {...form.getInputProps(`products.${index}.quantity`)}
                    min={1}
                    className="w-10"
                    classNames={{
                      input: "text-center font-semibold text-xs",
                    }}
                  />
                  <Button
                    isIconOnly
                    onPress={() => {
                      const quantity =
                        form.getValues().products[index].quantity;
                      form.setFieldValue(
                        `products.${index}.quantity`,
                        quantity + 1,
                      );
                    }}
                  >
                    <Plus size={16} />
                  </Button>
                </div>
              </div>
            ))}

            <Button
              className="w-fit"
              startContent={<Plus size={16} />}
              variant="light"
              onPress={openAddProductModal}
            >
              <Trans>Dodaj artikel</Trans>
            </Button>
          </div>
        </ManagePageWrapper>
      </form>

      <AddArticlesDrawer
        isOpen={isAddProductModalOpen}
        onOpenChange={openAddProductModalChange}
        locationId={form.getValues().locationId}
        handleSubmit={(data) => {
          const { products } = data;

          // Keep overlapping ids, remove/add others
          const productsToKeep = form
            .getValues()
            .products.filter((product) =>
              products.some((p) => p.id === product.productId),
            );

          const productsToAdd = products
            .filter(
              (product) =>
                !form
                  .getValues()
                  .products.some((p) => p.productId === product.id),
            )
            .map((product) => ({
              productId: product.id,
              name: product.name,
              quantity: 1,
            }));

          form.setValues({
            ...form.getValues(),
            products: [...productsToKeep, ...productsToAdd],
          });

          closeAddProductModal();
        }}
        preSelectedProductIds={form
          .getValues()
          .products.map((product) => product.productId)}
      />
    </>
  );
};

type AddArticlesForm = {
  products: {
    id: number;
    name: string;
  }[];
};
export const AddArticlesDrawer = ({
  isOpen,
  onOpenChange,
  locationId,
  handleSubmit,
  preSelectedProductIds,
}: {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  handleSubmit: (data: AddArticlesForm) => void;
  locationId?: number;
  preSelectedProductIds?: number[];
}) => {
  const form = useForm<AddArticlesForm>({
    initialValues: {
      products: [],
    },
  });

  const {
    data: productStocks,
    isFetching: isFetchingProductStocks,
    processedErrorMessage: fetchProductStocksErrorMessage,
  } = api.product.useGetProductStocks({
    locationId,
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();

      if (preSelectedProductIds) {
        form.setValues({
          products: preSelectedProductIds.map((id) => ({
            id,
            name: productStocks?.find((p) => p.productId === id)?.name || "",
          })),
        });
      }
    }
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} placement="bottom" onOpenChange={onOpenChange}>
      <DrawerContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <DrawerHeader>
              <Trans>Dodaj artikel</Trans>
            </DrawerHeader>
            <DrawerBody>
              <LimeAlert
                message={fetchProductStocksErrorMessage}
                color="danger"
              />

              {isFetchingProductStocks && <Spinner />}

              {!isFetchingProductStocks && !productStocks && (
                <>
                  <p className="font-medium">
                    <Trans>Artikli niso najdeni</Trans>
                  </p>
                  {!locationId && (
                    <p className="text-xs text-gray-500">
                      <Trans>Za prikaz artiklov morate izbrati lokacijo</Trans>
                    </p>
                  )}
                </>
              )}

              {!isFetchingProductStocks &&
                productStocks?.map((productStock) => (
                  <CheckboxGroup
                    value={form
                      .getValues()
                      .products.map((p) => p.id.toString())}
                    onChange={(value) => {
                      form.setValues({
                        ...form.getValues(),
                        products: value.map((id) => ({
                          id: Number(id),
                          name:
                            productStocks.find(
                              (p) => p.productId === Number(id),
                            )?.name || "",
                        })),
                      });
                    }}
                  >
                    <Checkbox
                      value={productStock.productId.toString()}
                      classNames={{
                        base: cn(
                          "inline-flex max-w-full w-full bg-content1 m-0",
                          "hover:bg-content2 items-center justify-start",
                          "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                          "data-[selected=true]:border-primary",
                        ),
                        label: "w-full",
                      }}
                    >
                      <div className="flex items-center justify-between gap-2">
                        <p>{productStock.name}</p>
                        <p className="text-sm text-gray-500">
                          {productStock.quantity} {productStock.unit}
                        </p>
                      </div>
                    </Checkbox>
                  </CheckboxGroup>
                ))}
            </DrawerBody>
            <DrawerFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                <Trans>Prekliči</Trans>
              </Button>
              <Button color="primary" type="submit">
                <Trans>Potrdi</Trans>
              </Button>
            </DrawerFooter>
          </form>
        )}
      </DrawerContent>
    </Drawer>
  );
};
