import { PrintableDocument, PrinterConfig } from "./types";
import {
  renderInvoiceToHtml,
  renderGiftCardToHtml,
  renderGiftCardReceiptToHtml,
  renderTransactionAccountReceiptToHtml,
  renderGiftCardTransactionAccountToHtml,
} from "./html-renderers";
import {
  renderInvoiceToEscPos,
  renderGiftCardToEscPos,
  renderGiftCardReceiptToEscPos,
  renderTransactionAccountReceiptToEscPos,
  renderGiftCardTransactionAccountToEscPos,
} from "./escpos-renderers";

export async function renderDocumentToHtml(
  doc: PrintableDocument,
  printerConfig: PrinterConfig,
): Promise<string> {
  switch (doc.type) {
    case "invoice":
      return await renderInvoiceToHtml({ ...doc, printerConfig });
    case "gift-card":
      return renderGiftCardToHtml(doc);
    case "gift-card-receipt":
      return renderGiftCardReceiptToHtml(doc);
    case "transaction-account":
      return await renderTransactionAccountReceiptToHtml(doc);
    case "gift-card-transaction-account":
      return renderGiftCardTransactionAccountToHtml(doc);
  }
}

export async function renderDocumentToEscPos(
  doc: PrintableDocument,
  printerConfig: PrinterConfig,
): Promise<string[]> {
  if (!printerConfig) {
    throw new Error("Printer config is required");
  }

  const rawCommands = await (async () => {
    switch (doc.type) {
      case "invoice":
        return renderInvoiceToEscPos({ ...doc, printerConfig });
      case "gift-card":
        return renderGiftCardToEscPos(doc);
      case "gift-card-receipt":
        return renderGiftCardReceiptToEscPos({ doc, printerConfig });
      case "transaction-account":
        return renderTransactionAccountReceiptToEscPos(doc);
      case "gift-card-transaction-account":
        return renderGiftCardTransactionAccountToEscPos(doc);
    }
  })();

  const singleString = String.fromCharCode(...rawCommands);
  return [singleString];
}
