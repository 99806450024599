import { api } from "@/lib/api-client";
import { Avatar, Button, Flex, Table } from "@mantine/core";
import { useState } from "react";
import { ImageUploadView } from "../ImageUploadView";
import { notifications } from "@mantine/notifications";
import { Snippet } from "@heroui/react";
import useAccountInfo from "@/hooks/useAccountInfo";

export const UsersTab = ({ clientId }: { clientId: number }) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  // API
  const { data, refetch } = api.user.useUserList({
    clientId,
    includeDeleted: false,
  });

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const { mutateAsync: uploadImage, isPending: isUploading } =
    api.admin.useUploadUserImage();

  const { mutateAsync: deleteUserImage, isPending: isDeleting } =
    api.admin.useDeleteUserImage();

  const { mutateAsync: loginAsAdmin } = api.admin.useLoginAsAdmin();
  const { mutateAsync: deleteCookie } = api.cookie.useDeleteHttpCookie();

  const handleLogin = async (userId: number) => {
    try {
      const response = await loginAsAdmin({
        clientId,
        userId,
      });

      localStorage.setItem("usr_token", response.token);
      localStorage.removeItem("pos-electronicDeviceId");
      localStorage.removeItem("pos-businessPremiseId");
      await deleteCookie({ cookieName: "pos-token" });

      window.location.reload();
    } catch (e) {
      notifications.show({
        message: "Niste sistemski admin",
        color: "red",
      });
    }
  };

  return (
    <>
      {selectedUserId ? (
        <ImageUploadView
          close={() => setSelectedUserId(null)}
          existingImage={
            data?.users.find((user) => user.userId === selectedUserId)?.imageUrl
          }
          onUploadImage={async (formData) => {
            try {
              const response = await uploadImage({
                body: formData,
                params: { clientId, userId: selectedUserId },
              });

              notifications.show({
                message: "Slika uspešno posodobljena",
              });

              setSelectedUserId(null);
              refetch();
            } catch (e) {
              notifications.show({
                message: "Napaka pri posodabljanju slik",
                color: "red",
              });
            }
          }}
          isUploadingImage={isUploading}
          onDeleteImage={async () => {
            await deleteUserImage({
              params: {
                clientId,
                userId: selectedUserId,
              },
            });

            setSelectedUserId(null);
            refetch();
          }}
          isDeletingImage={isDeleting}
          circularCrop
          defaultCenterCrop
          aspect={1 / 1}
        />
      ) : (
        <Flex direction={"column"}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>ID</Table.Th>
                <Table.Th>Ime</Table.Th>
                <Table.Th>Priimek</Table.Th>
                <Table.Th>Email</Table.Th>
                <Table.Th>Akcije</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.users.map((user) => (
                <Table.Tr key={user.userId}>
                  <Table.Td
                    onClick={() => setSelectedUserId(user.userId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar src={user.imageUrl}>
                      {`${user.name?.split(" ").at(0)?.at(0) || ""} ${
                        user.name?.split(" ").at(1)?.at(0) || ""
                      }`}
                    </Avatar>
                  </Table.Td>
                  <Table.Td>
                    <Snippet symbol="" variant="bordered" className="border-0">
                      {user.userId}
                    </Snippet>
                  </Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>{user.lastName}</Table.Td>
                  <Table.Td>{user.email}</Table.Td>
                  {accountInfo.systemRole === "admin" && (
                    <Table.Td>
                      <Button
                        onClick={() => handleLogin(user.userId)}
                        size="xs"
                        variant="light"
                      >
                        Prijava kot uporabnik
                      </Button>
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};
