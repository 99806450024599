import type { DetailField, GetClientForm, PutClientForm } from "@/server-types";
import {
  Collapse,
  ColorInput,
  NumberInput,
  Select,
  Switch,
  Table,
  TextInput,
  Textarea,
} from "@mantine/core";
import { Slider } from "@heroui/react";
import { FaPray } from "react-icons/fa";
import { RiServiceFill, RiUserAddFill } from "react-icons/ri";
import { LimeLocalePicker } from "@/Components/NextBase/LimeLocalePicker";

export const GeneralData = ({
  data,
  setData,
}: {
  data: NonNullable<
    PutClientForm["body"] & {
      details: DetailField[];
      backgroundImageUrl: string | null;
    }
  >;
  setData: (
    data:
      | (PutClientForm["body"] & {
          details: DetailField[];
          backgroundImageUrl: string | null;
        })
      | null,
  ) => void;
}) => {
  const handleDataChange = (
    key: keyof NonNullable<GetClientForm["response"]["form"]>,
    value: string | number | boolean | null | [],
  ) => {
    setData({ ...data, [key]: value });
  };

  return (
    <>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Ime forme</Table.Td>
            <Table.Td>
              <TextInput
                value={data.name || ""}
                onChange={(e) => handleDataChange("name", e.target.value)}
              ></TextInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Hash</Table.Td>
            <Table.Td>
              {" "}
              <TextInput
                value={data.hash || ""}
                onChange={(e) => handleDataChange("hash", e.target.value)}
              ></TextInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Template</Table.Td>
            <Table.Td>
              <Select
                data={[
                  { value: "regular", label: "Regular" },
                  { value: "pricepilot", label: "Pricepilot" },
                  { value: "goofy", label: "Goofy" },
                  { value: "prescheduled", label: "Prescheduled" },
                ]}
                value={data.formType}
                onChange={(e) => handleDataChange("formType", e)}
              ></Select>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>THEME</Table.Td>
            <Table.Td>
              <Select
                data={[
                  { value: "light", label: "Svetla" },
                  { value: "dark", label: "Temna" },
                ]}
                value={data.theme}
                onChange={(e) => handleDataChange("theme", e)}
              ></Select>
            </Table.Td>
          </Table.Tr>
          <Collapse in={data.formType === "prescheduled"}>
            <Table.Tr>
              <Table.Th>Prikaši zasedenost razpisanega termina</Table.Th>
              <Table.Td>
                <Switch
                  checked={!!data.showAttendance}
                  onChange={(e) => {
                    handleDataChange("showAttendance", e.target.checked);
                  }}
                ></Switch>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>Prikaži izvajalce storitve</Table.Th>
              <Table.Td>
                <Switch
                  checked={!!data.showUsers}
                  onChange={(e) => {
                    handleDataChange("showUsers", e.target.checked);
                  }}
                ></Switch>
              </Table.Td>
            </Table.Tr>
          </Collapse>
          <Table.Tr>
            <Table.Td>Jezik</Table.Td>
            <Table.Td>
              <LimeLocalePicker
                value={data.defaultLanguage}
                onChange={(lang) => handleDataChange("defaultLanguage", lang)}
                label={undefined}
                variant="bordered"
              />
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td>Form background opacity</Table.Td>
            <Table.Td>
              <Slider
                minValue={0.1}
                maxValue={1}
                step={0.1}
                value={
                  data.formBackgroundOpacity
                    ? Number(data.formBackgroundOpacity)
                    : undefined
                }
                onChange={(val) =>
                  handleDataChange("formBackgroundOpacity", val as number)
                }
                showTooltip
                defaultValue={0.1}
              />
            </Table.Td>
          </Table.Tr>

          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Primary color</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorPrimary || ""}
                onChange={(e) => {
                  handleDataChange("colorPrimary", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Light color</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorLight || ""}
                onChange={(e) => {
                  handleDataChange("colorLight", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Lighter color</Table.Th>
            <Table.Td>
              <ColorInput
                format="rgba"
                value={data.colorLighter || undefined}
                onChange={(e) => {
                  handleDataChange("colorLighter", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Font color na primary</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorTextOnPrimary || undefined}
                onChange={(e) => {
                  handleDataChange("colorTextOnPrimary", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Background color</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorBackground || undefined}
                onChange={(e) => {
                  handleDataChange("colorBackground", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          {/* <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Font color na ozadju</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorTextOnBackground || undefined}
                onChange={(e) => {
                  handleDataChange("colorTextOnBackground", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr> */}
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Siva barva</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorGray || undefined}
                onChange={(e) => {
                  handleDataChange("colorGray", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr style={{ borderBottom: "none" }}>
            <Table.Th>Error barva</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorTextError || undefined}
                onChange={(e) => {
                  handleDataChange("colorTextError", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Subtle</Table.Th>
            <Table.Td>
              <ColorInput
                value={data.colorTextSubtle || undefined}
                onChange={(e) => {
                  handleDataChange("colorTextSubtle", e);
                }}
              ></ColorInput>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Th>Prikaži ceno</Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.showPrice}
                onChange={(e) => {
                  handleDataChange("showPrice", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Prikaži trajanje</Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.showDuration}
                onChange={(e) => {
                  handleDataChange("showDuration", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Prikaži zasedene slote</Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.showBookedSlots}
                onChange={(e) => {
                  handleDataChange("showBookedSlots", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Za koliko dni je odprta forma</Table.Th>
            <Table.Td>
              <NumberInput
                value={data.formWindowDays || 0}
                onChange={(e) => {
                  handleDataChange("formWindowDays", e);
                }}
                min={1}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Koliko minut od sedaj se nemorejo narocit</Table.Th>
            <Table.Td>
              <NumberInput
                value={data.blockNextMinutes || 0}
                onChange={(e) => {
                  handleDataChange("blockNextMinutes", e);
                }}
                min={0}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Časovni korak</Table.Th>
            <Table.Td>
              <NumberInput
                value={data.timeStepMinutes || 0}
                onChange={(e) => {
                  handleDataChange("timeStepMinutes", e);
                }}
                min={0}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Časovni korak strategija</Table.Th>
            <Table.Td>
              <Select
                data={[
                  { value: "locationBased", label: "Na lokacijo" },
                  { value: "shiftBased", label: "Na shifte" },
                ]}
                value={data.timeStepStrategy}
                onChange={(e) => {
                  handleDataChange("timeStepStrategy", e);
                }}
              ></Select>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Prikaži prisotnost na terminih</Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.showAttendance}
                onChange={(e) => {
                  handleDataChange("showAttendance", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Th>
              <FaPray /> Sporočilo na thank you pejgu
            </Table.Th>
            <Table.Td>
              <Textarea
                value={data.message || ""}
                onChange={(e) => {
                  handleDataChange("message", e.target.value);
                }}
                autosize
                minRows={4}
                resize="vertical"
              ></Textarea>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiServiceFill /> Več storitev
            </Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.allowMultipleServices}
                onChange={(e) => {
                  handleDataChange("allowMultipleServices", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiServiceFill /> Išči vse vrstne rede izbranih storitev
            </Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.allowAnyServiceOrder}
                onChange={(e) => {
                  handleDataChange("allowAnyServiceOrder", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiServiceFill /> Prikaz storitev
            </Table.Th>
            <Table.Td>
              <Select
                data={[
                  { value: "regular", label: "Normalen" },
                  { value: "compact", label: "Kompakten" },
                ]}
                value={data.serviceType}
                onChange={(e) => {
                  handleDataChange("serviceType", e);
                }}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiUserAddFill /> Prikazi zaposlene
            </Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.showUsers}
                onChange={(e) => {
                  handleDataChange("showUsers", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiUserAddFill /> Prikaz zaposlenih
            </Table.Th>
            <Table.Td>
              <Select
                data={[
                  { value: "card", label: "Kartice" },
                  { value: "list", label: "List" },
                ]}
                value={data.userType}
                onChange={(e) => {
                  handleDataChange("userType", e);
                }}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiUserAddFill /> Ali sistem sam izbere zaposlenega (i)
            </Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.doesSystemPickUser}
                onChange={(e) => {
                  handleDataChange("doesSystemPickUser", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <RiUserAddFill /> USER - Dovoli brez preference uporabnik
            </Table.Th>
            <Table.Td>
              <Switch
                checked={!!data.allowUserNoPreference}
                onChange={(e) => {
                  handleDataChange("allowUserNoPreference", e.target.checked);
                }}
              ></Switch>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  );
};
