import { PrintData } from "qz-tray";
import { createPrinterAdapter } from "./adapters";
import { PrintableDocument, PrinterConfig } from "./types";
import { renderDocumentToEscPos, renderDocumentToHtml } from "./renderer";

export * from "./types";
export * from "./utils";
export * from "./adapters";

export async function preparePrintData(
  doc: PrintableDocument,
  config: PrinterConfig,
): Promise<PrintData> {
  if (!config) {
    throw new Error("Printer config is required");
  }

  const adapter = createPrinterAdapter(config);

  if (config.printerType === "escpos_generic") {
    const escPosCommands = await renderDocumentToEscPos(doc, config);
    // Convert commands to QZ Tray PrintData
    return adapter.adapt(escPosCommands);
  } else if (config.printerType === "star_mi") {
    const htmlString = await renderDocumentToHtml(doc, config);
    // Convert to QZ Tray PrintData
    return adapter.adapt(htmlString);
  }

  throw new Error(`Unsupported printer type: ${config.printerType}`);
}
