import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimePhoneInput } from "@/Components/NextBase/LimePhoneInput";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeTimeInput } from "@/Components/NextBase/LimeTimeInput";
import { api } from "@/lib/api-client";
import { GetLocations } from "@/server-types";
import { Time } from "@internationalized/date";
import { Collapse } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Card, CardBody, Divider } from "@heroui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { Trash } from "lucide-react";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";

export const TaxRegisterSetupCashRegisterSettings = () => {
  const navigate = useNavigate();

  const {
    data: locations,
    isFetching: isFetchingLocations,
    processedErrorMessage: getLocationsErrorMessage,
  } = api.location.useGetLocations({
    sortBy: "labelDesc",
  });

  return (
    <>
      <TaxRegisterAuthorizationDialog />

      <LimePageHeader
        title={t`Nastavitve blagajne`}
        subPage={{
          title: t`Blagajna`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mb-4">
        <LimeAlert color="danger" message={getLocationsErrorMessage} />

        <div className="mt-4 flex flex-col gap-4">
          {locations?.locations.map((location) => (
            <LocationSettings key={location.locationId} location={location} />
          ))}
        </div>
      </div>
    </>
  );
};

type LocationSettingsForm = {
  locationId: number;
  shouldAutomaticallyClose: boolean;
  automaticClosingTime: Time | null;
  emailRecipients: string[];
  //   gsmRecipients: string[];
};

const LocationSettings = ({
  location,
}: {
  location: GetLocations["response"]["locations"][number];
}) => {
  const [isAddingNewEmailRecipient, setIsAddingNewEmailRecipient] =
    useState(false);
  //   const [isAddingNewGsmRecipient, setIsAddingNewGsmRecipient] = useState(false);

  const form = useForm<LocationSettingsForm>({
    initialValues: {
      locationId: location.locationId,
      shouldAutomaticallyClose: false,
      automaticClosingTime: null,
      emailRecipients: [],
      //   gsmRecipients: [],
    },

    validate: {
      automaticClosingTime: (value) => {
        if (!form.getValues().shouldAutomaticallyClose) return null;

        if (!value) {
          return t`Vnesite čas`;
        }
      },
    },
  });

  const businessPremiseId = location.BusinessPremise[0]?.businessPremiseId;
  const {
    data: cashRegisterSettings,
    refetch: refetchCashRegisterSettings,
    isFetching: isFetchingCashRegisterSettings,
    processedErrorMessage: getCashRegisterSettingsErrorMessage,
  } = api.taxRegister.useGetCashRegisterSettings({ businessPremiseId });

  const {
    mutateAsync: updateCashRegisterSettings,
    isPending: isUpdatingCashRegisterSettings,
    processedErrorMessage: updateCashRegisterSettingsErrorMessage,
  } = api.taxRegister.usePutCashRegisterSettings();

  useEffect(() => {
    if (!cashRegisterSettings) return;

    // const gsmRecipients = cashRegisterSettings.gsmRecipients.map((r) => {
    //   const gsm = r.gsm;
    //   const countryCode = r.countryCode;

    //   const fullPhoneNumber = numberAndCountryCodeToFullPhoneNumber(
    //     gsm,
    //     countryCode,
    //   );
    //   return fullPhoneNumber;
    // });

    const emailRecipients = cashRegisterSettings.emailRecipients.map(
      (r) => r.email,
    );

    const automaticClosingTime = (() => {
      if (!cashRegisterSettings.automaticClosingTime) return null;

      const parsedTime = dayjs(cashRegisterSettings.automaticClosingTime);
      return new Time(parsedTime.hour(), parsedTime.minute());
    })();

    console.log("automaticClosingTime", automaticClosingTime);

    form.setValues({
      locationId: location.locationId,
      shouldAutomaticallyClose: !!cashRegisterSettings.automaticClosingTime,
      automaticClosingTime,
      //   gsmRecipients,
      emailRecipients,
    });
  }, [cashRegisterSettings]);

  const handleSubmit = async (values: LocationSettingsForm) => {
    console.log("values", values);

    const automaticClosingTime = (() => {
      if (!values.shouldAutomaticallyClose) return undefined;

      const time = values.automaticClosingTime;
      if (!time) return undefined;

      return dayjs()
        .hour(time.hour)
        .minute(time.minute)
        .second(0)
        .utc()
        .format("HH:mm:ss");
    })();

    await updateCashRegisterSettings({
      businessPremiseId: location.BusinessPremise[0].businessPremiseId,
      emailRecipients: values.emailRecipients,
      taxRegisterAutomaticClosingTime: automaticClosingTime,
    });

    toast.success(t`Konfiguracija blagajne je bila uspešno posodobljena`);

    await refetchCashRegisterSettings();
  };

  return (
    <Card className="mx-4 md:mx-8">
      <CardBody>
        <LimeAlert
          color="danger"
          className="mb-2"
          message={getCashRegisterSettingsErrorMessage}
        />

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <p className="mb-2 text-lg font-medium">{location.label}</p>
          <div className="flex flex-col gap-2">
            <LimeSwitch
              title={t`Samodejno zaključevanje blagajne`}
              {...form.getInputProps("shouldAutomaticallyClose", {
                type: "checkbox",
              })}
              isDisabled={
                isFetchingCashRegisterSettings ||
                getCashRegisterSettingsErrorMessage != null
              }
            />
            <Collapse in={form.getValues().shouldAutomaticallyClose}>
              <LimeTimeInput
                label={t`Samodejno zaključi blagajno vsak dan ob`}
                {...form.getInputProps("automaticClosingTime")}
                isDisabled={
                  isFetchingCashRegisterSettings ||
                  getCashRegisterSettingsErrorMessage != null
                }
                defaultValue={form.getValues().automaticClosingTime}
              />
            </Collapse>
          </div>

          <Button
            color="primary"
            type="submit"
            className="mt-2"
            isLoading={isUpdatingCashRegisterSettings}
            isDisabled={
              isFetchingCashRegisterSettings ||
              getCashRegisterSettingsErrorMessage != null
            }
          >
            <Trans>Shrani</Trans>
          </Button>

          <LimeAlert
            color="danger"
            message={updateCashRegisterSettingsErrorMessage}
            className="mt-2"
          />
        </form>
        <Divider className="my-2" />
        <p className="mb-2 text-sm">
          <Trans>Ob zaključku blagajne se stanje samodejno pošlje na</Trans>
        </p>

        {/* <p className="text-sm font-medium">
          <Trans>Email</Trans>
        </p> */}
        <div className="flex flex-col gap-2">
          {form.getValues().emailRecipients.map((email, index) => (
            <ExistingRecipientBlock
              key={index}
              type="email"
              email={email}
              handleDelete={() => form.removeListItem("emailRecipients", index)}
            />
          ))}
          {isAddingNewEmailRecipient ? (
            <NewEmailRecipientBlock
              existingEmails={form.getValues().emailRecipients}
              handleSubmit={(values) => {
                setIsAddingNewEmailRecipient(false);
                form.insertListItem("emailRecipients", values.email);
              }}
              handleCancel={() => {
                setIsAddingNewEmailRecipient(false);
              }}
            />
          ) : (
            <Button
              onPress={() => setIsAddingNewEmailRecipient(true)}
              className="w-fit"
              isDisabled={
                isFetchingCashRegisterSettings ||
                getCashRegisterSettingsErrorMessage != null
              }
            >
              <Trans>Dodaj prejemnika</Trans>
            </Button>
          )}
        </div>

        {/* <p className="text-sm font-medium">
          <Trans>Telefonska</Trans>
        </p>
        {form.getValues().gsmRecipients.map((gsm, index) => (
          <ExistingRecipientBlock
            key={index}
            type="gsm"
            gsm={gsm}
            defaultCountry={location.country}
          />
        ))}
        {isAddingNewGsmRecipient ? (
          <NewGsmRecipientBlock
            handleSubmit={(values) => {
              console.log("NEW GSM", values);
              setIsAddingNewGsmRecipient(false);
              form.insertListItem("gsmRecipients", values.gsm);
            }}
            defaultCountry={location.country}
          />
        ) : (
          <Button onPress={() => setIsAddingNewGsmRecipient(true)}>
            <Plus />
          </Button>
        )} */}
      </CardBody>
    </Card>
  );
};

type NewEmailRecipientForm = {
  email: string;
};
const NewEmailRecipientBlock = ({
  existingEmails,
  handleSubmit,
  handleCancel,
}: {
  existingEmails: string[];
  handleSubmit: (values: NewEmailRecipientForm) => void;
  handleCancel: () => void;
}) => {
  const form = useForm<NewEmailRecipientForm>({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => {
        const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(
          value,
        );
        if (!isValid) {
          return t`E-pošta ni veljavna`;
        }

        if (existingEmails.includes(value)) {
          return t`E-pošta je že dodana`;
        }

        return null;
      },
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        form.validate();

        if (form.isValid()) {
          const values = form.getValues();
          handleSubmit(values);
        }
      }}
      className="flex gap-2"
    >
      <LimeInput {...form.getInputProps("email")} />
      <Button
        color="danger"
        onPress={() => {
          form.reset();
          handleCancel();
        }}
      >
        <Trans>Prekliči</Trans>
      </Button>
      <Button color="primary" type="submit">
        <Trans>Potrdi</Trans>
      </Button>
    </form>
  );
};

// type NewGsmRecipientForm = {
//   gsm: string;
// };
// const NewGsmRecipientBlock = ({
//   handleSubmit,
//   defaultCountry,
// }: {
//   handleSubmit: (values: NewGsmRecipientForm) => void;
//   defaultCountry: string;
// }) => {
//   const form = useForm<NewGsmRecipientForm>({
//     initialValues: {
//       gsm: "",
//     },
//   });

//   return (
//     <form
//       onSubmit={(e) => {
//         e.preventDefault();
//         e.stopPropagation();

//         form.validate();

//         if (form.isValid()) {
//           const values = form.getValues();
//           handleSubmit(values);
//         }
//       }}
//     >
//       <LimePhoneInput
//         {...form.getInputProps("gsm")}
//         defaultCountry={defaultCountry}
//       />
//       <Button color="primary" type="submit">
//         <Trans>Dodaj prejemnika</Trans>
//       </Button>
//     </form>
//   );
// };

const ExistingRecipientBlock = (
  data: {
    handleDelete: () => void;
  } & (
    | {
        type: "gsm";
        gsm: string;
        defaultCountry: string;
      }
    | {
        type: "email";
        email: string;
      }
  ),
) => {
  return (
    <div className="flex gap-2">
      {data.type === "email" ? (
        <LimeInput value={data.email} isDisabled />
      ) : undefined}

      {data.type === "gsm" ? (
        <LimePhoneInput
          value={data.gsm}
          onChange={() => {}}
          defaultCountry={data.defaultCountry}
          isDisabled
        />
      ) : undefined}

      <Button isIconOnly color="danger" onPress={data.handleDelete}>
        <Trash />
      </Button>
    </div>
  );
};
