import React from "react";
import { Tabs, Tab, Select, SelectItem } from "@heroui/react";
import { TabGeneral } from "./components/TabGeneral";
import { TabAppointments } from "./components/TabAppointments";
import { TabUsers } from "./components/TabUsers/TabUsers";
import { RiMapPin2Fill, RiServiceFill } from "react-icons/ri";
import { Chip } from "@heroui/react";
import { useAnalyticsStore } from "./stores/useAnalyticsStore";
import { useAnalytics } from "./hooks/useAnalytics";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { DateRangeActions } from "@/Components/DateRangeActions";
import dayjs from "dayjs";
import { useEffect } from "react";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";

const ServicePicker = () => {
  const { services } = useAnalytics();
  const { selectedServices, setSelectedServices } = useAnalyticsStore(
    (state) => state,
  );

  return (
    <Select
      classNames={{
        base: "min-w-[300px]",
        trigger: "min-h-[36px] py-2 bg-[#f5f5f5] rounded-[10px]",
      }}
      isMultiline={true}
      items={services.map((service) => ({
        id: service.serviceId,
        name: service.nameLocalized[0]?.name || service.name || "",
        category: service.service_tag[0]?.tag?.TagNameLocalized[0]?.name || "",
      }))}
      placeholder={t`Storitve`}
      selectedKeys={selectedServices.map((s: number) => s.toString())}
      onSelectionChange={(keys) => {
        setSelectedServices(Array.from(keys).map(Number));
      }}
      renderValue={(items) => {
        const chipText = (() => {
          if (items.length === services.length) {
            return t`Vse storitve`;
          }
          if (items.length === 1) {
            return items[0]?.data?.name;
          }
          return items[0]?.data?.name + " + " + (items.length - 1);
        })();

        return (
          <div className="flex items-center gap-2">
            <RiServiceFill className="text-[#8c8c8c]" />
            <Chip size="sm">{chipText}</Chip>
          </div>
        );
      }}
      selectionMode="multiple"
    >
      {(service) => (
        <SelectItem key={service.id} textValue={service.name}>
          <div className="flex flex-col">
            <span className="text-small">{service.name}</span>
            <span className="text-tiny text-default-400">
              {service.category}
            </span>
          </div>
        </SelectItem>
      )}
    </Select>
  );
};

const LocationsPicker = () => {
  const { locations } = useAnalytics();
  const { selectedLocations, setSelectedLocations } = useAnalyticsStore(
    (state) => state,
  );

  return (
    <Select
      classNames={{
        base: "min-w-[300px]",
        trigger: "min-h-[36px] py-2 bg-[#f5f5f5] rounded-[10px]",
      }}
      isMultiline={true}
      items={locations.map((l) => ({
        id: l.locationId,
        name: l.label,
      }))}
      placeholder={t`Lokacija`}
      selectedKeys={selectedLocations.map((l: number) => l.toString())}
      onSelectionChange={(keys) => {
        setSelectedLocations(Array.from(keys).map(Number));
      }}
      renderValue={(items) => {
        const chipText = (() => {
          if (items.length === locations.length) {
            return t`Vse lokacije`;
          }
          if (items.length === 1) {
            return items[0]?.data?.name;
          }
          return items[0]?.data?.name + " + " + (items.length - 1);
        })();

        return (
          <div className="flex items-center gap-2">
            <RiMapPin2Fill className="text-[#8c8c8c]" />
            <Chip size="sm">{chipText}</Chip>
          </div>
        );
      }}
      selectionMode="multiple"
    >
      {(location) => (
        <SelectItem key={location.id} textValue={location.name}>
          <div className="flex flex-col">
            <span className="text-small">{location.name}</span>
          </div>
        </SelectItem>
      )}
    </Select>
  );
};

export function Analytics() {
  const permissions = useUserPermissionsStore((state) => state.permissions);
  const [selectedTab, setSelectedTab] = React.useState<string>(
    permissions.analytics_finance ? "general" : "appointments",
  );

  const { setDateRange } = useAnalyticsStore((state) => state);

  useEffect(() => {
    setDateRange([
      dayjs().startOf("day").subtract(1, "week").toDate(),
      dayjs().endOf("day").toDate(),
    ]);
  }, [setDateRange]);

  const handleTabChange = (key: React.Key) => {
    setSelectedTab(key.toString());
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "general":
        return permissions.analytics_finance ? <TabGeneral /> : null;
      case "appointments":
        return <TabAppointments />;
      case "users":
        return <TabUsers />;
      default:
        return <TabAppointments />;
    }
  };

  return (
    <>
      <LimePageHeader title={t`Analitika`} />
      <div className="mt-1 flex flex-col gap-8">
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <div className="flex-1">
            <Tabs
              aria-label="Options"
              selectedKey={selectedTab}
              onSelectionChange={handleTabChange}
              variant="underlined"
              color="primary"
            >
              {permissions.analytics_finance && (
                <Tab
                  key="general"
                  title={
                    <div className="flex items-center gap-2">
                      <span>
                        <Trans>Splošno</Trans>
                      </span>
                    </div>
                  }
                />
              )}
              <Tab
                key="appointments"
                title={
                  <div className="flex items-center gap-2">
                    <span>
                      <Trans>Termini</Trans>
                    </span>
                  </div>
                }
              />
              <Tab
                key="users"
                title={
                  <div className="flex items-center gap-2">
                    <span>
                      <Trans>Zaposleni</Trans>
                    </span>
                  </div>
                }
              />
            </Tabs>
          </div>

          <div className="flex w-full flex-col gap-4 md:w-auto md:flex-row">
            <LocationsPicker />
            <ServicePicker />
            <DateRangeActions
              borderRadius={10}
              handleChangeDateRange={(start, end) => {
                const startD = start ? dayjs(start).toDate() : null;
                const endD = end ? dayjs(end).toDate() : null;
                setDateRange([startD, endD]);
              }}
              disabledDateRangeOptions={["all"]}
              defaultSelectedOption="1w"
            />
          </div>
        </div>

        {renderContent()}
      </div>
    </>
  );
}
