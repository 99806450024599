import { Button } from "@heroui/react";
import { LimePageHeader, LimePageHeaderProps } from "../LimePageHeader";
import { LimeAlert } from "./LimeAlert";
import { cn } from "@/utils";
import { useSidebarStore } from "@/stores/useSidebarStore";

export const ManagePageWrapper = ({
  children,
  pageHeaderProps,
  actionsProps,
  errorMessage,
}: {
  children: React.ReactNode;
  pageHeaderProps: LimePageHeaderProps;
  actionsProps: {
    negativeAction?: {
      label: string;
      onClick: () => void;
      isLoading?: boolean;
    };
    positiveAction?: {
      label: string;
      isLoading?: boolean;
    };
  };
  errorMessage?: string | React.ReactNode;
}) => {
  const isSidebarOpen = useSidebarStore((state) => state.isOpen);

  return (
    <div>
      <LimePageHeader {...pageHeaderProps} />

      <div className="mb-20">{children}</div>

      {actionsProps.positiveAction || actionsProps.negativeAction ? (
        <div
          className={cn(
            "fixed bottom-0 right-0 gap-2 border-t-2 border-gray-50 bg-background p-2",
            "ease-out transition-width",
            "w-full",
            {
              "md:w-[calc(100vw-280px)]": isSidebarOpen,
              "md:w-[calc(100vw-70px)]": !isSidebarOpen,
            },
          )}
        >
          <LimeAlert message={errorMessage} color="danger" className="mb-2" />

          <div className="flex gap-2">
            {actionsProps.negativeAction ? (
              <Button
                fullWidth
                className="h-12"
                variant="bordered"
                onPress={actionsProps.negativeAction.onClick}
                isLoading={actionsProps.negativeAction.isLoading}
              >
                {actionsProps.negativeAction.label}
              </Button>
            ) : undefined}

            {actionsProps.positiveAction ? (
              <Button
                fullWidth
                className="h-12"
                color="primary"
                type="submit"
                isLoading={actionsProps.positiveAction.isLoading}
              >
                {actionsProps.positiveAction.label}
              </Button>
            ) : undefined}
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
