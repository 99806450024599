import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { useNavigate, useSearchParams } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@heroui/react";
import { api, queryClient } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import { POSPinSetupDialog } from "./Invoices/POS/PinSetupDialog";
import { useEffect, useRef } from "react";
import { LimeInputOtp } from "@/Components/NextBase/LimeInputOtp";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export type TaxRegisterAuthorizationForm = {
  userId: number | null;
  PIN: string;
};

export const TaxRegisterAuthorizationDialog = ({
  handleSuccessfulAuthorization,
  requiresSetupCompleted = true,
}: {
  handleSuccessfulAuthorization?: () => void;
  requiresSetupCompleted?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const preselectedUserId = searchParams.get("auid");

  const navigate = useNavigate();

  const { data: posAuthData, refetch: refetchPosAuth } =
    api.cookie.useGetPosToken();

  const {
    onOpen: openCreatePinDialog,
    isOpen: isCreatePinDialogOpen,
    onClose: closeCreatePinDialog,
  } = useDisclosure();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    data: taxRegisterSettings,
    isFetching: isFetchingTaxRegisterSettings,
    processedErrorMessage: taxRegisterSettingsErrorMessage,
  } = api.taxRegister.useGetOrganizationTaxRegisterSettings({});

  const { data: users, isFetching: isUsersFetching } = api.user.useUserList();

  const form = useForm<TaxRegisterAuthorizationForm>({
    initialValues: {
      PIN: "",
      userId: null,
    },

    onValuesChange: (values, previous) => {
      const didUserChange = values.userId !== previous.userId;
      if (didUserChange) {
        form.setFieldValue("locationId", null);
      }
    },

    validate: {
      PIN: (value) =>
        value || taxRegisterSettings?.skipAuthorizationGlobally
          ? null
          : t`Obvezno polje`,
      userId: (value) => (value ? null : t`Obvezno polje`),
    },
  });

  useEffect(() => {
    if (form.getValues().userId != null) {
      inputRef.current?.focus();
      return;
    }

    if (preselectedUserId) {
      form.setFieldValue("userId", Number(preselectedUserId));
      return;
    }

    if (!users) return;

    const firstUserWithTaxNumber = users.users.find((u) => u.taxNumber != null);
    if (firstUserWithTaxNumber) {
      form.setFieldValue("userId", firstUserWithTaxNumber.userId);
    }
  }, [users, form]);

  useEffect(() => {
    if (!taxRegisterSettings || !users || posAuthData != null) return;

    if (
      taxRegisterSettings.skipAuthorizationGlobally &&
      users?.users.filter((u) => u.taxNumber).length === 1
    ) {
      handleSubmit({
        userId: users.users.filter((u) => u.taxNumber)[0].userId,
        PIN: "",
      });
    }
  }, [taxRegisterSettings, users, posAuthData]);

  // Set timeout to refresh on token expiration
  useEffect(() => {
    if (!posAuthData) return;

    const timeout = setTimeout(() => {
      refetchPosAuth();
    }, posAuthData.expiresIn);

    return () => {
      clearTimeout(timeout);
    };
  }, [posAuthData]);

  // Set timeout to refresh on token expiration
  useEffect(() => {
    if (!posAuthData) return;

    const timeout = setTimeout(() => {
      refetchPosAuth();
    }, posAuthData.expiresIn);

    return () => {
      clearTimeout(timeout);
    };
  }, [posAuthData]);

  const {
    data: userHasPin,
    isFetching: isUserHasPinFetching,
    refetch: refetchUserHasPin,
  } = api.user.useGetUserHasPin({
    pinType: "POS",
    userId: form.getValues().userId || undefined,
  });

  const {
    mutateAsync: verifyUserPIN,
    isPending: isVerifyingUserPIN,
    processedErrorMessage: verifyUserPINErrorMessage,
  } = api.user.useVerifyUserPIN();

  const {
    mutateAsync: organizationSetupProgress,
    isPending: isCheckingSetupProgress,
  } = api.taxRegister.useGetOrganizationSetupProgressMutation();

  const handleAuthorizeModalClose = (open: boolean) => {
    if (!open) {
      navigate("/dashboard/");
    }
  };
  const handleSubmit = async (data: TaxRegisterAuthorizationForm) => {
    await verifyUserPIN({
      PIN: data.PIN,
      type: "POS",
      userId: data.userId!,
    });

    if (requiresSetupCompleted) {
      const setupProgress = await organizationSetupProgress();
      if (!setupProgress.isSetupCompleted) {
        navigate(`/dashboard/tax-register/settings/setup?auid=${data.userId}`);
        return;
      }
    }

    queryClient.invalidateQueries({ queryKey: ["user-permissions"] });
    queryClient.invalidateQueries({ queryKey: ["getOrganization"] });
    queryClient.invalidateQueries({
      queryKey: ["getOrganizationTaxRegisterSettings"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getOrganizationSetupProgress"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getOrganizationBusinessPremise"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getOrganizationElectronicDevice"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getPosToken"],
    });
    queryClient.invalidateQueries({
      queryKey: ["services"],
    });
    queryClient.invalidateQueries({
      queryKey: ["categories"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getProducts"],
    });
    queryClient.invalidateQueries({
      queryKey: ["getProductCategories"],
    });

    await refetchPosAuth();
    handleSuccessfulAuthorization?.();

    form.reset();
  };

  if (isCreatePinDialogOpen && form.getValues().userId != null) {
    return (
      <POSPinSetupDialog
        userId={form.getValues().userId!}
        handleCreatedPin={() => {
          refetchUserHasPin();
          closeCreatePinDialog();
        }}
        handleAuthorizeModalClose={() => {
          closeCreatePinDialog();
        }}
      />
    );
  }

  return (
    <Modal
      isOpen={posAuthData?.userId == null}
      onOpenChange={handleAuthorizeModalClose}
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader>
              <Trans>Avtorizacija</Trans>
            </ModalHeader>

            <ModalBody className="flex flex-col items-center justify-center gap-4">
              <LimeSelect
                label={t`Zaposleni`}
                items={
                  users?.users
                    .filter((u) => u.taxNumber)
                    .map((user) => ({
                      key: user.userId.toString(),
                      label: `${user.name} ${user.lastName}`,
                    })) || []
                }
                isLoading={
                  isUsersFetching ||
                  isFetchingTaxRegisterSettings ||
                  isVerifyingUserPIN
                }
                {...form.getInputProps("userId")}
              />

              {form.getValues().userId != null &&
                !isUserHasPinFetching &&
                !userHasPin?.hasPin && (
                  <div className="flex flex-col items-center gap-2">
                    <p>
                      <Trans>Nimate PIN kode</Trans>
                    </p>
                    <Button onPress={openCreatePinDialog} color="primary">
                      <Trans>Ustvari PIN kodo</Trans>
                    </Button>
                  </div>
                )}
              {(form.getValues().userId == null || userHasPin?.hasPin) &&
                !taxRegisterSettings?.skipAuthorizationGlobally && (
                  <div className="flex flex-col items-center gap-2">
                    <p className="font-semibold">
                      <Trans>PIN Koda</Trans>
                    </p>
                    <LimeInputOtp
                      length={4}
                      {...form.getInputProps("PIN")}
                      onComplete={() => {
                        if (form.isValid()) {
                          handleSubmit(form.values);
                        }
                      }}
                      type="password"
                      variant="bordered"
                      ref={inputRef}
                      data-identifier="pos-pin-input"
                    />
                  </div>
                )}

              <LimeAlert
                message={verifyUserPINErrorMessage}
                className="w-full"
                color="danger"
              />
              <LimeAlert
                message={taxRegisterSettingsErrorMessage}
                className="w-full"
                color="danger"
              />
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                isDisabled={isUsersFetching || isUserHasPinFetching}
                isLoading={isVerifyingUserPIN || isCheckingSetupProgress}
                color="primary"
              >
                <Trans>Potrdi</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};
