import { Input, InputProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";

export type Input = Omit<MantineFormValues, "onChange"> & InputProps;

export const LimeInput = React.forwardRef(
  ({ error, ...props }: Input, ref: React.Ref<HTMLInputElement>) => {
    if (props.type === "email" && props.isDisabled && props.value) {
      return (
        <a href={`mailto:${props.value}`} className="w-full">
          <SharedInput ref={ref} error={error} {...props} />
        </a>
      );
    }

    return <SharedInput ref={ref} error={error} {...props} />;
  },
);
LimeInput.displayName = "LimeInput";

const SharedInput = React.forwardRef(
  ({ error, ...props }: Input, ref: React.Ref<HTMLInputElement>) => {
    return (
      <Input
        ref={ref}
        errorMessage={error}
        isInvalid={!!error}
        {...props}
        classNames={{
          ...props.classNames,
          input: cn(props.classNames?.input, {
            "text-[16px]": isMobileSafari,
          }),
        }}
        inputMode={props.type === "number" ? "numeric" : props.inputMode}
      />
    );
  },
);
SharedInput.displayName = "SharedInput";
