import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getDurationsAndPrice } from "@/Utilities";
import { useLingui } from "@lingui/react";
import React from "react";
import { RiSearch2Line } from "react-icons/ri";
import styled from "styled-components";
import ServiceSelectList from "./ServiceSelectList";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";

const ServiceSelect = ({
  services,
  serviceTags,
  selectedServices,
  setSelectedServices,
}) => {
  useLingui();
  const [filter, setFilter] = React.useState("");

  const { lastSelectedUserId } = useCalendarStore((state) => state);

  const filteredServices = React.useMemo(() => {
    if (!services) return null;

    return services.filter((service) => {
      if (!service.permission) return false;
      if (service.isDeleted) return false;

      return service.name.toLowerCase().includes(filter.toLowerCase());
    });
  }, [services, filter]);

  const onSelectService = (service, isSelected) => {
    const { serviceOrder } = service;

    if (isSelected) {
      setSelectedServices(
        selectedServices
          .filter(
            (selectedService) =>
              selectedService.serviceId !== service.serviceId,
          )
          .sort(
            (a, b) =>
              (serviceOrder.indexOf(a.serviceId) || 99) -
              (serviceOrder.indexOf(b.serviceId) || 99),
          ),
      );
    } else {
      const user = service.users.find(
        (user) => user.userId === (lastSelectedUserId ?? service.users[0]),
      );
      const { startDuration, timeOffDuration, endDuration, price } =
        getDurationsAndPrice(service, user);

      const newServices = [
        ...selectedServices,
        {
          serviceId: service.serviceId,
          userId: lastSelectedUserId ?? null,
          users: lastSelectedUserId ? [lastSelectedUserId] : [],
          resources: [],
          price,
          startDuration,
          timeOffDuration,
          endDuration,
          shiftWarning: service.shiftWarning,
          overlapWarning: service.overlapWarning,
          relationWarning: service.relationWarning,
          blockBeforeMins: service.blockBeforeMins,
          blockAfterMins: service.blockAfterMins,
        },
      ].toSorted((a, b) => {
        return (
          (serviceOrder.indexOf(a.serviceId) ?? 99) -
          (serviceOrder.indexOf(b.serviceId) ?? 99)
        );
      });

      setSelectedServices(newServices);
    }
  };

  return (
    <Wrapper>
      <div className={"header"}>
        <p className="mb-4 text-sm font-medium">
          <Trans>Izberite storitev</Trans>
        </p>
        <LimeInput
          label={t`Išči storitve`}
          value={filter}
          size="sm"
          onChange={(e) => setFilter(e.target.value)}
          icon={<RiSearch2Line />}
        />
      </div>
      <ServiceSelectList
        serviceTags={serviceTags}
        services={filteredServices}
        selectedServices={selectedServices}
        onSelectService={onSelectService}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .header {
    gap: 2rem;
    padding-bottom: 1rem;

    .mantine-InputWrapper-root {
      flex: 1;
      height: 100%;
    }
  }

  .service-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export default ServiceSelect;
