import { Timeline, Text } from "@mantine/core";
import { api } from "@/lib/api-client";
import _ from "lodash";
import {
  generateLogTitle,
  generateDescription,
  generateAdditionalInfo,
} from "./logFormatters";

const AppointmentLog = ({ appointmentId }: { appointmentId: number }) => {
  const { data } = api.appointments.useAppointmentLog(appointmentId);

  const uniqueLogs = _.uniqBy(
    data?.logs.map((log) => ({
      title: generateLogTitle(log),
      description: generateDescription(log),
      additionalInfo: generateAdditionalInfo(log),
      timestamp: log.timestamp,
    })),
    (log) => `${log.title}|${log.description}|${log.timestamp}`,
  );
  return (
    <div>
      <Timeline bulletSize={12} lineWidth={2} mt="md">
        {uniqueLogs?.map((log, index) => (
          <Timeline.Item
            key={`${log.timestamp}-${index}`}
            title={log.title}
            __active={index === 0}
            lineVariant={index === 0 ? "dashed" : "solid"}
          >
            <Text size="sm" mt={8}>
              {log.description}
            </Text>
            <Text size="xs" mt={4} c="dimmed">
              {log.additionalInfo}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default AppointmentLog;
