import { IPrinterAdapter, PrinterConfig } from "./types";
import { PrintData } from "qz-tray";

export class HtmlAdapter implements IPrinterAdapter {
  constructor(private config: PrinterConfig) {}

  adapt(htmlString: string[] | string): PrintData {
    const html = Array.isArray(htmlString) ? htmlString.join("") : htmlString;

    // Wrap all HTML output in a <div> that sets the root font size
    const fontSize = this.config?.htmlRootFontSize || 12; // default fallback
    const wrappedHtml = `<div style="font-size: ${fontSize}px; max-width: 100%;">${html}</div>`;

    const pageWidth = this.config?.lineWidth || 384;

    return {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data: wrappedHtml,
      // options: {
      //   pageWidth,
      // },
    };
  }
}

/**
 * For generic ESC/POS, we return:
 *   {
 *     type: "raw",
 *     format: "command",
 *     flavor: "base64",
 *     data: base64EncodedString
 *   }
 *
 * If you have an array of ESC/POS commands, you'll typically join them
 * and then base64-encode. QZ Tray expects one base64-encoded string,
 * but you can also pass an array if you prefer.
 */
export class EscposAdapter implements IPrinterAdapter {
  adapt(commands: string[] | string): PrintData {
    // If commands is an array, join it into a single string:
    const escposData = Array.isArray(commands) ? commands.join("") : commands;
    // Base64-encode the commands:
    const base64Data = btoa(escposData);

    return {
      type: "raw",
      format: "command",
      flavor: "base64",
      data: base64Data,
    };
  }
}

export function createPrinterAdapter(config: PrinterConfig): IPrinterAdapter {
  switch (config?.printerType) {
    case "escpos_generic":
      // Both paths use the same ESC/POS approach (if that's what you intend)
      return new EscposAdapter();
    case "star_mi":
      return new HtmlAdapter(config);
    default:
      throw new Error(`Unsupported printer type: ${config?.printerType}`);
  }
}
