import { Trans } from "@lingui/react/macro";
import { Button, Drawer, Text, UnstyledButton } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import classes from "./LimeBottomDrawer.module.css";

import type { JSX } from "react";

export const LimeBottomDrawer = ({
  opened,
  onClose,
  title,
  content,
  buttons,
}: {
  opened: boolean;
  onClose: () => void;
  title: string;
  content: JSX.Element;
  buttons: {
    save: { text: string; disabled: boolean; action: () => void };
    cancel: { action: () => void };
  };
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Drawer.Root
      size={!isMobile ? 500 : "80vh"}
      position={!isMobile ? "right" : "bottom"}
      opened={opened}
      onClose={onClose}
      classNames={classes}
      className={classes.root}
    >
      <Drawer.Overlay opacity={0.35} blur={0} />
      <Drawer.Content style={{ overflowY: "scroll" }}>
        <Drawer.Header>
          <Drawer.Title className={classes.title}>
            <Text c={"#424b64"} size={"24px"} fw={600}>
              {title}
            </Text>
          </Drawer.Title>
          <Drawer.CloseButton></Drawer.CloseButton>
        </Drawer.Header>
        <Drawer.Body>{content}</Drawer.Body>
        <div className={classes.buttons}>
          <UnstyledButton onClick={buttons.cancel.action}>
            <Trans>Prekliči</Trans>
          </UnstyledButton>
          <Button
            onClick={buttons.save.action}
            disabled={buttons.save.disabled}
          >
            {buttons.save.text}
          </Button>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
};
